export const mantenimientoModel = {
  eq: null,
  hi: null,
  fa: null,
  tc: null,
  nt: null,
  tm: null,
  dt: null,
  dm: null,
  im: null,
  sr: false,
  st: false,
  ts: [],
  pc: [],
  in: [],
};
