import React from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Bloque from '../../commons/Bloque';
import CheckBox from '../../commons/CheckBox';
import TablaBody from '../../commons/TablaBody';
import Paginacion from '../../commons/Paginacion';
import { useTablaFilter } from '../../commons/Formulario';
import { formatoFecha } from '../../commons/Utilitarios';

const MantenimientoList = () => {
  const { setSelectedItem } = useTablaFilter();

  const columns = [
    {
      key: 'hi',
      align: 'center',
      content: (item) => (
        <>
          <VisibilityIcon
            onClick={() => setSelectedItem(item)}
            style={{ color: 'green', cursor: 'pointer' }}
          />
        </>
      ),
    },
    {
      key: 'eq.ne',
      label: 'Equipo',
      content: (item) => <span>{item.eq?.ne}</span>,
    },
    {
      key: 'fa',
      label: 'Fecha atención',
      align: 'right',
      content: (item) => formatoFecha(item.fa),
    },
    {
      key: 'nt',
      label: 'Técnico',
    },
    {
      key: 'dt',
      label: 'Tipo',
    },
    {
      key: 'st',
      label: 'Estado de registro',
      align: 'center',
      content: (item) => <CheckBox checked={item.st} disabled />,
    },
  ];

  return (
    <>
      <Bloque titulo="Mantenimiento de equipos">
        <div className="op-table">
          <TablaBody columns={columns} indice={1} />
          <Paginacion />
        </div>
      </Bloque>
    </>
  );
};

export default MantenimientoList;
