import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Combo from '../../commons/Combo';
import Boton from '../../commons/Boton';
import CheckBox from '../../commons/CheckBox';
import { confirmacion } from '../../commons/Mensajes';

import {
  closeModal,
  postAlmacenAction,
} from '../../redux/actions/almacen.action';

import SaveIcon from '@material-ui/icons/Save';

const AlmacenItem = ({
  selected,
  processing,
  errors,
  closeModal,
  sucursales,
  tipoAlmacenes,
  postAlmacenAction,
}) => {
  const [almacen, setAlmacen] = useState(selected);

  useEffect(() => {
    let searchTipo = tipoAlmacenes.find((s) => s.de === selected.dt);
    if (searchTipo) {
      handleChange({ name: 'ta', value: searchTipo.dt });
    }

    let sucursal = sucursales.find((s) => s.ns === selected.ns);
    if (sucursal) {
      handleChange({ name: 'su', value: sucursal.su });
    }
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setAlmacen((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Modal
      title={`${almacen.al ? 'Actualizar' : 'Nuevo'} almacen`}
      processing={processing}
      size="small"
      onActions={() => (
        <Boton
          className="op-grabar"
          disabled={processing}
          icon={() => <SaveIcon />}
          tabIndex={21}
          onClick={() =>
            confirmacion(
              '¿Desea guardar?',
              `${almacen.ca ? 'Actualizar' : 'Un nuevo'} almacen`
            )
              .then(() => postAlmacenAction(almacen))
              .catch(() => {})
          }
        >
          Guardar
        </Boton>
      )}
      onClose={() => closeModal()}
    >
      <Combo
        name="su"
        label="Sucursal"
        value={almacen.su}
        tabIndex={11}
        data={sucursales.map((i) => ({ value: i.su, label: i.ns }))}
        error={errors.su}
        onChange={handleChange}
      />
      <Texto
        name="na"
        value={almacen.na}
        label="Nombre"
        tabIndex={10}
        error={errors.na}
        onChange={handleChange}
        autoFocus
      />
      <Combo
        name="ta"
        label="Tipo almacen"
        value={almacen.ta}
        tabIndex={12}
        data={tipoAlmacenes.map((i) => ({ value: i.dt, label: i.de }))}
        error={errors.ta}
        onChange={handleChange}
      />
      {almacen.al && (
        <div className="op-form-group op-col-1 op-checkbox-group">
          <span>Estado del registro</span>
          <CheckBox name="st" checked={almacen.st} onChange={handleChange} />
        </div>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.almacen.selected,
    processing: state.almacen.processing,
    errors: state.almacen.errors,
    tipoAlmacenes: state.detalle.tipoAlmacenes,
    sucursales: state.sucursal.sucursales,
  };
};

export default connect(mapStateToProps, { closeModal, postAlmacenAction })(
  AlmacenItem
);
