import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';

import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import TodayIcon from '@material-ui/icons/Today';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import PersonIcon from '@material-ui/icons/Person';
import StateIcon from '../assets/icons/state.svg';

import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import DateFnsUtils from '@date-io/moment';

import Icon from './Icon';
import { formatoFecha } from './Utilitarios';
import CalendarPage from '../assets/icons/calendar-page.svg';
import '../css/commons/formulario.css';
import '../css/commons/modal.css';
import '../css/commons/calendar.css';

const Calendario = ({
  name,
  label,
  tabIndex,
  size,
  value,
  onSelected,
  onSelectedMonth,
  minDate,
  maxDate,
  disableWeekend,
  showMini,
  showTime,
  showWeek,
  showInline,
  largeNames,
  closeOnSelect,
  closeOnSelectTime,
  events,
  tasks,
  className,
  error,
  ...rest
}) => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [selectedDate, setSelectedDate] = useState({
    d: value ? new Date(value) : new Date(),
  });
  const [days, setDays] = useState([]);
  const [open, setOpen] = useState(false);
  const [openMonths, setOpenMonths] = useState(false);
  const [openEvents, setOpenEvents] = useState(false);
  const [tarea, setTarea] = useState(null);
  const [numberOfWeeks, setNumberOfWeeks] = useState(1);
  const [selectedWeek, setSelectedWeek] = useState(1);
  const [selectedLastWeek, setSelectedLastWeek] = useState(false);
  const [selectedCurrentWeek, setSelectedCurrentWeek] = useState(true);
  const [superior, setSuperior] = useState(20);

  let meses = [
    'ENERO',
    'FEBRERO',
    'MARZO',
    'ABRIL',
    'MAYO',
    'JUNIO',
    'JULIO',
    'AGOSTO',
    'SETIEMBRE',
    'OCTUBRE',
    'NOVIEMBRE',
    'DICIEMBRE',
  ];

  let dias = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];

  let corto = ['DOM', 'LUN', 'MAR', 'MIÉ', 'JUE', 'VIE', 'SÁB'];

  const defaultMaterialTheme = createMuiTheme({
    palette: {
      primary: { main: '#3498db', contrastText: '#fff' },
    },
    overrides: {
      MuiInputBase: {
        input: {
          color: '#fff',
        },
      },
    },
  });

  const handleSelectedMonth = (month) => {
    setCurrentMonth(month);
    setDays([]);
    createCalendar(new Date(currentYear, month, 1));
    if (onSelectedMonth) {
      onSelectedMonth({ month, year: currentYear });
    }
  };

  const handleSelectedYear = (direccion) => {
    setCurrentYear(currentYear + direccion);
    setDays([]);
    createCalendar(new Date(currentYear + direccion, currentMonth, 1));
  };

  const handleSelectedWeek = (direction) => {
    if (selectedWeek === 1 && direction === -1) {
      let cm = currentMonth - 1;
      if (cm >= 0) {
        setCurrentMonth(cm);
      } else {
        setCurrentMonth(11);
        setCurrentYear(currentYear + direction);
      }
      setSelectedLastWeek(true);
      setSelectedCurrentWeek(false);
      onSelected({
        name,
        value: new Date(currentYear - (cm >= 0 ? 0 : 1), cm, 1),
      });
    } else if (selectedWeek === numberOfWeeks && direction === 1) {
      let cm = currentMonth + 1;
      if (cm <= 11) {
        setCurrentMonth(cm);
      } else {
        setCurrentMonth(0);
        setCurrentYear(currentYear + direction);
      }
      setSelectedLastWeek(false);
      setSelectedCurrentWeek(false);
      onSelected({
        name,
        value: new Date(currentYear + (cm <= 11 ? 0 : 1), cm, 1),
      });
    } else {
      setSelectedLastWeek(false);
      setSelectedWeek(selectedWeek + direction);
    }
  };

  const createCalendar = (current) => {
    let firstDay = new Date(current.getFullYear(), current.getMonth(), 1);
    let lastDay = new Date(current.getFullYear(), current.getMonth() + 1, 0);
    let weeksOfMonth = 0;
    let week = 1;
    setSelectedWeek(1);
    let beforeDays = firstDay.getDay();
    let lastDayBefore = new Date(
      firstDay.getFullYear(),
      firstDay.getMonth(),
      0
    );
    lastDayBefore.setDate(lastDayBefore.getDate() - beforeDays + 1);

    for (let i = lastDayBefore.getDate(); beforeDays > 0; beforeDays--, i++) {
      const fecha = new Date(
        lastDayBefore.getFullYear(),
        lastDayBefore.getMonth(),
        i,
        selectedDate.d.getHours(),
        selectedDate.d.getMinutes()
      );
      let item = {
        day: i.toString(),
        month: lastDayBefore.getMonth(),
        date: fecha,
        isEnable: showWeek,
        isSelected: showWeek,
        isCurrent: false,
        week: week,
        events: [],
      };
      if (showWeek === undefined) setDays((days) => [...days, item]);
    }

    for (let i = firstDay.getDate(); i <= lastDay.getDate(); i++) {
      week = Math.trunc(weeksOfMonth / 7) + 1;
      const fecha = new Date(
        firstDay.getFullYear(),
        firstDay.getMonth(),
        i,
        selectedDate.d.getHours(),
        selectedDate.d.getMinutes()
      );
      let selected = false;
      let enable = false;

      if (
        fecha.getFullYear() === selectedDate.d.getFullYear() &&
        fecha.getMonth() === selectedDate.d.getMonth() &&
        i === selectedDate.d.getDate()
      ) {
        selected = true;
      }

      if (minDate && maxDate) {
        let minimo = new Date(minDate);
        minimo.setHours(0, 0, 0);
        let maximo = new Date(maxDate);
        maximo.setHours(23, 59, 0);

        if (
          minimo.getTime() <= fecha.getTime() &&
          fecha.getTime() <= maximo.getTime()
        ) {
          enable = true;
        }
      } else if (minDate) {
        let minimo = new Date(minDate);
        minimo.setHours(0, 0, 0);
        if (fecha.getTime() >= minimo.getTime()) {
          enable = true;
        }
      } else if (maxDate) {
        let maximo = new Date(maxDate);
        maximo.setHours(23, 59, 0);
        if (fecha.getTime() <= maximo.getTime()) {
          enable = true;
        }
      } else {
        enable = true;
      }

      if (
        disableWeekend === true &&
        (fecha.getDay() === 0 || fecha.getDay() === 6)
      ) {
        enable = false;
      }

      if (
        selected === true &&
        enable === true &&
        value &&
        showWeek === undefined
      ) {
        onSelected({ name, value: fecha });
      }

      let dayEvents = events?.filter(
        (f) =>
          f.datetime.getFullYear() === fecha.getFullYear() &&
          f.datetime.getMonth() === fecha.getMonth() &&
          f.datetime.getDate() === fecha.getDate()
      );

      let item = {
        day: i < 10 ? '0' + i : i.toString(),
        date: fecha,
        month: firstDay.getMonth(),
        isEnable: enable,
        isSelected: selected,
        isCurrent: true,
        week: week,
        events: dayEvents,
      };

      setDays((days) => [...days, item]);

      if (
        fecha.getFullYear() === selectedDate.d.getFullYear() &&
        fecha.getMonth() === selectedDate.d.getMonth() &&
        fecha.getDate() === selectedDate.d.getDate() &&
        showWeek &&
        selectedCurrentWeek
      ) {
        setSelectedWeek(week);
      }
      weeksOfMonth++;
    }

    let afterDays = lastDay.getDay();
    const firstDayAfter = new Date(
      lastDay.getFullYear(),
      lastDay.getMonth() + 1,
      1
    );

    for (let i = firstDayAfter.getDate(); afterDays < 6; afterDays++, i++) {
      const fecha = new Date(
        firstDayAfter.getFullYear(),
        firstDayAfter.getMonth(),
        i,
        selectedDate.d.getHours(),
        selectedDate.d.getMinutes()
      );
      let item = {
        day: i < 10 ? '0' + i : i.toString(),
        date: fecha,
        month: firstDayAfter.getMonth(),
        isEnable: showWeek,
        isSelected: showWeek,
        isCurrent: false,
        week: week,
        events: [],
      };

      if (showWeek === undefined) setDays((days) => [...days, item]);
    }

    setNumberOfWeeks(weeksOfMonth / 7);
  };

  const padLef = (n) => {
    return ('00' + n).slice(-2);
  };

  const getTime = (t) => {
    if (t < 12) {
      return `${padLef(t)}am`;
    } else if (t === 12) {
      return `${padLef(t)}pm`;
    } else if (t === 24) {
      return `00am`;
    } else {
      return `${padLef(t - 12)}pm`;
    }
  };

  const getStringTime = (h, m) => {
    let t = h >= 12 ? 'PM' : 'AM';
    if (h > 12 && h < 23) {
      h = h - 12;
    } else if (h === 23) {
      h = 0;
    }
    return `${padLef(h)}:${padLef(m)} ${t}`;
  };

  useEffect(() => {
    createCalendar(selectedDate.d);
  }, []);

  useEffect(() => {
    setDays([]);
    createCalendar(new Date(currentYear, currentMonth, 1));
  }, [events]);

  const calendarDiseign = () => (
    <div
      className={`op-content-calendar${openMonths ? ' on' : ''}${
        showInline ? ' inline' : ''
      }${showWeek ? ' week' : ''}${openEvents ? ' openEvent' : ''}`}
      {...rest}
    >
      <div className="content-months">
        <div className="content-year">
          <ArrowBackIosIcon
            onClick={() => handleSelectedYear(-1)}
            style={{ cursor: 'pointer' }}
          />
          {currentYear}
          <ArrowForwardIosIcon
            onClick={() => handleSelectedYear(1)}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <div className="months">
          {meses.map((m, i) => {
            return (
              <div
                key={i}
                className={`${currentMonth === i ? 'selected' : ''}`}
                onClick={() => {
                  handleSelectedMonth(i);
                  setOpenMonths(false);
                }}
              >
                {m}
              </div>
            );
          })}
        </div>
      </div>
      <div className="content-main">
        <div className="calendar-icon">
          <Icon
            svg={CalendarPage}
            color="#00adb5"
            size={30}
            onClick={() => {
              setOpenMonths(true);
            }}
          />
        </div>
        <div className="calendar-icon event">
          {showWeek ? (
            <>
              {/* <div title="Semana anterior">
                <ArrowBackIosIcon
                  onClick={() => handleSelectedWeek(-1)}
                  style={{ cursor: 'pointer' }}
                />
              </div>
              <div title="Pr&oacute;xima semana">
                <ArrowForwardIosIcon
                  onClick={() => handleSelectedWeek(1)}
                  style={{ cursor: 'pointer' }}
                />
              </div> */}
            </>
          ) : (
            <ArrowBackIosIcon
              style={{ color: '#00adb5', size: '30px' }}
              onClick={() => setOpenEvents(true)}
            />
          )}
        </div>
        <div className="content-month">
          {meses.map((m, i) => {
            return (
              <div key={i} className={`${currentMonth === i ? 'on' : 'off'}`}>
                {m}
              </div>
            );
          })}
        </div>
        <div className="content-week">
          {showInline && showWeek ? (
            <>
              {days?.map((d) => (
                <div
                  key={`${d.day}-${d.month}`}
                  className={`day${d.isCurrent ? ' current' : ''}`}
                >
                  <input
                    type="radio"
                    id={`${d.day}-${d.month}-${tabIndex}`}
                    defaultChecked={
                      d.date.getFullYear() === selectedDate.d.getFullYear() &&
                      d.date.getMonth() === selectedDate.d.getMonth() &&
                      d.date.getDate() === selectedDate.d.getDate()
                    }
                    name={`calendar-op-${tabIndex}`}
                  />
                  <label
                    htmlFor={`${d.day}-${d.month}-${tabIndex}`}
                    className={`day-week${d.isEnable ? ' enabled' : ''}`}
                    onClick={(e) => {
                      setSelectedDate((p) => ({ ...p, d: d.date }));
                      onSelected({ name, value: d.date });
                      setTarea(null);
                      if (closeOnSelect) {
                        setOpen(false);
                      }
                    }}
                  >
                    <span>{`${dias[d.date.getDay()]}, ${d.day}`}</span>
                  </label>
                  <div className="content-tasks">
                    {events
                      ?.filter(
                        (f) =>
                          f.datetime.getFullYear() === d.date.getFullYear() &&
                          f.datetime.getMonth() === d.date.getMonth() &&
                          f.datetime.getDate() === d.date.getDate()
                      )
                      .map((e, i) => (
                        <React.Fragment key={i}>
                          <div
                            className="task"
                            style={{
                              backgroundColor: `#808080`,
                            }}
                          ></div>
                        </React.Fragment>
                      ))}
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              <div>{`${showInline && largeNames ? 'Domingo' : 'Do'}`}</div>
              <div>{`${showInline && largeNames ? 'Lunes' : 'Lu'}`}</div>
              <div>{`${showInline && largeNames ? 'Martes' : 'Ma'}`}</div>
              <div>{`${showInline && largeNames ? 'Miércoles' : 'Mi'}`}</div>
              <div>{`${showInline && largeNames ? 'Jueves' : 'Ju'}`}</div>
              <div>{`${showInline && largeNames ? 'Viernes' : 'Vi'}`}</div>
              <div>{`${showInline && largeNames ? 'Sábado' : 'Sa'}`}</div>
            </>
          )}
        </div>
        {showInline && showWeek ? (
          <>
            <div className="content-times"></div>
            <div className="content-hours">
              <div className="content-day">
                {dias.map((m, i) => {
                  return (
                    <div
                      key={i}
                      className={`${
                        selectedDate.d.getDay() === i ? 'on' : 'off'
                      }`}
                    >
                      {`${m}, ${selectedDate.d.getDate()}`}
                    </div>
                  );
                })}
              </div>
              {events
                ?.filter(
                  (f) =>
                    f.datetime.getFullYear() === selectedDate.d.getFullYear() &&
                    f.datetime.getMonth() === selectedDate.d.getMonth() &&
                    f.datetime.getDate() === selectedDate.d.getDate()
                )
                .map((e) => (
                  <div key={e.key} className="day-hour-event">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      <AccessAlarmIcon
                        style={{
                          fontSize: 14,
                          color: '#00adb5',
                          marginRight: '5px',
                        }}
                      />
                      {getStringTime(
                        e.datetime.getHours(),
                        e.datetime.getMinutes()
                      )}
                    </div>
                    {e.messages.map((m, i) => (
                      <span key={i}>{m}</span>
                    ))}
                  </div>
                ))}
            </div>
          </>
        ) : (
          <div className="content-days">
            {days.map((d) => {
              return (
                <div
                  key={`${d.day}-${d.month}`}
                  className={`day${d.isCurrent ? ' current' : ''}`}
                >
                  {d.isEnable ? (
                    <>
                      <input
                        type="radio"
                        id={`${d.day}-${d.month}-${tabIndex}`}
                        defaultChecked={
                          d.date.getFullYear() ===
                            selectedDate.d.getFullYear() &&
                          d.date.getMonth() === selectedDate.d.getMonth() &&
                          d.date.getDate() === selectedDate.d.getDate()
                        }
                        name={`calendar-op-${tabIndex}`}
                      />
                      <label
                        htmlFor={`${d.day}-${d.month}-${tabIndex}`}
                        className={`${showInline ? 'label-task' : 'label-day'}${
                          d.isEnable ? ' enabled' : ''
                        }`}
                        onClick={(e) => {
                          setSelectedDate((p) => ({ ...p, d: d.date }));
                          onSelected({ name, value: d.date });
                          setTarea(null);
                          if (closeOnSelect) {
                            setOpen(false);
                          }
                        }}
                      >
                        {showInline ? (
                          <>
                            <span className="dia">{d.day}</span>
                            <div className="content-tasks">
                              {events
                                ?.filter(
                                  (f) =>
                                    f.datetime.getFullYear() ===
                                      d.date.getFullYear() &&
                                    f.datetime.getMonth() ===
                                      d.date.getMonth() &&
                                    f.datetime.getDate() === d.date.getDate()
                                )
                                .map((e, i) => (
                                  <React.Fragment key={i}>
                                    <div
                                      className="task"
                                      style={{
                                        backgroundColor: `#808080`,
                                      }}
                                    ></div>
                                  </React.Fragment>
                                ))}
                              {tasks
                                ?.filter(
                                  (f) =>
                                    d.date.getFullYear() ===
                                      f.fa.getFullYear() &&
                                    d.date.getMonth() === f.fa.getMonth() &&
                                    d.date.getDate() === f.fa.getDate()
                                )
                                .map((t, i) => (
                                  <div
                                    key={i}
                                    className="task"
                                    style={{ backgroundColor: `${t.co}` }}
                                  ></div>
                                ))}
                            </div>
                          </>
                        ) : (
                          d.day
                        )}
                      </label>
                    </>
                  ) : (
                    <label>{d.day}</label>
                  )}
                </div>
              );
            })}
          </div>
        )}
        {showInline === false && (
          <div className="content-footer">
            {showTime ? (
              <div className="content-time">
                <AccessAlarmIcon style={{ marginRight: '5px' }} />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <ThemeProvider theme={defaultMaterialTheme}>
                    <TimePicker
                      cancelLabel={'Cancelar'}
                      okLabel={'Ok'}
                      showTodayButton
                      todayLabel={'Ahora'}
                      value={selectedDate.d}
                      minutesStep={5}
                      onChange={(item) => {
                        onSelected({ name, value: item._d });
                        setSelectedDate((p) => ({ ...p, d: item._d }));
                        if (closeOnSelectTime) {
                          setOpen(false);
                        }
                      }}
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
              </div>
            ) : (
              <div></div>
            )}
            <button
              className="op-boton"
              type="button"
              onClick={() => setOpen(false)}
            >
              Cerrar
            </button>
          </div>
        )}
      </div>
      {showInline && showWeek === false && (
        <div className="content-events">
          <div className="calendar-icon event-close">
            <ArrowForwardIosIcon
              style={{ color: '#00adb5', size: '30px' }}
              onClick={() => setOpenEvents(false)}
            />
          </div>
          <div className="content-day">
            {dias.map((m, i) => {
              return (
                <div
                  key={i}
                  className={`${selectedDate.d.getDay() === i ? 'on' : 'off'}`}
                >
                  {`${m}, ${selectedDate.d.getDate()}`}
                </div>
              );
            })}
          </div>
          <div className="events">
            {events
              ?.filter(
                (f) =>
                  f.datetime.getFullYear() === selectedDate.d.getFullYear() &&
                  f.datetime.getMonth() === selectedDate.d.getMonth() &&
                  f.datetime.getDate() === selectedDate.d.getDate()
              )
              .map((e) => (
                <div key={e.key} className="day-hour-event">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <AccessAlarmIcon
                      style={{
                        fontSize: 14,
                        color: '#00adb5',
                        marginRight: '5px',
                      }}
                    />
                    {getStringTime(
                      e.datetime.getHours(),
                      e.datetime.getMinutes()
                    )}
                  </div>
                  {e.messages.map((m, i) => (
                    <span key={i}>{m}</span>
                  ))}
                </div>
              ))}
            {tasks
              ?.filter(
                (f) =>
                  selectedDate.d.getFullYear() === f.fa.getFullYear() &&
                  selectedDate.d.getMonth() === f.fa.getMonth() &&
                  selectedDate.d.getDate() === f.fa.getDate()
              )
              .map((t, i) => (
                <div
                  key={i}
                  className={`task ${
                    tarea && tarea.ta === t.ta ? 'selected' : ''
                  }`}
                  onClick={(e) => {
                    setTarea(t);
                    setSuperior(e.target.offsetTop - 30);
                  }}
                >
                  <span style={{ backgroundColor: `${t.co}` }}></span>
                  <span>{`${padLef(t.fa.getHours())}:${padLef(
                    t.fa.getMinutes()
                  )} `}</span>
                  <span
                    title={`${
                      t.ve === 'ACTCOM'
                        ? 'completado'
                        : t.ve === 'REGDOC'
                        ? ''
                        : 'anulado'
                    }`}
                  >
                    {t.dt}
                  </span>
                  <span
                    className={`${
                      t.ve === 'ACTCOM'
                        ? 'completo'
                        : t.ve === 'REGDOC'
                        ? ''
                        : 'anulado'
                    }`}
                  ></span>
                </div>
              ))}
          </div>
          {tarea && (
            <div className="content-task" style={{ top: `${superior}px` }}>
              <span className="close" onClick={() => setTarea(null)}></span>
              <label
                className="title"
                style={{ borderBottom: `1px solid ${tarea.co}` }}
              >
                Actividad
              </label>
              <div className="info">
                <span>
                  <AccessTimeIcon />
                </span>
                <span>{`${formatoFecha(tarea.fa)}`}</span>
              </div>
              <div className="info">
                <span>
                  <PersonIcon />
                </span>
                <span>{`${tarea.nu} (Responsable)`}</span>
              </div>
              <div className="info">
                <span>
                  <CalendarTodayIcon />
                </span>
                <span>Calendario</span>
              </div>
              <div className="info">
                <span>
                  <FormatAlignLeftIcon />
                </span>
                <span>{tarea.dt}</span>
              </div>
              <div className="separador"></div>
              <div className="info">
                <span>
                  <PersonIcon />
                </span>
                <span>{`${tarea.na} (Aprobador)`}</span>
              </div>
              <div className="info">
                <span>
                  <Icon svg={StateIcon} color="#222831" />
                </span>
                <span>{tarea.ds}</span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );

  const handleType = () => {
    if (showMini) {
      return (
        <div className={`op-content-calendar mini`}>
          <div className="content-mini-month">
            {meses.map((m, i) => {
              return (
                <div
                  key={i}
                  className={`${
                    selectedDate.d.getMonth() === i ? 'on' : 'off'
                  }`}
                >
                  {`${m}, ${selectedDate.d.getFullYear()}`}
                </div>
              );
            })}
          </div>
          <div className="content-mini-date">
            <CountUp end={selectedDate.d.getDate()} duration={1} />
          </div>
          <div className={`content-mini-day ${showTime ? 'time' : ''}`}>
            {dias.map((m, i) => {
              return (
                <div
                  key={i}
                  className={`${selectedDate.d.getDay() === i ? 'on' : 'off'}`}
                >
                  {m}
                </div>
              );
            })}
          </div>
          {showTime && (
            <div className="content-mini-time">
              {`${selectedDate.d.getHours()}:${selectedDate.d.getMinutes()}`}
            </div>
          )}
        </div>
      );
    } else if (showInline) {
      return <>{calendarDiseign()}</>;
    } else {
      return (
        <div
          className={`op-form-group${size ? ' op-col-' + size : ''}
  ${error ? ' error' : ''} op-calendar`}
          {...rest}
        >
          <input
            name={name}
            className={`op-form${className ? ' ' + className : ''}`}
            value={value == null ? '' : formatoFecha(value, '', showTime)}
            tabIndex={tabIndex}
            required="required"
            type={'text'}
            onChange={() => {}}
            onPaste={(e) => e.preventDefault()}
            onClick={() => setOpen(true)}
          />
          <label>{label}</label>
          <TodayIcon
            fontSize="large"
            style={{ cursor: 'pointer' }}
            onClick={() => setOpen(true)}
          />
          <span className="bar bottom"></span>
          <span className="bar left"></span>
          <span className="bar right"></span>
          <span className="bar top"></span>
          {error && <span className="op-form-error">{error}</span>}
        </div>
      );
    }
  };

  return (
    <>
      <>{handleType()}</>
      {open && (
        <div className="op-content-modal">
          <div className="op-modal op-calendar">
            <div className="op-modal-body">{calendarDiseign()}</div>
          </div>
        </div>
      )}
    </>
  );
};

Calendario.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  tabIndex: PropTypes.number,
  onSelected: PropTypes.func.isRequired,
  onSelectedMonth: PropTypes.func,
  size: PropTypes.oneOf([2, 3, 4]),
  value: PropTypes.any,
  className: PropTypes.string,
  error: PropTypes.string,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  disableWeekend: PropTypes.bool,
  showTime: PropTypes.bool,
  showMini: PropTypes.bool,
  showInline: PropTypes.bool,
  showWeek: PropTypes.bool,
  largeNames: PropTypes.bool,
  closeOnSelect: PropTypes.bool,
  closeOnSelectTime: PropTypes.bool,
  tasks: PropTypes.array,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      datetime: PropTypes.instanceOf(Date).isRequired,
      messages: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ),
};

Calendario.defaultProps = {
  showMini: false,
  disableWeekend: false,
  showTime: false,
  showInline: false,
  showWeek: false,
  tabIndex: 0,
  events: [],
};

export default Calendario;
