import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Boton from '../../commons/Boton';
import Tabla from '../../commons/Tabla';
import Combo from '../../commons/Combo';
import Calendario from '../../commons/Calendario';
import Wizard from '../../commons/Wizard';
import { confirmacion, mensajeMini } from '../../commons/Mensajes';

import InventarioList from '../inventario/InventarioList';
import ConductorList from '../conductor/ConductorList';
import Ubigueo from '../ubigueo/Ubigueo';

import { guiaModel } from '../../redux/models/guia.model';

import ProductMoveIcon from '../../assets/icons/move-product.svg';
import CardInfoIcon from '../../assets/icons/card-info.svg';
import GpsMapIcon from '../../assets/icons/gps-map.svg';
import TransportCarIcon from '../../assets/icons/transport-car.svg';
import PreviewIcon from '../../assets/icons/preview.svg';

import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import EditIcon from '@material-ui/icons/Edit';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import {
  closeModal,
  actualizarFiltros,
  getInventariosAction,
} from '../../redux/actions/inventario.action';

import { getConductoresAction } from '../../redux/actions/conductor.action';

import {
  postGenerarGuiaAction,
  putRectificarGuiaAction,
  closeModal as closeGuiaModal,
} from '../../redux/actions/guia.action';
import '../../css/components/guia.css';
import '../../css/ordencompra.css';
import { formatoFecha } from '../../commons/Utilitarios';

const GuiaNew = () => {
  const [other, setOther] = useState(false);
  const [open, setOpen] = useState(false);
  const [openConductor, setOpenConductor] = useState(false);
  const [bienes, setBienes] = useState([]);
  const [openUbPartida, setOpenUbPartida] = useState(false);
  const [openUbLlegada, setOpenUbLlegada] = useState(false);

  const dispatch = useDispatch();
  const { selected, processing, errors } = useSelector((state) => state.guia);
  const [documento] = useState(selected);
  const [guia, setGuia] = useState(selected.dc ? selected.gui : guiaModel);
  const { tipoDocumentoIdentidad, tiposTraslados } = useSelector(
    (state) => state.detalle
  );
  const datos = useSelector((state) => state.app.currentUser);
  const { sucursales } = useSelector((state) => state.sucursal);

  useEffect(() => {
    let principal = sucursales.find((s) => s.sp === 'S');
    if (principal) {
      handleChange({ name: 'dr', value: principal.dc });
    }

    if (guia.dtl) {
      guia.dtl.forEach((d) => {
        handleAgregarBienEditado(d);
      });
    }

    return () => {
      dispatch(getInventariosAction({}));
    };
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setGuia((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const columns = [
    {
      key: 'mt',
      align: 'center',
      content: (item) => (
        <div title="Eliminar" style={{ display: 'inline-block' }}>
          <DeleteIcon
            onClick={() =>
              confirmacion('¿Desea eliminar?', `${item.nm} de la lista`)
                .then(() => handleEliminarBien(item.mt))
                .catch(() => {})
            }
            style={{ color: 'red', cursor: 'pointer' }}
          />
        </div>
      ),
    },
    {
      key: 'cm',
      label: 'Código',
    },
    {
      key: 'nm',
      label: 'Bien',
    },
    {
      key: 'um',
      label: 'Unidad medida',
    },
    {
      key: 'un',
      align: 'center',
      label: 'Cantidad',
      content: (item) => (
        <div className="op-centrar-componentes">
          <AddIcon
            style={{ color: 'green', cursor: 'pointer' }}
            onClick={() => handleCantidadBienes(item.mt, 1)}
          />
          <div className={`cantidad${item.edit ? ' edit' : ''}`}>
            <span onClick={() => handleHabilitarEdicion(item.mt, !item.edit)}>
              {item.un}
            </span>
            <input
              type="text"
              value={item.un}
              onChange={(e) =>
                handleEditarCantidad(item.mt, 'un', e.target.value)
              }
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleHabilitarEdicion(item.mt, !item.edit);
                } else {
                  let re = new RegExp('[0-9]');
                  if (!re.test(e.key)) {
                    e.preventDefault();
                  }
                }
              }}
              onPaste={(e) => e.preventDefault()}
              onFocus={(e) => e.target.select()}
            />
            <div className="edicion">
              <EditIcon
                style={{ fontSize: '15px' }}
                onClick={() => handleHabilitarEdicion(item.mt, !item.edit)}
              />
            </div>
          </div>
          <RemoveIcon
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={() => handleCantidadBienes(item.mt, -1)}
          />
        </div>
      ),
    },
    {
      key: 'pb',
      align: 'center',
      label: 'Peso',
      content: (item) => (
        <div className="op-centrar-componentes">
          <div className={`cantidad${item.edit ? ' edit' : ''}`}>
            <span onClick={() => handleHabilitarEdicion(item.mt, !item.edit)}>
              {item.pb}
            </span>
            <input
              type="text"
              value={item.pb}
              onChange={(e) =>
                handleEditarCantidad(item.mt, 'pb', e.target.value)
              }
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleHabilitarEdicion(item.mt, !item.edit);
                } else {
                  let re = new RegExp('[0-9.]');
                  if (!re.test(e.key)) {
                    e.preventDefault();
                  }
                }
              }}
              onPaste={(e) => e.preventDefault()}
              onFocus={(e) => e.target.select()}
            />
            <div className="edicion">
              <EditIcon
                style={{ fontSize: '15px' }}
                onClick={() => handleHabilitarEdicion(item.mt, !item.edit)}
              />
            </div>
          </div>
        </div>
      ),
    },
  ];

  const columnsPreview = [
    {
      key: 'cm',
      label: 'Código',
    },
    {
      key: 'nm',
      label: 'Bien',
    },
    {
      key: 'um',
      label: 'Unidad medida',
    },
    {
      key: 'un',
      align: 'center',
      label: 'Cantidad',
    },
    {
      key: 'pb',
      align: 'center',
      label: 'Peso',
    },
  ];

  const handleAgregarBienEditado = (item) => {
    let existe = bienes.find((x) => x.mt === item.mt);
    if (existe === undefined) {
      item.edit = false;
      setBienes([...bienes, item]);
    }
  };

  const handleAgregarBien = (item) => {
    let existe = bienes.find((x) => x.mt === item.mt);
    if (existe === undefined) {
      item.un = 1;
      item.edit = false;
      item.pb = 1;
      item.st = true;
      setBienes([...bienes, item]);
    }
  };

  const handleEliminarBien = (mt) => {
    const currents = bienes.filter((x) => x.mt !== mt);
    setBienes(currents);
  };

  const handleCantidadBienes = (mt, valor) => {
    setBienes(
      bienes.map((x) =>
        x.mt === mt
          ? {
              ...x,
              un:
                valor === -1 && x.ct <= 1
                  ? 1
                  : parseInt(x.un ? x.un : 1) + valor,
            }
          : x
      )
    );
  };

  const handleHabilitarEdicion = (mt, valor) => {
    setBienes(bienes.map((x) => (x.mt === mt ? { ...x, edit: valor } : x)));
  };

  const handleEditarCantidad = (mt, name, valor) => {
    setBienes(
      bienes.map((x) =>
        x.mt === mt
          ? {
              ...x,
              [name]: valor <= 0 ? 1 : valor,
            }
          : x
      )
    );
  };

  return (
    <>
      <Modal
        title={`${documento.dc ? 'Rectificar' : 'Registrar'} guía`}
        processing={processing}
        loading="files"
        onClose={() => {
          dispatch(closeModal());
          dispatch(closeGuiaModal());
        }}
      >
        <Wizard>
          <Wizard.Header
            data={[
              { label: 'Datos', icon: CardInfoIcon },
              { label: 'Ubicación', icon: GpsMapIcon },
              { label: 'Bienes', icon: ProductMoveIcon },
              { label: 'Transporte', icon: TransportCarIcon },
              { label: 'Preliminar', icon: PreviewIcon },
            ]}
          />
          <Wizard.Pages className="op-wizard__content op-form">
            <>
              <Texto
                name="dr"
                value={guia.dr}
                label="Domicilio fiscal remitente"
                tabIndex={9}
                error={errors.dr}
                onChange={handleChange}
                info="La direcci&oacute;n es tomada de la sucursal principal"
              />
              <Combo
                name="tt"
                value={guia.tt}
                label="Tipo de transporte"
                size={2}
                tabIndex={10}
                data={[
                  { value: '0', label: 'Privado' },
                  { value: '1', label: 'Público' },
                ]}
                onChange={handleChange}
              />
              <Combo
                name="mt"
                value={guia.mt}
                label="Motivo de traslado"
                size={2}
                tabIndex={11}
                data={tiposTraslados.map((i) => ({
                  value: i.dt,
                  label: i.de,
                }))}
                onChange={(item) => {
                  handleChange(item);
                  setOther(item.label === 'Otras');
                }}
              />
              {other && (
                <Texto
                  name="do"
                  value={guia.do}
                  label="Descripci&oacute;n de otros"
                  tabIndex={12}
                  error={errors.do}
                  onChange={handleChange}
                />
              )}
              <Combo
                name="td"
                value={guia.td}
                label="Tipo documento destinatario"
                size={4}
                tabIndex={13}
                data={tipoDocumentoIdentidad.map((i) => ({
                  value: i.dt,
                  label: i.de,
                }))}
                onChange={handleChange}
              />
              <Texto
                name="nt"
                value={guia.nt}
                label="N&uacute;mero de documento"
                size={4}
                tabIndex={14}
                error={errors.nt}
                onChange={handleChange}
              />
              <Texto
                name="dd"
                value={guia.dd}
                label="Destinatario"
                size={2}
                tabIndex={15}
                error={errors.dd}
                onChange={handleChange}
              />
            </>
            <>
              <Combo
                name="sp"
                label="Sucursal de partida"
                value={guia.sp}
                tabIndex={22}
                size={2}
                data={sucursales.map((i) => ({
                  value: i.su,
                  label: i.ns,
                }))}
                onChange={(item) => {
                  handleChange(item);
                  if (item.value) {
                    let partida = sucursales.find((s) => s.su === item.value);
                    if (partida) {
                      handleChange({ name: 'up', value: partida.ub });
                      handleChange({
                        name: 'ups',
                        value: `${partida.cd}${partida.cp}${partida.ci} - ${partida.du}`,
                      });
                      handleChange({ name: 'ip', value: partida.dc });
                    }
                  }
                }}
              />
              {guia.mt &&
              tiposTraslados.find((t) => t.dt === guia.mt).vm === 'TRAMTT' ? (
                <Combo
                  name="sl"
                  label="Sucursal de llegada"
                  value={guia.sl}
                  tabIndex={22}
                  size={2}
                  data={sucursales.map((i) => ({
                    value: i.su,
                    label: i.ns,
                  }))}
                  onChange={(item) => {
                    handleChange(item);
                    if (item.value) {
                      let destino = sucursales.find((s) => s.su === item.value);
                      if (destino) {
                        handleChange({ name: 'ul', value: destino.ub });
                        handleChange({
                          name: 'uls',
                          value: `${destino.cd}${destino.cp}${destino.ci} - ${destino.du}`,
                        });
                        handleChange({ name: 'il', value: destino.dc });
                      }
                    }
                  }}
                />
              ) : (
                <div className="op-form-group op-col-2"></div>
              )}
              <Texto
                name="ups"
                value={guia.ups}
                label="Ubigueo de punto de partida"
                size={2}
                tabIndex={20}
                error={errors.up}
                onChange={() => {}}
                icon={() => (
                  <LocationOnIcon onClick={() => setOpenUbPartida(true)} />
                )}
              />
              <Texto
                name="ip"
                value={guia.ip}
                label="Direcci&oacute;n de punto de partida"
                size={2}
                tabIndex={21}
                error={errors.ip}
                onChange={handleChange}
              />

              <Texto
                name="uls"
                value={guia.uls}
                label="Ubigueo de punto de llegada"
                size={2}
                tabIndex={22}
                error={errors.ul}
                onChange={() => {}}
                icon={() => (
                  <LocationOnIcon onClick={() => setOpenUbLlegada(true)} />
                )}
              />
              <Texto
                name="il"
                value={guia.il}
                label="Direcci&oacute;n de punto de llegada"
                size={2}
                tabIndex={23}
                error={errors.il}
                onChange={handleChange}
              />
            </>
            <>
              <Boton
                className="op-dinamic"
                style={{ '--button-color': '#3285a8' }}
                icon={() => <AddIcon />}
                onClick={() => {
                  if (guia.sp) {
                    dispatch(actualizarFiltros({ su: guia.sp }));
                    dispatch(getInventariosAction({}));
                    setOpen(true);
                  } else {
                    mensajeMini('Debe seleccionar la sucursal de partida');
                  }
                }}
              >
                Agregar bien
              </Boton>
              <div className="op-form-group op-col-1 view-ordencompra">
                <Tabla
                  columns={columns}
                  data={bienes}
                  pagination={false}
                  searcheable={false}
                  hoverSelectedRow={false}
                  style={{ flexBasis: '100%', padding: '20px 0' }}
                />
              </div>
            </>
            <>
              <div className="op-form-group">
                <Boton
                  className="op-dinamic"
                  style={{ '--button-color': '#3285a8' }}
                  icon={() => <AddIcon />}
                  onClick={() => {
                    dispatch(getConductoresAction());
                    setOpenConductor(true);
                  }}
                >
                  Buscar conductor
                </Boton>
              </div>
              {guia.tt && guia.tt === '1' ? (
                <>
                  <Combo
                    name="to"
                    value={guia.to}
                    label="Tipo documento transportista"
                    size={4}
                    tabIndex={26}
                    data={tipoDocumentoIdentidad.map((i) => ({
                      value: i.dt,
                      label: i.de,
                    }))}
                    onChange={handleChange}
                  />
                  <Texto
                    name="tu"
                    value={guia.tu}
                    label="N&uacute;mero de documento"
                    size={4}
                    tabIndex={27}
                    error={errors.tu}
                    onChange={handleChange}
                  />
                  <Texto
                    name="tr"
                    value={guia.tr}
                    label="Denominaci&oacute;n del transportista"
                    size={2}
                    tabIndex={28}
                    error={errors.tr}
                    onChange={handleChange}
                  />
                </>
              ) : (
                <>
                  <Texto
                    name="nc"
                    value={guia.nc}
                    label="Nombre del conductor"
                    size={2}
                    tabIndex={29}
                    error={errors.nc}
                    onChange={handleChange}
                  />
                  <Texto
                    name="lc"
                    value={guia.lc}
                    label="Licencia del conductor"
                    size={2}
                    tabIndex={30}
                    error={errors.lc}
                    onChange={handleChange}
                  />
                  <Texto
                    name="vh"
                    value={guia.vh}
                    label="Marca y placa del veh&iacute;culo"
                    size={2}
                    tabIndex={31}
                    error={errors.vh}
                    onChange={handleChange}
                  />
                  <Texto
                    name="ch"
                    value={guia.ch}
                    label="Constancia del veh&iacute;culo"
                    size={2}
                    tabIndex={32}
                    error={errors.ch}
                    onChange={handleChange}
                  />
                </>
              )}
              <Calendario
                name="fe"
                value={guia.fe}
                label="Fecha entrega"
                tabIndex={31}
                size={2}
                minDate={new Date()}
                error={errors.fe}
                showTime
                onSelected={handleChange}
              />
              <Texto
                name="oe"
                value={guia.oe}
                label="Observaciones"
                size={2}
                tabIndex={32}
                error={errors.oe}
                onChange={handleChange}
              />
            </>
            <>
              <div className="op-content-preliminar">
                <div className="grupo">
                  <h2>{datos.ne}</h2>
                  <span>{datos.ns}</span>
                  <span>{guia.dr}</span>
                  <div className="cuadro">GUIA REMISI&Oacute;N</div>
                </div>
                <div className="grupo">
                  <label>Datos del inicio traslado</label>
                  <span>Fecha de emisi&oacute;n</span>
                  <span>{formatoFecha(new Date(), '', false)}</span>
                  <span>
                    Fecha y hora de entrega de bienes del transportista
                  </span>
                  <span>{formatoFecha(guia.fe)}</span>
                  <span>Sucursal de partida</span>
                  <span>
                    {guia.sp ? sucursales.find((s) => s.su === guia.sp).ns : ''}
                  </span>
                  <span>Direcci&oacute;n de punto de partida</span>
                  <span>{`${guia.ups ? guia.ups : ''} - ${
                    guia.ip ? guia.ip : ''
                  }`}</span>
                  <span>Motivo del traslado</span>
                  <span>
                    {guia.mt
                      ? tiposTraslados.find((t) => t.dt === guia.mt).de
                      : ''}
                  </span>
                  <span>Modalidad de traslado</span>
                  <span>{`${guia.tt === '1' ? 'Público' : 'Privado'}`}</span>
                </div>
                <div className="grupo">
                  <label>Datos del transportista</label>
                  {guia.tt && (guia.tt === '1' || guia.tt === 1) ? (
                    <>
                      <span>
                        Apellidos y nombres, denominaci&oacute;n o raz&oacute;n
                        social
                      </span>
                      <span>{guia.te ? guia.te : ''}</span>
                      <span>{`Número de ${
                        guia.to
                          ? tipoDocumentoIdentidad.find((d) => d.dt === guia.to)
                              .de
                          : ''
                      }`}</span>
                      <span>{guia.tu ? guia.tu : ''}</span>
                    </>
                  ) : (
                    <>
                      <span>Nombre del conductor</span>
                      <span>{guia.nc ? guia.nc : ''}</span>
                      <span>Licencia del conductor</span>
                      <span>{guia.lc ? guia.lc : ''}</span>
                      <span>Marca y placa del veh&iacute;culo</span>
                      <span>{guia.vh ? guia.vh : ''}</span>
                      <span>Constancia del veh&iacute;culo</span>
                      <span>{guia.ch ? guia.ch : ''}</span>
                    </>
                  )}
                </div>
                <div className="grupo">
                  <label>Datos del punto de destino</label>
                  {guia.sl && (
                    <>
                      <span>Sucursal de destino</span>
                      <span>
                        {guia.sl
                          ? sucursales.find((s) => s.su === guia.sl).ns
                          : ''}
                      </span>
                    </>
                  )}
                  <span>Direcci&oacute;n de punto de llegada</span>
                  <span>{`${guia.uls ? guia.uls : ''} - ${
                    guia.il ? guia.il : ''
                  }`}</span>
                </div>
                <div className="grupo">
                  <label>Datos del destinatario</label>
                  <span>
                    Apellidos y nombres, denominaci&oacute;n o raz&oacute;n
                    social
                  </span>
                  <span>{guia.dd ? guia.dd : ''}</span>
                  <span>Documento de identidad</span>
                  <span>{`${
                    guia.td
                      ? tipoDocumentoIdentidad.find((d) => d.dt === guia.td).de
                      : ''
                  } ${guia.nt ? guia.nt : ''}`}</span>
                </div>
                <div className="grupo">
                  <label>Datos de los bienes</label>
                  <Tabla
                    columns={columnsPreview}
                    data={bienes}
                    pagination={false}
                    searcheable={false}
                    hoverSelectedRow={false}
                    style={{ gridColumn: '1 / 3', padding: '20px 0' }}
                  />
                  <span>Observaciones</span>
                  <span>{guia.oe ? guia.oe : ''}</span>
                </div>
              </div>
            </>
          </Wizard.Pages>
          <div className="op-wizard__buttons">
            <Wizard.ButtonPrev
              className="op-wizard__buttons-left op-dinamic"
              tabIndex={50}
              style={{ '--button-color': '#3285a8' }}
            />
            <Wizard.ButtonNext
              className="op-wizard__buttons-right op-dinamic"
              style={{ '--button-color': '#3285a8' }}
              tabIndex={51}
            />
            <Wizard.ButtonFinish
              className="op-grabar"
              disabled={processing}
              icon={() => <SaveIcon />}
              onClick={() =>
                confirmacion(
                  '¿Está seguro?',
                  `${
                    documento.dc ? 'Rectificar la' : 'Generar una nueva'
                  } guía de remisión`
                )
                  .then(() => {
                    dispatch(
                      documento.dc
                        ? putRectificarGuiaAction(guia, documento.dc, bienes)
                        : postGenerarGuiaAction(guia, bienes)
                    );
                  })
                  .catch(() => {})
              }
            >
              {`${documento.dc ? 'Rectificar' : 'Generar'} guía`}
            </Wizard.ButtonFinish>
          </div>
        </Wizard>
      </Modal>
      {open && (
        <InventarioList
          searchable
          onClose={() => {
            setOpen(false);
            dispatch(actualizarFiltros({ su: null }));
          }}
          onSend={(item) => {
            handleAgregarBien(item);
            setOpen(false);
            dispatch(actualizarFiltros({ su: null }));
          }}
        />
      )}
      {openConductor && (
        <ConductorList
          searchable
          onClose={() => {
            setOpenConductor(false);
          }}
          onSend={(item) => {
            setOpenConductor(false);
            handleChange({ name: 'nc', value: item.nc });
            handleChange({ name: 'lc', value: item.lc });
            handleChange({ name: 'vh', value: `${item.mh} ${item.ph}` });
            handleChange({ name: 'ch', value: item.oh });
          }}
        />
      )}
      {openUbPartida && (
        <Ubigueo
          onClose={() => setOpenUbPartida(false)}
          onSend={(ubigueo) => {
            handleChange({
              name: documento.dc ? 'upl' : 'up',
              value: ubigueo.ub,
            });
            handleChange({ name: 'ups', value: ubigueo.di });
          }}
        />
      )}
      {openUbLlegada && (
        <Ubigueo
          onClose={() => setOpenUbLlegada(false)}
          onSend={(ubigueo) => {
            handleChange({
              name: documento.dc ? 'ull' : 'ul',
              value: ubigueo.ub,
            });
            handleChange({ name: 'uls', value: ubigueo.di });
          }}
        />
      )}
    </>
  );
};

export default GuiaNew;
