import React, { useState, useEffect } from 'react';

import { useFormularioContext } from '../../commons/Formulario';

const MantenimientoSupply = () => {
  const { selected } = useFormularioContext();

  const [maintenance, setMaintenance] = useState(selected);

  useEffect(() => {
    setMaintenance(selected);
  }, [selected.hi]);

  return (
    <div className="detail-equipments">
      <div className="title">
        <h3>{`Insumos `}</h3>
      </div>
      <div className="op-detail-contenedor">
        {maintenance.in.map((v, ii) => (
          <div key={ii} className="op-detail-result">
            <span>{v.mt.nm}</span>
            <span>{v.ct}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MantenimientoSupply;
