import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Filtros from '../../commons/Filtros';
import Combo from '../../commons/Combo';
import Texto from '../../commons/Texto';
import Boton from '../../commons/Boton';
import Icon from '../../commons/Icon';
import { useTablaFilter } from '../../commons/Formulario';

import Report from '../app/Report';

import PrintIcon from '@material-ui/icons/Print';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import XlsFile from '../../assets/icons/xls-file.svg';

import { servicios } from '../../redux/helpers/servicios';
import { TYPE_FILTER } from '../../redux/helpers/app.types';

const OperacionFilter = () => {
  const { getData, params, dispatch } = useTablaFilter();
  const { currentUser } = useSelector((state) => state.app);
  const { tipoIngresos, tipoSalidas } = useSelector((state) => state.detalle);
  const { sucursales } = useSelector((state) => state.sucursal);

  const [openReporte, setOpenReporte] = useState(false);
  const [openReporteExcel, setOpenReporteExcel] = useState(false);

  let date = new Date();
  let day =
    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate().toString();
  let month = date.getMonth() + 1;
  month = month < 10 ? `0${month}` : month.toString();
  let year = date.getFullYear();

  return (
    <>
      <Filtros
        onFilter={() => getData()}
        showNewButton={false}
        actions={() => (
          <>
            <Boton
              className="op-filter"
              icon={() => <PrintIcon />}
              tabIndex={22}
              dropdownable
              style={{
                '--button-icon-color': '#1ab394',
              }}
              showDropdownOnTop
              actions={[
                {
                  key: '01',
                  label: 'PDF',
                  icon: () => <PictureAsPdfIcon style={{ color: 'red' }} />,
                  onClick: () => {
                    setOpenReporte(true);
                  },
                },
                {
                  key: '02',
                  label: 'Excel',
                  icon: () => <Icon svg={XlsFile} color="#1ab394" />,
                  onClick: () => {
                    setOpenReporteExcel(true);
                  },
                },
              ]}
            >
              Imprimir
            </Boton>
          </>
        )}
      >
        <Combo
          name="su"
          label="Sucursal"
          value={params.su}
          placeholder="Todos"
          tabIndex={20}
          size={3}
          data={sucursales.map((x) => ({ value: x.su, label: x.ns }))}
          onChange={({ value }) => {
            dispatch({ type: TYPE_FILTER, payload: { su: value } });
          }}
        />
        <Combo
          name="ct"
          label="Tipo operaci&oacute;n"
          value={params.ct}
          placeholder="Todos"
          tabIndex={21}
          size={3}
          data={tipoIngresos
            .concat(tipoSalidas)
            .map((i) => ({ value: i.dt, label: i.de }))}
          searcheable
          dropdownTop
          onChange={({ value }) => {
            dispatch({ type: TYPE_FILTER, payload: { ct: value } });
          }}
        />
        <Combo
          name="is"
          value={params.is}
          label="Ingreso / Salida"
          placeholder="Ambos"
          size={3}
          tabIndex={22}
          data={[
            { value: '1', label: 'Ingreso' },
            { value: '0', label: 'Salida' },
          ]}
          onChange={({ value }) => {
            dispatch({ type: TYPE_FILTER, payload: { is: value } });
          }}
        />
        <Texto
          name="fi"
          value={params.fi}
          label="Fecha de inicio operaci&oacute;n"
          tabIndex={23}
          size={3}
          type={'date'}
          max={`${year}-${month}-${day}`}
          onChange={({ value }) => {
            dispatch({ type: TYPE_FILTER, payload: { fi: value } });
          }}
        />
        <Texto
          name="ff"
          value={params.ff}
          label="Fecha fin operaci&oacute;n"
          tabIndex={23}
          size={3}
          type={'date'}
          max={`${year}-${month}-${day}`}
          onChange={({ value }) => {
            dispatch({ type: TYPE_FILTER, payload: { ff: value } });
          }}
        />
      </Filtros>
      {openReporte && (
        <Report
          url={`${servicios.OPERACION}/${currentUser.ie}/imprimir`}
          params={params}
          extension={'pdf'}
          onClose={() => {
            setOpenReporte(false);
          }}
        />
      )}
      {openReporteExcel && (
        <Report
          url={`${servicios.OPERACION}/${currentUser.ie}/imprimir`}
          params={{ ...params, xl: 'y' }}
          onClose={() => {
            setOpenReporteExcel(false);
          }}
          filename={'ReporteMovimientos'}
        />
      )}
    </>
  );
};

export default OperacionFilter;
