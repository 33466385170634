import React from 'react';

import Bloque from '../../commons/Bloque';
import TablaBody from '../../commons/TablaBody';
import TablaSearch from '../../commons/TablaSearch';
import Paginacion from '../../commons/Paginacion';
import { useTablaFilter } from '../../commons/Formulario';
import {
  formatoFecha,
  formatoMonetario,
  redondeoDecimales,
} from '../../commons/Utilitarios';

import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const OperacionList = () => {
  const { setSelectedItem } = useTablaFilter();
  const columns = [
    {
      key: 'fo',
      label: 'Fecha operación',
      align: 'center',
      content: (item) => `${formatoFecha(item.fo, 'Indefinido', false)}`,
    },
    {
      key: 'ns',
      label: 'Sucursal',
    },
    {
      key: 'dt',
      label: 'Tipo operación',
      content: (item) => (
        <div
          className="op-centrar-componentes"
          style={{ justifyContent: 'flex-start' }}
        >
          {item.is === 1 ? (
            <TrendingUpIcon fontSize="small" style={{ color: 'green' }} />
          ) : (
            <TrendingDownIcon fontSize="small" style={{ color: 'red' }} />
          )}
          <span
            style={{
              color: `${item.is === 1 ? 'green' : 'red'}`,
            }}
          >
            {item.dt}
          </span>
        </div>
      ),
    },
    {
      key: 'nm',
      label: 'Producto / Insumo',
      content: (item) => (
        <div
          className="op-centrar-componentes"
          style={{ justifyContent: 'flex-start' }}
        >
          <span
            style={{
              color: `${item.is === 1 ? 'green' : 'red'}`,
            }}
          >
            {item.nm}
          </span>
        </div>
      ),
    },
    {
      key: 'um',
      label: 'Unidad de medida',
    },
    {
      key: 'cm',
      label: 'Cantidad',
      align: 'right',
      content: (item) => (
        <div className="op-centrar-componentes">
          {item.is === 1 ? (
            <AddIcon fontSize="small" style={{ color: 'green' }} />
          ) : (
            <RemoveIcon fontSize="small" style={{ color: 'red' }} />
          )}
          <span
            style={{
              color: `${item.is === 1 ? 'green' : 'red'}`,
            }}
          >
            {item.cm}
          </span>
        </div>
      ),
    },
    {
      key: 'pm',
      label: 'Precio',
      align: 'right',
      content: (item) => (
        <div className="op-centrar-componentes">
          {item.tm === 0 ? 'S/' : '$'}
          {formatoMonetario(redondeoDecimales(item.pm))}
        </div>
      ),
    },
  ];

  return (
    <Bloque titulo="Movimientos de inventario">
      <div className="op-table">
        <TablaSearch />
        <TablaBody
          columns={columns}
          onDoubleClick={(item) => setSelectedItem(item)}
        />
        <Paginacion />
      </div>
    </Bloque>
  );
};

export default OperacionList;
