import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Combo from '../../commons/Combo';
import Boton from '../../commons/Boton';
import Adjunto from '../../commons/Adjunto';

import {
  formatoFecha,
  formatoMonetario,
  redondeoDecimales,
} from '../../commons/Utilitarios';
import { confirmacion } from '../../commons/Mensajes';

import {
  patchPagarOrdenCompraAction,
  patchAdjuntarArchivoOrdenAction,
} from '../../redux/actions/ordencompra.action';

import SaveIcon from '@material-ui/icons/Save';

import '../../css/ordencompra.css';

const OrdenCompraPagar = ({
  selected,
  processing,
  errors,
  usuario,
  tipoDocumentos,
  tipoCajas,
  onClose,
  patchPagarOrdenCompraAction,
  patchAdjuntarArchivoOrdenAction,
}) => {
  const [orden, setOrden] = useState(selected);
  const [limite, setLimite] = useState(null);
  const [openTipoCambio, setOpenTipoCambio] = useState(false);

  useEffect(() => {
    let findDoc = tipoDocumentos.find((x) => x.de === orden.du);
    setOrden((prev) => ({
      ...prev,
      up: usuario,
      to: formatoMonetario(redondeoDecimales(prev.to)),
      su: formatoMonetario(redondeoDecimales(prev.su)),
      im: formatoMonetario(redondeoDecimales(prev.im)),
      bt: formatoMonetario(redondeoDecimales(prev.bt)),
      md: formatoMonetario(redondeoDecimales(prev.md)),
      td: formatoMonetario(redondeoDecimales(prev.td)),
      tt: formatoMonetario(redondeoDecimales(prev.tt)),
      ic: '0',
      tu: findDoc ? findDoc.dt : '',
    }));
    setOpenTipoCambio(selected.tt > 1);
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setOrden((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    let recepcion = new Date(orden.fe);
    recepcion.setDate(recepcion.getDate() + parseInt(orden.dd));
    setLimite(recepcion);
  }, [orden.dd]);

  return (
    <Modal
      title={`Pagar orden N°: ${selected.co}`}
      processing={processing}
      loading="payment"
      closeWithoutConfirmation
      onClose={() => {
        onClose();
      }}
      onActions={() => (
        <>
          <Boton
            className="op-grabar"
            disabled={processing}
            icon={() => <SaveIcon />}
            tabIndex={25}
            onClick={() =>
              confirmacion(
                '¿Está seguro?',
                `De pagar la orden de compra ${selected.co}, no olvide agregar todos los archivos adjuntos necesarios, luego de confirmar la operación ya no podrar adjuntar`
              )
                .then(() => patchPagarOrdenCompraAction(orden))
                .catch(() => {})
            }
          >
            Guardar
          </Boton>
        </>
      )}
    >
      <Texto
        name="up"
        value={orden.up}
        label="Usuario"
        size={2}
        tabIndex={10}
        error={errors.up}
        onChange={() => {}}
        disabled
      />
      <Texto
        name="cp"
        value={orden.cp}
        label="Gu&iacute;a de remisi&oacute;n u otro documento"
        tabIndex={11}
        size={2}
        error={errors.cp}
        onChange={handleChange}
        info={
          'Puede agregar el número de guía de remisión u otro documento del proveedor'
        }
      />
      <Texto
        name="rz"
        value={orden.rz}
        label="Proveedor"
        size={2}
        tabIndex={12}
        onChange={() => {}}
        disabled
      />
      <Texto
        name="nr"
        value={orden.nr}
        label="RUC"
        size={2}
        tabIndex={13}
        onChange={() => {}}
        disabled
      />
      <Combo
        name="ic"
        value={orden.ic}
        label="Pagar desde"
        size={2}
        tabIndex={14}
        data={[
          { value: '0', label: 'Caja general' },
          { value: '1', label: 'Caja chica' },
        ]}
        error={errors.ic}
        onChange={handleChange}
      />
      <Combo
        name="tc"
        label="Tipo caja"
        value={orden.tc}
        tabIndex={15}
        size={2}
        data={tipoCajas.map((i) => ({ value: i.dt, label: i.de }))}
        error={errors.tc}
        onChange={handleChange}
      />
      <Combo
        name="tu"
        label="Tipo documento pago"
        value={orden.tu}
        tabIndex={16}
        size={3}
        data={tipoDocumentos.map((i) => ({ value: i.dt, label: i.de }))}
        error={errors.tu}
        onChange={handleChange}
      />
      <Texto
        name="ip"
        value={orden.ip}
        label="Serie"
        tabIndex={17}
        size={3}
        error={errors.ip}
        onChange={handleChange}
      />
      <Texto
        name="dp"
        value={orden.dp}
        label="N&uacute;mero"
        tabIndex={18}
        size={3}
        error={errors.dp}
        onChange={handleChange}
      />
      <Texto
        name="fe"
        value={formatoFecha(orden.fe)}
        label="Fecha de recepci&oacute;n"
        tabIndex={19}
        size={4}
        onChange={() => {}}
        disabled
      />
      <Combo
        name="cc"
        value={orden.cc}
        label="Condici&oacute;n de pago"
        size={4}
        tabIndex={20}
        data={[
          { value: '1', label: 'Credito' },
          { value: '0', label: 'Contado' },
        ]}
        error={errors.cc}
        onChange={handleChange}
      />
      {orden.cc === '1' && (
        <>
          <Texto
            name="dd"
            value={orden.dd}
            label="D&iacute;as de pago"
            tabIndex={21}
            size={4}
            restrict={'int'}
            error={errors.dd}
            onChange={handleChange}
          />
          <Texto
            name="limite"
            value={formatoFecha(limite)}
            label="Fecha de l&iacute;mite"
            tabIndex={22}
            size={4}
            onChange={() => {}}
            disabled
          />
        </>
      )}
      {openTipoCambio && (
        <Texto
          name="tt"
          value={orden.tt}
          label="Tipo de cambio del d&iacute;a"
          tabIndex={23}
          size={2}
          restrict={'money'}
          error={errors.tt}
          onChange={handleChange}
          onBlur={(e) => {
            handleChange({
              name: 'tt',
              value: formatoMonetario(redondeoDecimales(e.target.value)),
            });
          }}
        />
      )}
      <Texto
        name="ia"
        value={orden.ia}
        label="Informaci&oacute;n adicional"
        tabIndex={24}
        error={errors.ia}
        onChange={handleChange}
      />
      <div className="op-form-group op-col-1">
        <div className="op-totales">
          <label>Importe Bruto soles</label>
          <span>{orden.su}</span>
          <label>I.G.V soles</label>
          <span>{orden.im}</span>
          <label>
            <strong>Importe Total soles</strong>
          </label>
          <span>
            <strong>{orden.to}</strong>
          </span>
          {orden.tt > 1 && (
            <>
              <div className="separador"></div>
              <label>Importe Bruto dolares</label>
              <span>{orden.bt}</span>
              <label>I.G.V dolares</label>
              <span>{orden.md}</span>
              <label>
                <strong>Importe Total dolares</strong>
              </label>
              <span>
                <strong>{orden.td}</strong>
              </span>
            </>
          )}
        </div>
      </div>
      <Adjunto
        stateParent={orden.st}
        nameParent={'orden'}
        processing={processing}
        data={
          orden.adj &&
          orden.adj.dtl &&
          orden.adj.dtl.map((a) => ({
            id: a.cr,
            name: a.na,
            extension: a.ea,
            state: a.st,
          }))
        }
        file={orden.adj && orden.adj.ar}
        onNew={(documento) =>
          patchAdjuntarArchivoOrdenAction(orden.oc, documento)
        }
      />
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.ordenCompra.selected,
    processing: state.ordenCompra.processing,
    usuario: state.app.currentUser.np,
    errors: state.ordenCompra.errors,
    tipoCajas: state.detalle.tipoCajas,
    tipoDocumentos: state.detalle.tipoDocumentos,
  };
};

export default connect(mapStateToProps, {
  patchPagarOrdenCompraAction,
  patchAdjuntarArchivoOrdenAction,
})(OrdenCompraPagar);
