import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';

import Adjunto from '../../commons/Adjunto';
import Bloque from '../../commons/Bloque';
import Boton from '../../commons/Boton';
import Icon from '../../commons/Icon';
import Loading from '../../commons/Loading';

import { useFormularioContext } from '../../commons/Formulario';

import useAdjunto from '../../hooks/useAdjunto';
import useMantenimiento from '../../hooks/useMantenimiento';

import SolicitudEquipment from './SolicitudEquipment';
import SolicitudHandBookView from './SolicitudHandBookView';
import SolicitudMaintenance from './SolicitudMaintenance';
import SolicitudProcessesResult from './SolicitudProcessesResult';
import SolicitudTestingsResult from './SolicitudTestingsResult';
import SolicitudSupplyResult from './SolicitudSupplyResult';

import { solicitudModel } from '../../redux/models/solicitud.model';

import RequestIcon from '../../assets/icons/file-request.svg';
import BookIcon from '../../assets/icons/book.svg';
import TestIcon from '../../assets/icons/test-tube.svg';
import PhotosIcon from '../../assets/icons/photos.svg';
import SuppliesIcon from '../../assets/icons/supplies.svg';
import { servicios } from '../../redux/helpers';
import { mensajeMini } from '../../commons/Mensajes';

const SolicitudTask = () => {
  const {
    selected,
    processing,
    dispatch,
    accionTask,
    successTask,
    successImage,
    openModalTask,
    callTransaction,
    equipment,
    fileAttached,
  } = useFormularioContext();
  const [solicitud, setSolicitud] = useState(selected);
  const [childSelected, setChildSelected] = useState(0);
  const { processingMaintenance, maintenance, getPreviusMaintenance } =
    useMantenimiento();
  const { processAttached, attached, getAttached } = useAdjunto();
  const { currentUser } = useSelector((state) => state.app);

  useEffect(() => {
    setSolicitud(selected);
    setChildSelected(0);
  }, [selected.so, openModalTask]);

  useEffect(() => {
    if (selected.so && openModalTask) {
      getPreviusMaintenance(selected.so);
    }
  }, [successTask]);

  useEffect(() => {
    if (fileAttached) {
      getAttached(fileAttached);
    } else if (selected.so && openModalTask && selected.aa.ar) {
      getAttached(selected.aa.ar);
    }
  }, [successImage, fileAttached]);

  if (!openModalTask) {
    return null;
  }

  const handleSaveAttached = (attached) => {
    if (equipment && equipment.eq) {
      let form_data = new FormData();
      form_data.append('file', attached);
      form_data.append('ie', currentUser.ie);
      form_data.append('ua', currentUser.us);
      form_data.append('so', solicitud.so);
      form_data.append('eq', equipment.eq.eq);
      form_data.append('ia', accionTask);

      callTransaction({
        method: 'patch',
        url: `${servicios.SOLICITUD}/adjuntar`,
        data: form_data,
        headers: { 'content-type': 'multipart/form-data; charset=utf-8' },
        model: solicitudModel,
        successResponse: 'SUCCESS_IMAGE',
      });
    } else {
      mensajeMini('Debe seleccionar un equipo para guardar las imagenes');
    }
  };

  return (
    <>
      {(processing || processingMaintenance) && <Loading tipo={'request'} />}
      <Bloque
        titulo={`Solicitud - ${solicitud.cs}`}
        className={`maintenance ${openModalTask ? 'active' : ''}`}
        hideArrowButton
        onActions={() => (
          <Boton
            className="op-cerrar"
            icon={() => <CloseIcon />}
            onClick={() => dispatch({ type: 'CLOSE_TASK' })}
          >
            Cerrar
          </Boton>
        )}
      >
        <div className="op-detail-container" style={{ padding: '20px' }}>
          <div
            className={`op-detail-icon ${childSelected === 0 ? 'active' : ''}`}
            onClick={() => setChildSelected(0)}
          >
            <Icon svg={RequestIcon} size={50} transparent />
            <span>Solicitud</span>
          </div>
          <div
            className={`op-detail-icon ${childSelected === 1 ? 'active' : ''}`}
            onClick={() => setChildSelected(1)}
          >
            <Icon svg={BookIcon} size={50} transparent />
            <span>Manuales</span>
          </div>
          {/*<div
            className={`op-detail-icon ${childSelected === 2 ? 'active' : ''}`}
            onClick={() => setChildSelected(2)}
          >
            <Icon svg={ProcessIcon} size={50} transparent />
            <span>Procesamientos</span>
        </div>*/}
          <div
            className={`op-detail-icon ${childSelected === 3 ? 'active' : ''}`}
            onClick={() => setChildSelected(3)}
          >
            <Icon svg={TestIcon} size={50} transparent />
            <span>Testeos</span>
          </div>
          <div
            className={`op-detail-icon ${childSelected === 4 ? 'active' : ''}`}
            onClick={() => setChildSelected(4)}
          >
            <Icon svg={SuppliesIcon} size={50} transparent />
            <span>Insumos</span>
          </div>
          <div
            className={`op-detail-icon ${childSelected === 5 ? 'active' : ''}`}
            onClick={() => setChildSelected(5)}
          >
            <Icon svg={PhotosIcon} size={50} transparent />
            <span>Fotos</span>
          </div>
          <div className="op-detail-main">
            <div
              className={`op-detail-component ${
                childSelected === 0 ? 'active' : ''
              }`}
            >
              <SolicitudEquipment onActions={() => {}} />
              <SolicitudMaintenance />
            </div>
            <div
              className={`op-detail-component ${
                childSelected === 1 ? 'active' : ''
              }`}
            >
              <SolicitudHandBookView />
            </div>
            <div
              className={`op-detail-component ${
                childSelected === 2 ? 'active' : ''
              }`}
            >
              <SolicitudProcessesResult previus={maintenance} />
            </div>
            <div
              className={`op-detail-component ${
                childSelected === 3 ? 'active' : ''
              }`}
            >
              <SolicitudTestingsResult previus={maintenance} />
            </div>
            <div
              className={`op-detail-component ${
                childSelected === 4 ? 'active' : ''
              }`}
            >
              <SolicitudSupplyResult previus={maintenance} />
            </div>
            <div
              className={`op-detail-component ${
                childSelected === 5 ? 'active' : ''
              }`}
            >
              {!processAttached && (
                <Adjunto
                  stateParent={solicitud.st}
                  nameParent={'solicitud'}
                  extensions={['jpg', 'jpeg', 'png']}
                  processing={processAttached || processing}
                  title="Imagenes del mantenimiento"
                  addButtonText="Nueva imagen"
                  confirmButtonText="Adjuntar imagen"
                  confirmNewText="Se agregara una nueva imagen del mantenimiento"
                  confirmEditText="Se actualizara la imagen"
                  data={
                    attached && attached.dtl
                      ? attached.dtl.map((a) => ({
                          id: a.cr,
                          name: a.na,
                          extension: a.ea,
                          state: a.st,
                        }))
                      : []
                  }
                  file={attached && attached.ar}
                  onNew={(documento) => handleSaveAttached(documento)}
                  onSuccess={() => getAttached(selected.aa.ar)}
                />
              )}
            </div>
          </div>
        </div>
      </Bloque>
    </>
  );
};

export default SolicitudTask;
