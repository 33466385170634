import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Proptypes from 'prop-types';

import Modal from './Modal';
import Boton from './Boton';
import Texto from './Texto';
import Icon from './Icon';
import FileUpload from './FileUpload';
import Report from '../components/app/Report';

import { obtenerIcono } from './Utilitarios';
import { confirmacion, mensaje, mensajeMini } from './Mensajes';

import {
  patchDarBajaArchivoAction,
  patchEditarArchivoAction,
} from '../redux/actions/archivo.action';
import { servicios } from '../redux/helpers/servicios';

import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { api } from '../redux/axios/api';

const Adjunto = ({
  onNew,
  onEdit,
  onDown,
  onSuccess,
  extensions,
  data,
  stateParent,
  nameParent,
  file,
  processing,
  title,
  addButtonText,
  addButtonColor,
  addButtonIcon,
  confirmEditText,
  confirmNewText,
  confirmButtonColor,
  confirmButtonIcon,
  confirmButtonText,
}) => {
  const [openFile, setOpenFile] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [filename, setFilename] = useState(null);
  const [downloadname, setDownloadname] = useState(null);
  const [extension, setExtension] = useState(null);
  const [documento, setDocumento] = useState(null);
  const [archivo, setArchivo] = useState(null);
  const [processingFile, setProcessingFile] = useState(false);

  const dispatch = useDispatch();

  const empresa = useSelector((state) => state.app.currentUser.ie);

  const patchEditarArchivo = (data) => {
    let form_data = new FormData();
    form_data.append('ar', file);
    form_data.append('co', data);
    form_data.append('ie', empresa);
    form_data.append('file', documento);
    setProcessingFile(true);
    api
      .patch(`${servicios.ARCHIVO}/editar`, form_data, {
        headers: { 'content-type': 'multipart/form-data; charset=utf-8' },
      })
      .then(() => handleSuccess())
      .catch((error) => console.log(error.data))
      .finally(() => {
        setOpenNew(false);
        setProcessingFile(false);
      });
  };

  const patchBajaArchivo = (data) => {
    setProcessingFile(true);
    api
      .patch(`${servicios.ARCHIVO}/baja`, { ar: file, ie: empresa, co: data })
      .then(() => handleSuccess())
      .catch((error) => console.log(error.data))
      .finally(() => {
        setOpenNew(false);
        setProcessingFile(false);
      });
  };

  const handleSuccess = () => {
    setFilename(null);
    setDocumento(null);
    setArchivo(null);
    setExtension(null);
    setDownloadname(null);
    mensaje('Operación Satisfactoria');
    onSuccess();
  };

  return (
    <>
      <div
        className="op-form-group"
        style={{ gridColumn: '1/3', borderTop: '1px solid #3498db' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '5px',
          }}
        >
          <span>{title}</span>
          <Boton
            className="op-dinamic"
            disabled={processing}
            icon={() => (addButtonIcon ? addButtonIcon() : <AttachFileIcon />)}
            tabIndex={25}
            style={{ '--button-color': addButtonColor }}
            onClick={() => setOpenNew(true)}
          >
            {addButtonText}
          </Boton>
        </div>
        {data &&
          data.map((d) => (
            <div
              key={d.id}
              style={{
                display: 'grid',
                gridTemplateColumns: '30px 20px auto',
                columnGap: '5px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  marginRight: '5px',
                }}
              >
                {stateParent && (
                  <EditIcon
                    style={{
                      color: '#1ab394',
                      fontSize: '18px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setArchivo(d);
                      setOpenNew(true);
                    }}
                  />
                )}
                {stateParent && d.state && (
                  <CloseIcon
                    style={{
                      color: 'red',
                      fontSize: '18px',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      confirmacion(
                        '¿Está seguro?',
                        `De anular el archivo ${d.name}.${d.extension}`
                      )
                        .then(() => {
                          if (onSuccess) {
                            patchBajaArchivo(d.id);
                          } else if (onDown) {
                            onDown(d.id);
                          } else {
                            dispatch(
                              patchDarBajaArchivoAction(file, d.id, nameParent)
                            );
                          }
                        })
                        .catch(() => {})
                    }
                  />
                )}
              </div>
              <Icon svg={obtenerIcono(d.extension)} transparent />
              <div
                style={{ cursor: 'pointer', display: 'flex' }}
                onClick={() => {
                  setFilename(d.id);
                  setExtension(d.extension);
                  setOpenFile(true);
                  setDownloadname(d.name);
                }}
              >
                <span
                  style={{
                    textDecoration: `${d.state ? 'none' : 'line-through'}`,
                    color: `${d.state ? 'inherit' : 'red'}`,
                  }}
                >{`${d.name}.${d.extension}`}</span>
              </div>
            </div>
          ))}
      </div>
      {openFile && (
        <Report
          url={`${servicios.ARCHIVO}/${file}/image64/${empresa}/${filename}`}
          params={{}}
          extension={extension}
          filename={downloadname}
          onClose={() => setOpenFile(false)}
        />
      )}
      {openNew && (
        <Modal
          title={`${archivo ? 'Editar' : 'Adjuntar'} archivo`}
          size="small"
          processing={processing || processingFile}
          loading="files"
          onActions={() => (
            <Boton
              className="op-dinamic"
              disabled={processing || processingFile}
              icon={() =>
                confirmButtonIcon ? confirmButtonIcon() : <SaveIcon />
              }
              tabIndex={501}
              style={{ '--button-color': confirmButtonColor }}
              onClick={() => {
                if (documento) {
                  confirmacion(
                    '¿Desea guardar?',
                    `${archivo ? confirmEditText : confirmNewText}`
                  )
                    .then(() => {
                      if (archivo) {
                        if (onSuccess) {
                          patchEditarArchivo(archivo.id);
                        } else if (onEdit) {
                          onEdit(documento, archivo.id);
                          setOpenNew(false);
                        } else {
                          dispatch(
                            patchEditarArchivoAction(
                              file,
                              archivo.id,
                              documento,
                              nameParent
                            )
                          );
                        }
                      } else {
                        onNew(documento);
                        setOpenNew(false);
                      }
                    })
                    .catch(() => {});
                } else {
                  mensajeMini('Seleccione archivo');
                }
              }}
            >
              {confirmButtonText}
            </Boton>
          )}
          closeWithoutConfirmation
          onClose={() => {
            setArchivo(null);
            setOpenNew(false);
          }}
        >
          {archivo && (
            <Texto
              name="nd"
              value={`${archivo.name}.${archivo.extension}`}
              label="Nombre de archivo"
              tabIndex={500}
              disabled
              onChange={() => {}}
            />
          )}
          <FileUpload
            extensions={extensions}
            onSelectedFile={(files) => {
              setDocumento(files.length > 0 ? files[0].file : null);
            }}
          />
        </Modal>
      )}
    </>
  );
};

Adjunto.propTypes = {
  onNew: Proptypes.func.isRequired,
  onEdit: Proptypes.func,
  onDown: Proptypes.func,
  onSuccess: Proptypes.func,
  extensions: Proptypes.arrayOf(
    Proptypes.oneOf(['pdf', 'docx', 'doc', 'xls', 'xlsx', 'jpg', 'jpeg', 'png'])
  ),
  data: Proptypes.arrayOf(
    Proptypes.shape({
      id: Proptypes.string.isRequired,
      name: Proptypes.string.isRequired,
      extension: Proptypes.string.isRequired,
      state: Proptypes.bool.isRequired,
    })
  ),
  stateParent: Proptypes.bool.isRequired,
  nameParent: Proptypes.string.isRequired,
  file: Proptypes.string,
  processing: Proptypes.bool,
  title: Proptypes.string,
  addButtonText: Proptypes.string,
  addButtonColor: Proptypes.string,
  addButtonIcon: Proptypes.func,
  confirmEditText: Proptypes.string,
  confirmNewText: Proptypes.string,
  confirmButtonColor: Proptypes.string,
  confirmButtonIcon: Proptypes.func,
  confirmButtonText: Proptypes.string,
};

Adjunto.defaultProps = {
  extensions: ['pdf', 'jpg', 'jpeg', 'png'],
  processing: false,
  title: 'Archivos adjuntos',
  textNewFile: 'Nuevo archivo',
  confirmEditText: 'Se agregara un nuevo archivo',
  confirmNewText: 'Se agregara un nuevo archivo',
  confirmButtonColor: '#1ab394',
  confirmButtonText: 'Adjuntar archivo',
  addButtonText: 'Nuevo archivo',
  addButtonColor: '#3285a8',
};

export default Adjunto;
