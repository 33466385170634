import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Texto from '../commons/Texto';
import Boton from '../commons/Boton';
import Loading from '../commons/Loading';

import {
  getCaptchaAction,
  putCaptchaAction,
  patchOlvidoContraseniaAction,
} from '../redux/actions/app.action';

import RefreshIcon from '@material-ui/icons/Refresh';

import '../css/olvido.css';

const Olvido = ({
  processing,
  captcha,
  captchaProcessing,
  error,
  getCaptchaAction,
  putCaptchaAction,
  patchOlvidoContraseniaAction,
}) => {
  const [user, setUser] = useState({
    us: '',
    tx: '',
  });

  useEffect(() => {
    getCaptchaAction();
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    patchOlvidoContraseniaAction(user);
    setUser((prev) => ({
      ...prev,
      tx: '',
    }));
  };

  const handleRefreshCaptcha = () => {
    putCaptchaAction(captcha.ct);
  };

  return (
    <div className="op-content-forgot">
      <div className="op-content">
        <label className="titulo">He olvidado la contrase&ntilde;a</label>
        <div className="op-forgot">
          <h1>Introduce tu nombre de usuario</h1>
          <h3>El que necesitar&aacute;s para iniciar sesi&oacute;n</h3>
          <Texto
            name="us"
            value={user.us}
            label="Correo"
            tabIndex={1}
            onChange={handleChange}
          ></Texto>
          <div className="op-form-group op-captcha">
            {captchaProcessing ? (
              <>
                <div></div>
                <i className="op-loading"></i>
              </>
            ) : (
              <>
                <img
                  alt="captcha"
                  src={`data:image/jpg;base64,${captcha.img}`}
                  style={{ cursor: 'pointer' }}
                ></img>
                <RefreshIcon fontSize="large" onClick={handleRefreshCaptcha} />
              </>
            )}
          </div>
          {captchaProcessing === false && <p>{captcha.si}</p>}
          <Texto
            label="Escribe los caracteres"
            name="tx"
            value={user.tx}
            tabIndex={3}
            onChange={handleChange}
          />
          <Boton
            className="op-grabar"
            disabled={processing}
            onClick={handleSubmit}
            tabIndex={4}
          >
            Enviar
          </Boton>
          {error && <label className="op-error">{error}</label>}
          {processing === true && <Loading />}
          <NavLink className="op-boton op-cerrar iniciar" to="/">
            Retornar al inicio
          </NavLink>
          <h5>@ 2020 Soluciones OP - Todos los derechos reservados</h5>
        </div>
      </div>
    </div>
  );
};

const mapStateToProp = (state) => {
  return {
    processing: state.app.processing,
    error: state.app.error,
    captcha: state.app.captcha,
    captchaProcessing: state.app.captchaProcessing,
  };
};

export default connect(mapStateToProp, {
  patchOlvidoContraseniaAction,
  getCaptchaAction,
  putCaptchaAction,
})(Olvido);
