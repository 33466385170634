export const equipoModel = {
  eq: null,
  ce: null,
  ne: null,
  de: null,
  cb: null,
  im: null,
  cl: null,
  scl: null,
  nc: null,
  mr: null,
  ns: null,
  mo: null,
  pn: null,
  st: false,
  gc: true,
  tt: [],
  mm: [],
};
