import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Boton from '../../commons/Boton';
import Modal from '../../commons/Modal';
import Texto from '../../commons/Texto';
import Combo from '../../commons/Combo';
import { confirmacion } from '../../commons/Mensajes';

import { patchConfirmarPagoPedidoAction } from '../../redux/actions/pedido.action';

import SaveIcon from '@material-ui/icons/Save';
import { formatoMonetario, redondeoDecimales } from '../../commons/Utilitarios';

const PedidoPagar = ({ onClose }) => {
  const dispatch = useDispatch();
  const { selected, processing, errors } = useSelector((state) => state.pedido);
  const { tipoBancos } = useSelector((state) => state.detalle);
  const [pedido, setPedido] = useState(selected);

  const handleChange = (item) => {
    const { name, value } = item;
    setPedido((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    handleChange({ name: 'td', value: 0 });
    handleChange({ name: 'fp', value: 0 });
    if (pedido.ms > 0) {
      handleChange({
        name: 'ds',
        value: formatoMonetario(redondeoDecimales(pedido.ms)),
      });
      handleChange({ name: 'mg', value: pedido.pp });
    }
  }, []);

  const getCategoria = (cc) => {
    switch (cc) {
      case 'LE':
        return 'Lavado premium';
      case 'LS':
        return 'Lavado al seco';
      case 'PL':
        return 'Planchado';
      default:
        return 'Lavado';
    }
  };

  return (
    <Modal
      title={`Confirmar pago - ${pedido.np}`}
      processing={processing}
      size="medium"
      onActions={() => (
        <Boton
          className="op-grabar"
          disabled={processing}
          icon={() => <SaveIcon />}
          onClick={() =>
            confirmacion('¿Está seguro?', `Confirmar el pago del pedido`)
              .then(() => dispatch(patchConfirmarPagoPedidoAction(pedido)))
              .catch(() => {})
          }
        >
          Confirmar pago
        </Boton>
      )}
      onClose={() => onClose()}
    >
      <div className="op-form-group op-pestania">
        <span
          className={`${pedido.td == 0 ? 'activo' : ''}`}
          onClick={() => handleChange({ name: 'td', value: 0 })}
        >
          Boleta
        </span>
        <span
          className={`${pedido.td == 1 ? 'activo' : ''}`}
          onClick={() => handleChange({ name: 'td', value: 1 })}
        >
          Factura
        </span>
      </div>
      <div className="op-form-group">
        {selected.det.map((x) => (
          <div key={x.co} className="op-venta-item">
            <div className="op-item">{x.ns}</div>
            <div className="op-total">{`S/${formatoMonetario(
              redondeoDecimales(x.ps * x.ca)
            )}`}</div>
            <div className="op-descripcion">
              <span>{x.ca}</span>
              <span>{` -P. unit: S/ ${formatoMonetario(
                redondeoDecimales(x.ps)
              )} - ${getCategoria(x.cc)}`}</span>
            </div>
            {x.ds > 0 && (
              <div className="op-descripcion">
                <span>{`S/ ${formatoMonetario(
                  redondeoDecimales(x.ds * -1)
                )}`}</span>
              </div>
            )}
          </div>
        ))}
        {selected.md > 0 && (
          <div className="op-venta-item">
            <div className="op-item">Delivery</div>
            <div className="op-total">{`S/${formatoMonetario(
              redondeoDecimales(selected.md)
            )}`}</div>
            <div className="op-descripcion">
              <span>1</span>
              <span>{` -P. unitario`}</span>
            </div>
          </div>
        )}
      </div>
      <Texto
        name="ds"
        value={pedido.ds}
        label="Descuento global"
        tabIndex={8}
        size={2}
        restrict="money"
        error={errors.ds}
        onChange={handleChange}
        onBlur={(e) => {
          handleChange({
            name: 'ds',
            value: formatoMonetario(redondeoDecimales(e.target.value)),
          });
        }}
      />
      <Texto
        name="mg"
        value={pedido.mg}
        label="Motivo descuento global"
        tabIndex={9}
        size={2}
        error={errors.mg}
        onChange={handleChange}
      />
      <div className="op-form-group op-pestania">
        <span
          className={`${pedido.fp == 0 ? 'activo' : ''}`}
          onClick={() => {
            handleChange({ name: 'fp', value: 0 });
            handleChange({ name: 'ip', value: '' });
            handleChange({ name: 'tb', value: '' });
          }}
        >
          Efectivo
        </span>
        <span
          className={`${pedido.fp == 1 ? 'activo' : ''}`}
          onClick={() => {
            handleChange({ name: 'fp', value: 1 });
            handleChange({ name: 'pe', value: 0 });
            handleChange({ name: 'vu', value: 0 });
          }}
        >
          Tarjeta
        </span>
      </div>
      {pedido.fp === 0 ? (
        <>
          <Texto
            name="mp"
            value={formatoMonetario(
              redondeoDecimales(selected.mp + selected.md - selected.ms)
            )}
            label="Monto total"
            tabIndex={10}
            size={3}
            disabled
            onChange={() => {}}
          />
          <Texto
            name="pe"
            value={pedido.pe}
            label="Paga con"
            tabIndex={11}
            size={3}
            restrict={'money'}
            error={errors.pe}
            onChange={handleChange}
            onBlur={(e) => {
              let vuelto =
                parseFloat(e.target.value) -
                parseFloat(selected.mp + selected.md - selected.ms);
              handleChange({
                name: 'pe',
                value: formatoMonetario(redondeoDecimales(e.target.value)),
              });
              handleChange({
                name: 'vu',
                value: formatoMonetario(redondeoDecimales(vuelto)),
              });
            }}
          />
          <Texto
            name="vu"
            value={pedido.vu}
            label="Vuelto"
            tabIndex={12}
            size={3}
            disabled
            onChange={() => {}}
          />
        </>
      ) : (
        <>
          <Combo
            name="tb"
            value={pedido.tb}
            label="Bancos"
            tabIndex={14}
            size={2}
            error={errors.tb}
            data={tipoBancos.map((x) => ({ value: x.dt, label: x.de }))}
            onChange={handleChange}
          />
          <Texto
            name="ip"
            value={pedido.ip}
            label="N&uacute;mero de comprobante"
            tabIndex={15}
            size={2}
            error={errors.ip}
            onChange={handleChange}
          />
        </>
      )}
      <Texto
        name="oc"
        value={pedido.oc}
        label="Observaciones"
        tabIndex={16}
        error={errors.oc}
        onChange={handleChange}
      />
    </Modal>
  );
};

export default PedidoPagar;
