import React from 'react';
import { useSelector } from 'react-redux';

import { procedimientoModel } from '../../redux/models/procedimiento.model';
import { servicios } from '../../redux/helpers';
import Formulario from '../../commons/Formulario';

import ProcedimientoFilter from './ProcedimientoFilter';
import ProcedimientoItem from './ProcedimientoItem';
import ProcedimientoList from './ProcedimientoList';
import { TYPE_FILTER } from '../../redux/helpers/app.types';

const Procedimiento = () => {
  const { currentUser } = useSelector((state) => state.app);

  const initialState = {
    params: {
      em: currentUser.ie,
      st: '',
    },
    serviceName: servicios.PROCEDIMIENTO,
    selected: procedimientoModel,
    errors: procedimientoModel,
    openModal: false,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case TYPE_FILTER:
        return {
          ...state,
          params: {
            ...state.params,
            st:
              action.payload.st !== undefined
                ? action.payload.st === ''
                  ? null
                  : action.payload.st
                : state.params.st,
          },
        };
      default:
        return state;
    }
  };
  return (
    <Formulario reducer={reducer} initialState={initialState}>
      <ProcedimientoList />
      <ProcedimientoFilter />
      <ProcedimientoItem />
    </Formulario>
  );
};

export default Procedimiento;
