import React, { useState, useEffect } from 'react';
import Proptypes from 'prop-types';
import { useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import Boton from '../../commons/Boton';
import Busqueda from '../../commons/Busqueda';
import { useFormularioContext } from '../../commons/Formulario';
import Icon from '../../commons/Icon';
import { confirmacion } from '../../commons/Mensajes';
import Report from '../../components/app/Report';
import {
  formatoFecha,
  cambiarAccionEfecto,
  obtenerIcono,
  addNewLine,
} from '../../commons/Utilitarios';

import RobotIcon from '../../assets/icons/robot.svg';
import { servicios, columnsEquipments } from '../../redux/helpers';
import { solicitudModel } from '../../redux/models/solicitud.model';

const SolicitudEquipment = ({ onActions, bitacora }) => {
  const { selected, dispatch, equipment, callTransaction } =
    useFormularioContext();
  const { currentUser } = useSelector((state) => state.app);

  const [solicitud, setSolicitud] = useState(selected);
  const [showEquipments, setShowEquipments] = useState(false);
  const [equipments, setEquipments] = useState(selected.qq);
  const [openReporte, setOpenReporte] = useState(false);
  const [{ cs, wo, pv }, setMaintenance] = useState({
    cs: '',
    pv: true,
    wo: false,
  });

  useEffect(() => {
    setSolicitud(selected);
    setEquipments(selected.qq);
  }, [selected.so]);

  const handleAddEquipment = (item) => {
    confirmacion('¿Desea agregar?', `El equipo ${item.ne} a la solicitud`)
      .then(() => {
        let request = {
          so: solicitud.so,
          ie: currentUser.ie,
          eq: item.eq,
        };
        callTransaction({
          method: 'post',
          url: `${servicios.SOLICITUD}/equipo`,
          data: request,
          model: solicitudModel,
        });
      })
      .catch(() => {})
      .finally(() => setShowEquipments(false));
  };

  const handleDeleteEquipment = (item) => {
    confirmacion(
      '¿Esta seguro?',
      `De quitar el equipo ${item.ne} de la solicitud`
    )
      .then(() => {
        let request = {
          so: solicitud.so,
          ie: currentUser.ie,
          eq: item.eq,
        };
        callTransaction({
          method: 'put',
          url: `${servicios.SOLICITUD}/equipo`,
          data: request,
          model: solicitudModel,
        });
      })
      .catch(() => {});
  };
  return (
    <div className="detail-equipments">
      <div className="detail-info">
        <span>Cliente:</span>
        <span>{solicitud.ii?.dn}</span>
      </div>
      <div className="detail-info">
        <span>Direcci&oacute;n:</span>
        <span>{solicitud.ii?.di}</span>
      </div>
      <div className="detail-info">
        <span>Problema:</span>
        <span>{addNewLine(solicitud.ds)}</span>
      </div>
      {solicitud.ep === 1 && (
        <>
          <div className="detail-info">
            <span>Motivo Cancelaci&oacute;n:</span>
            <span>{solicitud.mc}</span>
          </div>
        </>
      )}
      {solicitud.ep === 3 && (
        <div className="detail-info">
          <span>Motivo Reasignaci&oacute;n:</span>
          <span>{solicitud.mc}</span>
        </div>
      )}
      <div className="detail-info">
        <span>Notas:</span>
        <span>{addNewLine(solicitud.no)}</span>
      </div>
      {bitacora && bitacora()}
      {solicitud.ep !== 1 && (
        <div className="container">
          {equipments?.map((m) => (
            <div
              key={m.eq?.eq}
              className={`equipment ${
                equipment && equipment.eq?.eq === m.eq?.eq ? 'active' : ''
              }`}
              onClick={() =>
                dispatch({ type: 'SELECTED_EQUIPMENT', payload: m })
              }
            >
              <Icon svg={RobotIcon} size={30} transparent />
              <span>{m.eq?.ce}</span>
              <span>{m.eq?.ne}</span>
              {solicitud.ep !== 2 && (
                <div className="close">
                  <CancelIcon
                    color="secondary"
                    onClick={() => handleDeleteEquipment(m.eq)}
                  />
                </div>
              )}
            </div>
          ))}
          {onActions && onActions()}
          {solicitud.ep !== 2 && (
            <Boton
              className="op-dinamic"
              icon={() => <AddIcon />}
              tabIndex={18}
              style={{ '--button-color': '#3285a8' }}
              onClick={() => setShowEquipments(true)}
              autoFocus
            >
              Agregar equipo
            </Boton>
          )}
          {solicitud.ep == 2 && (
            <>
              <Boton
                className="op-dinamic"
                icon={() => <PictureAsPdfIcon />}
                tabIndex={18}
                style={{ '--button-color': 'red' }}
                onClick={() => {
                  setMaintenance({ cs: solicitud.so, pv: true });
                  setOpenReporte(true);
                }}
              >
                Generar informe
              </Boton>
              <Boton
                className="op-dinamic"
                icon={() => (
                  <Icon svg={obtenerIcono('doc')} color={'#295394'} />
                )}
                tabIndex={18}
                style={{ '--button-color': '#295394' }}
                onClick={() => {
                  setMaintenance({ cs: solicitud.so, pv: true, wo: true });
                  setOpenReporte(true);
                }}
              >
                Generar informe
              </Boton>
            </>
          )}
        </div>
      )}
      {showEquipments && (
        <Busqueda
          serviceName={servicios.EQUIPO}
          title="B&uacute;squeda de equipos"
          columns={columnsEquipments((item) => handleAddEquipment(item))}
          params={{ cl: solicitud.ii?.cl, st: '1' }}
          emptyRowsMessage="Cliente no tiene equipos"
          onClose={() => setShowEquipments(false)}
          onDoubleClick={(item) => handleAddEquipment(item)}
        />
      )}
      {openReporte && (
        <Report
          url={`${servicios.MANTENIMIENTO}/${currentUser.ie}/informe`}
          method="put"
          data={{
            cs,
            pv,
            wo,
          }}
          extension={wo ? 'doc' : 'pdf'}
          filename="Informe"
          onClose={() => {
            setMaintenance({ cs: '', pv: true, wo: false });
            setOpenReporte(false);
          }}
        />
      )}
    </div>
  );
};

SolicitudEquipment.propTypes = {
  onActions: Proptypes.func,
  bitacora: Proptypes.func,
};

export default SolicitudEquipment;
