import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';

import Adjunto from '../../commons/Adjunto';
import Boton from '../../commons/Boton';
import CheckBox from '../../commons/CheckBox';
import Modal from '../../commons/Modal';
import Texto from '../../commons/Texto';
import { useFormularioContext } from '../../commons/Formulario';

import { confirmacion } from '../../commons/Mensajes';

import ClienteList from '../../components/cliente/ClienteList';

import { getClientesAction } from '../../redux/actions/cliente.action';
import { equipoModel } from '../../redux/models/equipo.model';
import { servicios } from '../../redux/helpers';

const EquipoItem = () => {
  const dispatch = useDispatch();
  const {
    selected,
    openModal,
    processing,
    errors,
    callTransaction,
    cancelTransaction,
  } = useFormularioContext();
  const [equipo, setEquipo] = useState(selected);
  const [showClient, setShowClient] = useState(false);
  const { currentUser } = useSelector((state) => state.app);

  const handleChange = (item) => {
    const { name, value } = item;
    setEquipo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    setEquipo(selected);
    if (selected.cl) {
      handleChange({ name: 'scl', value: selected.cl?.cl });
      handleChange({ name: 'nc', value: selected.cl?.dn });
    }
  }, [selected, openModal]);

  if (!openModal) {
    return null;
  }

  const handleSave = () => {
    let item = {
      ...equipo,
      ie: currentUser.ie,
    };
    callTransaction({
      method: 'post',
      url: servicios.EQUIPO,
      data: item,
      model: equipoModel,
    });
  };

  const handleSaveDocument = (documento) => {
    let form_data = new FormData();
    form_data.append('file', documento);
    form_data.append('eq', equipo.eq);
    form_data.append('ie', currentUser.ie);

    callTransaction({
      method: 'patch',
      url: `${servicios.EQUIPO}/adjuntar`,
      data: form_data,
      headers: { 'content-type': 'multipart/form-data; charset=utf-8' },
      model: equipoModel,
    });
  };
  return (
    <>
      <Modal
        title={`${equipo.eq ? 'Editar ' + equipo.ne : 'Nuevo equipo'}`}
        processing={processing}
        onActions={() => (
          <>
            <Boton
              className="op-grabar"
              disabled={processing}
              icon={() => <SaveIcon />}
              tabIndex={19}
              onClick={() =>
                confirmacion(
                  '¿Desea guardar?',
                  `${
                    equipo.eq
                      ? 'Una actualización de ' + equipo.ne
                      : 'Un nuevo equipo'
                  }`
                )
                  .then(() => handleSave())
                  .catch(() => {})
              }
            >
              Guardar
            </Boton>
          </>
        )}
        onClose={() => cancelTransaction(equipoModel)}
      >
        {equipo.eq === null && (
          <Texto
            name="ce"
            value={equipo.ce}
            label="C&oacute;digo"
            tabIndex={10}
            size={2}
            error={errors.ce}
            onChange={handleChange}
            disabled={equipo.gc}
          />
        )}
        <Texto
          name="ne"
          value={equipo.ne}
          label="Nombre"
          size={2}
          tabIndex={11}
          error={errors.ne}
          onChange={handleChange}
        />
        <Texto
          name="mr"
          value={equipo.mr}
          label="Marca"
          size={2}
          tabIndex={12}
          error={errors.mr}
          onChange={handleChange}
        />
        <Texto
          name="ns"
          value={equipo.ns}
          label="N&uacute;mero de serie"
          size={2}
          tabIndex={13}
          error={errors.ns}
          onChange={handleChange}
        />
        <Texto
          name="mo"
          value={equipo.mo}
          label="Modelo"
          size={2}
          tabIndex={14}
          error={errors.mo}
          onChange={handleChange}
        />
        <Texto
          name="pn"
          value={equipo.pn}
          label="C&oacute;digo PNC"
          size={2}
          tabIndex={15}
          error={errors.pn}
          onChange={handleChange}
        />
        <Texto
          name="scl"
          value={equipo.nc}
          label="Cliente"
          size={2}
          tabIndex={16}
          disabled
          error={errors.scl}
          icon={() => (
            <SearchIcon
              onClick={() => {
                setShowClient(true);
                dispatch(getClientesAction({}));
              }}
            />
          )}
          onChange={() => {}}
        />
        <Texto
          name="dir"
          value={equipo.dir}
          label="Direcci&oacute;n cliente"
          size={2}
          tabIndex={16}
          disabled
          onChange={() => {}}
        />
        {equipo.eq ? (
          <>
            <Texto
              name="cb"
              value={equipo.cb}
              label="C&oacute;digo barra"
              size={2}
              tabIndex={17}
              error={errors.cb}
              onChange={handleChange}
            />
            <div className="op-form-group op-col-2 op-checkbox-group">
              <span>Estado del registro</span>
              <CheckBox
                name="st"
                tabIndex={18}
                checked={equipo.st}
                onChange={handleChange}
              />
            </div>
          </>
        ) : (
          <div className="op-form-group op-col-2 op-checkbox-group">
            <span>¿Desea autogenerar el c&oacute;digo?</span>
            <CheckBox
              name="gc"
              tabIndex={19}
              checked={equipo.gc}
              onChange={handleChange}
            />
          </div>
        )}
        <Texto
          name="de"
          value={equipo.de}
          label="Descripci&oacute;n"
          tabIndex={20}
          error={errors.de}
          onChange={handleChange}
        />
        {equipo.eq && (
          <Adjunto
            stateParent={equipo.st}
            nameParent={'equipo'}
            extensions={['jpg', 'jpeg', 'png']}
            processing={processing}
            data={
              equipo.im &&
              equipo.im.dtl &&
              equipo.im.dtl.map((a) => ({
                id: a.cr,
                name: a.na,
                extension: a.ea,
                state: a.st,
              }))
            }
            file={equipo.im && equipo.im.ar}
            onNew={(documento) => handleSaveDocument(documento)}
          />
        )}
      </Modal>
      {showClient && (
        <ClienteList
          searchable
          onSend={(item) => {
            handleChange({ name: 'scl', value: item.cl });
            handleChange({ name: 'nc', value: item.dn });
            handleChange({ name: 'dir', value: item.di });
          }}
          onClose={() => setShowClient(false)}
        />
      )}
    </>
  );
};

export default EquipoItem;
