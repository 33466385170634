export const cotizacionModel = {
  co: '',
  ie: '',
  is: '',
  cc: '',
  gf: '',
  tm: 0,
  cp: '',
  vd: 0,
  ii: false,
  re: '',
  rq: '',
  nt: '',
  de: [],
};
