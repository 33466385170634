import React, { useState, useEffect } from 'react';
import Proptypes from 'prop-types';
import { useSelector } from 'react-redux';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';

import Boton from '../../commons/Boton';
import Calendario from '../../commons/Calendario';
import Combo from '../../commons/Combo';
import Icon from '../../commons/Icon';
import Texto from '../../commons/Texto';

import { useFormularioContext } from '../../commons/Formulario';
import { formatoFecha } from '../../commons/Utilitarios';
import { confirmacion } from '../../commons/Mensajes';

import { servicios } from '../../redux/helpers';
import { solicitudModel } from '../../redux/models/solicitud.model';
import useUsuario from '../../hooks/useUsuario';
import useSolicitud from '../../hooks/useSolicitud';

import EngineerIcon from '../../assets/icons/engineer.svg';

const SolicitudTecnicians = ({ onActions }) => {
  const { selected, errors, processing, callTransaction } =
    useFormularioContext();
  const { processSolicitud, requests, getAssignedRequest } = useSolicitud();
  const { currentUser } = useSelector((state) => state.app);
  const { sucursales } = useSelector((state) => state.sucursal);
  const { processingUser, users, getUsersByStore } = useUsuario();

  const [solicitud, setSolicitud] = useState(selected);
  const [tecnicians, setTecnicians] = useState([]);
  const [sucursal, setSucursal] = useState('');
  const [usuario, setUsuario] = useState('');
  const [reasign, setReasign] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const [mesAsignado] = useState(new Date());

  useEffect(() => {
    if (currentUser.tr === 'NOBASICO') {
      getAssignedRequest(mesAsignado);
    }
  }, []);

  useEffect(() => {
    setSolicitud(selected);
    setTecnicians(selected.ta ? selected.ta : []);
  }, [selected.so]);

  const handleChange = (item) => {
    const { name, value } = item;
    setSolicitud((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddTecnician = (item) => {
    let exist = tecnicians.find((x) => x.us === item.value);
    if (exist === undefined) {
      setTecnicians([...tecnicians, { us: item.value, np: item.label }]);
    }
  };

  const handleDeleteTecnician = (item) => {
    setTecnicians(tecnicians.filter((x) => x.us !== item));
  };

  const handleSaveTecnicians = (item) => {
    setShowButtons(item.ep === 0);
    if (!reasign && solicitud.ep === 3) {
      setReasign(true);
      setTecnicians([]);
      return;
    }
    confirmacion('¿Está seguro?', `De ${item.na.toLowerCase()}`)
      .then(() => {
        let request = {
          so: solicitud.so,
          ie: currentUser.ie,
          ua: currentUser.us,
          fv: solicitud.fv,
          tt: item.ep === 3 ? [] : tecnicians.map((m) => m.us),
          ia: item.pe,
          mc: solicitud.mc,
        };
        callTransaction({
          method: 'patch',
          url: `${servicios.SOLICITUD}/tecnico`,
          data: request,
          model: solicitudModel,
        });
      })
      .catch(() => {});
  };

  return (
    <div className="detail-equipments">
      <div className="op-form">
        {selected.fv && !reasign ? (
          <Texto
            name="fv"
            value={formatoFecha(solicitud.fv)}
            label="Fecha visita t&eacute;cnica"
            tabIndex={49}
            size={2}
            onChange={() => {}}
            disabled={true}
          />
        ) : (
          <>
            {showButtons && (
              <>
                <Combo
                  name="sucursal"
                  value={sucursal}
                  label="Sucursal"
                  size={2}
                  tabIndex={30}
                  disabled={processingUser || processing}
                  data={sucursales.map((x) => ({ value: x.su, label: x.ns }))}
                  onChange={(item) => {
                    setSucursal(item.value);
                    if (item.value) {
                      getUsersByStore(item.value);
                    }
                  }}
                />
                <Combo
                  name="usuario"
                  value={usuario}
                  label="Técnico"
                  size={2}
                  tabIndex={30}
                  disabled={processingUser || processing}
                  data={users.map((x) => ({ value: x.us, label: x.np }))}
                  onChange={(item) => {
                    setUsuario(item.value);
                    if (item.value) {
                      confirmacion('¿Está seguro?', `De agregar técnico`)
                        .then(() => handleAddTecnician(item))
                        .catch(() => {});
                    }
                  }}
                />
              </>
            )}
            <Calendario
              label={'Fecha visita técnica'}
              name={'fv'}
              value={solicitud.fv}
              tabIndex={49}
              size={2}
              showTime
              minDate={new Date()}
              error={errors.fv}
              closeOnSelectTime
              onSelected={handleChange}
            />
          </>
        )}
        {solicitud.ee.va === '2' && (
          <Texto
            name="mc"
            value={solicitud.mc}
            label="Motivo de reasignaci&oacute;n"
            tabIndex={50}
            onChange={handleChange}
          />
        )}
      </div>
      <div className="container">
        {errors.tt && <span className="op-error">{errors.tt}</span>}
        {tecnicians.map((m) => (
          <div key={m.us} className="equipment">
            <Icon svg={EngineerIcon} size={30} transparent />
            <span></span>
            <span>{m.np}</span>
            {!(selected.fv && !reasign) && (
              <div className="close">
                <CancelIcon
                  color="secondary"
                  onClick={() => handleDeleteTecnician(m.us)}
                />
              </div>
            )}
          </div>
        ))}
        {onActions.map((x, i) => (
          <Boton
            key={x.pe}
            className="op-dinamic"
            disabled={processing}
            icon={() => <SaveIcon />}
            style={{ '--button-color': x.co }}
            tabIndex={21 + i}
            onClick={() => handleSaveTecnicians(x)}
          >
            {x.na}
          </Boton>
        ))}
      </div>
      {(selected.fv === null || reasign) && currentUser.tr === 'NOBASICO' && (
        <>
          <Calendario
            showWeek
            showInline
            tabIndex={50}
            events={requests.map((m) => ({
              key: m.so,
              datetime: new Date(m.fv),
              messages: [m.cs, `Cliente: ${m.ii.dn}`, `Técnico: ${m.ta[0].np}`],
            }))}
            onSelected={() => {}}
            onSelectedMonth={({ month, year }) =>
              getAssignedRequest(new Date(year, month, 1))
            }
          />
        </>
      )}
    </div>
  );
};

SolicitudTecnicians.propTypes = {
  onActions: Proptypes.arrayOf(Proptypes.object),
};

export default SolicitudTecnicians;
