import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

import Texto from '../../commons/Texto';
import Filtros from '../../commons/Filtros';
import { useTablaFilter } from '../../commons/Formulario';

import { getClientesAction } from '../../redux/actions/cliente.action';
import ClienteList from '../../components/cliente/ClienteList';

import { TYPE_FILTER } from '../../redux/helpers/app.types';

const CotizacionFilter = () => {
  const dispatchMain = useDispatch();
  const { getData, params, dispatch } = useTablaFilter();
  const [showClient, setShowClient] = useState(false);
  const [cliente, setCliente] = useState(null);
  const [registro, setRegistro] = useState(null);
  return (
    <>
      <Filtros
        onFilter={() => getData()}
        onNew={() => dispatch({ type: 'MODAL_NEW' })}
      >
        <Texto
          name="cl"
          value={cliente}
          label="Cliente"
          size={3}
          tabIndex={201}
          disabled
          icon={() => (
            <>
              {params.cl && (
                <CloseIcon
                  style={{ color: 'red' }}
                  onClick={() => {
                    dispatch({
                      type: TYPE_FILTER,
                      payload: { cl: null, fr: params.fr },
                    });
                    setCliente(null);
                  }}
                />
              )}
              <SearchIcon
                onClick={() => {
                  setShowClient(true);
                  dispatchMain(getClientesAction({}));
                }}
              />
            </>
          )}
          onChange={() => {}}
        />
        <Texto
          name="fr"
          value={registro}
          label="Fecha de registro"
          tabIndex={202}
          size={3}
          type={'date'}
          onChange={({ value }) => {
            setRegistro(value);
            dispatch({
              type: TYPE_FILTER,
              payload: { cl: params.cl, fr: value },
            });
          }}
        />
      </Filtros>
      {showClient && (
        <ClienteList
          searchable
          onSend={(item) => {
            dispatch({
              type: TYPE_FILTER,
              payload: { cl: item.cl, fr: params.fr },
            });
            setCliente(item.dn);
          }}
          onClose={() => setShowClient(false)}
        />
      )}
    </>
  );
};

export default CotizacionFilter;
