import React, { useState } from 'react';
import { connect } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Boton from '../../commons/Boton';
import Adjunto from '../../commons/Adjunto';
import { confirmacion } from '../../commons/Mensajes';
import {
  formatoFecha,
  formatoMonetario,
  redondeoDecimales,
} from '../../commons/Utilitarios';

import {
  closeModal,
  getSeleccionarOperacionCajaAction,
  patchEliminarOperacionCajaAction,
  patchAdjuntarArchivoOperacionCajaAction,
} from '../../redux/actions/caja.action';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';

const CajaView = ({
  operation,
  processing,
  closeModal,
  getSeleccionarOperacionCajaAction,
  patchEliminarOperacionCajaAction,
  patchAdjuntarArchivoOperacionCajaAction,
}) => {
  const [operacion] = useState(operation);

  return (
    <>
      <Modal
        title={`Detalle operación ${formatoFecha(operacion.fo)}`}
        processing={processing}
        onActions={() =>
          operacion.st && (
            <>
              <Boton
                className="op-dinamic"
                disabled={processing}
                icon={() => <DeleteForeverIcon />}
                tabIndex={19}
                style={{ '--button-color': 'red' }}
                onClick={() =>
                  confirmacion(
                    '¿Está seguro de eliminar la operación?',
                    'Ya no podra revertir los cambios'
                  )
                    .then(() => patchEliminarOperacionCajaAction(operacion))
                    .catch(() => {})
                }
              >
                Eliminar operaci&oacute;n
              </Boton>
              <Boton
                className="op-grabar"
                disabled={processing}
                icon={() => <EditIcon />}
                tabIndex={21}
                onClick={() => {
                  getSeleccionarOperacionCajaAction(operacion);
                }}
              >
                Rectificar operaci&oacute;n
              </Boton>
            </>
          )
        }
        onClose={() => closeModal()}
      >
        <Texto
          name="rp"
          value={operacion.rp}
          label="Raz&oacute;n social proveedor (opcional)"
          size={2}
          tabIndex={10}
          onChange={() => {}}
          disabled={true}
        />
        <Texto
          name="up"
          value={operacion.up}
          label="Ruc proveedor (opcional)"
          size={2}
          tabIndex={11}
          onChange={() => {}}
          disabled={true}
        />
        <Texto
          name="dd"
          value={operacion.dd}
          label="Tipo documento (opcional)"
          size={3}
          tabIndex={12}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="ns"
          value={operacion.ns}
          label="N&uacute;mero serie (opcional)"
          size={3}
          tabIndex={13}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="nd"
          value={operacion.nd}
          label="Numeraci&oacute;n documento (opcional)"
          size={3}
          tabIndex={14}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="co"
          value={operacion.co}
          label="Concepto"
          size={2}
          tabIndex={15}
          onChange={() => {}}
          disabled
          autoFocus
        />
        <Texto
          name="dt"
          value={operacion.dt}
          label="Tipo de caja"
          size={2}
          tabIndex={16}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="mo"
          value={formatoMonetario(redondeoDecimales(operacion.mo))}
          label={`Monto`}
          size={2}
          tabIndex={17}
          restrict={'money'}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="tm"
          value={operacion.tm === 0 ? 'Soles' : 'Dolares'}
          label="Moneda"
          size={2}
          tabIndex={18}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="ic"
          value={formatoMonetario(redondeoDecimales(operacion.ic))}
          label={`IGV`}
          size={2}
          tabIndex={19}
          restrict={'money'}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="pc"
          value={formatoMonetario(redondeoDecimales(operacion.pc))}
          label={`Percepción`}
          size={2}
          tabIndex={20}
          restrict={'money'}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="ia"
          value={operacion.ia}
          label="Informaci&oacute;n (opcional)"
          size={2}
          tabIndex={21}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="tm"
          value={operacion.is === 0 ? 'Salida' : 'Ingreso'}
          label="Movimiento"
          size={2}
          tabIndex={22}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="nu"
          value={operacion.nu}
          label="Responsable"
          size={2}
          tabIndex={23}
          onChange={() => {}}
          disabled
        />
        {operacion.st === false && (
          <Texto
            name="st"
            value={'Eliminado'}
            label="Estado del registro"
            size={2}
            tabIndex={24}
            onChange={() => {}}
            disabled
            info="Las operaciones eliminadas no se consideran para c&aacute;lculo de saldos, y solo quedan como registro hist&oacute;rico"
          />
        )}
        <Adjunto
          stateParent={operacion.st}
          nameParent={'caja'}
          processing={processing}
          data={
            operacion.arc &&
            operacion.arc.dtl &&
            operacion.arc.dtl.map((a) => ({
              id: a.cr,
              name: a.na,
              extension: a.ea,
              state: a.st,
            }))
          }
          file={operacion.arc && operacion.arc.ar}
          onNew={(documento) =>
            patchAdjuntarArchivoOperacionCajaAction(operacion, documento)
          }
        />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    processing: state.caja.processing,
    operation: state.caja.operacion,
  };
};

export default connect(mapStateToProps, {
  closeModal,
  getSeleccionarOperacionCajaAction,
  patchEliminarOperacionCajaAction,
  patchAdjuntarArchivoOperacionCajaAction,
})(CajaView);
