import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import { servicios } from '../../redux/helpers/servicios';
import { api } from '../../redux/axios/api';

import '../../css/commons/modal.css';

const File = ({ filename, onClose, dir, showInline, service }) => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    let params = new URLSearchParams();

    if (dir) {
      dir.forEach((x) => {
        params.append('di', x);
      });
    }

    if (filename) {
      api
        .get(service ? service : `${servicios.FILES}/image64/${filename}`, {
          params,
        })
        .then((response) => {
          setUrl(response);
        })
        .catch(async (error) => {
          const text = await new Response(error.data).text();
          const errorjson = JSON.parse(text);

          let message = 'Error al generar documento';
          if (errorjson.detail) {
            message = errorjson.detail;
          } else if (errorjson.errors) {
            const [first] = Object.values(errorjson.errors);
            message = first;
          }

          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 6000,
            timerProgressBar: true,
          });

          Toast.fire({
            icon: error.status === 500 ? 'error' : 'warning',
            title: message,
          });
          onClose();
        });
    }
  }, []);

  const handleLoad = () => {
    if (filename) {
      return (
        <>
          {/[^.]+$/.exec(filename)[0].toLowerCase() === 'pdf' ? (
            <>
              {url && (
                <div style={{ width: '100%' }}>
                  <center>
                    <object
                      data={url}
                      width={'100%'}
                      height={'500px'}
                      type={'application/pdf'}
                    />
                  </center>
                </div>
              )}
            </>
          ) : (
            <img
              src={url}
              alt="Imagen"
              style={{ width: '100%', objectFit: 'cover' }}
            />
          )}
        </>
      );
    } else {
      return <span>No ha seleccionado ning&uacute;n archivo</span>;
    }
  };

  return (
    <>
      {showInline ? (
        handleLoad()
      ) : (
        <div className="op-content-modal">
          <div className="op-modal op-big content-imagen">
            <div className="op-modal-header">
              <div className="op-modal-close" onClick={() => onClose()}></div>
            </div>
            <div className="op-modal-body">{handleLoad()}</div>
          </div>
        </div>
      )}
    </>
  );
};

File.propTypes = {
  filename: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  dir: PropTypes.array,
  showInline: PropTypes.bool,
  service: PropTypes.string,
};

File.defaultProps = {
  dir: [],
};

export default File;
