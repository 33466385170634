import * as fromInventario from '../actions/inventario.action';
import { invetarioModel } from '../models/inventario.model';

let initialState = {
  inventarios: [],
  su: null,
  ct: null,
  mt: null,
  st: null,
  search: null,
  total: 0,
  processing: false,
  selected: null,
  openModal: false,
  openModalConvertir: false,
  openModalTraslado: false,
  openModalInicial: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromInventario.INVENTARIO_PROCESS:
      return {
        ...state,
        processing: true,
        errors: invetarioModel,
      };
    case fromInventario.INVENTARIO_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        inventarios: action.payload.data,
        total: action.payload.count,
      };
    case fromInventario.INVENTARIO_LIST_FAIL:
      return {
        ...state,
        processing: false,
        inventarios: [],
        total: 0,
      };
    case fromInventario.INVENTARIO_SELECTED:
      return {
        ...state,
        selected: action.payload,
        errors: invetarioModel,
        openModal: true,
        processing: false,
      };
    case fromInventario.INVENTARIO_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        errors: invetarioModel,
        openModal: false,
        openModalConvertir: false,
        openModalInicial: false,
        selected: null,
      };
    case fromInventario.INVENTARIO_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromInventario.INVENTARIO_FILTER:
      return {
        ...state,
        su: action.payload.su,
        ct: action.payload.ct,
        mt: action.payload.mt,
        st: action.payload.st,
        search: action.payload.search,
      };
    case fromInventario.INVENTARIO_TRASLADO:
      return {
        ...state,
        openModalTraslado: true,
      };
    case fromInventario.INVENTARIO_TURN_INTO:
      return {
        ...state,
        openModalConvertir: true,
        selected: action.payload,
      };
    case fromInventario.INVENTARIO_INITIAL:
      return {
        ...state,
        openModalInicial: true,
      };
    case fromInventario.INVENTARIO_CLOSE:
      return {
        ...state,
        selected: null,
        errors: invetarioModel,
        openModal: false,
        openModalTraslado: false,
        openModalConvertir: false,
        openModalInicial: false,
      };
    default:
      return state;
  }
}
