import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Boton from '../../commons/Boton';
import Combo from '../../commons/Combo';
import { confirmacion } from '../../commons/Mensajes';

import {
  closeModal,
  postCorrelativoAction,
} from '../../redux/actions/correlativo.action';

import SaveIcon from '@material-ui/icons/Save';

const CorrelativoNew = () => {
  const { selected, processing, errors } = useSelector(
    (state) => state.correlativo
  );
  const [correlativo, setCorrelativo] = useState(selected);

  const dispatch = useDispatch();

  const handleChange = (item) => {
    const { name, value } = item;
    setCorrelativo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Modal
      title={`Nuevo correlativo`}
      processing={processing}
      size="small"
      onActions={() => (
        <Boton
          className="op-grabar"
          disabled={processing}
          icon={() => <SaveIcon />}
          tabIndex={21}
          onClick={() =>
            confirmacion('¿Desea guardar?', `El nuevo correlativo`)
              .then(() => dispatch(postCorrelativoAction(correlativo)))
              .catch(() => {})
          }
        >
          Guardar
        </Boton>
      )}
      onClose={() => dispatch(closeModal())}
    >
      <Combo
        name="td"
        value={correlativo.td}
        label="Tipo documento"
        tabIndex={10}
        data={[
          { value: 'BOLETA', label: 'Boleta' },
          { value: 'FACTURA', label: 'Factura' },
          { value: 'GUIA', label: 'Guía de remisión' },
          { value: 'PRODUCTO', label: 'Producto' },
          { value: 'INSUMO', label: 'Insumo' },
          { value: 'SERVICIO', label: 'Servicio' },
          { value: 'ORDENCOMPRA', label: 'Orden de compra' },
          { value: 'LOTEINVENTARIO', label: 'Lote de inventario' },
          { value: 'CONTRATO', label: 'Contrato' },
        ]}
        onChange={handleChange}
      />
      <Texto
        name="se"
        value={correlativo.se}
        label="Serie"
        tabIndex={12}
        restrict="int"
        error={errors.se}
        onChange={handleChange}
      />
      <Texto
        name="nc"
        value={correlativo.nc}
        label="Correlativo"
        tabIndex={13}
        restrict="int"
        error={errors.nc}
        onChange={handleChange}
      />
    </Modal>
  );
};

export default CorrelativoNew;
