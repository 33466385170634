import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

import Icon from '../../commons/Icon';
import Modal from '../../commons/Modal';
import { useFormularioContext } from '../../commons/Formulario';

import MantenimientoDetail from './MantenimientoDetail';
import MantenimientoTesting from './MantenimientoTesting';
import MantenimientoSupply from './MantenimientoSupply';

import { mantenimientoModel } from '../../redux/models/mantenimiento.model';

import Boton from '../../commons/Boton';
import Texto from '../../commons/Texto';

import { servicios } from '../../redux/helpers';
import RequestIcon from '../../assets/icons/file-request.svg';
import TestIcon from '../../assets/icons/test-tube.svg';
import SuppliesIcon from '../../assets/icons/supplies.svg';
import { confirmacion } from '../../commons/Mensajes';

const MantenimientoItem = () => {
  const { currentUser } = useSelector((state) => state.app);
  const {
    selected,
    errors,
    openModal,
    processing,
    cancelTransaction,
    callTransaction,
  } = useFormularioContext();
  const [maintenance, setMaintenance] = useState(selected);
  const [childSelected, setChildSelected] = useState(0);
  const [showEdit, setshowEdit] = useState(false);

  useEffect(() => {
    setMaintenance(selected);
    setChildSelected(0);
  }, [selected, openModal]);

  const handleChange = (item) => {
    const { name, value } = item;
    setMaintenance((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  if (!openModal) {
    return null;
  }

  const handleUpdateMaintenance = () => {
    let request = {
      ie: currentUser.ie,
      eq: maintenance.eq.eq,
      ua: currentUser.us,
      hi: maintenance.hi,
      dm: maintenance.dm,
      rc: maintenance.rc,
    };
    callTransaction({
      method: 'put',
      url: `${servicios.MANTENIMIENTO}`,
      data: request,
      model: mantenimientoModel,
    });
  };

  return (
    <>
      <Modal
        title={`Equipo - ${maintenance.eq?.ne}`}
        processing={processing}
        onActions={() => (
          <Boton
            className="op-dinamic"
            icon={() => <EditIcon />}
            tabIndex={18}
            style={{ '--button-color': '#3285a8' }}
            onClick={() => setshowEdit(true)}
          >
            Actualizar
          </Boton>
        )}
        onClose={() => cancelTransaction(mantenimientoModel)}
      >
        <div
          className="op-detail-container"
          style={{ gridTemplateColumns: 'repeat(3, 1fr)' }}
        >
          <div
            className={`op-detail-icon ${childSelected === 0 ? 'active' : ''}`}
            onClick={() => setChildSelected(0)}
          >
            <Icon svg={RequestIcon} size={50} transparent />
            <span>Solicitud</span>
          </div>
          <div
            className={`op-detail-icon ${childSelected === 2 ? 'active' : ''}`}
            onClick={() => setChildSelected(2)}
          >
            <Icon svg={TestIcon} size={50} transparent />
            <span>Testeos</span>
          </div>
          <div
            className={`op-detail-icon ${childSelected === 3 ? 'active' : ''}`}
            onClick={() => setChildSelected(3)}
          >
            <Icon svg={SuppliesIcon} size={50} transparent />
            <span>Insumos</span>
          </div>
          <div className="op-detail-main">
            <div
              className={`op-detail-component ${
                childSelected === 0 ? 'active' : ''
              }`}
            >
              <MantenimientoDetail />
            </div>
            <div
              className={`op-detail-component ${
                childSelected === 2 ? 'active' : ''
              }`}
            >
              <MantenimientoTesting />
            </div>
            <div
              className={`op-detail-component ${
                childSelected === 3 ? 'active' : ''
              }`}
            >
              <MantenimientoSupply />
            </div>
          </div>
        </div>
      </Modal>
      {showEdit && (
        <Modal
          title="Actualizar Mantenimiento"
          size={'medium'}
          processing={processing}
          closeWithoutConfirmation
          onActions={() => (
            <Boton
              className="op-grabar"
              disabled={processing}
              icon={() => <SaveIcon />}
              tabIndex={19}
              onClick={() =>
                confirmacion(
                  '¿Está seguro?',
                  `De actualizar el mantenimiento del equipo ${maintenance.eq?.ne}`
                )
                  .then(() => handleUpdateMaintenance())
                  .catch(() => {})
              }
            >
              Actualizar
            </Boton>
          )}
          onClose={() => setshowEdit(false)}
        >
          <Texto
            name="dm"
            value={maintenance.dm}
            label="Mantenimiento"
            tabIndex={30}
            error={errors.dm}
            onChange={handleChange}
          />
          <Texto
            name="rc"
            value={maintenance.rc}
            label="Recomendaciones y/o observaciones"
            tabIndex={31}
            error={errors.rc}
            onChange={handleChange}
          />
        </Modal>
      )}
    </>
  );
};

export default MantenimientoItem;
