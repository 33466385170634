import React, { useEffect, useState } from 'react';

import Modal from '../../commons/Modal';
import Texto from '../../commons/Texto';
import { useFormularioContext } from '../../commons/Formulario';

import { operacionModel } from '../../redux/models/operacion.model';
import { formatoFecha, formatoMonetario } from '../../commons/Utilitarios';

const OperacionItem = () => {
  const { selected, openModal, cancelTransaction } = useFormularioContext();
  const [operation, setOperation] = useState(selected);

  useEffect(() => {
    setOperation(selected);
  }, [selected, openModal]);

  if (!openModal) {
    return null;
  }

  return (
    <Modal
      title={`Operacion - ${operation.nm}`}
      closeWithoutConfirmation
      onClose={() => cancelTransaction(operacionModel)}
    >
      <Texto
        name="fo"
        value={formatoFecha(operation.fo, '', false)}
        label="Fecha de operaci&oacute;n"
        size={2}
        tabIndex={10}
        disabled
        onChange={() => {}}
      />
      <Texto
        name="ns"
        value={operation.ns}
        label="Sucursal"
        size={2}
        tabIndex={11}
        disabled
        onChange={() => {}}
      />
      <Texto
        name="nm"
        value={operation.nm}
        label="Producto / Insumo"
        size={2}
        tabIndex={12}
        disabled
        onChange={() => {}}
      />
      <Texto
        name="dt"
        value={operation.dt}
        label="Tipo operaci&oacute;n"
        size={2}
        tabIndex={13}
        disabled
        onChange={() => {}}
      />
      <Texto
        name="um"
        value={operation.um}
        label="Unidad de medida"
        size={2}
        tabIndex={14}
        disabled
        onChange={() => {}}
      />
      <Texto
        name="is"
        value={operation.is === 1 ? 'Ingreso' : 'Salida'}
        label="Tipo"
        size={4}
        tabIndex={15}
        disabled
        onChange={() => {}}
      />
      <Texto
        name="cm"
        value={operation.cm}
        label="Cantidad"
        size={4}
        tabIndex={16}
        disabled
        onChange={() => {}}
      />
      <Texto
        name="nu"
        value={operation.nu}
        label="Responsable"
        size={2}
        tabIndex={17}
        disabled
        onChange={() => {}}
      />
      <Texto
        name="pm"
        value={`${operation.tm === 0 ? 'S/' : '$'} ${formatoMonetario(
          operation.pm
        )}`}
        label="Costo"
        size={2}
        tabIndex={18}
        disabled
        onChange={() => {}}
      />
      <Texto
        name="ds"
        value={operation.ds}
        label="Descripci&oacute;n"
        tabIndex={19}
        disabled
        onChange={() => {}}
      />
    </Modal>
  );
};

export default OperacionItem;
