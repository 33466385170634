import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';

import Boton from '../../commons/Boton';
import Busqueda from '../../commons/Busqueda';
import CheckBox from '../../commons/CheckBox';
import Modal from '../../commons/Modal';
import Tabla from '../../commons/Tabla';
import Texto from '../../commons/Texto';
import { useFormularioContext } from '../../commons/Formulario';

import { confirmacion, mensajeMini } from '../../commons/Mensajes';

import { solicitudModel } from '../../redux/models/solicitud.model';
import { servicios } from '../../redux/helpers';
import { formatoMonetario, redondeoDecimales } from '../../commons/Utilitarios';

const SolicitudItem = () => {
  const {
    selected,
    openModal,
    processing,
    errors,
    callTransaction,
    cancelTransaction,
  } = useFormularioContext();
  const [solicitud, setSolicitud] = useState(selected);
  const [showClients, setShowClients] = useState(false);
  const [showEquipments, setShowEquipments] = useState(false);
  const [detail, setDetail] = useState([]);
  const { currentUser } = useSelector((state) => state.app);

  const handleChange = (item) => {
    const { name, value } = item;
    setSolicitud((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    setSolicitud(selected);
    setDetail([]);
  }, [selected, openModal]);

  if (!openModal) {
    return null;
  }

  const handleSave = () => {
    let item = {
      ...solicitud,
      ie: currentUser.ie,
      is: currentUser.is,
      iv: currentUser.us,
      qq: detail,
    };
    callTransaction({
      method: 'post',
      url: servicios.SOLICITUD,
      data: item,
      model: solicitudModel,
    });
  };

  const columns = [
    {
      key: 'cl',
      align: 'center',
      content: (item) => (
        <>
          <div title="Enviar">
            <CheckIcon
              onClick={() => {
                handleChange({ name: 'cl', value: item.cl });
                handleChange({ name: 'nl', value: item.dn });
                setShowClients(false);
              }}
              style={{ color: 'green', cursor: 'pointer' }}
            />
          </div>
        </>
      ),
    },
    {
      key: 'dt',
      label: 'Tipo documento',
    },
    {
      key: 'nd',
      label: 'Número de documento',
    },
    {
      key: 'dn',
      label: 'Nombres completos',
    },
    {
      key: 'di',
      label: 'Dirección',
    },
    {
      key: 'st',
      label: 'Estado de registro',
      align: 'center',
      content: (item) => <CheckBox checked={item.st} disabled />,
    },
  ];

  const columnsEquipments = [
    {
      key: 'eq',
      align: 'center',
      content: (item) => (
        <div title="Enviar">
          <CheckIcon
            onClick={() => {
              handleAddDetail(item);
              setShowEquipments(false);
            }}
            style={{ color: 'green', cursor: 'pointer' }}
          />
        </div>
      ),
    },
    {
      key: 'ne',
      label: 'Nombre',
    },
    {
      key: 'cl.dn',
      label: 'Cliente',
      content: (item) => <span>{item.cl?.dn}</span>,
    },
    {
      key: 'cl.di',
      label: 'Dirección',
      content: (item) => <span>{item.cl?.di}</span>,
    },
    {
      key: 'st',
      label: 'Estado de registro',
      align: 'center',
      content: (item) => <CheckBox checked={item.st} disabled />,
    },
  ];

  const columnsDetail = [
    {
      key: 'eq',
      align: 'center',
      content: (item) => (
        <>
          <DeleteForeverIcon
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={() =>
              confirmacion(
                '¿Deseas eliminar?',
                `Está seguro de eliminar ${item.ne} de la lista`
              )
                .then(() => handleDeleteDetail(item))
                .catch(() => {})
            }
          />
        </>
      ),
    },
    {
      key: 'ne',
      label: 'Nombre',
    },
  ];

  const handleAddDetail = (item) => {
    let exits = detail.find((x) => x.eq === item.eq);
    if (exits === undefined) {
      item.st = true;
      setDetail([...detail, item]);
    }
  };

  const handleDeleteDetail = (item) => {
    setDetail(detail.filter((x) => x.eq !== item.eq));
  };

  return (
    <>
      <Modal
        title={`Nueva solicitud`}
        processing={processing}
        onActions={() => (
          <>
            <Boton
              className="op-dinamic"
              icon={() => <AddIcon />}
              tabIndex={18}
              style={{ '--button-color': '#3285a8' }}
              onClick={() => {
                if (solicitud.cl) {
                  setShowEquipments(true);
                } else {
                  mensajeMini('Seleccione cliente');
                }
              }}
            >
              Agregar Equipo
            </Boton>
            <Boton
              className="op-grabar"
              disabled={processing}
              icon={() => <SaveIcon />}
              tabIndex={19}
              onClick={() =>
                confirmacion('¿Desea guardar?', `Una nueva solicitud`)
                  .then(() => handleSave())
                  .catch(() => {})
              }
            >
              Guardar
            </Boton>
          </>
        )}
        onClose={() => cancelTransaction(solicitudModel)}
      >
        <Texto
          name="cs"
          value={solicitud.cs}
          label="C&oacute;digo"
          tabIndex={10}
          size={2}
          error={errors.cs}
          onChange={handleChange}
          disabled={solicitud.gc}
        />
        <div className="op-form-group op-col-2 op-checkbox-group">
          <span>¿Desea autogenerar el c&oacute;digo?</span>
          <CheckBox
            name="gc"
            tabIndex={11}
            checked={solicitud.gc}
            onChange={handleChange}
          />
        </div>
        <Texto
          name="ns"
          value={currentUser.ns}
          label="Sucursal"
          size={2}
          tabIndex={12}
          disabled
          onChange={() => {}}
        />
        <Texto
          name="nv"
          value={currentUser.np}
          label="Vendedor"
          size={2}
          tabIndex={13}
          disabled
          onChange={() => {}}
        />
        <Texto
          name="cl"
          value={solicitud.nl}
          label="Cliente"
          size={2}
          tabIndex={14}
          disabled
          error={errors.cl}
          icon={() => <SearchIcon onClick={() => setShowClients(true)} />}
          onChange={() => {}}
        />
        <Texto
          name="dir"
          value={solicitud.dir}
          label="Direcci&oacute;n cliente"
          size={2}
          tabIndex={15}
          disabled
          onChange={() => {}}
        />
        <Texto
          name="ds"
          value={solicitud.ds}
          label="Descripci&oacute;n del problema"
          tabIndex={16}
          error={errors.ds}
          onChange={handleChange}
        />
        <div className="op-form-group op-col-1">
          <div className="op-table" style={{ padding: 0, flexBasis: '100%' }}>
            <Tabla
              columns={columnsDetail}
              data={detail}
              searcheable={false}
              pagination={false}
              hoverSelectedRow={false}
              emptyRowsMessage="Ning&uacute;n equipo asignado"
            />
          </div>
        </div>
      </Modal>
      {showClients && (
        <Busqueda
          serviceName={servicios.CLIENTE}
          title="B&uacute;squeda de clientes"
          columns={columns}
          params={{ st: '1' }}
          onClose={() => setShowClients(false)}
          onDoubleClick={(item) => {
            handleChange({ name: 'cl', value: item.cl });
            handleChange({ name: 'nl', value: item.dn });
            handleChange({ name: 'dir', value: item.di });
            setShowClients(false);
          }}
        />
      )}
      {showEquipments && (
        <Busqueda
          serviceName={servicios.EQUIPO}
          title="B&uacute;squeda de equipos"
          columns={columnsEquipments}
          params={{ cl: solicitud.cl, st: '1' }}
          emptyRowsMessage="Cliente no tiene equipos"
          onClose={() => setShowEquipments(false)}
          onDoubleClick={(item) => {
            handleAddDetail(item);
            setShowEquipments(false);
          }}
        />
      )}
    </>
  );
};

export default SolicitudItem;
