import React from 'react';
import { connect } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Bloque from '../../commons/Bloque';
import Modal from '../../commons/Modal';
import Icon from '../../commons/Icon';
import { formatoMonetario } from '../../commons/Utilitarios';

import {
  getInventariosAction,
  getSeleccionarInventarioAction,
  actualizarFiltros,
  getConvertirInventarioAction,
} from '../../redux/actions/inventario.action';

import { getSeleccionarOperacionAction } from '../../redux/actions/operacion.action';

import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import ProductConvert from '../../assets/icons/convert-product.svg';
import UpDown from '../../assets/icons/upDown.svg';

const InventarioList = ({
  inventarios,
  processing,
  perfil,
  total,
  getInventariosAction,
  getSeleccionarInventarioAction,
  actualizarFiltros,
  getConvertirInventarioAction,
  getSeleccionarOperacionAction,
  searchable,
  onClose,
  onSend,
}) => {
  const columns = [
    {
      key: 'su',
      align: 'center',
      content: (item) => (
        <div style={{ display: 'flex' }}>
          {perfil === 'NOBASICO' && (
            <>
              {searchable ? (
                <div title="Enviar">
                  <CheckIcon
                    onClick={() => {
                      onSend(item);
                      onClose();
                    }}
                    style={{ color: 'green', cursor: 'pointer' }}
                  />
                </div>
              ) : (
                <>
                  <div title="Editar">
                    <EditIcon
                      onClick={() => getSeleccionarInventarioAction(item)}
                      style={{ color: 'green', cursor: 'pointer' }}
                    />
                  </div>
                  <Icon
                    svg={ProductConvert}
                    color="#3f51b5"
                    title="Convertir"
                    onClick={() => getConvertirInventarioAction(item)}
                  />
                  <Icon
                    svg={UpDown}
                    color="blue"
                    size={25}
                    title="Registrar movimiento"
                    onClick={() => getSeleccionarOperacionAction(item)}
                  />
                </>
              )}
            </>
          )}
        </div>
      ),
    },
    {
      key: 'ns',
      label: 'Sucursal',
    },
    {
      key: 'cm',
      align: 'center',
      label: 'Código',
    },
    {
      key: 'nm',
      label: 'Producto / Insumo',
    },
    {
      key: 'um',
      label: 'Unidad de medida',
    },
    {
      key: 'sd',
      align: 'center',
      label: 'Stock disponible',
    },
    {
      key: 'pv',
      align: 'right',
      label: 'Precio venta',
      content: (item) =>
        `${item.tm === 0 ? 'PEN' : 'USD'} ${formatoMonetario(item.pv)}`,
    },
    {
      key: 'dt',
      label: 'Categoría',
    },
  ];

  return (
    <>
      {searchable ? (
        <Modal
          title="B&uacute;squeda de inventarios"
          closePressEscape={false}
          closeWithoutConfirmation
          onClose={() => onClose()}
        >
          <Tabla
            columns={columns}
            data={inventarios}
            processing={processing}
            total={total}
            onPagination={({ page, rows }) =>
              getInventariosAction({ page, pageSize: rows })
            }
            onSearching={() => getInventariosAction({})}
            onChangeSearching={(search) => actualizarFiltros({ search })}
            onDoubleClick={(item) => {
              onSend(item);
              onClose();
            }}
            style={{ flexBasis: '100%' }}
          />
        </Modal>
      ) : (
        <Bloque titulo="Resultado de b&uacute;squeda">
          <Tabla
            columns={columns}
            data={inventarios}
            processing={processing}
            total={total}
            onPagination={({ page, rows }) =>
              getInventariosAction({ page, pageSize: rows })
            }
            onSearching={() => getInventariosAction({})}
            onChangeSearching={(search) => actualizarFiltros({ search })}
          />
        </Bloque>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    inventarios: state.inventario.inventarios,
    processing: state.inventario.processing,
    total: state.inventario.total,
    perfil: state.app.currentUser.tr,
  };
};

export default connect(mapStateToProps, {
  getInventariosAction,
  getSeleccionarInventarioAction,
  actualizarFiltros,
  getConvertirInventarioAction,
  getSeleccionarOperacionAction,
})(InventarioList);
