import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';

import Boton from '../../commons/Boton';
import Icon from '../../commons/Icon';
import { confirmacion } from '../../commons/Mensajes';
import { useFormularioContext } from '../../commons/Formulario';

import ProcessIcon from '../../assets/icons/tasks.svg';
import Busqueda from '../../commons/Busqueda';
import { servicios, columnsProcesses } from '../../redux/helpers';
import { solicitudModel } from '../../redux/models/solicitud.model';

const SolicitudProcesses = () => {
  const { selected, processing, equipment, callTransaction } =
    useFormularioContext();
  const { currentUser } = useSelector((state) => state.app);
  const [solicitud, setSolicitud] = useState(selected);
  const [processes, setProcesses] = useState([]);
  const [showProcesses, setShowProcesses] = useState(false);

  useEffect(() => {
    setSolicitud(selected);
    setProcesses(equipment.pc ? equipment.pc : []);
  }, [selected.so]);

  const handleAddProcess = (item) => {
    let exist = processes.find((x) => x.pr === item.pr);
    if (exist === undefined) {
      setProcesses([...processes, item]);
    }
    setShowProcesses(false);
  };

  const handleDeleteProcess = (cp) => {
    setProcesses(processes.filter((x) => x.cp !== cp));
  };

  const handleSaveProcess = () => {
    let request = {
      so: solicitud.so,
      ie: currentUser.ie,
      eq: equipment.eq.eq,
      pc: processes.map((h) => h.pr),
      ts: equipment.ts?.map((t) => t.te),
      mn: equipment.mn?.map((m) => m.mn),
    };
    callTransaction({
      method: 'patch',
      url: `${servicios.SOLICITUD}/equipodetalle`,
      data: request,
      model: solicitudModel,
    });
  };

  return (
    <div className="detail-equipments">
      <h3>{`Procedimientos - ${equipment.eq?.ne}`}</h3>
      <div className="container">
        {processes?.map((m) => (
          <div key={m.pr} className="equipment">
            <Icon svg={ProcessIcon} size={30} transparent />
            <span>{m.cp}</span>
            <span>{m.np}</span>
            {solicitud.ep !== 2 && (
              <div className="close">
                <CancelIcon
                  color="secondary"
                  onClick={() => handleDeleteProcess(m.cp)}
                />
              </div>
            )}
          </div>
        ))}
        {solicitud.ep !== 2 && (
          <>
            <Boton
              className="op-dinamic"
              disabled={processing}
              icon={() => <AddIcon />}
              tabIndex={18}
              style={{ '--button-color': '#3285a8' }}
              onClick={() => setShowProcesses(true)}
            >
              Agregar Procedimiento
            </Boton>
            <Boton
              className="op-grabar"
              disabled={processing}
              icon={() => <SaveIcon />}
              tabIndex={19}
              onClick={() =>
                confirmacion(
                  '¿Está seguro?',
                  `De registrar los procedimientos al equipo ${equipment.eq.ne}`
                )
                  .then(() => handleSaveProcess())
                  .catch(() => {})
              }
            >
              Guardar procedimientos
            </Boton>
          </>
        )}
      </div>
      {showProcesses && (
        <Busqueda
          serviceName={servicios.PROCEDIMIENTO}
          title="B&uacute;squeda de procedimientos"
          columns={columnsProcesses((item) => handleAddProcess(item))}
          emptyRowsMessage="No existen procedimientos"
          onClose={() => setShowProcesses(false)}
          onDoubleClick={(item) => handleAddProcess(item)}
        />
      )}
    </div>
  );
};

export default SolicitudProcesses;
