import React from 'react';
import { connect, useDispatch } from 'react-redux';

import { NavLink } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import { postLoginAction } from '../redux/actions/app.action';
import Loading from '../commons/Loading';

import '../css/login.css';
import { mensajeMini } from '../commons/Mensajes';
import { constantes } from '../redux/helpers';

const RedSocial = ({ processing, error }) => {
  const dispatch = useDispatch();
  const responseGoogle = (response) => {
    let user = {
      us: response.profileObj.email,
      pd: response.tokenId,
      mo: -1,
    };
    dispatch(postLoginAction(user));
  };
  return (
    <div className="op-content-login">
      <div className="op-login">
        <h1>Bienvenido a</h1>
        <h1>Wuasho</h1>
        <GoogleLogin
          clientId={constantes.GOOGLE_AUTH}
          buttonText="Inicia sesi&oacute;n con google"
          onSuccess={responseGoogle}
          onFailure={() => mensajeMini('Fallo el servicio', 'error')}
          disabled={processing}
          cookiePolicy={'single_host_origin'}
        />
        {error && <label className="op-error">{error}</label>}
        <p></p>
        <NavLink className="op-button-link" to="/login">
          <AlternateEmailIcon style={{ color: '#3498db' }} />
          <span>Inicia sesi&oacute;n con una cuenta</span>
        </NavLink>
        <NavLink className="op-button-link" to="/auth/login">
          <VerifiedUserIcon style={{ color: 'red' }} />
          <span>Inicia sesi&oacute;n con Autenticador OP</span>
        </NavLink>
        {processing === true && <Loading />}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    processing: state.app.processing,
    error: state.app.error,
  };
};

export default connect(mapStateToProps)(RedSocial);
