import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import RemoveIcon from '@material-ui/icons/Remove';
import SearchIcon from '@material-ui/icons/Search';
import '../../../node_modules/draft-js/dist/Draft.css';
import '../../css/commons/richEditor.css';

import Report from '../../components/app/Report';

import Boton from '../../commons/Boton';
import Busqueda from '../../commons/Busqueda';
import Combo from '../../commons/Combo';
import CheckBox from '../../commons/CheckBox';
import Modal from '../../commons/Modal';
import Tabla from '../../commons/Tabla';
import Texto from '../../commons/Texto';
import TextArea from '../../commons/TextArea';

import { useFormularioContext } from '../../commons/Formulario';

import {
  columnsClients,
  columnsServices,
  columnsEquipments,
  columnsInventories,
  servicios,
} from '../../redux/helpers';
import {
  caracteresUnicode,
  formatoMonetario,
  quitarFormatoMonetario,
  redondeoDecimales,
  removeStrangeCharacters,
} from '../../commons/Utilitarios';
import { confirmacion, mensajeMini } from '../../commons/Mensajes';

const CotizacionNew = () => {
  const { openNew, dispatch, getData } = useFormularioContext();
  const { currentUser } = useSelector((state) => state.app);
  const [
    {
      cl = null,
      rc = null,
      nl = null,
      dir = null,
      tm = 0,
      pp = 10,
      dm = '0',
      vc = 3,
      ii = false,
      rq = null,
      nt = null,
    },
    setPrice,
  ] = useState({});
  const [openReporte, setOpenReporte] = useState(false);
  const [showClients, setShowClients] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [showEquipments, setShowEquipments] = useState(false);
  const [showCustom, setShowCustom] = useState(false);
  const [custom, setCustom] = useState({
    ci: null,
    di: null,
    pi: 0,
    cu: 1,
    be: false,
    bp: false,
    bs: false,
  });
  const [detail, setDetail] = useState([]);

  const handleChange = (item) => {
    const { name, value } = item;
    setPrice((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeCustom = (item) => {
    const { name, value } = item;
    setCustom((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  if (!openNew) {
    return null;
  }

  const handleAddClient = (item) => {
    handleChange({ name: 'cl', value: item.cl });
    handleChange({ name: 'nl', value: item.dn });
    handleChange({ name: 'dir', value: item.di });
    setShowClients(false);
  };

  const handleAddDetail = (item) => {
    let exits = detail.find((x) => x.ci === item.ci);
    if (exits) {
      setDetail(detail.map((x) => (x.ci === item.ci ? item : x)));
    } else {
      setDetail([
        ...detail,
        {
          ci: item.ci,
          di: item.di,
          cu: item.cu,
          pi: item.pi,
          be: item.be,
          bp: item.bp,
          bs: item.bs,
          edit: false,
          editPrice: false,
        },
      ]);
    }
    setShowCustom(false);
  };

  const handleAddService = (item) => {
    let exits = detail.find((x) => x.ci === item.cs);
    if (exits === undefined) {
      setDetail([
        ...detail,
        {
          ci: item.cs,
          di: item.ns,
          cu: 1,
          pi: item.ps,
          be: false,
          bp: false,
          bs: true,
          edit: false,
          editPrice: false,
        },
      ]);
    }
    setShowServices(false);
  };

  const handleAddEquipment = (item) => {
    let exits = detail.find((x) => x.ci === item.ce);
    if (exits === undefined) {
      setDetail([
        ...detail,
        {
          ci: item.ce,
          di: item.ne,
          cu: 1,
          pi: 0,
          be: true,
          bp: false,
          bs: false,
          edit: false,
          editPrice: false,
        },
      ]);
    }
    setShowEquipments(false);
  };

  const handleAddInventory = (item) => {
    let exits = detail.find((x) => x.ci === item.cm);
    if (exits === undefined) {
      setDetail([
        ...detail,
        {
          ci: item.cm,
          di: item.nm,
          cu: 1,
          pi: item.pv,
          be: false,
          bp: true,
          bs: false,
          edit: false,
          editPrice: false,
        },
      ]);
    }
    setShowProducts(false);
  };

  const handleDeleteDetail = (item) => {
    setDetail(detail.filter((x) => x.ci !== item.ci));
  };

  const handleEditDetail = (ci, name, valor, e) => {
    setDetail(detail.map((x) => (x.ci === ci ? { ...x, [name]: valor } : x)));
    if (e) {
      let caja = e.target
        .closest('.op-centrar-componentes')
        .querySelector('input');
      let label = e.target
        .closest('.op-centrar-componentes')
        .querySelector('span');
      caja.style.width = `${label.offsetWidth}px`;
      caja.focus();
    }
  };

  const handleUnitDetail = (ci, valor) => {
    setDetail(
      detail.map((x) =>
        x.ci === ci
          ? {
              ...x,
              cu:
                valor === -1 && x.cu <= 1
                  ? 1
                  : parseInt(x.cu ? x.cu : 1) + valor,
            }
          : x
      )
    );
  };

  const columnsDetail = [
    {
      key: 'sv',
      align: 'center',
      content: (item) => (
        <>
          <EditIcon
            onClick={() => {
              setCustom(item);
              setShowCustom(true);
            }}
            style={{ color: 'green', cursor: 'pointer' }}
          />
          <DeleteForeverIcon
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={() =>
              confirmacion(
                '¿Deseas eliminar?',
                `Está seguro de eliminar ${item.di} de la lista`
              )
                .then(() => handleDeleteDetail(item))
                .catch(() => {})
            }
          />
        </>
      ),
    },
    {
      key: 'ci',
      label: 'Código',
    },
    {
      key: 'di',
      label: 'Detalle',
    },
    {
      key: 'cu',
      label: 'Cantidad',
      align: 'center',
      content: (item) => (
        <div className="op-centrar-componentes">
          <AddIcon
            style={{ color: 'green', cursor: 'pointer' }}
            onClick={() => handleUnitDetail(item.ci, 1)}
          />
          <div className={`cantidad${item.edit ? ' edit' : ''}`}>
            <span
              onClick={(e) => handleEditDetail(item.ci, 'edit', !item.edit, e)}
            >
              {item.cu}
            </span>
            <input
              type="text"
              value={item.cu}
              onChange={(e) => handleEditDetail(item.ci, 'cu', e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleEditDetail(item.ci, 'edit', !item.edit, e);
                } else {
                  let re = new RegExp('[0-9]');
                  if (!re.test(e.key)) {
                    e.preventDefault();
                  }
                }
              }}
              onPaste={(e) => e.preventDefault()}
              onFocus={(e) => e.target.select()}
            />
            <div className="edicion">
              <EditIcon
                style={{ fontSize: '15px' }}
                onClick={(e) =>
                  handleEditDetail(item.ci, 'edit', !item.edit, e)
                }
              />
            </div>
          </div>
          <RemoveIcon
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={() => handleUnitDetail(item.ci, -1)}
          />
        </div>
      ),
    },
    {
      key: 'pi',
      label: 'Precio unitario',
      align: 'right',
      content: (item) => (
        <div className="op-centrar-componentes">
          <div className={`cantidad${item.editPrice ? ' edit' : ''}`}>
            <span
              onClick={(e) =>
                handleEditDetail(item.ci, 'editPrice', !item.editPrice, e)
              }
            >
              {formatoMonetario(redondeoDecimales(item.pi))}
            </span>
            <input
              type="text"
              value={item.pi}
              onChange={(e) => handleEditDetail(item.ci, 'pi', e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleEditDetail(item.ci, 'editPrice', !item.editPrice, e);
                } else {
                  let re = new RegExp('[0-9.]');
                  if (!re.test(e.key)) {
                    e.preventDefault();
                  }
                }
              }}
              onBlur={(e) => {
                formatoMonetario(redondeoDecimales(e.target.value));
              }}
              onPaste={(e) => e.preventDefault()}
              onFocus={(e) => e.target.select()}
            />
            <div className="edicion">
              <EditIcon
                style={{ fontSize: '15px' }}
                onClick={(e) =>
                  handleEditDetail(item.ci, 'editPrice', !item.editPrice, e)
                }
              />
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <Modal
        title="Nueva Cotizaci&oacute;n"
        onActions={() => (
          <>
            <Boton
              className="op-dinamic"
              icon={() => <AddIcon />}
              tabIndex={20}
              style={{ '--button-color': '#3285a8' }}
              onClick={() => {
                setCustom({
                  ci: null,
                  di: null,
                  pi: 0,
                  cu: 1,
                  be: false,
                  bp: false,
                  bs: false,
                });
                setShowCustom(true);
              }}
            >
              Agregar detalle
            </Boton>
            <Boton
              className="op-dinamic"
              icon={() => <AddIcon />}
              tabIndex={20}
              style={{ '--button-color': '#3285a8' }}
              onClick={() => setShowServices(true)}
            >
              Buscar servicios
            </Boton>
            <Boton
              className="op-dinamic"
              icon={() => <AddIcon />}
              tabIndex={20}
              style={{ '--button-color': '#3285a8' }}
              onClick={() => setShowProducts(true)}
            >
              Buscar productos
            </Boton>
            <Boton
              className="op-dinamic"
              icon={() => <AddIcon />}
              tabIndex={20}
              style={{ '--button-color': '#3285a8' }}
              onClick={() => {
                if (cl) {
                  setShowEquipments(true);
                } else {
                  mensajeMini('Seleccione cliente');
                }
              }}
            >
              Buscar equipos
            </Boton>
            <Boton
              className="op-dinamic"
              icon={() => <PictureAsPdfIcon />}
              tabIndex={20}
              style={{ '--button-color': 'red' }}
              onClick={() => {
                if (cl === null || cl === '') {
                  mensajeMini('Seleccione cliente');
                  return;
                }
                if (dm === '') {
                  mensajeMini('Seleccione condición');
                  return;
                }
                if (vc === '') {
                  mensajeMini('Ingrese vigencia');
                  return;
                }
                if (parseInt(vc) > 15) {
                  mensajeMini('Vigencia no puede ser superior a 15 días');
                  return;
                }
                if (detail.length === 0) {
                  mensajeMini('Seleccione al menos un servicio');
                  return;
                }
                setOpenReporte(true);
              }}
            >
              Imprimir
            </Boton>
          </>
        )}
        closeWithoutConfirmation
        onClose={() => {
          setPrice({
            cl: null,
            rc: null,
            nl: null,
            dir: null,
            tm: 0,
            pp: 1,
            dm: null,
            vc: 1,
            ii: false,
            rq: null,
            nt: null,
          });
          setDetail([]);
          dispatch({ type: 'MODAL_NEW' });
        }}
      >
        <Texto
          name="na"
          value={currentUser.np}
          label="Usuario"
          tabIndex={10}
          size={2}
          onChange={() => {}}
          disabled
        />
        <Combo
          name="tm"
          label="Tipo moneda"
          value={tm}
          tabIndex={11}
          size={2}
          data={[
            { value: '0', label: 'Soles' },
            { value: '1', label: 'Dolares' },
          ]}
          onChange={handleChange}
        />
        <Texto
          name="nl"
          value={nl}
          label="Cliente"
          size={2}
          tabIndex={14}
          disabled
          icon={() => <SearchIcon onClick={() => setShowClients(true)} />}
          onChange={() => {}}
        />
        <Texto
          name="dir"
          value={dir}
          label="Direcci&oacute;n cliente"
          size={2}
          tabIndex={15}
          disabled
          onChange={() => {}}
        />
        <Texto
          name="pp"
          value={pp}
          label="Condici&oacute;n pago"
          size={2}
          restrict="int"
          tabIndex={16}
          onChange={handleChange}
        />
        <Combo
          name="dm"
          label="D&iacute;as / Meses"
          value={dm}
          tabIndex={17}
          size={2}
          data={[
            { value: '0', label: 'Días' },
            { value: '1', label: 'Meses' },
          ]}
          onChange={handleChange}
        />
        <Texto
          name="rc"
          value={rc}
          label="Recepciona"
          size={2}
          tabIndex={20}
          onChange={handleChange}
        />
        <Texto
          name="vc"
          value={vc}
          label="D&iacute;as vigencia cotizaci&oacute;n"
          size={4}
          restrict="int"
          tabIndex={18}
          onChange={handleChange}
        />
        <div className="op-form-group op-col-4 op-checkbox-group">
          <span>¿Precios incluyen IGV?</span>
          <CheckBox
            name="ii"
            tabIndex={21}
            checked={ii}
            onChange={handleChange}
          />
        </div>
        <TextArea
          name="rq"
          value={rq}
          label="Requerimiento"
          tabIndex={22}
          rows={3}
          onChange={handleChange}
          enabledPaste
          onBlur={(e) => {
            const text = e.target.value;
            handleChange({
              name: 'rq',
              value: removeStrangeCharacters(text),
            });
          }}
        />
        <TextArea
          name="nt"
          value={nt}
          label="Notas"
          tabIndex={23}
          rows={3}
          onChange={handleChange}
          enabledPaste
          onBlur={(e) => {
            const text = e.target.value;
            handleChange({
              name: 'nt',
              value: removeStrangeCharacters(text),
            });
          }}
        />
        <div className="op-form-group op-col-1">
          <div className="op-table" style={{ padding: 0, flexBasis: '100%' }}>
            <Tabla
              columns={columnsDetail}
              data={detail}
              searcheable={false}
              pagination={false}
              hoverSelectedRow={false}
              emptyRowsMessage="Ning&uacute;n detalle"
            />
          </div>
        </div>
      </Modal>
      {openReporte && (
        <Report
          url={`${servicios.COTIZACION}/${currentUser.ie}/base64`}
          method="post"
          data={{
            rc,
            cl,
            tm,
            pp,
            dm,
            vc,
            ii,
            rq,
            nt,
            dtl: detail.map((x) => ({
              ...x,
              pi: quitarFormatoMonetario(x.pi),
            })),
          }}
          extension={'pdf'}
          filename="Cotizaci&oacute;n"
          onClose={() => {
            setOpenReporte(false);
          }}
          onSucess={() => getData()}
        />
      )}
      {showClients && (
        <Busqueda
          serviceName={servicios.CLIENTE}
          title="B&uacute;squeda de clientes"
          columns={columnsClients((item) => handleAddClient(item))}
          emptyRowsMessage="No existen clientes"
          onClose={() => setShowClients(false)}
          onDoubleClick={(item) => handleAddClient(item)}
        />
      )}
      {showServices && (
        <Busqueda
          serviceName={servicios.SERVICIO}
          title="B&uacute;squeda de servicios"
          columns={columnsServices((item) => handleAddService(item))}
          emptyRowsMessage="No existen servicios"
          onClose={() => setShowServices(false)}
          onDoubleClick={(item) => handleAddService(item)}
        />
      )}
      {showEquipments && (
        <Busqueda
          serviceName={servicios.EQUIPO}
          title="B&uacute;squeda de equipos"
          columns={columnsEquipments((item) => handleAddEquipment(item))}
          emptyRowsMessage="No existen equipos"
          params={{ cl }}
          onClose={() => setShowEquipments(false)}
          onDoubleClick={(item) => handleAddEquipment(item)}
        />
      )}
      {showProducts && (
        <Busqueda
          serviceName={servicios.INVENTARIO}
          title="B&uacute;squeda de inventario"
          columns={columnsInventories((item) => handleAddInventory(item))}
          emptyRowsMessage="No existen productos en inventario"
          onClose={() => setShowProducts(false)}
          onDoubleClick={(item) => handleAddInventory(item)}
        />
      )}
      {showCustom && (
        <Modal
          title={`${custom.ci ? 'Modificar' : 'Agregar'} detalle`}
          closeConfirmationMessage="La ventana de detalle"
          size="medium"
          onActions={() => (
            <Boton
              className="op-dinamic"
              icon={() => <AddIcon />}
              tabIndex={42}
              style={{ '--button-color': '#3285a8' }}
              onClick={() => {
                handleAddDetail({
                  ...custom,
                  ci: custom.ci ? custom.ci : new Date().getTime().toString(),
                });
              }}
            >
              Registrar detalle
            </Boton>
          )}
          onClose={() => setShowCustom(false)}
        >
          <TextArea
            name="di"
            value={custom.di}
            label="Descripci&oacute;n"
            tabIndex={40}
            rows={10}
            enabledPaste
            onChange={handleChangeCustom}
            onBlur={(e) => {
              const text = e.target.value;
              handleChangeCustom({
                name: 'di',
                value: removeStrangeCharacters(text),
              });
            }}
          />
          <Texto
            name="cu"
            value={custom.cu}
            label="Cantidad"
            tabIndex={41}
            restrict={'int'}
            enabledPaste
            size={2}
            onChange={handleChangeCustom}
          />
          <Texto
            name="pi"
            value={custom.pi}
            label="Precio"
            tabIndex={42}
            onChange={handleChangeCustom}
            restrict={'money'}
            size={2}
            onBlur={(e) => {
              handleChangeCustom({
                name: 'pi',
                value: formatoMonetario(redondeoDecimales(e.target.value)),
              });
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default CotizacionNew;
