import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Modal from '../../commons/Modal';
import Bloque from '../../commons/Bloque';
import CheckBox from '../../commons/CheckBox';

import {
  getConductoresAction,
  getSeleccionarConductorAction,
  actualizarFiltros,
  paginado,
} from '../../redux/actions/conductor.action';

import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';

const ConductorList = ({
  conductores,
  processing,
  total,
  rows,
  searchable,
  onClose,
  onSend,
}) => {
  const [buscar, setBuscar] = useState();
  const dispatch = useDispatch();
  const columns = [
    {
      key: 'ch',
      align: 'center',
      content: (item) => (
        <>
          {searchable ? (
            <div title="Enviar">
              <CheckIcon
                onClick={() => {
                  onSend(item);
                  onClose();
                }}
                style={{ color: 'green', cursor: 'pointer' }}
              />
            </div>
          ) : (
            <div title="Editar" style={{ display: 'inline-block' }}>
              <EditIcon
                onClick={() => dispatch(getSeleccionarConductorAction(item))}
                style={{ color: 'green', cursor: 'pointer' }}
              />
            </div>
          )}
        </>
      ),
    },
    {
      key: 'dd',
      label: 'Tipo documento',
    },
    {
      key: 'nd',
      label: 'Número de documento',
    },
    {
      key: 'nc',
      label: 'Nombres completos',
    },
    {
      key: 'lc',
      label: 'Licencia conductor',
    },
    {
      key: 'st',
      label: 'Estado de registro',
      align: 'center',
      content: (item) => <CheckBox checked={item.st} disabled />,
    },
  ];

  return (
    <>
      {searchable ? (
        <Modal
          title="B&uacute;squeda de conductores"
          closePressEscape={false}
          closeWithoutConfirmation
          onClose={() => onClose()}
        >
          <Tabla
            columns={columns}
            data={conductores}
            processing={processing}
            total={total}
            rows={rows}
            onPagination={({ page }) => {
              dispatch(paginado(page));
              dispatch(getConductoresAction());
            }}
            onSearching={() => {
              dispatch(actualizarFiltros({ search: buscar }));
              dispatch(getConductoresAction());
            }}
            onChangeSearching={(search) => setBuscar(search)}
            onDoubleClick={(item) => {
              onSend(item);
              onClose();
            }}
            style={{ flexBasis: '100%' }}
          />
        </Modal>
      ) : (
        <Bloque titulo="Resultado de b&uacute;squeda">
          <Tabla
            columns={columns}
            data={conductores}
            processing={processing}
            total={total}
            rows={rows}
            onPagination={({ page }) => {
              dispatch(paginado(page));
              dispatch(getConductoresAction());
            }}
            onSearching={() => {
              dispatch(actualizarFiltros({ search: buscar }));
              dispatch(getConductoresAction());
            }}
            onChangeSearching={(search) => setBuscar(search)}
          />
        </Bloque>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    conductores: state.conductor.conductores,
    processing: state.conductor.processing,
    total: state.conductor.total,
    rows: state.conductor.rows,
  };
};

export default connect(mapStateToProps)(ConductorList);
