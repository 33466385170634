import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import { api } from '../../redux/axios/api';

import Loading from '../../commons/Loading';

import '../../css/commons/modal.css';

const Report = ({
  url,
  method,
  params,
  onClose,
  data,
  filename,
  extension,
  showInline,
  hideToolBar,
  onSucess,
}) => {
  const [processing, setProcessing] = useState(true);
  const [urlReport, setUrlReport] = useState('');
  const modalRef = useRef(null);

  useEffect(() => {
    api({
      url,
      method,
      data,
      params,
    })
      .then((response) => {
        setProcessing(false);
        setUrlReport(response);
        if (onSucess) {
          onSucess();
        }
      })
      .catch(async (error) => {
        const text = await new Response(error.data).text();
        const errorjson = JSON.parse(text);

        let message = 'Error al generar reporte';
        if (errorjson.detail) {
          message = errorjson.detail;
        } else if (errorjson.errors) {
          const [first] = Object.values(errorjson.errors);
          message = first;
        }

        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 6000,
          timerProgressBar: true,
        });

        Toast.fire({
          icon: error.status === 500 ? 'error' : 'warning',
          title: message,
        });
        onClose();
      });
    if (showInline === undefined) {
      modalRef.current.focus();
    }
  }, []);

  const hanldeType = () => {
    if (extension === 'pdf' && urlReport) {
      return (
        <div style={{ width: '100%' }}>
          <center>
            <object
              data={`${urlReport}`}
              width={'100%'}
              height={'500px'}
              type={'application/pdf'}
              title={'ejemplo.pdf'}
            />
          </center>
        </div>
      );
    } else if (['png', 'jpg', 'jpeg'].includes(extension) && urlReport) {
      return (
        <img
          src={urlReport}
          alt="Imagen"
          style={{
            width: '100%',
            objectFit: 'cover',
            pointerEvents: hideToolBar ? 'none' : 'inherit',
          }}
        />
      );
    } else if (
      ['doc', 'docx', 'xls', 'xlsx'].includes(extension) &&
      urlReport
    ) {
      return (
        <a
          href={urlReport}
          download={`${filename}.${extension}`}
          onClick={() => onClose()}
        >
          {`Descargar ${filename}.${extension}`}
        </a>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {showInline ? (
        hanldeType()
      ) : (
        <div
          className="op-content-modal"
          ref={modalRef}
          onContextMenu={(e) => (hideToolBar ? e.preventDefault() : {})}
        >
          <div
            className={`op-modal ${
              ['doc', 'docx', 'xls', 'xlsx'].includes(extension)
                ? 'op-small'
                : 'op-medium'
            } content-imagen`}
          >
            <div className="op-modal-header">
              <div className="op-modal-close" onClick={() => onClose()}></div>
            </div>
            <div className="op-modal-body">{hanldeType()}</div>
            {processing && <Loading tipo="images" />}
          </div>
        </div>
      )}
    </>
  );
};

Report.propTypes = {
  url: PropTypes.string.isRequired,
  params: PropTypes.any,
  method: PropTypes.oneOf(['get', 'post', 'put', 'patch']),
  data: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  downloadable: PropTypes.bool,
  filename: PropTypes.string,
  extension: PropTypes.string,
  showInline: PropTypes.bool,
  hideToolBar: PropTypes.bool,
  onSucess: PropTypes.func,
};

Report.defaultProps = {
  downloadable: false,
  filename: 'reporte',
  extension: 'xlsx',
  method: 'get',
};

export default Report;
