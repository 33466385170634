import React, { useState, useEffect } from 'react';

import { useFormularioContext } from '../../commons/Formulario';

const MantenimientoTesting = () => {
  const { selected } = useFormularioContext();

  const [maintenance, setMaintenance] = useState(selected);
  const [results, setResults] = useState([]);

  useEffect(() => {
    setMaintenance(selected);
    if (selected.ts) {
      setResults(
        selected.ts.reduce((r, o) => {
          const { tp, nt, rs } = o;
          r[tp] = [
            ...(r[tp] || []),
            {
              nt,
              rs,
            },
          ];

          return r;
        }, {})
      );
    }
  }, [selected.hi]);

  return (
    <div className="detail-equipments">
      {Object.keys(results).map((x, i) => (
        <React.Fragment key={i}>
          <div className="title">
            <h3>{`Testeo - ${x}`}</h3>
          </div>
          <div className="op-detail-contenedor">
            {results[x].map((v, ii) => (
              <div key={ii} className="op-detail-result">
                <span>{v.nt}</span>
                <span>{v.rs}</span>
              </div>
            ))}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default MantenimientoTesting;
