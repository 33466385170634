import React from 'react';
import EditIcon from '@material-ui/icons/Edit';

import Bloque from '../../commons/Bloque';
import CheckBox from '../../commons/CheckBox';
import TablaBody from '../../commons/TablaBody';
import TablaSearch from '../../commons/TablaSearch';
import Paginacion from '../../commons/Paginacion';
import { useTablaFilter } from '../../commons/Formulario';

const TesteoList = () => {
  const { setSelectedItem } = useTablaFilter();

  const columns = [
    {
      key: 'te',
      align: 'center',
      content: (item) => (
        <EditIcon
          onClick={() => setSelectedItem(item)}
          style={{ color: 'green', cursor: 'pointer' }}
        />
      ),
    },
    {
      key: 'ct',
      label: 'Código',
    },
    {
      key: 'nt',
      label: 'Nombre',
    },
    {
      key: 'st',
      label: 'Estado de registro',
      align: 'center',
      content: (item) => <CheckBox checked={item.st} disabled />,
    },
  ];

  return (
    <Bloque titulo="Testeos">
      <div className="op-table">
        <TablaSearch />
        <TablaBody columns={columns} />
        <Paginacion />
      </div>
    </Bloque>
  );
};

export default TesteoList;
