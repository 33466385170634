import styled from 'styled-components';

import icons from '/src/assets/fonts/FontAwesome.ttf';
import glyphMap from '/src/assets/glyphmaps/FontAwesome.json';

//Se obtiene todos los name permitidos
//const IconNamePropType = PropTypes.oneOf(Object.keys(glyphMap));
/**
 * Obtiene c&oacute;digo de icono.
 * @param {string} name nombre del icono
 * @returns c&oacute;digo
 */
export function GlyphMapFontAwesome(name) {
  let glyph = name ? glyphMap[name] || '?' : '';
  if (typeof glyph === 'number') {
    glyph = String.fromCodePoint(glyph);
  }
  return glyph;
}

export const FontAwesomeIcons = styled.span`
  @font-face {
    font-family: FontAwesomeIcons;
    src: url('${icons}') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  font-family: FontAwesomeIcons;
  border-color: ${({ color }) => color};
  color: ${({ color }) => color};
  display: flex;
  width: 90px;
  height: 100px;
  justify-content: center;
  align-items: center;
  &&:before {
    content: '${({ icon }) => icon}';
    font-size: ${({ size }) => size};
  }
`;
