import React from 'react';
import { useSelector } from 'react-redux';

import { mantenimientoModel } from '../../redux/models/mantenimiento.model';
import { servicios } from '../../redux/helpers';
import Formulario from '../../commons/Formulario';

import MantenimientoFilter from './MantenimientoFilter';
import MantenimientoItem from './MantenimientoItem';
import MantenimientoList from './MantenimientoList';

import { TYPE_FILTER } from '../../redux/helpers/app.types';

import '../../css/components/solicitud.css';

const Mantenimiento = () => {
  const { currentUser } = useSelector((state) => state.app);

  const initialState = {
    params: {
      em: currentUser.ie,
      so: '',
      eq: '',
      st: '',
    },
    serviceName: servicios.MANTENIMIENTO,
    selected: mantenimientoModel,
    errors: mantenimientoModel,
    viewContent: 'op-view-solicitud',
    openModal: false,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case TYPE_FILTER:
        return {
          ...state,
          params: {
            ...state.params,
            so:
              action.payload.so !== undefined
                ? action.payload.so === ''
                  ? null
                  : action.payload.so
                : state.params.so,
            eq:
              action.payload.eq !== undefined
                ? action.payload.eq === ''
                  ? null
                  : action.payload.eq
                : state.params.eq,
            st:
              action.payload.st !== undefined
                ? action.payload.st === ''
                  ? null
                  : action.payload.st
                : state.params.st,
          },
        };
      default:
        return state;
    }
  };
  return (
    <Formulario reducer={reducer} initialState={initialState}>
      <MantenimientoList />
      <MantenimientoItem />
      <MantenimientoFilter />
    </Formulario>
  );
};

export default Mantenimiento;
