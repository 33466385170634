import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Boton from '../../commons/Boton';
import Modal from '../../commons/Modal';
import Texto from '../../commons/Texto';
import FileUpload from '../../commons/FileUpload';
import File from '../app/File';

import { confirmacion } from '../../commons/Mensajes';
import { formatoMonetario, redondeoDecimales } from '../../commons/Utilitarios';

import {
  patchConfigurarEmpresaAction,
  patchInformacionAdicional,
  openModalConfiguracion,
} from '../../redux/actions/app.action';

import BuildIcon from '@material-ui/icons/Build';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';

const EmpresaConfiguracion = () => {
  const dispatch = useDispatch();
  const { errors, processing, currentUser, datos } = useSelector(
    (state) => state.app
  );
  const [configuracion, setConfiguracion] = useState(datos);
  const [imagen, setImage] = useState(null);

  const handleChange = (item) => {
    const { name, value } = item;
    setConfiguracion((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    handleChange({
      name: 'mm',
      value: formatoMonetario(redondeoDecimales(configuracion.mm)),
    });
    handleChange({
      name: 'md',
      value: formatoMonetario(redondeoDecimales(configuracion.md)),
    });
  }, []);

  return (
    <Modal
      title={'Configuración de empresa'}
      onActions={() => (
        <>
          <Boton
            className="op-dinamic"
            disabled={processing}
            icon={() => <InsertPhotoIcon />}
            style={{ '--button-color': '#3498db' }}
            onClick={() =>
              confirmacion(
                `¿Desea guardar la imagen?`,
                `Este sera información adicional que aparecera en tus reportes`
              )
                .then(() => dispatch(patchInformacionAdicional(imagen)))
                .catch(() => {})
            }
          >
            Guardar informaci&oacute;n adicional
          </Boton>
          <Boton
            className="op-grabar"
            disabled={processing}
            icon={() => <BuildIcon />}
            tabIndex={21}
            onClick={() =>
              confirmacion(
                '¿Está seguro?',
                `Actualizar la configuración de la empresa`
              )
                .then(() =>
                  dispatch(patchConfigurarEmpresaAction(configuracion))
                )
                .catch(() => {})
            }
          >
            Actualizar configuraci&oacute;n
          </Boton>
        </>
      )}
      closeWithoutConfirmation
      onClose={() => dispatch(openModalConfiguracion(false))}
    >
      <Texto
        name="mm"
        value={configuracion.mm}
        label="Monto m&iacute;nimo de delivery gratis"
        tabIndex={51}
        size={2}
        error={errors.mm}
        onChange={handleChange}
        restrict={'money'}
        onBlur={(e) => {
          handleChange({
            name: 'mm',
            value: formatoMonetario(redondeoDecimales(e.target.value)),
          });
        }}
      />
      <Texto
        name="md"
        value={configuracion.md}
        label="Precio de delivery"
        tabIndex={52}
        size={2}
        error={errors.md}
        onChange={handleChange}
        restrict={'money'}
        onBlur={(e) => {
          handleChange({
            name: 'md',
            value: formatoMonetario(redondeoDecimales(e.target.value)),
          });
        }}
      />
      {configuracion.ai && (
        <File
          filename={configuracion.ai}
          dir={[currentUser.ie]}
          onClose={() => {}}
          showInline
        />
      )}
      <div
        className="op-form-group op-col-1"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <FileUpload
          label={'Información adicional'}
          extensions={['jpg', 'jpeg', 'png']}
          onSelectedFile={(files) => {
            if (files.length > 0) {
              setImage(files[0].file);
            }
          }}
        />
      </div>
    </Modal>
  );
};

export default EmpresaConfiguracion;
