import React from 'react';
import { useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';

import Bloque from '../../commons/Bloque';
import CheckBox from '../../commons/CheckBox';
import TablaBody from '../../commons/TablaBody';
import TablaSearch from '../../commons/TablaSearch';
import Paginacion from '../../commons/Paginacion';
import { useTablaFilter } from '../../commons/Formulario';

const EquipoList = () => {
  const { setSelectedItem, dispatch } = useTablaFilter();
  const perfil = useSelector((state) => state.app.currentUser.tr);

  const columns = [
    {
      key: 'eq',
      align: 'center',
      content: (item) => (
        <>
          <EditIcon
            onClick={() => setSelectedItem(item)}
            style={{ color: 'green', cursor: 'pointer' }}
          />
          {perfil === 'NOBASICO' && (
            <DeviceHubIcon
              onClick={() =>
                dispatch({ type: 'EQUIPO_PRECONFIG', payload: item })
              }
              style={{ color: 'blue', cursor: 'pointer' }}
            />
          )}
        </>
      ),
    },
    {
      key: 'ne',
      label: 'Nombre',
    },
    {
      key: 'cl.dn',
      label: 'Cliente',
      content: (item) => <span>{item.cl?.dn}</span>,
    },
    {
      key: 'st',
      label: 'Estado de registro',
      align: 'center',
      content: (item) => <CheckBox checked={item.st} disabled />,
    },
  ];

  return (
    <Bloque titulo="Equipos">
      <div className="op-table">
        <TablaSearch />
        <TablaBody columns={columns} />
        <Paginacion />
      </div>
    </Bloque>
  );
};

export default EquipoList;
