import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { contratoModel } from '../../redux/models/contrato.model';

import Texto from '../../commons/Texto';
import CheckBox from '../../commons/CheckBox';
import Modal from '../../commons/Modal';
import Boton from '../../commons/Boton';
import Combo from '../../commons/Combo';
import { confirmacion } from '../../commons/Mensajes';

import ServicioList from '../servicio/ServicioList';
import ClienteList from '../cliente/ClienteList';
import ContratoDetalle from './ContratoDetalle';

import {
  postContratoAction,
  closeModal,
} from '../../redux/actions/contrato.action';

import SaveIcon from '@material-ui/icons/Save';

const ContratoNew = () => {
  const [openServicios, setOpenServicio] = useState(false);
  const [openCliente, setOpenCliente] = useState(false);
  const [contrato, setContrato] = useState(contratoModel);
  const [templates, setTemplates] = useState([]);
  const [accion, setAccion] = useState(null);
  const [autogenerar, setAutogenerar] = useState(true);
  const [imagenes, setImagenes] = useState([]);

  const { processing, errors } = useSelector((state) => state.contrato);
  const { plantillas } = useSelector((state) => state.plantilla);
  const { perfiles } = useSelector((state) => state.perfiles);
  const { currentUser } = useSelector((state) => state.app);
  const { formasPago } = useSelector((state) => state.detalle);
  const dispatch = useDispatch();

  useEffect(() => {
    setTemplates(
      plantillas
        .filter((x) => x.or === 1 && x.st)
        .map((x, i) => ({ co: i + 1, pd: x.pl, vd: null, nd: x.nd, vt: x.vt }))
    );
    let acc = perfiles.find((x) => x.or === 1);
    if (acc) {
      setAccion(acc.na);
    }
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setContrato((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === 'gc') {
      setAutogenerar(!autogenerar);
    }
  };

  return (
    <>
      <Modal
        title={`Nuevo solicitud`}
        processing={processing}
        onActions={() => (
          <>
            {accion && (
              <Boton
                className="op-grabar"
                disabled={processing}
                icon={() => <SaveIcon />}
                tabIndex={21}
                onClick={() =>
                  confirmacion('¿Desea registrar?', `Un nueva solicitud`)
                    .then(() =>
                      dispatch(
                        postContratoAction(contrato, templates, imagenes)
                      )
                    )
                    .catch(() => {})
                }
              >
                {accion}
              </Boton>
            )}
          </>
        )}
        onClose={() => dispatch(closeModal())}
      >
        <Texto
          name="nu"
          value={currentUser.np}
          label="Vendedor"
          size={2}
          tabIndex={11}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="ns"
          value={currentUser.ns}
          label="Sucursal"
          size={2}
          tabIndex={12}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="ss"
          value={contrato.ss}
          label="Servicio"
          size={2}
          tabIndex={13}
          onChange={() => {}}
          error={errors.cs}
          search
          disabled
          onSearch={() => setOpenServicio(true)}
        />
        <Texto
          name="nc"
          value={contrato.nc}
          label="Cliente"
          size={2}
          tabIndex={14}
          onChange={() => {}}
          error={errors.cc}
          search
          disabled
          onSearch={() => setOpenCliente(true)}
        />
        <Combo
          name="mp"
          label="Forma de pago"
          value={contrato.mp}
          tabIndex={12}
          size={2}
          data={formasPago.map((i) => ({ value: i.dt, label: i.de }))}
          error={errors.mp}
          onChange={handleChange}
        />
        <ContratoDetalle
          lista={templates}
          onChange={(item) => {
            if (item.name === 'files') {
              handleChange({ name: 'pd', value: item.value.pd });
              setImagenes(item.value.files);
            } else {
              setTemplates(
                templates.map((p) =>
                  p.pd === item.name
                    ? {
                        ...p,
                        vd: item.value,
                        lb: item.label,
                      }
                    : p
                )
              );
            }
          }}
        />
        {!autogenerar && (
          <Texto
            name="ot"
            value={contrato.ot}
            label="C&oacute;digo de contrato"
            tabIndex={20}
            size={2}
            error={errors.ot}
            onChange={handleChange}
            disabled={autogenerar}
          />
        )}
        <div className="op-form-group op-col-2 op-checkbox-group">
          <span>¿Desea autogenerar el c&oacute;digo?</span>
          <CheckBox
            name="gc"
            tabIndex={21}
            checked={contrato.gc}
            onChange={handleChange}
          />
        </div>
      </Modal>
      {openServicios && (
        <ServicioList
          searchable
          onClose={() => setOpenServicio(false)}
          onSend={(item) => {
            handleChange({ name: 'cs', value: item.sv });
            handleChange({ name: 'ss', value: item.ns });
          }}
        />
      )}
      {openCliente && (
        <ClienteList
          searchable
          onClose={() => setOpenCliente(false)}
          onSend={(item) => {
            handleChange({ name: 'cc', value: item.cl });
            handleChange({ name: 'nc', value: item.dn });
          }}
        />
      )}
    </>
  );
};

export default ContratoNew;
