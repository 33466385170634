import React, { useState } from 'react';
import { connect } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Modal from '../../commons/Modal';
import Bloque from '../../commons/Bloque';
import CheckBox from '../../commons/CheckBox';

import {
  getProveedoresAction,
  getSeleccionarProveedorAction,
  actualizarFiltros,
} from '../../redux/actions/proveedor.action';

import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';

const ProveedorList = ({
  proveedores,
  processing,
  total,
  getSeleccionarProveedorAction,
  getProveedoresAction,
  actualizarFiltros,
  searchable,
  onClose,
  onSend,
}) => {
  const [buscar, setBuscar] = useState(null);
  const columns = [
    {
      key: 'pv',
      align: 'center',
      content: (item) => (
        <>
          {searchable ? (
            <div title="Enviar">
              <CheckIcon
                onClick={() => {
                  onSend(item);
                  onClose();
                }}
                style={{ color: 'green', cursor: 'pointer' }}
              />
            </div>
          ) : (
            <div title="Editar" style={{ display: 'inline-block' }}>
              <EditIcon
                onClick={() => getSeleccionarProveedorAction(item)}
                style={{ color: 'green', cursor: 'pointer' }}
              />
            </div>
          )}
        </>
      ),
    },
    {
      key: 'rz',
      label: 'Razón social',
    },
    {
      key: 'nr',
      label: 'Número de ruc',
    },
    {
      key: 'co',
      label: 'Correo electrónico',
    },
    {
      key: 'st',
      label: 'Estado de registro',
      align: 'center',
      content: (item) => <CheckBox checked={item.st} disabled />,
    },
  ];

  return (
    <>
      {searchable ? (
        <Modal
          title="B&uacute;squeda de proveedores"
          closePressEscape={false}
          closeWithoutConfirmation
          onClose={() => onClose()}
        >
          <Tabla
            columns={columns}
            data={proveedores}
            processing={processing}
            total={total}
            onPagination={({ page, rows }) =>
              getProveedoresAction({ page, pageSize: rows })
            }
            onSearching={() => {
              actualizarFiltros({ search: buscar });
              getProveedoresAction({});
            }}
            onChangeSearching={(search) => setBuscar(search)}
            onDoubleClick={(item) => {
              onSend(item);
              onClose();
            }}
            style={{ flexBasis: '100%' }}
          />
        </Modal>
      ) : (
        <Bloque titulo="Resultado de b&uacute;squeda">
          <Tabla
            columns={columns}
            data={proveedores}
            processing={processing}
            total={total}
            onPagination={({ page, rows }) =>
              getProveedoresAction({ page, pageSize: rows })
            }
            onSearching={() => getProveedoresAction({})}
            onChangeSearching={(search) => actualizarFiltros({ search })}
          />
        </Bloque>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    proveedores: state.proveedor.proveedores,
    processing: state.proveedor.processing,
    total: state.proveedor.total,
  };
};

export default connect(mapStateToProps, {
  getProveedoresAction,
  getSeleccionarProveedorAction,
  actualizarFiltros,
})(ProveedorList);
