import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Formulario from '../../commons/Formulario';

import { getDetallesPorCabeceraAction } from '../../redux/actions/detalle.action';
import { getSucursalesAction } from '../../redux/actions/sucursal.action';

import { operacionModel } from '../../redux/models/operacion.model';
import { servicios } from '../../redux/helpers';
import { TYPE_FILTER } from '../../redux/helpers/app.types';

import OperacionFilter from './OperacionFilter';
import OperacionItem from './OperacionItem';
import OperacionList from './OperacionList';

const Operacion = () => {
  const { currentUser } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSucursalesAction({}));
    dispatch(
      getDetallesPorCabeceraAction({
        ca: 'TIPOINGRESO',
        page: 0,
        pageSize: 30,
      })
    );
    dispatch(
      getDetallesPorCabeceraAction({
        ca: 'TIPOSALIDA',
        page: 0,
        pageSize: 30,
      })
    );
  }, []);

  const initialState = {
    params: {
      em: currentUser.ie,
      ct: '',
      su: '',
      is: '',
      fi: '',
      ff: '',
    },
    serviceName: servicios.OPERACION,
    selected: operacionModel,
    errors: operacionModel,
    openModal: false,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case TYPE_FILTER:
        return {
          ...state,
          params: {
            ...state.params,
            ct: action.payload.ct ? state.params.ct : action.payload.ct,
            su: action.payload.su ? state.params.su : action.payload.su,
            is: action.payload.is ? state.params.is : action.payload.is,
            fi: action.payload.fi ? state.params.fi : action.payload.fi,
            ff: action.payload.ff ? state.params.ff : action.payload.ff,
          },
        };
      default:
        return state;
    }
  };

  return (
    <Formulario reducer={reducer} initialState={initialState}>
      <OperacionList />
      <OperacionItem />
      <OperacionFilter />
    </Formulario>
  );
};

export default Operacion;
