import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import Modal from '../../commons/Modal';
import Texto from '../../commons/Texto';
import Boton from '../../commons/Boton';
import Icon from '../../commons/Icon';
import Report from '../app/Report';
import {
  formatoFecha,
  formatoMonetario,
  redondeoDecimales,
} from '../../commons/Utilitarios';
import { confirmacion } from '../../commons/Mensajes';

import ContratoDetalle from './ContratoDetalle';

import {
  closeModal,
  putContratoAction,
} from '../../redux/actions/contrato.action';

import { servicios } from '../../redux/helpers/servicios';

import SaveIcon from '@material-ui/icons/Save';
import PrintIcon from '@material-ui/icons/Print';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import XlsFile from '../../assets/icons/xls-file.svg';

const ContratoView = () => {
  const [botones, setBotones] = useState([]);
  const [accion, setAccion] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [cajas, setCajas] = useState([]);
  const [colors] = useState([
    '#1ab394',
    'green',
    '#1ab394',
    '#3498db',
    '#1ab394',
  ]);
  const [open, setOpen] = useState(false);
  const [params, setParams] = useState({});
  const [openReporte, setOpenReporte] = useState(false);
  const [openReporteExcel, setOpenReporteExcel] = useState(false);
  const dispatch = useDispatch();

  const { selected, processing } = useSelector((state) => state.contrato);
  const { tr, ie } = useSelector((state) => state.app.currentUser);
  const [contrato, setContrato] = useState(selected);

  useEffect(() => {
    setBotones(
      contrato.act.map((x) => ({
        pe: x.pe,
        na: x.na,
        de: x.de,
        ce: x.ce,
        ep: x.ep,
        color: colors[Math.floor(Math.random() * colors.length)],
      }))
    );
    setTemplates(
      contrato.plt.map((x, i) => ({
        co: i + 1,
        pd: x.pl,
        vd: null,
        nd: x.nd,
        vt: x.vt,
        de: x.de,
        va: x.va,
        lb: null,
      }))
    );
  }, []);

  const handleTemplateByAccion = (item) => {
    let currents = templates.filter((x) => x.de === item.de);
    setCajas(currents);
    setAccion(item);
    if (item.ep == 1 || item.ep == 3) {
      Swal.fire({
        input: 'textarea',
        inputLabel: `${item.na} proceso de  ${item.de.toLowerCase()}`,
        inputPlaceholder: 'Ingrese el motivo ...',
        inputAttributes: {
          'aria-label': 'Ingrese el motivo',
          style: { resize: 'none' },
        },
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirmar',
        cancelButtonColor: 'red',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          confirmacion(
            '¿Está seguro?',
            `De ${item.na.toLowerCase()} la solicitud `
          )
            .then(() =>
              dispatch(
                putContratoAction(
                  contrato,
                  item.ce,
                  item.pe,
                  cajas,
                  null,
                  result.value
                )
              )
            )
            .catch(() => {});
        }
      });
    } else if (currents.length != 0) {
      setOpen(true);
    } else {
      confirmacion(
        `¿Deseas ${item.na.toLowerCase()}?`,
        `El proceso de  ${item.de.toLowerCase()}`
      )
        .then(() =>
          dispatch(putContratoAction(contrato, item.ce, item.pe, cajas))
        )
        .catch(() => {});
    }
  };

  return (
    <>
      <Modal
        title={`Contrato - ${selected.ot}`}
        processing={processing}
        onActions={() => (
          <>
            {tr === 'NOBASICO' &&
              selected.fn === null &&
              selected.ep === 0 &&
              selected.fi && (
                <Boton
                  className="op-dinamic"
                  icon={() => <PrintIcon />}
                  tabIndex={22}
                  dropdownable
                  style={{
                    '--button-color': '#3285a8',
                    '--button-icon-color': '#1ab394',
                  }}
                  showDropdownOnTop
                  actions={[
                    {
                      key: '01',
                      label: 'PDF',
                      icon: () => <PictureAsPdfIcon style={{ color: 'red' }} />,
                      onClick: () => {
                        setOpenReporte(true);
                        setParams({ co: selected.co });
                      },
                    },
                    {
                      key: '02',
                      label: 'Excel',
                      icon: () => <Icon svg={XlsFile} color="#1ab394" />,
                      onClick: () => {
                        setOpenReporteExcel(true);
                        setParams({ co: selected.co, xl: '0' });
                      },
                    },
                  ]}
                >
                  Simulador cobranzas
                </Boton>
              )}
            {botones.map((x, i) => (
              <Boton
                key={x.pe}
                className="op-dinamic"
                disabled={processing}
                icon={() => <SaveIcon />}
                style={{ '--button-color': x.color }}
                tabIndex={21 + i}
                onClick={() => handleTemplateByAccion(x)}
              >
                {x.na}
              </Boton>
            ))}
          </>
        )}
        onClose={() => dispatch(closeModal())}
      >
        <Texto
          name="ns"
          value={selected.ns}
          label="Sucursal"
          size={2}
          tabIndex={10}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="nu"
          value={selected.nu}
          label="Vendedor"
          size={2}
          tabIndex={11}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="fv"
          value={formatoFecha(selected.fv)}
          label="Fecha de venta"
          size={2}
          tabIndex={12}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="ss"
          value={selected.ss}
          label="Servicio"
          size={2}
          tabIndex={13}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="fa"
          value={formatoFecha(selected.fa)}
          label="Fecha de acci&oacute;n"
          size={2}
          tabIndex={12}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="nt"
          value={selected.nt}
          label="&Uacute;ltima acci&oacute;n"
          size={2}
          tabIndex={13}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="an"
          value={selected.an}
          label="Usuario responsable de la acci&oacute;n"
          size={2}
          tabIndex={13}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="de"
          value={selected.de}
          label="Estado contrato"
          size={2}
          tabIndex={16}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="nc"
          value={selected.nc}
          label="Cliente"
          size={2}
          tabIndex={14}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="dc"
          value={selected.dc}
          label="Documento del cliente"
          size={2}
          tabIndex={15}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="dm"
          value={selected.dm}
          label="Forma de pago"
          size={2}
          tabIndex={15}
          onChange={() => {}}
          disabled
        />
        {selected.fi && (
          <>
            <Texto
              name={'fi'}
              value={formatoFecha(selected.fi, 'Indefinido', false)}
              tabIndex={16}
              label={'Inicio de contrato'}
              size={2}
              disabled
              onChange={() => {}}
            />
            <Texto
              name="ci"
              value={selected.ci}
              label="Ciclo de facturaci&oacute;n"
              size={2}
              tabIndex={17}
              disabled
              onChange={() => {}}
            />
            <Texto
              name="cu"
              value={selected.cu}
              label="N&uacute;mero de cuotas del contrato"
              size={2}
              tabIndex={18}
              disabled
              onChange={() => {}}
            />
            <Texto
              name={'ff'}
              value={formatoFecha(selected.ff, 'Indefinido', false)}
              tabIndex={19}
              label={'Final de contrato'}
              size={2}
              disabled
              onChange={() => {}}
            />
            <Texto
              name="si"
              value={formatoMonetario(redondeoDecimales(selected.si))}
              label="Costo de instalaci&oacute;n"
              size={2}
              tabIndex={20}
              disabled
              onChange={() => {}}
            />
            <Texto
              name="ic"
              value={selected.ic}
              label="N&uacute;mero de cuotas de la instalaci&oacute;n"
              size={2}
              tabIndex={21}
              disabled
              onChange={() => {}}
            />
          </>
        )}
        {selected.fn && (
          <>
            <Texto
              name={'fn'}
              value={formatoFecha(selected.fn, 'Indefinido')}
              tabIndex={21}
              label={'Fecha de anulación'}
              size={2}
              disabled
              onChange={() => {}}
            />
            <Texto
              name="un"
              value={selected.un}
              label="Usuario anulo"
              size={2}
              tabIndex={22}
              disabled
              onChange={() => {}}
            />
            <Texto
              name="ma"
              value={selected.ma}
              label="Motivo de anulaci&oacute;n"
              size={2}
              tabIndex={23}
              disabled
              onChange={() => {}}
            />
          </>
        )}
        {(selected.ep === 1 || selected.ep === 3) && (
          <Texto
            name="al"
            value={selected.al}
            label={`${selected.nt} por motivo`}
            size={2}
            tabIndex={22}
            onChange={() => {}}
            disabled
          />
        )}
        <ContratoDetalle
          lista={selected.dtl.map((x) => ({ ...x, nd: x.np }))}
          disabled
          onChange={() => {}}
        />
      </Modal>
      {open && (
        <ContratoDetalle
          lista={cajas}
          accion={accion}
          searchable
          onClose={() => setOpen(false)}
          onAction={(c, e, a, i) => {
            setOpen(false);
            dispatch(putContratoAction(c, e, a, cajas, i));
          }}
          onChange={(item) =>
            setCajas(
              cajas.map((p) =>
                p.pd === item.name
                  ? {
                      ...p,
                      vd: item.value,
                      lb: item.label,
                    }
                  : p
              )
            )
          }
        />
      )}
      {openReporte && (
        <Report
          url={`${servicios.CONTRATO}/${ie}/simulador`}
          params={params}
          extension={'pdf'}
          onClose={() => {
            setOpenReporte(false);
          }}
        />
      )}
      {openReporteExcel && (
        <Report
          url={`${servicios.CONTRATO}/${ie}/simulador`}
          params={params}
          onClose={() => {
            setOpenReporteExcel(false);
          }}
          filename={'ReporteContratos'}
        />
      )}
    </>
  );
};

export default ContratoView;
