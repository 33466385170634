import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

import Combo from '../../commons/Combo';
import Texto from '../../commons/Texto';
import Filtros from '../../commons/Filtros';
import { useTablaFilter } from '../../commons/Formulario';

import { getClientesAction } from '../../redux/actions/cliente.action';
import ClienteList from '../../components/cliente/ClienteList';

import { equipoModel } from '../../redux/models/equipo.model';
import { TYPE_FILTER } from '../../redux/helpers/app.types';

const EquipoFilter = () => {
  const dispatchMain = useDispatch();
  const { getData, params, dispatch, setSelectedItem } = useTablaFilter();
  const [showClient, setShowClient] = useState(false);
  const [cliente, setCliente] = useState(null);
  return (
    <>
      <Filtros
        onFilter={() => getData()}
        onNew={() => setSelectedItem(equipoModel)}
      >
        <Texto
          name="cl"
          value={cliente}
          label="Cliente"
          size={3}
          tabIndex={201}
          disabled
          icon={() => (
            <>
              {params.cl && (
                <CloseIcon
                  style={{ color: 'red' }}
                  onClick={() => {
                    dispatch({ type: TYPE_FILTER, payload: { cl: null } });
                    setCliente(null);
                  }}
                />
              )}
              <SearchIcon
                onClick={() => {
                  setShowClient(true);
                  dispatchMain(getClientesAction({}));
                }}
              />
            </>
          )}
          onChange={() => {}}
        />
        <Combo
          name="st"
          label="Estado de registro"
          value={params.st}
          placeholder="Todos"
          tabIndex={202}
          size={3}
          data={[
            { value: '1', label: 'Vigente' },
            { value: '0', label: 'No Vigente' },
          ]}
          onChange={({ value }) => {
            dispatch({ type: TYPE_FILTER, payload: { st: value } });
          }}
        />
      </Filtros>
      {showClient && (
        <ClienteList
          searchable
          onSend={(item) => {
            dispatch({ type: TYPE_FILTER, payload: { cl: item.cl } });
            setCliente(item.dn);
          }}
          onClose={() => setShowClient(false)}
        />
      )}
    </>
  );
};

export default EquipoFilter;
