import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

import Busqueda from '../../commons/Busqueda';
import Combo from '../../commons/Combo';
import Filtros from '../../commons/Filtros';
import Texto from '../../commons/Texto';
import { useTablaFilter } from '../../commons/Formulario';

import { TYPE_FILTER } from '../../redux/helpers/app.types';
import { servicios, columnsEquipments } from '../../redux/helpers';
import { columnsRequests } from '../../redux/helpers/columns';

const MantenimientoFilter = () => {
  const { getData, params, dispatch, setSelectedItem } = useTablaFilter();
  const [showEquipments, setShowEquipments] = useState(false);
  const [showRequests, setShowRequests] = useState(false);
  const [equipment, setEquipment] = useState(null);
  const [request, setRequest] = useState(null);

  const handleAddEquipment = (item) => {
    dispatch({ type: TYPE_FILTER, payload: { eq: item.eq } });
    setEquipment(item.ne);
    setShowEquipments(false);
  };

  const handleAddRequest = (item) => {
    dispatch({ type: TYPE_FILTER, payload: { so: item.so } });
    setRequest(item.cs);
    setShowRequests(false);
  };

  return (
    <>
      <Filtros onFilter={() => getData()} showNewButton={false}>
        <Texto
          name="so"
          value={request}
          label="Solicitud"
          size={3}
          tabIndex={201}
          disabled
          icon={() => (
            <>
              {params.so && (
                <CloseIcon
                  style={{ color: 'red' }}
                  onClick={() => {
                    dispatch({ type: TYPE_FILTER, payload: { so: null } });
                    setRequest(null);
                  }}
                />
              )}
              <SearchIcon onClick={() => setShowRequests(true)} />
            </>
          )}
          onChange={() => {}}
        />
        <Texto
          name="eq"
          value={equipment}
          label="Equipo"
          size={3}
          tabIndex={202}
          disabled
          icon={() => (
            <>
              {params.eq && (
                <CloseIcon
                  style={{ color: 'red' }}
                  onClick={() => {
                    dispatch({ type: TYPE_FILTER, payload: { eq: null } });
                    setEquipment(null);
                  }}
                />
              )}
              <SearchIcon onClick={() => setShowEquipments(true)} />
            </>
          )}
          onChange={() => {}}
        />
        <Combo
          name="st"
          label="Estado de registro"
          value={params.st}
          placeholder="Todos"
          tabIndex={203}
          size={3}
          data={[
            { value: '1', label: 'Vigente' },
            { value: '0', label: 'No Vigente' },
          ]}
          onChange={({ value }) => {
            dispatch({ type: TYPE_FILTER, payload: { st: value } });
          }}
        />
      </Filtros>
      {showEquipments && (
        <Busqueda
          serviceName={servicios.EQUIPO}
          title="B&uacute;squeda de equipos"
          columns={columnsEquipments((item) => handleAddEquipment(item))}
          onClose={() => setShowEquipments(false)}
          onDoubleClick={(item) => handleAddEquipment(item)}
        />
      )}
      {showRequests && (
        <Busqueda
          serviceName={`${servicios.SOLICITUD}`}
          params={{ ep: 2 }}
          title="B&uacute;squeda de solicitudes"
          columns={columnsRequests((item) => handleAddRequest(item))}
          onClose={() => setShowRequests(false)}
          onDoubleClick={(item) => handleAddRequest(item)}
        />
      )}
    </>
  );
};

export default MantenimientoFilter;
