import React from 'react';
import PropTypes from 'prop-types';

import {
  MaterialCommunityIcons,
  GlypMaterialCommunity,
} from './Icons/MaterialCommunityIcons';
import { getColorProcess, toHexadecimal } from './Utilitarios';
import {
  GlyphMapFontAwesome,
  FontAwesomeIcons,
} from './Icons/FontAwesomeIcons';

export const DEFAULT_ICON_SIZE = 30;

const IconRequest = ({ process, state, image, off, size }) => {
  switch (process) {
    case 0:
    case 1:
    case 2:
    case 3:
      return (
        <MaterialCommunityIcons
          icon={`\\${toHexadecimal(
            GlypMaterialCommunity(image ?? 'bug-outline')
          )}`}
          color={getColorProcess(off ? 4 : process, state ?? '')}
          size={`${size}px`}
        />
      );
    default:
      return (
        <FontAwesomeIcons
          icon={`\\${toHexadecimal(GlyphMapFontAwesome(image ?? 'bug'))}`}
          color={getColorProcess(off ? 4 : process, state ?? '')}
          size={`${size}px`}
        />
      );
  }
};

IconRequest.propTypes = {
  process: PropTypes.number.isRequired,
  state: PropTypes.string,
  image: PropTypes.string,
  off: PropTypes.bool,
  size: PropTypes.number,
};

IconRequest.defaultProps = {
  size: DEFAULT_ICON_SIZE,
};

export default IconRequest;
