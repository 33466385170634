import React from 'react';
import { useSelector } from 'react-redux';

import { cotizacionModel } from '../../redux/models/cotizacion.model';
import { servicios } from '../../redux/helpers';
import Formulario from '../../commons/Formulario';

import CotizacionFilter from './CotizacionFilter';
import CotizacionList from './CotizacionList';
import CotizacionNew from './CotizacionNew';
import CotizacionItem from './CotizacionItem';

import { TYPE_FILTER } from '../../redux/helpers/app.types';

import '../../css/components/solicitud.css';

const Cotizacion = () => {
  const { currentUser } = useSelector((state) => state.app);

  const initialState = {
    params: {
      em: currentUser.ie,
      cl: '',
      fr: null,
    },
    serviceName: servicios.COTIZACION,
    selected: cotizacionModel,
    errors: cotizacionModel,
    viewContent: 'op-view-solicitud',
    rows: 30,
    openNew: false,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case TYPE_FILTER:
        return {
          ...state,
          params: {
            ...state.params,
            cl:
              action.payload.cl !== undefined
                ? action.payload.cl === ''
                  ? null
                  : action.payload.cl
                : state.params.cl,
            fr: action.payload.fr ? action.payload.fr : null,
          },
        };
      case 'MODAL_NEW':
        return {
          ...state,
          openNew: !state.openNew,
        };
      default:
        return state;
    }
  };

  return (
    <Formulario reducer={reducer} initialState={initialState}>
      <CotizacionList />
      <CotizacionFilter />
      <CotizacionNew />
      <CotizacionItem />
    </Formulario>
  );
};

export default Cotizacion;
