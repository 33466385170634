import { useState } from 'react';
import { useSelector } from 'react-redux';
import { api } from '../redux/axios/api';
import { servicios } from '../redux/helpers';

const useAlmacen = () => {
  const { currentUser } = useSelector((state) => state.app);
  const [processingStore, setProcessingStore] = useState(false);
  const [stores, setStores] = useState([]);

  const getStores = (su) => {
    setProcessingStore(true);
    api
      .get(servicios.ALMACEN, { params: { ie: currentUser.ie, su } })
      .then((response) => setStores(response.data))
      .catch((error) => console.log(error.data))
      .finally(() => setProcessingStore(false));
  };
  return {
    processingStore,
    stores,
    getStores,
  };
};

export default useAlmacen;
