import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveIcon from '@material-ui/icons/Remove';
import SaveIcon from '@material-ui/icons/Save';

import Boton from '../../commons/Boton';
import Busqueda from '../../commons/Busqueda';
import Combo from '../../commons/Combo';
import Tabla from '../../commons/Tabla';

import { useFormularioContext } from '../../commons/Formulario';
import { confirmacion, mensajeMini } from '../../commons/Mensajes';

import { servicios, columnsInventories } from '../../redux/helpers';
import { solicitudModel } from '../../redux/models/solicitud.model';
import useAlmacen from '../../hooks/useAlmacen';

import '../../css/ordencompra.css';

const SolicitudSupply = () => {
  const { selected, processing, callTransaction } = useFormularioContext();
  const { currentUser } = useSelector((state) => state.app);
  const { sucursales } = useSelector((state) => state.sucursal);
  const { processingStore, stores, getStores } = useAlmacen();

  const [sucursal, setSucursal] = useState('');
  const [almacen, setAlmacen] = useState('');
  const [solicitud, setSolicitud] = useState(selected);
  const [showSupplies, setShowSupplies] = useState(false);
  const [supplies, setSupplies] = useState([]);

  useEffect(() => {
    setSolicitud(selected);
    setSupplies(
      selected.nn
        ? selected.nn.map((m, i) => ({
            ...m.mt,
            id: i,
            um: m.mt.du,
            un: m.ia,
            iu: m.iu,
            in: m.in,
            ds: 0,
            pb: 0,
            st: m.st,
            edit: false,
          }))
        : []
    );
  }, [selected.so]);

  const handleAddSupply = (item) => {
    let exist = supplies.find((x) => x.cm === item.cm && x.ns === item.ns);
    if (exist === undefined) {
      setSupplies([
        ...supplies,
        {
          ...item,
          id: new Date().getTime().toString(),
          un: 1,
          ds: 0,
          pb: 0,
          st: true,
          edit: false,
        },
      ]);
    }
    setShowSupplies(false);
  };

  const handleDeleteSupply = (id) => {
    setSupplies(supplies.filter((x) => x.id !== id));
  };

  const handleCantidadMaterial = (id, valor) => {
    setSupplies(
      supplies.map((x) =>
        x.id === id
          ? {
              ...x,
              un:
                valor === -1 && x.un <= 1
                  ? 1
                  : parseInt(x.un ? x.un : 1) + valor,
            }
          : x
      )
    );
  };

  const handleHabilitarEdicion = (id, valor) => {
    setSupplies(supplies.map((x) => (x.id === id ? { ...x, edit: valor } : x)));
  };

  const handleEditarCantidad = (id, valor) => {
    setSupplies(
      supplies.map((x) =>
        x.id === id
          ? {
              ...x,
              un: valor <= 0 ? 1 : valor,
            }
          : x
      )
    );
  };

  const columns = [
    {
      key: 'id',
      align: 'center',
      content: (item) => (
        <>
          {item.st && (
            <div title="Eliminar" style={{ display: 'inline-block' }}>
              <DeleteIcon
                onClick={() =>
                  confirmacion('¿Desea eliminar?', `${item.cm} de la lista`)
                    .then(() => handleDeleteSupply(item.id))
                    .catch(() => {})
                }
                style={{ color: 'red', cursor: 'pointer' }}
              />
            </div>
          )}
        </>
      ),
    },
    {
      key: 'cm',
      label: 'Código',
      content: (item) => setStateItem(item.st, item.cm),
    },
    {
      key: 'nm',
      label: 'Material / Producto',
      content: (item) => setStateItem(item.st, item.nm),
    },
    {
      key: 'um',
      label: 'Unidad medida',
      content: (item) => setStateItem(item.st, item.um),
    },
    {
      key: 'ct',
      align: 'center',
      label: 'Cantidad',
      content: (item) => (
        <>
          {item.st && (
            <div className="op-centrar-componentes">
              <AddIcon
                style={{ color: 'green', cursor: 'pointer' }}
                onClick={() => handleCantidadMaterial(item.id, 1)}
              />
              <div className={`cantidad${item.edit ? ' edit' : ''}`}>
                <span
                  onClick={() => handleHabilitarEdicion(item.id, !item.edit)}
                >
                  {item.un}
                </span>
                <input
                  type="text"
                  value={item.un}
                  onChange={(e) =>
                    handleEditarCantidad(item.id, e.target.value)
                  }
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleHabilitarEdicion(item.id, !item.edit);
                    } else {
                      let re = new RegExp('[0-9]');
                      if (!re.test(e.key)) {
                        e.preventDefault();
                      }
                    }
                  }}
                  onPaste={(e) => e.preventDefault()}
                  onFocus={(e) => e.target.select()}
                />
                <div className="edicion">
                  <EditIcon
                    style={{ fontSize: '15px' }}
                    onClick={() => handleHabilitarEdicion(item.id, !item.edit)}
                  />
                </div>
              </div>
              <RemoveIcon
                style={{ color: 'red', cursor: 'pointer' }}
                onClick={() => handleCantidadMaterial(item.id, -1)}
              />
            </div>
          )}
        </>
      ),
    },
  ];

  const columnsSupplies = [
    {
      key: 'id',
      label: 'Código',
      content: (item) => setStateItem(item.st, item.cm),
    },
    {
      key: 'nm',
      label: 'Material / Producto',
      content: (item) => setStateItem(item.st, item.nm),
    },
    {
      key: 'um',
      label: 'Unidad medida',
      content: (item) => setStateItem(item.st, item.um),
    },
    {
      key: 'un',
      align: 'center',
      label: 'Asignados',
      content: (item) => <span>{item.un}</span>,
    },
    {
      key: 'iu',
      align: 'center',
      label: 'Usados',
      content: (item) => <span>{item.iu}</span>,
    },
    {
      key: 'in',
      align: 'center',
      label: 'No usados',
      content: (item) => <span>{item.in}</span>,
    },
  ];

  const setStateItem = (st, tx) => {
    if (st) return tx;
    return (
      <span style={{ color: 'red', textDecoration: 'line-through' }}>{tx}</span>
    );
  };

  const handleSaveSupplies = () => {
    let request = {
      so: solicitud.so,
      ie: currentUser.ie,
      ua: currentUser.us,
      in: supplies.filter((x) => x.st),
    };
    callTransaction({
      method: 'patch',
      url: `${servicios.SOLICITUD}/insumo`,
      data: request,
      model: solicitudModel,
    });
  };

  const handleReturnSupplies = () => {
    if (almacen) {
      let request = {
        so: solicitud.so,
        ie: currentUser.ie,
        ua: currentUser.us,
        al: almacen,
      };
      callTransaction({
        method: 'patch',
        url: `${servicios.SOLICITUD}/retornar`,
        data: request,
        model: solicitudModel,
      });
    } else {
      mensajeMini('Seleccione almacen');
    }
  };

  return (
    <div className="detail-equipments view-ordencompra">
      <h3>{`Insumos`}</h3>
      <div className="op-form">
        {currentUser.tr === 'NOBASICO' && (
          <Combo
            name="sucursal"
            value={sucursal}
            label="Sucursal"
            size={2}
            tabIndex={30}
            disabled={processingStore || processing}
            data={sucursales.map((x) => ({ value: x.su, label: x.ns }))}
            onChange={(item) => {
              setSucursal(item.value);
              if (item.value) {
                getStores(item.value);
              }
            }}
          />
        )}
        {solicitud.ep !== 0 && currentUser.tr === 'NOBASICO' && (
          <Combo
            name="almacen"
            value={almacen}
            label="Almacen"
            size={2}
            tabIndex={30}
            disabled={processingStore || processing}
            data={stores.map((x) => ({ value: x.al, label: x.na }))}
            onChange={(item) => {
              setAlmacen(item.value);
            }}
          />
        )}
      </div>
      <Tabla
        columns={solicitud.ep === 0 ? columns : columnsSupplies}
        data={supplies}
        searcheable={false}
        pagination={false}
        hoverSelectedRow={false}
        emptyRowsMessage="Ning&uacute;n material seleccionado"
        style={{ padding: 0 }}
      />

      <div className="container">
        {solicitud.ep !== 0 && currentUser.tr === 'NOBASICO' && (
          <Boton
            className="op-grabar"
            disabled={processing}
            icon={() => <SaveIcon />}
            tabIndex={19}
            onClick={() =>
              confirmacion(
                '¿Está seguro?',
                `De retornar los insumos de la solicitud ${solicitud.cs} al inventario`
              )
                .then(() => handleReturnSupplies())
                .catch(() => {})
            }
          >
            Retornar insumos
          </Boton>
        )}
        {solicitud.ep === 0 && currentUser.tr === 'NOBASICO' && (
          <>
            <Boton
              className="op-dinamic"
              icon={() => <AddIcon />}
              tabIndex={18}
              style={{ '--button-color': '#3285a8' }}
              onClick={() => {
                if (sucursal) {
                  setShowSupplies(true);
                } else {
                  mensajeMini('Seleccione sucursal');
                }
              }}
            >
              Agregar insumo
            </Boton>
            <Boton
              className="op-grabar"
              disabled={processing}
              icon={() => <SaveIcon />}
              tabIndex={19}
              onClick={() =>
                confirmacion(
                  '¿Está seguro?',
                  `De registrar los insumos a la solicitud ${solicitud.cs}`
                )
                  .then(() => handleSaveSupplies())
                  .catch(() => {})
              }
            >
              Guardar insumos
            </Boton>
          </>
        )}
      </div>
      {showSupplies && (
        <Busqueda
          serviceName={servicios.INVENTARIO}
          title="B&uacute;squeda de inventarios"
          columns={columnsInventories((item) => handleAddSupply(item))}
          params={{ su: sucursal }}
          emptyRowsMessage="Cliente no tiene inventario"
          onClose={() => setShowSupplies(false)}
          onDoubleClick={(item) => handleAddSupply(item)}
        />
      )}
    </div>
  );
};

export default SolicitudSupply;
