import React from 'react';
import { connect } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Modal from '../../commons/Modal';
import Bloque from '../../commons/Bloque';
import CheckBox from '../../commons/CheckBox';

import {
  getClientesAction,
  getSeleccionarClienteAction,
  actualizarFiltros,
} from '../../redux/actions/cliente.action';

import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';

const ClienteList = ({
  clientes,
  processing,
  total,
  getClientesAction,
  getSeleccionarClienteAction,
  actualizarFiltros,
  searchable,
  onClose,
  onSend,
}) => {
  const columns = [
    {
      key: 'cl',
      align: 'center',
      content: (item) => (
        <>
          {searchable ? (
            <div title="Enviar">
              <CheckIcon
                onClick={() => {
                  onSend(item);
                  onClose();
                }}
                style={{ color: 'green', cursor: 'pointer' }}
              />
            </div>
          ) : (
            <div title="Editar" style={{ display: 'inline-block' }}>
              <EditIcon
                onClick={() => getSeleccionarClienteAction(item)}
                style={{ color: 'green', cursor: 'pointer' }}
              />
            </div>
          )}
        </>
      ),
    },
    {
      key: 'dt',
      label: 'Tipo documento',
    },
    {
      key: 'nd',
      label: 'Número de documento',
    },
    {
      key: 'dn',
      label: 'Nombres completos',
    },
    {
      key: 'ce',
      label: 'Correo electrónico',
    },
    {
      key: 'st',
      label: 'Estado de registro',
      align: 'center',
      content: (item) => <CheckBox checked={item.st} disabled />,
    },
  ];

  return (
    <>
      {searchable ? (
        <Modal
          title="B&uacute;squeda de clientes"
          closePressEscape={false}
          closeWithoutConfirmation
          onClose={() => onClose()}
        >
          <Tabla
            columns={columns}
            data={clientes}
            processing={processing}
            total={total}
            onPagination={({ page, rows }) =>
              getClientesAction({ page, pageSize: rows })
            }
            onSearching={() => getClientesAction({})}
            onChangeSearching={(search) => actualizarFiltros({ search })}
            onDoubleClick={(item) => {
              onSend(item);
              onClose();
            }}
            style={{ flexBasis: '100%' }}
          />
        </Modal>
      ) : (
        <Bloque titulo="Resultado de b&uacute;squeda">
          <Tabla
            columns={columns}
            data={clientes}
            processing={processing}
            total={total}
            onPagination={({ page, rows }) =>
              getClientesAction({ page, pageSize: rows })
            }
            onSearching={() => getClientesAction({})}
            onChangeSearching={(search) => actualizarFiltros({ search })}
          />
        </Bloque>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    clientes: state.cliente.clientes,
    processing: state.cliente.processing,
    total: state.cliente.total,
  };
};

export default connect(mapStateToProps, {
  getClientesAction,
  getSeleccionarClienteAction,
  actualizarFiltros,
})(ClienteList);
