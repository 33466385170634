import React, { useState } from 'react';
import { useTablaSearch } from './Formulario';

import { caracteresUnicode } from './Utilitarios';

const TablaSearch = () => {
  const { setSearch, search } = useTablaSearch();
  const [text, setText] = useState(search);

  const handlePressSearch = (e) => {
    let re = new RegExp(`[a-zA-Z0-9-\_${caracteresUnicode()}]`);
    if (e.key === 'Enter') {
      setSearch(e.target.value);
    }
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div className="op-search">
      <input
        type="text"
        placeholder="Buscar..."
        data-search="texto"
        onKeyPress={(e) => handlePressSearch(e)}
        onChange={(e) => setText(e.target.value)}
        onPaste={(e) => e.preventDefault()}
      />
      <i
        onClick={() => {
          setSearch(text);
        }}
        className="op-icon-search"
      ></i>
    </div>
  );
};

export default TablaSearch;
