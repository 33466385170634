import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import DateFnsUtils from '@date-io/moment';

import Boton from '../../commons/Boton';
import Combo from '../../commons/Combo';
import Icon from '../../commons/Icon';
import Filtros from '../../commons/Filtros';
import Modal from '../../commons/Modal';
import Report from '../app/Report';
import { formatearFecha } from '../../commons/Utilitarios';

import {
  getPedidosAction,
  getSeleccionarPedidoAction,
  actualizarFiltros,
  restartNotificacion,
} from '../../redux/actions/pedido.action';
import { agregarHeaderAction } from '../../redux/actions/app.action';
import { getSucursalesAction } from '../../redux/actions/sucursal.action';

import { servicios } from '../../redux/helpers/servicios';

import MapIcon from '@material-ui/icons/Map';
import PrintIcon from '@material-ui/icons/Print';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import XlsFile from '../../assets/icons/xls-file.svg';

const PedidoFilter = ({
  estadoPedido,
  estado,
  sucursales,
  perfil,
  empresa,
  su,
}) => {
  const dispatch = useDispatch();
  const [selectedDate, handleDateChange] = useState(new Date());
  const [openModal, setOpenModal] = useState(false);
  const [openReporte, setOpenReporte] = useState(false);
  const [openReporteExcel, setOpenReporteExcel] = useState(false);

  const defaultMaterialTheme = createMuiTheme({
    palette: {
      primary: { main: '#3498db', contrastText: '#fff' },
    },
  });

  useEffect(() => {
    if (perfil === 'NOBASICO') {
      dispatch(getSucursalesAction({}));
    }
  }, []);

  return (
    <>
      <Filtros
        onNew={() => dispatch(getSeleccionarPedidoAction())}
        onFilter={() => {
          dispatch(getPedidosAction());
          dispatch(restartNotificacion());
        }}
        actions={() => (
          <>
            {perfil === 'NOBASICO' && (
              <Boton
                className="op-filter"
                icon={() => <PrintIcon />}
                tabIndex={22}
                style={{
                  '--button-icon-color': '#1ab394',
                }}
                onClick={() => setOpenModal(true)}
              >
                Imprimir
              </Boton>
            )}
            <Boton
              className="op-filter"
              icon={() => <MapIcon />}
              tabIndex={21}
              onClick={() => {
                let head = {
                  mm: 'COM_PED_MAP',
                  nf: false,
                  op: 'Rutas',
                  pp: 'COM_PED',
                  or: 3,
                  im: 'map',
                  st: true,
                };
                dispatch(agregarHeaderAction(head));
              }}
            >
              Rutas
            </Boton>
          </>
        )}
      >
        {perfil === 'NOBASICO' && (
          <Combo
            name="su"
            label="Sucursal"
            value={su}
            placeholder="Todos"
            tabIndex={30}
            size={3}
            dropdownTop={true}
            data={sucursales.map((i) => ({ value: i.su, label: i.ns }))}
            onChange={({ value }) => dispatch(actualizarFiltros({ su: value }))}
          />
        )}
        <Combo
          name="tb"
          value={estado}
          label="Estados"
          tabIndex={31}
          placeholder="Todos"
          size={3}
          data={estadoPedido.map((x) => ({ value: x.dt, label: x.de }))}
          onChange={({ value }) => dispatch(actualizarFiltros({ ep: value }))}
        />
      </Filtros>
      {openModal && (
        <Modal
          title={'Reporte de pedidos'}
          size={'small'}
          onActions={() => (
            <>
              <Boton
                className="op-dinamic"
                icon={() => <PictureAsPdfIcon />}
                tabIndex={31}
                style={{ '--button-color': 'red' }}
                onClick={() => setOpenReporte(true)}
              >
                PDF
              </Boton>
              <Boton
                className="op-dinamic"
                icon={() => <Icon svg={XlsFile} color="#1ab394" />}
                tabIndex={32}
                style={{ '--button-color': '#1ab394' }}
                onClick={() => setOpenReporteExcel(true)}
              >
                Excel
              </Boton>
            </>
          )}
          onClose={() => setOpenModal(false)}
        >
          <div className="op-form-group">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <ThemeProvider theme={defaultMaterialTheme}>
                <DatePicker
                  variant="inline"
                  openTo="year"
                  views={['year', 'month']}
                  label="Fecha pedido"
                  helperText="A&ntilde;o y Mes"
                  value={selectedDate}
                  autoOk={true}
                  format="YYYY-MM"
                  maxDate={
                    new Date(
                      new Date().getFullYear(),
                      new Date().getMonth() + 1,
                      0
                    )
                  }
                  onChange={handleDateChange}
                  onMonthChange={(item) => {
                    actualizarFiltros({
                      fo: formatearFecha(item._d, 'YYYY-MM-DD'),
                    });
                  }}
                />
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </div>
        </Modal>
      )}
      {openReporte && (
        <Report
          url={`${servicios.PEDIDO}/${empresa}/imprimir`}
          params={{
            ep: estado,
            fp: formatearFecha(selectedDate, 'YYYY-MM-DD'),
          }}
          extension={'pdf'}
          onClose={() => {
            setOpenReporte(false);
          }}
        />
      )}
      {openReporteExcel && (
        <Report
          url={`${servicios.PEDIDO}/${empresa}/imprimir`}
          params={{
            ep: estado,
            fp: formatearFecha(selectedDate, 'YYYY-MM-DD'),
            xl: '0',
          }}
          onClose={() => {
            setOpenReporteExcel(false);
          }}
          filename={'ReportePedidos'}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    sucursales: state.sucursal.sucursales,
    perfil: state.app.currentUser.tr,
    estadoPedido: state.detalle.estadoPedido,
    estado: state.pedido.ep,
    su: state.pedido.su,
    empresa: state.app.currentUser.ie,
  };
};

export default connect(mapStateToProps)(PedidoFilter);
