import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { NavLink } from 'react-router-dom';

import Texto from '../../commons/Texto';
import Boton from '../../commons/Boton';
import Loading from '../../commons/Loading';

import {
  getCaptchaAction,
  putCaptchaAction,
  patchRestablecerContraseniaAction,
} from '../../redux/actions/app.action';

import RefreshIcon from '@material-ui/icons/Refresh';

import '../../css/olvido.css';

const RestablecerContrasenia = ({
  processing,
  captcha,
  captchaProcessing,
  error,
  getCaptchaAction,
  putCaptchaAction,
  patchRestablecerContraseniaAction,
  location,
}) => {
  const [user, setUser] = useState({
    co: '',
    us: '',
    nu: '',
    nc: '',
    tx: '',
  });

  const params = new URLSearchParams(location.search);

  useEffect(() => {
    getCaptchaAction();
    setUser((prev) => ({
      ...prev,
      us: params.get('ss'),
    }));
  }, []);

  const handleSubmit = () => {
    patchRestablecerContraseniaAction(user);
    setUser((prev) => ({
      ...prev,
      tx: '',
    }));
  };

  const handleChange = (item) => {
    const { name, value } = item;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleRefreshCaptcha = () => {
    putCaptchaAction(captcha.ct);
  };

  return (
    <div className="op-content-forgot">
      <div className="op-content">
        <div className="op-forgot">
          <h1>Restablecer usuario</h1>
          <h3>
            Se te envi&oacute; un correo con el c&oacute;digo de
            verificaci&oacute;n
          </h3>
          <Texto
            name="co"
            value={user.co}
            type={'password'}
            label="C&oacute;digo de verificaci&oacute;n"
            tabIndex={1}
            enabledPaste={true}
            onChange={handleChange}
          ></Texto>
          <Texto
            name="nu"
            value={user.nu}
            label="Contrase&ntilde;a nueva"
            type="password"
            tabIndex={2}
            onChange={handleChange}
          />
          <Texto
            name="nc"
            value={user.nc}
            label="Confirmar contrase&ntilde;a nueva"
            type="password"
            tabIndex={3}
            onChange={handleChange}
          />
          <div className="op-form-group op-captcha">
            {captchaProcessing ? (
              <>
                <div></div>
                <i className="op-loading"></i>
              </>
            ) : (
              <>
                <img
                  alt="captcha"
                  src={`data:image/jpg;base64,${captcha.img}`}
                  style={{ cursor: 'pointer' }}
                ></img>
                <RefreshIcon fontSize="large" onClick={handleRefreshCaptcha} />
              </>
            )}
          </div>
          {captchaProcessing === false && (
            <p>{`La imagen contiene ${captcha.si} caracteres`}</p>
          )}
          <Texto
            label="Escribe los caracteres"
            name="tx"
            value={user.tx}
            tabIndex={4}
            onChange={handleChange}
          />
          <Boton
            className="op-grabar"
            disabled={processing}
            onClick={handleSubmit}
            tabIndex={5}
          >
            Enviar
          </Boton>
          {error && <label className="op-error">{error}</label>}
          {processing === true && <Loading />}
          <NavLink className="op-boton op-cerrar iniciar" to="/">
            Iniciar sesi&oacute;n
          </NavLink>
          <h5>@ 2020 Soluciones OP - Todos los derechos reservados</h5>
        </div>
      </div>
    </div>
  );
};

const mapStateToProp = (state) => {
  return {
    processing: state.app.processing,
    error: state.app.error,
    captcha: state.app.captcha,
    captchaProcessing: state.app.captchaProcessing,
  };
};

export default connect(mapStateToProp, {
  getCaptchaAction,
  putCaptchaAction,
  patchRestablecerContraseniaAction,
})(RestablecerContrasenia);
