import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';

import Boton from '../../commons/Boton';
import Combo from '../../commons/Combo';
import CheckBox from '../../commons/CheckBox';
import Modal from '../../commons/Modal';
import Texto from '../../commons/Texto';

const TesteoDetail = ({ selected, onClose, onSend }) => {
  const [detail, setDetail] = useState(selected);

  const { tipoDatos } = useSelector((state) => state.detalle);

  useEffect(() => {
    let exist = tipoDatos.find((t) => t.de === selected.de);
    if (exist) {
      handleChange({ name: 'td', value: exist.dt });
    }
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setDetail((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Modal
      title={`${selected.co ? 'Editar' : 'Nuevo'} testeo unitario`}
      onActions={() => (
        <Boton
          className="op-dinamic"
          icon={() => <AddIcon />}
          tabIndex={22}
          style={{ '--button-color': '#3285a8' }}
          onClick={() => onSend(detail)}
        >
          {`${selected.co ? 'Editar' : 'Agregar'} Testeo Unitario`}
        </Boton>
      )}
      closeWithoutConfirmation
      onClose={() => onClose()}
      size="small"
    >
      <Texto
        name="nt"
        value={detail.nt}
        label="Nombre"
        tabIndex={11}
        onChange={handleChange}
      />
      <Combo
        name="td"
        value={detail.td}
        label="Tipo de dato"
        tabIndex={11}
        data={tipoDatos.map((i) => ({ value: i.dt, label: i.de }))}
        onChange={(item) => {
          handleChange(item);
          handleChange({ name: 'de', value: item.label });
        }}
      />
      {detail.co && (
        <div className="op-form-group op-checkbox-group">
          <span>Estado del registro</span>
          <CheckBox
            name="st"
            tabIndex={12}
            checked={detail.st}
            onChange={handleChange}
          />
        </div>
      )}
    </Modal>
  );
};

export default TesteoDetail;
