import React, { useEffect, useRef } from 'react';
import Proptypes from 'prop-types';
import lottie from 'lottie-web';

import '../css/commons/loading.css';

const Loading = ({ tipo }) => {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: handleType(),
    });
  }, []);

  const handleType = () => {
    switch (tipo) {
      case 'files':
        return require('../assets/json/files-transfer.json');
      case 'payment':
        return require('../assets/json/payment.json');
      case 'supplies':
        return require('../assets/json/supplies.json');
      case 'ubication':
        return require('../assets/json/ubication.json');
      case 'images':
        return require('../assets/json/images.json');
      case 'request':
        return require('../assets/json/request.json');
      case 'calendar':
        return require('../assets/json/calendar.json');
      default:
        return require('../assets/json/loading.json');
    }
  };

  return (
    <div className="op-content-loader">
      <div className="container" ref={container}></div>
    </div>
  );
};

Loading.propTypes = {
  tipo: Proptypes.string,
};

Loading.defaultProps = {
  tipo: 'default',
};

export default Loading;
