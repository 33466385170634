import React, { useEffect, useState } from 'react';
import { useTablaPagination } from './Formulario';

const Paginacion = () => {
  const {
    goNextPage,
    goPrevPage,
    goSelectedPage,
    activePageIndex,
    rows,
    total,
  } = useTablaPagination();
  const [elements, setElements] = useState([]);
  const [show, setShow] = useState(true);

  useEffect(() => {
    let currentElements = [];
    if (total === 0) {
      setShow(false);
    } else {
      setShow(true);
      let totalPages = Math.trunc(total / rows);
      let currentePage = (activePageIndex - 1) / rows;
      let beforePages = currentePage - 1;
      let afterPages = currentePage + 1;

      if (totalPages > 6) {
        if (currentePage > 1) {
          currentElements.push({
            selectable: true,
            label: '1',
            order: 0,
          });
          if (currentePage > 2) {
            currentElements.push({
              selectable: false,
              label: '...',
              order: -1,
            });
          }
        }

        if (currentePage === totalPages - 1) {
          beforePages = beforePages - 2;
        } else if (currentePage === totalPages - 2) {
          beforePages = beforePages - 1;
        }

        if (currentePage === 0) {
          afterPages = afterPages + 2;
        } else if (currentePage === 1) {
          afterPages = afterPages + 1;
        }

        for (
          let pageLength = beforePages;
          pageLength <= afterPages;
          pageLength++
        ) {
          if (pageLength > totalPages - 1) {
            continue;
          }
          if (pageLength === -1) {
            pageLength = pageLength + 1;
          }
          currentElements.push({
            selectable: true,
            label: `${pageLength + 1}`,
            order: pageLength,
          });
        }

        if (currentePage < totalPages - 2) {
          if (currentePage < totalPages - 3) {
            currentElements.push({
              selectable: false,
              label: '...',
              order: -2,
            });
          }
          currentElements.push({
            selectable: true,
            label: `${totalPages}`,
            order: totalPages - 1,
          });
        }

        if (total > totalPages * rows) {
          currentElements.push({
            selectable: true,
            label: `${totalPages + 1}`,
            order: totalPages,
          });
        }
      } else {
        for (let i = 0; i <= totalPages; i++) {
          currentElements.push({
            selectable: true,
            label: `${i + 1}`,
            order: i,
          });
        }
      }
    }
    setElements(currentElements);
  }, [total]);

  const handlePaginado = () => {
    let p = Math.trunc(activePageIndex / rows) + 1;
    return p * rows > total ? total : p * rows;
  };
  return (
    <>
      {show === true && (
        <div className="op-pagination">
          <span>{`Del ${activePageIndex} al ${handlePaginado()} de ${total} registros`}</span>
          <ul>
            <li
              className={`op-prev${activePageIndex === 1 ? ' disabled' : ''}`}
              onClick={() => goPrevPage()}
            ></li>
            {elements.map((item) => (
              <li
                key={item.order}
                onClick={(e) =>
                  item.selectable ? goSelectedPage(rows * item.order) : null
                }
                data-page={rows * item.order}
                data-rows={rows}
                className={`op-pagina${
                  rows * item.order === activePageIndex - 1 ? ' op-current' : ''
                }`}
              >
                {item.label}
              </li>
            ))}
            <li
              className={`op-next${
                Math.trunc(activePageIndex / rows) + 1 >
                Math.trunc(total / rows) + (total % rows > 0 ? 0 : -1)
                  ? ' disabled'
                  : ''
              }`}
              onClick={() => goNextPage()}
            ></li>
          </ul>
        </div>
      )}
    </>
  );
};

export default Paginacion;
