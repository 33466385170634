import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';

import Adjunto from '../../commons/Adjunto';
import Boton from '../../commons/Boton';
import CheckBox from '../../commons/CheckBox';
import Modal from '../../commons/Modal';
import Texto from '../../commons/Texto';
import useAdjunto from '../../hooks/useAdjunto';
import { useFormularioContext } from '../../commons/Formulario';

import { confirmacion } from '../../commons/Mensajes';

import { manualModel } from '../../redux/models/manual.model';
import { servicios } from '../../redux/helpers';

const ManualItem = () => {
  const {
    selected,
    openModal,
    processing,
    errors,
    successImage,
    callTransaction,
    cancelTransaction,
  } = useFormularioContext();
  const [manual, setManual] = useState(selected);
  const { currentUser } = useSelector((state) => state.app);
  const { processAttached, attached, getAttached } = useAdjunto();

  const handleChange = (item) => {
    const { name, value } = item;
    setManual((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    setManual(selected);
  }, [selected, openModal]);

  useEffect(() => {
    if (selected.mn && openModal && selected.dm) {
      getAttached(selected.dm.ar);
    }
  }, [successImage]);

  if (!openModal) {
    return null;
  }

  const handleSave = () => {
    let item = {
      ...manual,
      ie: currentUser.ie,
    };
    callTransaction({
      method: 'post',
      url: servicios.MANUAL,
      data: item,
      model: manualModel,
    });
  };

  const handleSaveDocument = (documento) => {
    let form_data = new FormData();
    form_data.append('file', documento);
    form_data.append('mn', manual.mn);
    form_data.append('ie', currentUser.ie);

    callTransaction({
      method: 'patch',
      url: `${servicios.MANUAL}/adjuntar`,
      data: form_data,
      headers: { 'content-type': 'multipart/form-data; charset=utf-8' },
      model: manualModel,
      successfulAction: { type: 'SUCCESS_IMAGE' },
    });
  };

  const handleEditDocument = (documento, id) => {
    let form_data = new FormData();
    form_data.append('file', documento);
    form_data.append('ar', attached.ar);
    form_data.append('ie', currentUser.ie);
    form_data.append('co', id);

    callTransaction({
      method: 'patch',
      url: `${servicios.ARCHIVO}/editar`,
      data: form_data,
      headers: { 'content-type': 'multipart/form-data; charset=utf-8' },
      model: manualModel,
      successfulAction: { type: 'SUCCESS_IMAGE' },
    });
  };

  const handleDownDocument = (id) => {
    let archivo = {
      ar: attached.ar,
      ie: currentUser.ie,
      co: id,
    };
    callTransaction({
      method: 'patch',
      url: `${servicios.ARCHIVO}/baja`,
      data: archivo,
      model: manualModel,
      successfulAction: { type: 'SUCCESS_IMAGE' },
    });
  };

  return (
    <Modal
      title={`${manual.mn ? 'Editar manual ' + manual.cm : 'Nuevo manual'}`}
      processing={processing}
      onActions={() => (
        <>
          <Boton
            className="op-grabar"
            disabled={processing}
            icon={() => <SaveIcon />}
            tabIndex={19}
            onClick={() =>
              confirmacion(
                '¿Desea guardar?',
                `${
                  manual.mn
                    ? 'Una actualización del manual' + manual.cm
                    : 'Un nuevo manual'
                }`
              )
                .then(() => handleSave())
                .catch(() => {})
            }
          >
            Guardar
          </Boton>
        </>
      )}
      onClose={() => cancelTransaction(manualModel)}
    >
      {manual.mn === null && (
        <Texto
          name="cm"
          value={manual.cm}
          label="C&oacute;digo"
          tabIndex={10}
          size={2}
          error={errors.cm}
          onChange={handleChange}
          disabled={manual.gc}
        />
      )}
      <Texto
        name="nm"
        value={manual.nm}
        label="Nombre"
        size={2}
        tabIndex={11}
        error={errors.nm}
        onChange={handleChange}
      />
      {manual.mn ? (
        <div className="op-form-group op-col-2 op-checkbox-group">
          <span>Estado del registro</span>
          <CheckBox
            name="st"
            tabIndex={12}
            checked={manual.st}
            onChange={handleChange}
          />
        </div>
      ) : (
        <div className="op-form-group op-col-2 op-checkbox-group">
          <span>¿Desea autogenerar el c&oacute;digo?</span>
          <CheckBox
            name="gc"
            tabIndex={13}
            checked={manual.gc}
            onChange={handleChange}
          />
        </div>
      )}
      {manual.mn && (
        <Adjunto
          stateParent={manual.st}
          nameParent={'manual'}
          extensions={['pdf', 'jpg', 'jpeg', 'png']}
          processing={processing || processAttached}
          data={
            attached && attached.dtl
              ? attached.dtl.map((a) => ({
                  id: a.cr,
                  name: a.na,
                  extension: a.ea,
                  state: a.st,
                }))
              : []
          }
          file={attached && attached.ar}
          onNew={(documento) => handleSaveDocument(documento)}
          onEdit={(documento, id) => handleEditDocument(documento, id)}
          onDown={(id) => handleDownDocument(id)}
        />
      )}
    </Modal>
  );
};

export default ManualItem;
