import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Tabla from '../../commons/Tabla';
import Boton from '../../commons/Boton';
import Calendario from '../../commons/Calendario';
import { confirmacion } from '../../commons/Mensajes';
import { formatoMonetario, redondeoDecimales } from '../../commons/Utilitarios';

import ServicioList from '../servicio/ServicioList';
import ClienteList from '../cliente/ClienteList';

import {
  closeModal,
  postNuevoPedidoAction,
} from '../../redux/actions/pedido.action';

import { getServiciosAction } from '../../redux/actions/servicio.action';
import { getClientesAction } from '../../redux/actions/cliente.action';

import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const PedidoNew = () => {
  const dispatch = useDispatch();
  const { selected, processing, errors } = useSelector((state) => state.pedido);
  const [pedido, setPedido] = useState(selected);
  const [detalle, setDetalle] = useState([]);
  const [openServicio, setOpenServicio] = useState(false);
  const [openCliente, setOpenCliente] = useState(false);

  const columns = [
    {
      key: 'co',
      align: 'center',
      content: (item) => (
        <div title="Eliminar" style={{ display: 'inline-block' }}>
          <DeleteIcon
            onClick={() =>
              confirmacion('¿Desea eliminar?', `${item.ns} del pedido`)
                .then(() => handleEliminarServicio(item.sv))
                .catch(() => {})
            }
            style={{ color: 'red', cursor: 'pointer' }}
          />
        </div>
      ),
    },
    {
      key: 'ns',
      label: 'Prenda',
      content: (item) => (
        <span
          style={{
            color: `${item.st === true ? 'black' : 'red'}`,
            textDecoration: `${item.st === true ? 'none' : 'line-through'}`,
          }}
        >
          {item.ns}
        </span>
      ),
    },
    {
      key: 'nc',
      label: 'Categoria',
      content: (item) => (
        <span
          style={{
            color: `${item.st === true ? 'black' : 'red'}`,
            textDecoration: `${item.st === true ? 'none' : 'line-through'}`,
          }}
        >
          {item.nc}
        </span>
      ),
    },
    {
      key: 'ps',
      label: 'Precio',
      align: 'right',
      content: (item) => (
        <div className="op-centrar-componentes">
          {formatoMonetario(redondeoDecimales(item.ps))}
        </div>
      ),
    },
    {
      key: 'ds',
      label: 'Descuento',
      align: 'right',
      content: (item) => (
        <div className="op-centrar-componentes">
          {formatoMonetario(redondeoDecimales(item.ds))}
        </div>
      ),
    },
    {
      key: 'ca',
      label: 'Cantidad',
      align: 'right',
      content: (item) => (
        <div className="op-centrar-componentes">
          <AddIcon
            style={{ color: 'green', cursor: 'pointer' }}
            onClick={() => handleCantidadMaterial(item.sv, 1)}
          />
          <div className={`cantidad${item.edit ? ' edit' : ''}`}>
            <span
              style={{ color: `${item.st === true ? 'black' : 'red'}` }}
              onClick={(e) =>
                handleHabilitarEdicion(item.sv, !item.edit, 'edit', e)
              }
            >
              {item.ca}
            </span>
            <input
              type="text"
              value={item.ct}
              onChange={(e) => handleEditarValor(item.mt, e.target.value, 'ca')}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleHabilitarEdicion(item.sv, !item.edit, 'edit', e);
                } else {
                  let re = new RegExp('[0-9]');
                  if (!re.test(e.key)) {
                    e.preventDefault();
                  }
                }
              }}
              onPaste={(e) => e.preventDefault()}
              onFocus={(e) => e.target.select()}
            />
            <div className="edicion">
              <EditIcon
                style={{ fontSize: '15px' }}
                onClick={(e) =>
                  handleHabilitarEdicion(item.sv, !item.edit, 'edit', e)
                }
              />
            </div>
          </div>
          <RemoveIcon
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={() => handleCantidadMaterial(item.sv, -1)}
          />
        </div>
      ),
    },
    {
      key: 'su',
      label: 'SubTotales',
      align: 'right',
      content: (item) => (
        <span
          style={{
            color: `${item.st === true ? 'black' : 'red'}`,
            textDecoration: `${item.st === true ? 'none' : 'line-through'}`,
          }}
        >
          {`${formatoMonetario(
            redondeoDecimales(parseFloat(item.ps - item.ds) * parseInt(item.ca))
          )}`}
        </span>
      ),
    },
  ];

  const handleChange = (item) => {
    const { name, value } = item;
    setPedido((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEliminarServicio = (sv) => {
    const currents = detalle.filter((x) => x.sv !== sv);
    setDetalle(currents);
  };

  const handleAgregarServicio = (item) => {
    let existe = detalle.find((x) => x.cs === item.cs);
    if (existe === undefined) {
      item.ca = 1;
      item.sv = item.sv;
      item.edit = false;
      setDetalle([...detalle, item]);
    } else {
      setDetalle(
        detalle.map((x) => (x.cs === item.cs ? { ...x, ca: x.ca + 1 } : x))
      );
    }
  };

  const handleCantidadMaterial = (sv, valor) => {
    setDetalle(
      detalle.map((x) =>
        x.sv === sv
          ? {
              ...x,
              ca:
                valor === -1 && x.ca <= 1
                  ? 1
                  : parseInt(x.ca ? x.ca : 1) + valor,
            }
          : x
      )
    );
  };

  const handleHabilitarEdicion = (sv, valor, name, e) => {
    setDetalle(detalle.map((x) => (x.sv === sv ? { ...x, [name]: valor } : x)));
    let caja = e.target
      .closest('.op-centrar-componentes')
      .querySelector('input');
    let label = e.target
      .closest('.op-centrar-componentes')
      .querySelector('span');
    caja.style.width = `${label.offsetWidth}px`;
    caja.focus();
  };

  const handleEditarValor = (sv, valor, name) => {
    setDetalle(
      detalle.map((x) =>
        x.sv === sv
          ? {
              ...x,
              [name]: valor < 0 ? 0 : valor,
            }
          : x
      )
    );
  };

  useEffect(() => {
    handleChange({ name: 'dp', value: 'En Tienda' });
  }, []);

  useEffect(() => {
    let total = detalle
      .filter((f) => f.st)
      .reduce((a, b) => a + (b.ps - b.ds) * b.ca, 0);

    handleChange({
      name: 'mp',
      value: formatoMonetario(redondeoDecimales(total)),
    });
  }, [detalle]);
  return (
    <>
      <Modal
        title="Nuevo pedido"
        processing={processing}
        onActions={() => (
          <>
            <Boton
              className="op-dinamic"
              icon={() => <AddIcon />}
              tabIndex={26}
              style={{ '--button-color': '#3285a8' }}
              onClick={() => {
                dispatch(getServiciosAction({}));
                setOpenServicio(true);
              }}
            >
              Agregar servicio
            </Boton>
            <Boton
              className="op-grabar"
              disabled={processing}
              icon={() => <SaveIcon />}
              onClick={() =>
                confirmacion('¿Está seguro?', `Registrar un nuevo pedido`)
                  .then(() => dispatch(postNuevoPedidoAction(pedido, detalle)))
                  .catch(() => {})
              }
            >
              Registrar pedido
            </Boton>
          </>
        )}
        onClose={() => dispatch(closeModal())}
      >
        <Texto
          name="nc"
          value={pedido.nc}
          label="Cliente"
          size={2}
          tabIndex={10}
          disabled
          error={errors.nc}
          icon={() => (
            <SearchIcon
              onClick={() => {
                setOpenCliente(true);
                dispatch(getClientesAction({}));
              }}
            />
          )}
          onChange={() => {}}
        />
        <Texto
          name="dp"
          value={pedido.dp}
          label="Direcci&oacute;n del pedido"
          size={2}
          tabIndex={11}
          error={errors.dp}
          disabled
          onChange={() => {}}
        />
        <Calendario
          name="fp"
          value={pedido.fp}
          label="Fecha pedido"
          tabIndex={12}
          size={2}
          minDate={new Date()}
          error={errors.fp}
          onSelected={handleChange}
        />
        <div className="op-form-group op-col-1">
          <Tabla
            columns={columns}
            data={detalle}
            pagination={false}
            searcheable={false}
            hoverSelectedRow={false}
            style={{ padding: '0px' }}
          />
          <div className="op-totales">
            <label>
              <strong>Importe Total</strong>
            </label>
            <span>
              <strong>{formatoMonetario(redondeoDecimales(pedido.mp))}</strong>
            </span>
          </div>
        </div>
      </Modal>
      {openServicio && (
        <ServicioList
          searchable
          fromPedido
          onClose={() => setOpenServicio(false)}
          onSend={(item) => handleAgregarServicio(item)}
        />
      )}
      {openCliente && (
        <ClienteList
          searchable
          onClose={() => setOpenCliente(false)}
          onSend={(item) => {
            handleChange({ name: 'll', value: item.cl });
            handleChange({ name: 'nc', value: item.dn });
          }}
        />
      )}
    </>
  );
};

export default PedidoNew;
