import React, { useState } from 'react';
import { connect } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Boton from '../../commons/Boton';
import { confirmacion } from '../../commons/Mensajes';

import {
  closeModal,
  postSucursalAction,
} from '../../redux/actions/sucursal.action';

import SaveIcon from '@material-ui/icons/Save';

const SucursalNew = ({
  selected,
  processing,
  errors,
  closeModal,
  postSucursalAction,
}) => {
  const [sucursal, setSucursal] = useState(selected);

  const handleChange = (item) => {
    const { name, value } = item;
    setSucursal((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <Modal
      title="Nueva sucursal"
      processing={processing}
      size="small"
      onActions={() => (
        <Boton
          className="op-grabar"
          disabled={processing}
          icon={() => <SaveIcon />}
          tabIndex={21}
          onClick={() =>
            confirmacion('¿Está seguro?', `Crear una nueva sucursal`)
              .then(() => postSucursalAction(sucursal))
              .catch(() => {})
          }
        >
          Guardar
        </Boton>
      )}
      onClose={() => closeModal()}
    >
      <Texto
        name="ns"
        value={sucursal.ns}
        label="Nombre"
        tabIndex={11}
        error={errors.ns}
        onChange={handleChange}
      />
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.sucursal.selected,
    processing: state.sucursal.processing,
    errors: state.sucursal.errors,
  };
};

export default connect(mapStateToProps, {
  closeModal,
  postSucursalAction,
})(SucursalNew);
