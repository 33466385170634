import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveIcon from '@material-ui/icons/Remove';
import SaveIcon from '@material-ui/icons/Save';

import Boton from '../../commons/Boton';
import Busqueda from '../../commons/Busqueda';
import Combo from '../../commons/Combo';
import Modal from '../../commons/Modal';
import Tabla from '../../commons/Tabla';

import { confirmacion } from '../../commons/Mensajes';

import { getAlmacenesAction } from '../../redux/actions/almacen.action';
import {
  closeModal,
  patchInventarioInicialAction,
} from '../../redux/actions/inventario.action';

import { servicios, columnsMaterials } from '../../redux/helpers';

const InventarioInicial = () => {
  const dispatch = useDispatch();
  const { tipoIngresos } = useSelector((state) => state.detalle);
  const { almacenes } = useSelector((state) => state.almacen);
  const { processing } = useSelector((state) => state.inventario);
  const [inventario, setInventario] = useState({ al: '', ct: '' });
  const [showMaterial, setShowMaterial] = useState(false);
  const [materials, setMaterials] = useState([]);

  const handleChange = (item) => {
    const { name, value } = item;
    setInventario((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    dispatch(getAlmacenesAction({}));
  }, []);

  const handleAddMaterial = (item) => {
    let exits = materials.find((x) => x.mt === item.mt);
    if (exits) {
      setMaterials(
        materials.map((x) => (x.mt === item.mt ? { ...x, ct: item.ct + 1 } : x))
      );
    } else {
      setMaterials([
        ...materials,
        {
          mt: item.mt,
          ct: 1,
          tm: 0,
          cm: item.cm,
          pr: item.pc,
          nm: item.nm,
          du: item.du,
          st: true,
          edit: false,
        },
      ]);
    }
    setShowMaterial(false);
  };

  const handleDeleteMaterial = (item) => {
    setMaterials(materials.filter((x) => x.mt !== item.mt));
  };

  const handleCantidadMaterial = (mt, valor) => {
    setMaterials(
      materials.map((x) =>
        x.mt === mt
          ? {
              ...x,
              ct:
                valor === -1 && x.ct <= 1
                  ? 1
                  : parseInt(x.ct ? x.ct : 1) + valor,
            }
          : x
      )
    );
  };

  const handleHabilitarEdicion = (mt, valor) => {
    setMaterials(
      materials.map((x) => (x.mt === mt ? { ...x, edit: valor } : x))
    );
  };

  const handleEditarCantidad = (mt, valor) => {
    setMaterials(
      materials.map((x) =>
        x.mt === mt
          ? {
              ...x,
              ct: valor <= 0 ? 1 : valor,
            }
          : x
      )
    );
  };

  const columns = [
    {
      key: 'mt',
      align: 'center',
      content: (item) => (
        <div title="Eliminar" style={{ display: 'inline-block' }}>
          <DeleteIcon
            onClick={() =>
              confirmacion('¿Desea eliminar?', `${item.cm} de la lista`)
                .then(() => handleDeleteMaterial(item))
                .catch(() => {})
            }
            style={{ color: 'red', cursor: 'pointer' }}
          />
        </div>
      ),
    },
    {
      key: 'cm',
      label: 'Código',
    },
    {
      key: 'nm',
      label: 'Material / Producto',
    },
    {
      key: 'du',
      label: 'Unidad medida',
    },
    {
      key: 'ct',
      align: 'center',
      label: 'Cantidad',
      content: (item) => (
        <div className="op-centrar-componentes">
          <AddIcon
            style={{ color: 'green', cursor: 'pointer' }}
            onClick={() => handleCantidadMaterial(item.mt, 1)}
          />
          <div className={`cantidad${item.edit ? ' edit' : ''}`}>
            <span onClick={() => handleHabilitarEdicion(item.mt, !item.edit)}>
              {item.ct}
            </span>
            <input
              type="text"
              value={item.ct}
              onChange={(e) => handleEditarCantidad(item.mt, e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleHabilitarEdicion(item.mt, !item.edit);
                } else {
                  let re = new RegExp('[0-9]');
                  if (!re.test(e.key)) {
                    e.preventDefault();
                  }
                }
              }}
              onPaste={(e) => e.preventDefault()}
              onFocus={(e) => e.target.select()}
            />
            <div className="edicion">
              <EditIcon
                style={{ fontSize: '15px' }}
                onClick={() => handleHabilitarEdicion(item.mt, !item.edit)}
              />
            </div>
          </div>
          <RemoveIcon
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={() => handleCantidadMaterial(item.mt, -1)}
          />
        </div>
      ),
    },
  ];

  return (
    <Modal
      title={`Operaciones de inventario`}
      processing={processing}
      onActions={() => (
        <>
          <Boton
            className="op-dinamic"
            icon={() => <AddIcon />}
            tabIndex={22}
            style={{ '--button-color': '#3285a8' }}
            onClick={() => setShowMaterial(true)}
          >
            Agregar Material
          </Boton>
          <Boton
            className="op-grabar"
            disabled={processing}
            icon={() => <SaveIcon />}
            tabIndex={19}
            onClick={() =>
              confirmacion('¿Está seguro?', `Cargar inventario`)
                .then(() =>
                  dispatch(
                    patchInventarioInicialAction({
                      ...inventario,
                      dt: materials,
                    })
                  )
                )
                .catch(() => {})
            }
          >
            Guardar
          </Boton>
        </>
      )}
      onClose={() => dispatch(closeModal())}
    >
      <Combo
        name="al"
        label="Almacen"
        value={inventario.al}
        tabIndex={10}
        size={2}
        data={almacenes.map((x) => ({ value: x.al, label: x.na }))}
        onChange={handleChange}
      />
      <Combo
        name="ct"
        label="Tipo operaci&oacute;n"
        value={inventario.ct}
        tabIndex={11}
        size={2}
        data={tipoIngresos
          .filter((x) => x.vm === '16' || x.vm === '19')
          .map((i) => ({ value: i.dt, label: i.de }))}
        onChange={handleChange}
      />
      <div className="op-form-group op-col-1">
        <div className="op-table" style={{ padding: 0, flexBasis: '100%' }}>
          <Tabla
            columns={columns}
            data={materials}
            searcheable={false}
            pagination={false}
            hoverSelectedRow={false}
            emptyRowsMessage="Ning&uacute;n material seleccionado"
          />
        </div>
        {showMaterial && (
          <Busqueda
            serviceName={servicios.MATERIAL}
            title="B&uacute;squeda de materiales"
            columns={columnsMaterials((item) => handleAddMaterial(item))}
            emptyRowsMessage="No existen materiales"
            onClose={() => setShowMaterial(false)}
            onDoubleClick={(item) => handleAddMaterial(item)}
          />
        )}
      </div>
    </Modal>
  );
};

export default InventarioInicial;
