import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Texto from '../../commons/Texto';
import Combo from '../../commons/Combo';
import Modal from '../../commons/Modal';
import Boton from '../../commons/Boton';
import Tabla from '../../commons/Tabla';
import Adjunto from '../../commons/Adjunto';

import { confirmacion } from '../../commons/Mensajes';
import { formatoMonetario, redondeoDecimales } from '../../commons/Utilitarios';

import {
  patchRecepcionarOrdenCompraAction,
  patchAdjuntarArchivoOrdenAction,
  ORDENCOMPRA_NEW,
} from '../../redux/actions/ordencompra.action';
import {
  getAlmacenesAction,
  actualizarFiltros,
} from '../../redux/actions/almacen.action';
import {
  getMaterialesAction,
  actualizarFiltros as actualizarMaterialFiltros,
} from '../../redux/actions/material.action';

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import EditIcon from '@material-ui/icons/Edit';

import '../../css/ordencompra.css';

const OrdenCompraRecepcionar = ({
  selected,
  processing,
  processingAlmacen,
  almacenes,
  materiales,
  tipoDocumentos,
  total,
  processingList,
  errors,
  usuario,
  sucursales,
  onClose,
  patchRecepcionarOrdenCompraAction,
  getAlmacenesAction,
  actualizarFiltros,
  getMaterialesAction,
  actualizarMaterialFiltros,
  patchAdjuntarArchivoOrdenAction,
}) => {
  const [orden, setOrden] = useState(selected);
  const [detalle, setDetalle] = useState(selected.lst);
  const [tipo, setTipo] = useState('');
  const [openProductos, setOpenProductos] = useState(false);
  const [openTipoCambio, setOpenTipoCambio] = useState(false);

  useEffect(() => {
    setOrden((prev) => ({
      ...prev,
      ua: usuario,
      to: formatoMonetario(redondeoDecimales(prev.to)),
      su: formatoMonetario(redondeoDecimales(prev.su)),
      im: formatoMonetario(redondeoDecimales(prev.im)),
      bt: formatoMonetario(redondeoDecimales(prev.bt)),
      md: formatoMonetario(redondeoDecimales(prev.md)),
      td: formatoMonetario(redondeoDecimales(prev.td)),
      tt: formatoMonetario(redondeoDecimales(prev.tt)),
    }));
    actualizarFiltros({ iu: selected.sd, su: null });
    getAlmacenesAction({ page: 0, pageSize: 100 });
    setOpenTipoCambio(selected.tt > 1);
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setOrden((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const columns = [
    {
      key: 'cm',
      align: 'center',
      content: (item) => (
        <div
          title={`${item.st === true ? 'Quitar' : 'Agregar'}`}
          style={{ display: 'inline-block' }}
        >
          {item.st === true ? (
            <CheckIcon
              onClick={() =>
                confirmacion(
                  '¿Está seguro?',
                  `De quitar ${item.nm} de la orden de compra`
                )
                  .then(() => handleTacharMaterial(item.mt, false))
                  .catch(() => {})
              }
              style={{ color: '#1ab394', cursor: 'pointer' }}
            />
          ) : (
            <CloseIcon
              onClick={() =>
                confirmacion(
                  '¿Está seguro?',
                  `De volver agregar ${item.nm} a la orden de compra`
                )
                  .then(() => handleTacharMaterial(item.mt, true))
                  .catch(() => {})
              }
              style={{ color: 'red', cursor: 'pointer' }}
            />
          )}
        </div>
      ),
    },
    {
      key: 'nm',
      label: 'Insumo / Producto',
      content: (item) => (
        <span
          style={{
            color: `${item.st === true ? 'black' : 'red'}`,
            textDecoration: `${item.st === true ? 'none' : 'line-through'}`,
          }}
        >
          {item.nm}
        </span>
      ),
    },
    {
      key: 'du',
      label: 'Unidad medida',
      content: (item) => (
        <span
          style={{
            color: `${item.st === true ? 'black' : 'red'}`,
            textDecoration: `${item.st === true ? 'none' : 'line-through'}`,
          }}
        >
          {item.du}
        </span>
      ),
    },
    {
      key: 'tm',
      label: 'Tipo de moneda',
      align: 'center',
      content: (item) => (
        <div className="op-content-tipomoneda">
          <div
            className={`${item.tm === 0 ? 'on' : 'off'}`}
            onClick={() => handleCambiarMoneda(item.mt, 1)}
          >
            Soles
          </div>
          <div
            className={`${item.tm === 1 ? 'on' : 'off'}`}
            onClick={() => handleCambiarMoneda(item.mt, 0)}
          >
            Dolares
          </div>
        </div>
      ),
    },
    {
      key: 'pr',
      label: 'Precio unitario compra',
      align: 'right',
      content: (item) => (
        <div className="op-centrar-componentes">
          <div className={`cantidad${item.editPrecio ? ' edit' : ''}`}>
            <span
              style={{ color: `${item.st === true ? 'black' : 'red'}` }}
              onClick={(e) =>
                handleHabilitarEdicion(
                  item.mt,
                  !item.editPrecio,
                  'editPrecio',
                  e
                )
              }
            >
              {formatoMonetario(item.pr)}
            </span>
            <input
              type="text"
              value={item.pr}
              onChange={(e) => handleEditarValor(item.mt, e.target.value, 'pr')}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleHabilitarEdicion(
                    item.mt,
                    !item.editPrecio,
                    'editPrecio',
                    e
                  );
                } else {
                  let re = new RegExp('[0-9.]');
                  if (!re.test(e.key)) {
                    e.preventDefault();
                  }
                }
              }}
              onBlur={(e) => {
                formatoMonetario(e.target.value);
              }}
              onPaste={(e) => e.preventDefault()}
              onFocus={(e) => e.target.select()}
            />
            <div className="edicion">
              <EditIcon
                style={{ fontSize: '15px' }}
                onClick={(e) =>
                  handleHabilitarEdicion(
                    item.mt,
                    !item.editPrecio,
                    'editPrecio',
                    e
                  )
                }
              />
            </div>
          </div>
        </div>
      ),
    },
    {
      key: 'ct',
      label: 'Cantidad',
      align: 'right',
      content: (item) => (
        <div className="op-centrar-componentes">
          <AddIcon
            style={{ color: 'green', cursor: 'pointer' }}
            onClick={() => handleCantidadMaterial(item.mt, 1)}
          />
          <div className={`cantidad${item.edit ? ' edit' : ''}`}>
            <span
              style={{ color: `${item.st === true ? 'black' : 'red'}` }}
              onClick={(e) =>
                handleHabilitarEdicion(item.mt, !item.edit, 'edit', e)
              }
            >
              {item.ct}
            </span>
            <input
              type="text"
              value={item.ct}
              onChange={(e) => handleEditarValor(item.mt, e.target.value, 'ct')}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleHabilitarEdicion(item.mt, !item.edit, 'edit', e);
                } else {
                  let re = new RegExp('[0-9]');
                  if (!re.test(e.key)) {
                    e.preventDefault();
                  }
                }
              }}
              onPaste={(e) => e.preventDefault()}
              onFocus={(e) => e.target.select()}
            />
            <div className="edicion">
              <EditIcon
                style={{ fontSize: '15px' }}
                onClick={(e) =>
                  handleHabilitarEdicion(item.mt, !item.edit, 'edit', e)
                }
              />
            </div>
          </div>
          <RemoveIcon
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={() => handleCantidadMaterial(item.mt, -1)}
          />
        </div>
      ),
    },
    {
      key: 'su',
      label: 'SubTotales',
      align: 'right',
      content: (item) => (
        <span
          style={{
            color: `${item.st === true ? 'black' : 'red'}`,
            textDecoration: `${item.st === true ? 'none' : 'line-through'}`,
          }}
        >
          {`${formatoMonetario(
            redondeoDecimales(parseFloat(item.pr) * parseInt(item.ct))
          )}`}
        </span>
      ),
    },
  ];

  const columnsProductos = [
    {
      key: 'mt',
      label: 'Enviar',
      align: 'center',
      content: (item) => (
        <div title="Enviar">
          <CheckIcon
            onClick={() => {
              handleAgregarMaterial(item);
              setOpenProductos(false);
            }}
            style={{ color: 'green', cursor: 'pointer' }}
          />
        </div>
      ),
    },
    {
      key: 'cm',
      align: 'center',
      label: 'Código',
    },
    {
      key: 'nm',
      label: 'Producto / Insumo',
    },
    {
      key: 'du',
      label: 'Unidad de medida',
    },
  ];

  const handleAgregarMaterial = (item) => {
    let existe = detalle.find((x) => x.mt === tipo);
    if (existe === undefined) {
      item.ct = 1;
      item.pr = item.pc;
      item.tm = 0;
      item.edit = false;
      setDetalle([...detalle, item]);
    }
  };

  const handleTacharMaterial = (mt, valor) => {
    setDetalle(
      detalle.map((x) =>
        x.mt === mt
          ? {
              ...x,
              st: valor,
            }
          : x
      )
    );
  };

  const handleCantidadMaterial = (mt, valor) => {
    setDetalle(
      detalle.map((x) =>
        x.mt === mt
          ? {
              ...x,
              ct:
                valor === -1 && x.ct <= 1
                  ? 1
                  : parseInt(x.ct ? x.ct : 1) + valor,
            }
          : x
      )
    );
  };

  const handleHabilitarEdicion = (mt, valor, name, e) => {
    setDetalle(detalle.map((x) => (x.mt === mt ? { ...x, [name]: valor } : x)));
    let caja = e.target
      .closest('.op-centrar-componentes')
      .querySelector('input');
    let label = e.target
      .closest('.op-centrar-componentes')
      .querySelector('span');
    caja.style.width = `${label.offsetWidth}px`;
    caja.focus();
  };

  const handleEditarValor = (mt, valor, name) => {
    setDetalle(
      detalle.map((x) =>
        x.mt === mt
          ? {
              ...x,
              [name]: valor < 0 ? 0 : valor,
            }
          : x
      )
    );
  };

  const handleCambiarMoneda = (mt, valor) => {
    setDetalle(
      detalle.map((x) =>
        x.mt === mt
          ? {
              ...x,
              tm: valor,
            }
          : x
      )
    );
    setOpenTipoCambio(
      valor === 1 || detalle.filter((x) => x.mt !== mt).find((d) => d.tm === 1)
    );
  };

  useEffect(() => {
    let sucursal = sucursales.find((s) => s.ns === selected.ns);
    if (sucursal) {
      handleChange({ name: 'sd', value: sucursal.su });
    }
    getMaterialesAction({});

    return () => {
      actualizarMaterialFiltros({ pr: null, um: null, st: null, search: null });
    };
  }, []);

  useEffect(() => {
    let soles = detalle
      .filter((f) => f.tm === 0 && f.st)
      .reduce((a, b) => a + b.ct * b.pr, 0);
    let dolares = detalle
      .filter((f) => f.tm === 1 && f.st)
      .reduce((a, b) => a + b.ct * b.pr, 0);

    let subtotalSoles = soles / 1.18;
    let subtotalDolares = dolares / 1.18;

    handleChange({
      name: 'to',
      value: formatoMonetario(redondeoDecimales(soles)),
    });
    handleChange({
      name: 'su',
      value: formatoMonetario(redondeoDecimales(subtotalSoles)),
    });
    handleChange({
      name: 'im',
      value: formatoMonetario(redondeoDecimales(soles - subtotalSoles)),
    });
    handleChange({
      name: 'td',
      value: formatoMonetario(redondeoDecimales(dolares)),
    });
    handleChange({
      name: 'bt',
      value: formatoMonetario(redondeoDecimales(subtotalDolares)),
    });
    handleChange({
      name: 'md',
      value: formatoMonetario(redondeoDecimales(dolares - subtotalDolares)),
    });
  }, [detalle]);

  return (
    <>
      <Modal
        title={`Recepcionar orden N°: ${selected.co}`}
        processing={processing}
        loading="supplies"
        closeWithoutConfirmation
        onClose={() => {
          actualizarFiltros({ iu: null, su: null });
          onClose();
        }}
        onActions={() => (
          <>
            <Boton
              className="op-dinamic"
              icon={() => <AddIcon />}
              tabIndex={26}
              style={{ '--button-color': '#3285a8' }}
              onClick={() => setOpenProductos(true)}
            >
              Agregar producto
            </Boton>
            <Boton
              className="op-grabar"
              disabled={processing}
              icon={() => <SaveIcon />}
              tabIndex={27}
              onClick={() =>
                confirmacion(
                  '¿Está seguro?',
                  `De recepcionar la orden de compra ${selected.co}`
                )
                  .then(() => {
                    patchRecepcionarOrdenCompraAction(orden, detalle);
                  })
                  .catch(() => {})
              }
            >
              Guardar
            </Boton>
          </>
        )}
      >
        <Texto
          name="ua"
          value={orden.ua}
          label="Usuario"
          size={2}
          tabIndex={10}
          error={errors.ua}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="cp"
          value={orden.cp}
          label="Gu&iacute;a de remisi&oacute;n u otro documento"
          tabIndex={11}
          size={2}
          error={errors.cp}
          onChange={handleChange}
          info={
            'Puede agregar el número de guía de remisión u otro documento del proveedor'
          }
        />
        <Combo
          name="sd"
          value={orden.sd}
          label="Sucursal"
          size={2}
          data={sucursales.map((x) => ({ value: x.su, label: x.ns }))}
          tabIndex={13}
          disabled={processingAlmacen}
          error={errors.sd}
          onChange={(item) => {
            handleChange(item);
            actualizarFiltros({ su: item.value, iu: null });
            getAlmacenesAction({ page: 0, pageSize: 100 });
          }}
          info={'Tienda donde se enviará la mercaderia'}
        />
        <Combo
          name="al"
          label="Almacen"
          value={orden.al}
          tabIndex={13}
          size={2}
          data={almacenes.map((i) => ({ value: i.al, label: i.na }))}
          disabled={processingAlmacen}
          error={errors.al}
          onChange={handleChange}
          info={
            'Almacén donde se guardarán los productos recibidos, en caso no muestre ningún elemento debe agregar almacenes a la tienda asignada en el menu de almacenes'
          }
        />
        <Combo
          name="tu"
          label="Documento proveedor"
          value={orden.tu}
          tabIndex={14}
          size={3}
          data={tipoDocumentos.map((i) => ({ value: i.dt, label: i.de }))}
          error={errors.tu}
          onChange={handleChange}
        />
        <Texto
          name="ip"
          value={orden.ip}
          label="Serie"
          tabIndex={15}
          size={3}
          error={errors.ip}
          onChange={handleChange}
        />
        <Texto
          name="dp"
          value={orden.dp}
          label="N&uacute;mero"
          tabIndex={16}
          size={3}
          error={errors.dp}
          onChange={handleChange}
        />
        <Combo
          name="cc"
          value={orden.cc}
          label="Condici&oacute;n de pago"
          size={2}
          tabIndex={20}
          data={[
            { value: '1', label: 'Credito' },
            { value: '0', label: 'Contado' },
          ]}
          error={errors.cc}
          onChange={handleChange}
        />
        {orden.cc === '1' && (
          <Texto
            name="dd"
            value={orden.dd}
            label="D&iacute;as de pago"
            tabIndex={21}
            size={2}
            restrict={'int'}
            error={errors.dd}
            onChange={handleChange}
          />
        )}
        {openTipoCambio && (
          <Texto
            name="tt"
            value={orden.tt}
            label="Tipo de cambio del d&iacute;a"
            tabIndex={15}
            size={2}
            restrict={'money'}
            error={errors.tt}
            onChange={handleChange}
            onBlur={(e) => {
              handleChange({
                name: 'tt',
                value: formatoMonetario(redondeoDecimales(e.target.value)),
              });
            }}
          />
        )}
        <div className="op-form-group op-col-1">
          <Tabla
            columns={columns}
            data={detalle}
            pagination={false}
            searcheable={false}
            hoverSelectedRow={false}
            style={{ padding: '0px' }}
          />
          <div className="op-totales">
            <label>Importe Bruto soles</label>
            <span>{orden.su}</span>
            <label>I.G.V soles</label>
            <span>{orden.im}</span>
            <label>
              <strong>Importe Total soles</strong>
            </label>
            <span>
              <strong>{orden.to}</strong>
            </span>
            {orden.tt > 1 && (
              <>
                <div className="separador"></div>
                <label>Importe Bruto dolares</label>
                <span>{orden.bt}</span>
                <label>I.G.V dolares</label>
                <span>{orden.md}</span>
                <label>
                  <strong>Importe Total dolares</strong>
                </label>
                <span>
                  <strong>{orden.td}</strong>
                </span>
              </>
            )}
          </div>
        </div>
        <Adjunto
          stateParent={orden.st}
          nameParent={'orden'}
          processing={processing}
          data={
            orden.adj &&
            orden.adj.dtl &&
            orden.adj.dtl.map((a) => ({
              id: a.cr,
              name: a.na,
              extension: a.ea,
              state: a.st,
            }))
          }
          file={orden.adj && orden.adj.ar}
          onNew={(documento) =>
            patchAdjuntarArchivoOrdenAction(orden.oc, documento)
          }
        />
      </Modal>
      {openProductos && (
        <Modal
          title="B&uacute;squeda de producto / insumo"
          closePressEscape={false}
          onClose={() => setOpenProductos(false)}
        >
          <Tabla
            columns={columnsProductos}
            data={materiales}
            total={total}
            processing={processingList}
            onPagination={({ page, rows }) =>
              getMaterialesAction({ page, pageSize: rows })
            }
            onSearching={() => getMaterialesAction({})}
            onChangeSearching={(search) =>
              actualizarMaterialFiltros({ search })
            }
            onDoubleClick={(item) => {
              handleAgregarMaterial(item);
              setOpenProductos(false);
            }}
            style={{ flexBasis: '100%' }}
          />
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.ordenCompra.selected,
    processing: state.ordenCompra.processing,
    processingAlmacen: state.almacen.processing,
    usuario: state.app.currentUser.np,
    almacenes: state.almacen.almacenes,
    materiales: state.material.materiales,
    total: state.material.total,
    processingList: state.material.processing,
    errors: state.ordenCompra.errors,
    sucursales: state.sucursal.sucursales,
    tipoDocumentos: state.detalle.tipoDocumentos,
  };
};

export default connect(mapStateToProps, {
  patchRecepcionarOrdenCompraAction,
  getAlmacenesAction,
  actualizarFiltros,
  getMaterialesAction,
  actualizarMaterialFiltros,
  patchAdjuntarArchivoOrdenAction,
})(OrdenCompraRecepcionar);
