import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Texto from '../../commons/Texto';
import Combo from '../../commons/Combo';
import Modal from '../../commons/Modal';
import Boton from '../../commons/Boton';
import Tooltip from '../../commons/Tooltip';
import CheckBox from '../../commons/CheckBox';
import RadioButton from '../../commons/RadioButton';
import { confirmacion } from '../../commons/Mensajes';

import {
  closeModal,
  postPerfilesAction,
} from '../../redux/actions/perfiles.action';

import SaveIcon from '@material-ui/icons/Save';

const PerfilesEdit = ({ selected, processing, errors, estados }) => {
  const [perfil, setPerfil] = useState(selected);

  const dispatch = useDispatch();

  useEffect(() => {
    let estado = estados.find((s) => s.na === selected.de);
    if (estado) {
      handleChange({ name: 'ce', value: estado.ad });
    }

    setPerfil((prev) => ({
      ...prev,
      pfs: selected.rls.filter((i) => i.st === true).map((i) => i.pf),
    }));
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setPerfil((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleTypeProcess = (p) => {
    switch (p) {
      case 'corregir':
        return 3;
      case 'exito':
        return 2;
      case 'fin':
        return 1;
      default:
        return 0;
    }
  };

  return (
    <Modal
      title={`${perfil.pe ? 'Actualizar' : 'Nueva'} acción`}
      processing={processing}
      onActions={() => (
        <Boton
          className="op-grabar"
          disabled={processing}
          icon={() => <SaveIcon />}
          tabIndex={16}
          onClick={() =>
            confirmacion(
              '¿Desea guardar?',
              `${perfil.pe ? 'Una actualización de' : 'Nuevos'} perfiles`
            )
              .then(() => dispatch(postPerfilesAction(perfil)))
              .catch(() => {})
          }
        >
          Guardar
        </Boton>
      )}
      onClose={() => dispatch(closeModal())}
    >
      <Texto
        name="na"
        value={perfil.na}
        label="Nombre de acci&oacute;n"
        tabIndex={9}
        size={2}
        error={errors.na}
        onChange={handleChange}
        autoFocus
      />
      <Texto
        name="im"
        value={perfil.im}
        label="Imagen de la acci&oacute;n"
        tabIndex={10}
        size={2}
        error={errors.im}
        onChange={handleChange}
        info="Se debe seleccionar la imagen de material-community-icons"
      />
      <Combo
        name="ce"
        value={perfil.ce}
        label="Estado contrato"
        tabIndex={11}
        size={2}
        data={estados.map((i) => ({ value: i.ad, label: i.na }))}
        error={errors.ce}
        onChange={handleChange}
        info="Este acción se ejecutara cuando los perfiles seleccionados y el estado del contrato seleccionado este activo"
      />
      <Combo
        name="ce"
        values={perfil.pfs}
        label="Perfiles"
        tabIndex={12}
        size={2}
        data={selected.rls.map((i) => ({ value: i.pf, label: i.np }))}
        onChange={(item) => {
          handleChange({ name: 'pfs', value: item.map((r) => r.value) });
        }}
        multiple
        info="Los perfiles que tiene autorizaci&oacute;n para realizar esta acci&oacute;n"
      />
      <div className="op-form-group op-col-2 op-checkbox-group">
        <span>Estado del registro</span>
        <CheckBox
          name="st"
          tabIndex={14}
          checked={perfil.st}
          onChange={handleChange}
        />
      </div>
      <div className="op-form-group op-col-1 op-checkbox-group">
        <RadioButton
          name="ep"
          data={[
            {
              name: 'flujo',
              label: 'Continuar',
              checked: perfil.ep === 0,
            },
            {
              name: 'fin',
              label: 'Terminar',
              checked: perfil.ep === 1,
            },
            {
              name: 'exito',
              label: 'Fin existoso',
              checked: perfil.ep === 2,
            },
            {
              name: 'corregir',
              label: 'Corregir',
              checked: perfil.ep === 3,
            },
          ]}
          onChange={(item) => {
            handleChange({ name: 'ep', value: handleTypeProcess(item.value) });
          }}
        />
        <Tooltip>
          {
            'Para determinar el flujo del proceso. Continuar: El flujo del proceso continuará como esta planificado, Terminar: El flujo  del proceso se termina sin ser exitoso, Fin exitoso: El flujo del proceso finaliza de la forma planificada, Corregir: Editar dato del flujo'
          }
        </Tooltip>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.perfiles.selected,
    processing: state.perfiles.processing,
    errors: state.perfiles.errors,
    estados: state.adicional.estados,
    roles: state.usuario.roles,
  };
};

export default connect(mapStateToProps)(PerfilesEdit);
