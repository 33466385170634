import { useState } from 'react';
import { useSelector } from 'react-redux';
import { api } from '../redux/axios/api';
import { servicios } from '../redux/helpers';

const useAdjunto = () => {
  const { currentUser } = useSelector((state) => state.app);
  const [processAttached, setProcessAttached] = useState(false);
  const [attached, setAttached] = useState(null);

  const getAttached = (ar) => {
    setProcessAttached(true);
    setAttached(null);
    api
      .get(`${servicios.ARCHIVO}/${ar}/promocion/${currentUser.ie}?ls=ls`)
      .then((response) => setAttached(response))
      .catch((error) => console.log(error.data))
      .finally(() => setProcessAttached(false));
  };
  return {
    processAttached,
    attached,
    getAttached,
  };
};

export default useAdjunto;
