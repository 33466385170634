import { api } from '../redux/axios/api';
import { constantes, servicios } from '../redux/helpers';

const useAuth = () => {
  async function requestLogin(data) {
    try {
      const response = await api.post(`${servicios.LOGIN}/solicitar`, {
        ...data,
        ia: constantes.APLICACION,
      });
      const result = {
        success: true,
        status: 'requesting',
        result: response,
      };
      return result;
    } catch (error) {
      const result = {
        success: false,
        status: 'fail',
      };
      if (error.data) {
        let e = error.data;
        result.fieldsErrors = e.errors;
        result.messageError = e.detail;
        if (e.errorCode === 1020) {
          result.status = 'blocked';
        } else if (e.errorCode === 1030) {
          result.status = 'invalid';
        }
      } else {
        result.messageError = 'Error en el servicio';
      }
      return result;
    }
  }

  async function validateRequest(co) {
    try {
      let response = await api.patch(`${servicios.USUARIO}/consultar`, {
        co,
        ia: constantes.APLICACION,
      });
      let result = {
        success: true,
        status: 'requesting',
      };
      if (response.us) {
        result.status = 'success';
        localStorage.setItem('currentUser', JSON.stringify(response.us));
      }
      return result;
    } catch (error) {
      let result = {
        success: false,
        status: 'finished',
      };
      if (error.data) {
        let e = error.data;
        result.messageError = e.errors ? Object.values(e.errors)[0] : e.detail;
      } else {
        result.messageError = 'Error en el servicio consulta';
      }
      return result;
    }
  }

  return {
    requestLogin,
    validateRequest,
  };
};

export default useAuth;
