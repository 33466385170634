import React from 'react';
import Proptypes from 'prop-types';
import { useSelector } from 'react-redux';

import Formulario from './Formulario';
import Modal from './Modal';
import Paginacion from './Paginacion';
import TablaBody from './TablaBody';
import TablaSearch from './TablaSearch';

const Busqueda = ({
  serviceName,
  title,
  columns,
  onClose,
  params,
  onDoubleClick,
  emptyRowsMessage,
}) => {
  const { currentUser } = useSelector((state) => state.app);

  const initialState = {
    params: {
      ...params,
      em: currentUser.ie,
    },
    serviceName,
  };

  return (
    <Formulario initialState={initialState}>
      <Modal
        title={title}
        closePressEscape={false}
        closeWithoutConfirmation
        onClose={() => onClose()}
      >
        <div className="op-table" style={{ padding: '0', flexBasis: '100%' }}>
          <TablaSearch />
          <TablaBody
            columns={columns}
            emptyRowsMessage={emptyRowsMessage}
            onDoubleClick={onDoubleClick}
          />
          <Paginacion />
        </div>
      </Modal>
    </Formulario>
  );
};

Busqueda.propTypes = {
  serviceName: Proptypes.string.isRequired,
  title: Proptypes.string.isRequired,
  columns: Proptypes.arrayOf(
    Proptypes.shape({
      key: Proptypes.string.isRequired,
      label: Proptypes.string,
      align: Proptypes.oneOf(['center', 'left', 'right']),
      width: Proptypes.string,
      content: Proptypes.func,
    })
  ).isRequired,
  onClose: Proptypes.func.isRequired,
  params: Proptypes.object,
  onDoubleClick: Proptypes.func,
  emptyRowsMessage: Proptypes.string,
};

Busqueda.defaultProps = {
  emptyRowsMessage: 'No se encontraron registros',
};

export default Busqueda;
