import CheckIcon from '@material-ui/icons/Check';

import CheckBox from '../../commons/CheckBox';
import {
  formatoFecha,
  formatoMonetario,
  redondeoDecimales,
} from '../../commons/Utilitarios';

export const columnsRequests = (onClick) => {
  return [
    {
      key: 'so',
      align: 'center',
      content: (item) => (
        <div title="Enviar">
          <CheckIcon
            onClick={() => onClick(item)}
            style={{ color: 'green', cursor: 'pointer' }}
          />
        </div>
      ),
    },
    {
      key: 'cs',
      label: 'Código',
    },
    {
      key: 'ii',
      label: 'Cliente',
      content: (item) => <span>{item.ii.dn}</span>,
    },
    {
      key: 'ta',
      label: 'Técnico',
      content: (item) => <span>{item.ta[0].np}</span>,
    },
    {
      key: 'fv',
      align: 'right',
      label: 'Visita técnica',
      content: (item) => <span>{formatoFecha(item.fv)}</span>,
    },
    {
      key: 'ft',
      label: 'Atención',
      content: (item) => <span>{formatoFecha(item.ft)}</span>,
    },
  ];
};

export const columnsEquipments = (onClick) => {
  return [
    {
      key: 'eq',
      align: 'center',
      content: (item) => (
        <div title="Enviar">
          <CheckIcon
            onClick={() => onClick(item)}
            style={{ color: 'green', cursor: 'pointer' }}
          />
        </div>
      ),
    },
    {
      key: 'ne',
      label: 'Nombre',
    },
    {
      key: 'mr',
      label: 'Marca',
    },
    {
      key: 'mo',
      label: 'Modelo',
    },
    {
      key: 'pn',
      label: 'PNC',
    },
  ];
};

export const columnsHandBooks = (onClick) => {
  return [
    {
      key: 'mn',
      align: 'center',
      content: (item) => (
        <CheckIcon
          onClick={() => onClick(item)}
          style={{ color: 'green', cursor: 'pointer' }}
        />
      ),
    },
    {
      key: 'cm',
      label: 'Código',
    },
    {
      key: 'nm',
      label: 'Nombre',
    },
    {
      key: 'st',
      label: 'Estado de registro',
      align: 'center',
      content: (item) => <CheckBox checked={item.st} disabled />,
    },
  ];
};

export const columnsProcesses = (onClick) => {
  return [
    {
      key: 'te',
      align: 'center',
      content: (item) => (
        <CheckIcon
          onClick={() => onClick(item)}
          style={{ color: 'green', cursor: 'pointer' }}
        />
      ),
    },
    {
      key: 'cp',
      label: 'Código',
    },
    {
      key: 'np',
      label: 'Nombre',
    },
    {
      key: 'st',
      label: 'Estado de registro',
      align: 'center',
      content: (item) => <CheckBox checked={item.st} disabled />,
    },
  ];
};

export const columnsTestings = (onClick) => {
  return [
    {
      key: 'te',
      align: 'center',
      content: (item) => (
        <CheckIcon
          onClick={() => onClick(item)}
          style={{ color: 'green', cursor: 'pointer' }}
        />
      ),
    },
    {
      key: 'ct',
      label: 'Código',
    },
    {
      key: 'nt',
      label: 'Nombre',
    },
    {
      key: 'st',
      label: 'Estado de registro',
      align: 'center',
      content: (item) => <CheckBox checked={item.st} disabled />,
    },
  ];
};

export const columnsInventories = (onClick) => {
  return [
    {
      key: 'su',
      align: 'center',
      content: (item) => (
        <CheckIcon
          onClick={() => onClick(item)}
          style={{ color: 'green', cursor: 'pointer' }}
        />
      ),
    },
    {
      key: 'ns',
      label: 'Sucursal',
    },
    {
      key: 'cm',
      align: 'center',
      label: 'Código',
    },
    {
      key: 'nm',
      label: 'Producto / Insumo',
    },
    {
      key: 'um',
      label: 'Unidad de medida',
    },
    {
      key: 'sd',
      align: 'center',
      label: 'Stock disponible',
    },
    {
      key: 'pv',
      align: 'right',
      label: 'Precio venta',
      content: (item) =>
        `${item.tm === 0 ? 'PEN' : 'USD'} ${formatoMonetario(item.pv)}`,
    },
    {
      key: 'dt',
      label: 'Categoría',
    },
  ];
};

export const columnsMaterials = (onClick) => {
  return [
    {
      key: 'mt',
      align: 'center',
      content: (item) => (
        <CheckIcon
          onClick={() => onClick(item)}
          style={{ color: 'green', cursor: 'pointer' }}
        />
      ),
    },
    {
      key: 'cm',
      label: 'Código',
    },
    {
      key: 'nm',
      label: 'Nombre',
    },
    {
      key: 'du',
      label: 'Unidad medida',
    },
    {
      key: 'pr',
      label: 'Producto / Insumo',
      content: (item) => `${item.pr ? 'Producto' : 'Insumo'}`,
    },
    {
      key: 'st',
      label: 'Estado de registro',
      align: 'center',
      content: (item) => <CheckBox checked={item.st} disabled />,
    },
  ];
};

export const columnsClients = (onClick) => {
  return [
    {
      key: 'cl',
      align: 'center',
      content: (item) => (
        <CheckIcon
          onClick={() => onClick(item)}
          style={{ color: 'green', cursor: 'pointer' }}
        />
      ),
    },
    {
      key: 'dn',
      label: 'Nombres completos',
    },
    {
      key: 'di',
      label: 'Dirección',
    },
    {
      key: 'dt',
      label: 'Tipo documento',
    },
    {
      key: 'nd',
      label: 'Número de documento',
    },
    {
      key: 'st',
      label: 'Estado de registro',
      align: 'center',
      content: (item) => <CheckBox checked={item.st} disabled />,
    },
  ];
};

export const columnsServices = (onClick) => {
  return [
    {
      key: 'sv',
      align: 'center',
      content: (item) => (
        <CheckIcon
          onClick={() => onClick(item)}
          style={{ color: 'green', cursor: 'pointer' }}
        />
      ),
    },
    {
      key: 'cs',
      label: 'Código',
    },
    {
      key: 'ns',
      label: 'Nombre',
    },
    {
      key: 'ps',
      label: 'Precio',
      align: 'right',
      content: (item) => formatoMonetario(redondeoDecimales(item.ps)),
    },
    {
      key: 'st',
      label: 'Estado de registro',
      align: 'center',
      content: (item) => <CheckBox checked={item.st} disabled />,
    },
  ];
};
