import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Boton from '../../commons/Boton';
import { confirmacion } from '../../commons/Mensajes';

import {
  closeModal,
  putCorrelativoAction,
} from '../../redux/actions/correlativo.action';

import SaveIcon from '@material-ui/icons/Save';

const CorrelativoItem = () => {
  const { selected, processing, errors } = useSelector(
    (state) => state.correlativo
  );
  const [correlativo, setCorrelativo] = useState(selected);

  const dispatch = useDispatch();

  useEffect(() => {
    handleChange({ name: 'sn', value: correlativo.se });
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setCorrelativo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Modal
      title={`Actualizar correlativo`}
      processing={processing}
      size="small"
      onActions={() => (
        <Boton
          className="op-grabar"
          disabled={processing}
          icon={() => <SaveIcon />}
          tabIndex={21}
          onClick={() =>
            confirmacion('¿Desea actualizar?', `Los datos del correlativo`)
              .then(() => dispatch(putCorrelativoAction(correlativo)))
              .catch(() => {})
          }
        >
          Guardar
        </Boton>
      )}
      onClose={() => dispatch(closeModal())}
    >
      <Texto
        name="td"
        value={correlativo.td}
        label="Tipo de documento"
        tabIndex={10}
        error={errors.td}
        onChange={() => {}}
        disabled
      />
      <Texto
        name="sn"
        value={correlativo.sn}
        label="Serie"
        tabIndex={12}
        restrict="int"
        error={errors.sn}
        onChange={handleChange}
      />
      <Texto
        name="nc"
        value={correlativo.nc}
        label="Correlativo"
        tabIndex={13}
        restrict="int"
        error={errors.nc}
        onChange={handleChange}
        info={'Valor de inicio'}
      />
    </Modal>
  );
};

export default CorrelativoItem;
