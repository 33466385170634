import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Combo from '../../commons/Combo';
import Boton from '../../commons/Boton';
import CheckBox from '../../commons/CheckBox';
import { confirmacion, mensajeMini } from '../../commons/Mensajes';

import Ubigueo from '../../components/ubigueo/Ubigueo';

import {
  closeModal,
  pathcSucursalAction,
} from '../../redux/actions/sucursal.action';

import SaveIcon from '@material-ui/icons/Save';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MapIcon from '@material-ui/icons/Map';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import CheckIcon from '@material-ui/icons/Check';

const SucursalItem = ({
  selected,
  processing,
  errors,
  closeModal,
  pathcSucursalAction,
}) => {
  const [sucursal, setSucursal] = useState(selected);
  const [openUbigueo, setOpenUbigueo] = useState(false);
  const [openHorarios, setOpenHorarios] = useState(false);
  const [horarios, setHorarios] = useState([
    {
      di: 1,
      op: false,
      ti: '00:00',
      tf: '00:00',
    },
    {
      di: 2,
      op: false,
      ti: '00:00',
      tf: '00:00',
    },
    {
      di: 3,
      op: false,
      ti: '00:00',
      tf: '00:00',
    },
    {
      di: 4,
      op: false,
      ti: '00:00',
      tf: '00:00',
    },
    {
      di: 5,
      op: false,
      ti: '00:00',
      tf: '00:00',
    },
    {
      di: 6,
      op: false,
      ti: '00:00',
      tf: '00:00',
    },
    {
      di: 0,
      op: false,
      ti: '00:00',
      tf: '00:00',
    },
  ]);

  useEffect(() => {
    if (selected.hor) {
      setHorarios(selected.hor);
    }
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setSucursal((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeHorarios = (id, name, value) => {
    setHorarios(
      horarios.map((h) => (h.di === id ? { ...h, [name]: value } : h))
    );
  };

  const getNameDay = (day) => {
    let names = [
      'Domingo',
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
    ];
    return names[day];
  };

  return (
    <>
      <Modal
        title={`Actualizar sucursal`}
        processing={processing}
        onActions={() => (
          <>
            <Boton
              className="op-dinamic"
              icon={() => <AccessAlarmIcon />}
              tabIndex={21}
              style={{
                '--button-color': '#3285a8',
                '--button-icon-color': '#1ab394',
              }}
              onClick={() => setOpenHorarios(true)}
            >
              Horarios de atenci&oacute;n
            </Boton>
            <Boton
              className="op-dinamic"
              icon={() => <MapIcon />}
              tabIndex={21}
              style={{
                '--button-color': '#3285a8',
                '--button-icon-color': '#1ab394',
              }}
              onClick={() =>
                Swal.fire({
                  title: 'Ubicación en el mapa',
                  icon: 'info',
                  html:
                    '<ul>' +
                    '<li>Ubicar sucursal en el mapa</li>' +
                    '<li>Click derecho en su ubicación</li>' +
                    '<li>Copiar latitud y longitud: (ejemplo: -12.045927822376395, -77.03056849391912)</li>' +
                    '</ul>' +
                    '<a href="https://www.google.com.pe/maps/@-12.0462426,-77.0302359,17z?hl=es-419" target="_blank" rel="noopener noreferrer">ir al mapa</a> ',
                  input: 'text',
                  inputPlaceholder: 'Pegar aquí...',
                  inputAttributes: {
                    'aria-label': 'Pegar aquí',
                    style: { resize: 'none' },
                  },
                  showCancelButton: true,
                  confirmButtonColor: '#1ab394',
                  confirmButtonText: 'Confirmar',
                  cancelButtonColor: 'red',
                  cancelButtonText: 'Cancelar',
                  reverseButtons: true,
                }).then((result) => {
                  if (result.value) {
                    let valores = result.value.split(',');
                    if (valores.length != 2) {
                      mensajeMini('Datos inválidos');
                    }
                    let latitud = parseFloat(valores[0]);
                    let longitud = parseFloat(valores[1]);
                    if (latitud) {
                      pathcSucursalAction(sucursal, latitud, longitud);
                    } else {
                      mensajeMini('Datos inválidos');
                    }
                  } else {
                    mensajeMini('Debe copiar los datos de ubicación');
                  }
                })
              }
            >
              Abrir mapa
            </Boton>
            <Boton
              className="op-grabar"
              disabled={processing}
              icon={() => <SaveIcon />}
              tabIndex={21}
              onClick={() =>
                confirmacion('¿Está seguro?', `Actualizar sucursal`)
                  .then(() =>
                    pathcSucursalAction(sucursal, sucursal.la, sucursal.lo)
                  )
                  .catch(() => {})
              }
            >
              Guardar
            </Boton>
          </>
        )}
        onClose={() => closeModal()}
      >
        <Texto
          name="ns"
          value={sucursal.ns}
          label="Nombre"
          tabIndex={10}
          size={2}
          error={errors.ns}
          onChange={handleChange}
          autoFocus
        />
        <Combo
          name="sp"
          label="¿Es la principal?"
          value={sucursal.sp}
          tabIndex={11}
          size={2}
          data={[
            { value: 'S', label: 'Si' },
            { value: 'N', label: 'No' },
          ]}
          onChange={handleChange}
          error={errors.sp}
        />
        <Texto
          name="du"
          value={sucursal.du}
          label="Ubigueo"
          size={2}
          tabIndex={15}
          error={errors.du}
          onChange={() => {}}
          icon={() => <LocationOnIcon onClick={() => setOpenUbigueo(true)} />}
        />
        <Texto
          name="dc"
          value={sucursal.dc}
          label="Direcci&oacute;n"
          tabIndex={15}
          size={2}
          error={errors.dc}
          onChange={handleChange}
        />
        <Texto
          name="nt"
          value={sucursal.nt}
          label="N&uacute;mero de tel&eacute;fono"
          tabIndex={16}
          size={2}
          restrict={'int'}
          error={errors.nt}
          onChange={handleChange}
        />
        <div className="op-form-group op-col-2 op-checkbox-group">
          <span>Delivery incluido</span>
          <CheckBox
            name="dv"
            tabIndex={17}
            checked={sucursal.dv}
            onChange={handleChange}
          />
        </div>
        <div className="op-form-group op-col-2 op-checkbox-group">
          <span>Sucursal disponible para app</span>
          <CheckBox
            name="da"
            tabIndex={18}
            checked={sucursal.da}
            onChange={handleChange}
          />
        </div>
        {sucursal.la != 0 && (
          <div className="op-form-group op-col-2">
            <a
              href={`https://maps.google.com/?q=${sucursal.la},${sucursal.lo}&z=25`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Ubicaci&oacute;n en el mapa
            </a>
            <MapIcon />
          </div>
        )}
      </Modal>
      {openUbigueo && (
        <Ubigueo
          onClose={() => setOpenUbigueo(false)}
          onSend={(ubigueo) => {
            handleChange({ name: 'ub', value: ubigueo.ub });
            handleChange({ name: 'du', value: ubigueo.di });
          }}
        />
      )}
      {openHorarios && (
        <Modal
          title="Horarios de atenci&oacute;n"
          size="medium"
          onActions={() => (
            <Boton
              className="op-grabar"
              disabled={processing}
              icon={() => <CheckIcon />}
              onClick={() => {
                handleChange({ name: 'hor', value: horarios });
                setOpenHorarios(false);
              }}
            >
              Enviar
            </Boton>
          )}
          onClose={() => setOpenHorarios(false)}
        >
          <div className="op-form-group">
            <span>
              <strong>Agregar los horarios en formato de 24 horas</strong>
            </span>
          </div>
          {horarios.map((h, i) => (
            <React.Fragment key={h.di}>
              <div className="op-form-group op-col-2 op-checkbox-group">
                <span>{getNameDay(h.di)}</span>
                <CheckBox
                  name={h.di}
                  tabIndex={30 + i}
                  checked={h.op}
                  onChange={({ value }) =>
                    handleChangeHorarios(h.di, 'op', value)
                  }
                />
              </div>
              <Texto
                name={`${h.di}_ini`}
                value={h.ti}
                label="Apertura"
                tabIndex={31}
                type={'time'}
                size={4}
                onChange={({ value }) =>
                  handleChangeHorarios(h.di, 'ti', value)
                }
              />
              <Texto
                name={`${h.di}_fin`}
                value={h.tf}
                label="Cierre"
                tabIndex={32}
                type={'time'}
                size={4}
                onChange={({ value }) =>
                  handleChangeHorarios(h.di, 'tf', value)
                }
              />
            </React.Fragment>
          ))}
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.sucursal.selected,
    processing: state.sucursal.processing,
    errors: state.sucursal.errors,
  };
};

export default connect(mapStateToProps, {
  closeModal,
  pathcSucursalAction,
})(SucursalItem);
