import React, { useState } from 'react';
import PropTypes from 'prop-types';

import '../css/commons/bloque.css';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const Bloque = ({
  titulo,
  className,
  onActions,
  onBackButton,
  hideArrowButton,
  children,
  ...rest
}) => {
  const [open, setOpen] = useState(true);
  const actionButtons = () => (
    <>
      {onActions ? (
        <div className="op-bloque-actions">
          {onActions()}
          {hideArrowButton === undefined && (
            <label className="icon" onClick={() => setOpen(!open)}>
              <span></span>
              <span></span>
            </label>
          )}
        </div>
      ) : (
        <label className="icon" onClick={() => setOpen(!open)}>
          <span></span>
          <span></span>
        </label>
      )}
    </>
  );

  return (
    <div
      className={`op-bloque${open ? '' : ' op-close'} ${
        className ? className : ''
      }`}
      {...rest}
    >
      <div className="op-bloque-head">
        {onBackButton ? (
          <div className="op-bloque-actions">
            <ArrowBackIcon /> <span>{titulo}</span>
          </div>
        ) : (
          <span onClick={() => setOpen(!open)}>{titulo}</span>
        )}
        {actionButtons()}
      </div>
      <div className="op-bloque-content">{children}</div>
    </div>
  );
};

Bloque.propTypes = {
  titulo: PropTypes.string,
  className: PropTypes.string,
  onActions: PropTypes.func,
  onBackButton: PropTypes.func,
  hideArrowButton: PropTypes.bool,
};

Bloque.defaultProps = {
  titulo: 'Búsqueda',
};

export default Bloque;
