import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getInventariosAction } from '../../redux/actions/inventario.action';

import { getDetallesPorCabeceraAction } from '../../redux/actions/detalle.action';

import { getSucursalesAction } from '../../redux/actions/sucursal.action';

import InventarioList from './InventarioList';
import InventarioFilter from './InventarioFilter';
import InventarioItem from './InventarioItem';
import InventarioConvertir from './InventarioConvertir';
import InventarioMovimiento from './InventarioMovimiento';
import InventarioInicial from './InventarioInicial';
import GuiaNew from '../guia/GuiaNew';

import '../../css/inventario.css';
import '../../css/ordencompra.css';

const Inventario = ({
  openModal,
  openModalConvertir,
  openModalMovimiento,
  openModalTraslado,
  openModalInicial,
  getSucursalesAction,
  getInventariosAction,
  getDetallesPorCabeceraAction,
}) => {
  useEffect(() => {
    getSucursalesAction({});
    getInventariosAction({});
    getDetallesPorCabeceraAction({
      ca: 'CATEGORIAPRODUCTO',
    });
    getDetallesPorCabeceraAction({
      ca: 'TIPOINGRESO',
      page: 0,
      pageSize: 30,
    });
    getDetallesPorCabeceraAction({
      ca: 'TIPOSALIDA',
      page: 0,
      pageSize: 30,
    });
    getDetallesPorCabeceraAction({
      ca: 'TIPODOCUMENTOIDENTIDAD',
    });
    getDetallesPorCabeceraAction({
      ca: 'MOTIVOTRASLADO',
      page: 0,
      pageSize: 30,
    });
  }, []);
  return (
    <div className="op-component op-view-inventario view-ordencompra">
      <InventarioList />
      <InventarioFilter />
      {openModal && <InventarioItem />}
      {openModalConvertir && <InventarioConvertir />}
      {openModalMovimiento && <InventarioMovimiento />}
      {openModalInicial && <InventarioInicial />}
      {openModalTraslado && <GuiaNew />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openModal: state.inventario.openModal,
    openModalConvertir: state.inventario.openModalConvertir,
    openModalMovimiento: state.operacion.openModal,
    openModalTraslado: state.inventario.openModalTraslado,
    openModalInicial: state.inventario.openModalInicial,
  };
};

export default connect(mapStateToProps, {
  getSucursalesAction,
  getInventariosAction,
  getDetallesPorCabeceraAction,
})(Inventario);
