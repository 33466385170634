import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Boton from '../../commons/Boton';
import Combo from '../../commons/Combo';
import RadioButton from '../../commons/RadioButton';
import { confirmacion } from '../../commons/Mensajes';
import {
  formatoFecha,
  formatoMonetario,
  redondeoDecimales,
} from '../../commons/Utilitarios';

import ProveedorList from '../proveedor/ProveedorList';

import {
  closeModal,
  postOperacionCajaAction,
  putOperacionCajaAction,
} from '../../redux/actions/caja.action';

import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';

const CajaOperacion = ({
  operation,
  processing,
  errors,
  closeModal,
  postOperacionCajaAction,
  putOperacionCajaAction,
  tipoCajas,
  tipoDocumentos,
}) => {
  const [operacion, setOperacion] = useState(operation);
  const [moneda, setMoneda] = useState(true);
  const [arrow, setArrow] = useState(true);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let caja = tipoCajas.find((x) => x.de === operacion.dt);
    if (caja) {
      handleChange({ name: 'tc', value: caja.dt });
    }
    let compra = tipoDocumentos.find((x) => x.de === operacion.dd);
    if (compra) {
      handleChange({ name: 'td', value: compra.dt });
    }
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setOperacion((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <Modal
        title={`${operacion.fo ? 'Rectificar' : 'Registrar'} operación ${
          operacion.fo ? formatoFecha(operacion.fo) : ''
        }`}
        processing={processing}
        loading="payment"
        onActions={() => (
          <>
            <Boton
              className="op-dinamic"
              disabled={processing}
              icon={() => <SearchIcon />}
              tabIndex={20}
              style={{ '--button-color': '#3285a8' }}
              onClick={() => setOpen(true)}
            >
              Buscar Proveedor
            </Boton>
            <>
              {operacion.fo ? (
                <Boton
                  className="op-grabar"
                  disabled={processing}
                  icon={() => <SaveIcon />}
                  tabIndex={21}
                  onClick={() =>
                    confirmacion('¿Está seguro?', `Rectificar la operación`)
                      .then(() => {
                        putOperacionCajaAction(operacion);
                      })
                      .catch(() => {})
                  }
                >
                  Rectificar
                </Boton>
              ) : (
                <Boton
                  className="op-grabar"
                  disabled={processing}
                  icon={() => <SaveIcon />}
                  tabIndex={21}
                  onClick={() =>
                    confirmacion('¿Está seguro?', `Registrar la operación`)
                      .then(() => {
                        postOperacionCajaAction(operacion);
                      })
                      .catch(() => {})
                  }
                >
                  Guardar
                </Boton>
              )}
            </>
          </>
        )}
        onClose={() => closeModal()}
      >
        <Texto
          name="rp"
          value={operacion.rp}
          label="Raz&oacute;n social proveedor (opcional)"
          size={2}
          tabIndex={10}
          error={errors.rp}
          onChange={handleChange}
        />
        <Texto
          name="up"
          value={operacion.up}
          label="Ruc proveedor (opcional)"
          size={2}
          tabIndex={11}
          error={errors.up}
          onChange={handleChange}
        />
        <Combo
          name="td"
          label={`Tipo de documento (opcional)`}
          value={operacion.td}
          tabIndex={12}
          size={3}
          data={tipoDocumentos.map((i) => ({ value: i.dt, label: i.de }))}
          error={errors.td}
          onChange={handleChange}
        />
        <Texto
          name="ns"
          value={operacion.ns}
          label="N&uacute;mero serie (opcional)"
          size={3}
          tabIndex={13}
          error={errors.ns}
          onChange={handleChange}
        />
        <Texto
          name="nd"
          value={operacion.nd}
          label="Numeraci&oacute;n documento (opcional)"
          size={3}
          tabIndex={14}
          error={errors.nd}
          onChange={handleChange}
        />
        <Texto
          name="co"
          value={operacion.co}
          label="Concepto"
          size={2}
          tabIndex={15}
          error={errors.co}
          onChange={handleChange}
          autoFocus
        />
        <Combo
          name="tc"
          label={`Tipo de caja`}
          value={operacion.tc}
          tabIndex={16}
          size={2}
          data={tipoCajas.map((i) => ({ value: i.dt, label: i.de }))}
          error={errors.tc}
          onChange={handleChange}
        />
        <Texto
          name="mo"
          value={operacion.mo}
          label={`Monto`}
          size={2}
          tabIndex={17}
          error={errors.mo}
          restrict={'money'}
          onChange={handleChange}
          onBlur={(e) => {
            handleChange({
              name: 'mo',
              value: formatoMonetario(redondeoDecimales(e.target.value)),
            });
          }}
        />
        <div className="op-form-group op-col-2 op-checkbox-group">
          <RadioButton
            name="tm"
            data={[
              {
                name: 'so',
                label: 'Soles',
                checked: moneda,
              },
              {
                name: 'do',
                label: 'Dolares',
                checked: !moneda,
              },
            ]}
            onChange={(item) => {
              setMoneda(item.value === 'so');
              handleChange({
                name: 'tm',
                value: item.value === 'so' ? '0' : '1',
              });
            }}
          />
        </div>
        <Texto
          name="ic"
          value={operacion.ic}
          label={`IGV`}
          size={2}
          tabIndex={19}
          error={errors.ic}
          restrict={'money'}
          onChange={handleChange}
          onBlur={(e) => {
            handleChange({
              name: 'ic',
              value: formatoMonetario(redondeoDecimales(e.target.value)),
            });
          }}
        />
        <Texto
          name="pc"
          value={operacion.pc}
          label={`Percepción`}
          size={2}
          tabIndex={20}
          error={errors.pc}
          restrict={'money'}
          onChange={handleChange}
          onBlur={(e) => {
            handleChange({
              name: 'pc',
              value: formatoMonetario(redondeoDecimales(e.target.value)),
            });
          }}
        />
        <Texto
          name="ia"
          value={operacion.ia}
          label="Informaci&oacute;n (opcional)"
          size={2}
          tabIndex={21}
          error={errors.ia}
          onChange={handleChange}
        />
        <div className="op-form-group op-col-2 op-checkbox-group">
          <RadioButton
            name="is"
            data={[
              {
                name: 'in',
                label: 'Ingreso',
                checked: arrow,
              },
              {
                name: 'out',
                label: 'Salida',
                checked: !arrow,
              },
            ]}
            onChange={(item) => {
              setArrow(item.value === 'in');
              handleChange({
                name: 'is',
                value: item.value === 'in' ? '1' : '0',
              });
            }}
          />
        </div>
      </Modal>
      {open && (
        <ProveedorList
          searchable
          onClose={() => setOpen(false)}
          onSend={(item) => {
            handleChange({ name: 'rp', value: item.rz });
            handleChange({ name: 'up', value: item.nr });
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    processing: state.caja.processing,
    errors: state.caja.errors,
    tipoCajas: state.detalle.tipoCajas,
    operation: state.caja.operacion,
    tipoDocumentos: state.detalle.tipoDocumentos,
  };
};

export default connect(mapStateToProps, {
  closeModal,
  postOperacionCajaAction,
  putOperacionCajaAction,
})(CajaOperacion);
