import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';

import BookIcon from '../../assets/icons/book.svg';
import TestIcon from '../../assets/icons/test-tube.svg';

import Adjunto from '../../commons/Adjunto';
import Boton from '../../commons/Boton';
import Busqueda from '../../commons/Busqueda';
import CheckBox from '../../commons/CheckBox';
import Icon from '../../commons/Icon';
import Modal from '../../commons/Modal';
import Texto from '../../commons/Texto';
import { useFormularioContext } from '../../commons/Formulario';

import { confirmacion } from '../../commons/Mensajes';

import { equipoModel } from '../../redux/models/equipo.model';
import {
  servicios,
  columnsHandBooks,
  columnsTestings,
} from '../../redux/helpers';

const EquipoPreconfig = () => {
  const {
    selected,
    openModalPre,
    processing,
    errors,
    callTransaction,
    cancelTransaction,
  } = useFormularioContext();
  const [equipo, setEquipo] = useState(selected);
  const [showTestings, setShowTestings] = useState(false);
  const [showHandbooks, setShowHandbooks] = useState(false);
  const [testings, setTestings] = useState([]);
  const [handbooks, setHandbooks] = useState([]);
  const { currentUser } = useSelector((state) => state.app);

  useEffect(() => {
    setEquipo(selected);
    handleChange({ name: 'scl', value: selected.cl?.cl });
    setTestings([]);
    setHandbooks([]);
    if (selected.ttr) {
      setTestings(selected.ttr);
    }
    if (selected.mmr) {
      setHandbooks(selected.mmr);
    }
  }, [selected, openModalPre]);

  const handleChange = (item) => {
    const { name, value } = item;
    setEquipo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  if (!openModalPre) {
    return null;
  }

  const handleAddTestings = (item) => {
    let exist = testings.find((x) => x.ct === item.ct);
    if (exist === undefined) {
      setTestings([...testings, item]);
    }
    setShowTestings(false);
  };

  const handleAddHandBook = (item) => {
    let exist = handbooks.find((x) => x.cm === item.cm);
    if (exist === undefined) {
      setHandbooks([...handbooks, item]);
    }
    setShowHandbooks(false);
  };

  const handleDeleteTesting = (ct) => {
    setTestings(testings.filter((x) => x.ct !== ct));
  };

  const handleDeleteHandBook = (cm) => {
    setHandbooks(handbooks.filter((x) => x.cm !== cm));
  };

  const handleSave = () => {
    let item = {
      ...equipo,
      ie: currentUser.ie,
      tt: testings.map((x) => x.te),
      mm: handbooks.map((x) => x.mn),
    };
    callTransaction({
      method: 'post',
      url: servicios.EQUIPO,
      data: item,
      model: equipoModel,
    });
  };

  return (
    <>
      <Modal
        title={`Agregar preconfiguraciones a ${equipo.ne}`}
        processing={processing}
        onActions={() => (
          <>
            <Boton
              className="op-grabar"
              disabled={processing}
              icon={() => <SaveIcon />}
              tabIndex={19}
              onClick={() =>
                confirmacion(
                  '¿Está seguro?',
                  `De guardar las preconfiguraciones del equipo ${equipo.ne}`
                )
                  .then(() => handleSave())
                  .catch(() => {})
              }
            >
              Guardar preconfiguraciones
            </Boton>
            <Boton
              className="op-dinamic"
              disabled={processing}
              icon={() => <AddIcon />}
              tabIndex={18}
              style={{ '--button-color': '#3285a8' }}
              onClick={() => setShowTestings(true)}
            >
              Agregar Testeo
            </Boton>
            <Boton
              className="op-dinamic"
              disabled={processing}
              icon={() => <AddIcon />}
              tabIndex={18}
              style={{ '--button-color': '#3285a8' }}
              onClick={() => setShowHandbooks(true)}
            >
              Agregar manual
            </Boton>
          </>
        )}
        onClose={() => cancelTransaction(equipoModel)}
      >
        <div className="op-content-preconfig">
          <div className="op-preconfig">
            <h2>Testeos</h2>
            {testings?.map((m) => (
              <div key={m.te} className="op-preconfig-item">
                <Icon svg={TestIcon} size={30} transparent />
                <span>{m.ct}</span>
                <span>{m.nt}</span>
                <div className="close">
                  <CancelIcon
                    color="secondary"
                    onClick={() => handleDeleteTesting(m.ct)}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="op-preconfig">
            <h2>Manuales</h2>
            {handbooks?.map((m) => (
              <div key={m.mn} className="op-preconfig-item">
                <Icon svg={BookIcon} size={30} transparent />
                <span>{m.cm}</span>
                <span>{m.nm}</span>
                <div className="close">
                  <CancelIcon
                    color="secondary"
                    onClick={() => handleDeleteHandBook(m.cm)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
      {showTestings && (
        <Busqueda
          serviceName={servicios.TESTEO}
          title="B&uacute;squeda de testeos"
          columns={columnsTestings((item) => handleAddTestings(item))}
          emptyRowsMessage="No existen testeos"
          onClose={() => setShowTestings(false)}
          onDoubleClick={(item) => handleAddTestings(item)}
        />
      )}
      {showHandbooks && (
        <Busqueda
          serviceName={servicios.MANUAL}
          title="B&uacute;squeda de manuales"
          columns={columnsHandBooks((item) => handleAddHandBook(item))}
          emptyRowsMessage="No existen manuales"
          onClose={() => setShowHandbooks(false)}
          onDoubleClick={(item) => handleAddHandBook(item)}
        />
      )}
    </>
  );
};

export default EquipoPreconfig;
