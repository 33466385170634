import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  Subscription,
  concatMap,
  takeUntil,
  takeWhile,
  tap,
  timer,
} from 'rxjs';

import Texto from '../../commons/Texto';
import Boton from '../../commons/Boton';
import { mensajeMini } from '../../commons/Mensajes';
import Loading from '../../commons/Loading';
import Modal from '../../commons/Modal';

import useAuth from '../../hooks/useAuth';

const Autenticador = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useHistory();
  const { requestLogin, validateRequest } = useAuth();
  const [user, setUser] = useState({
    co: '',
    tx: '',
  });
  const [processing, setProcessing] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [fieldsErrors, setFieldsErrors] = useState(undefined);
  const [numbervalidate, setNumberValidate] = useState(undefined);
  const [retriesBlock, setRetriesBlock] = useState(false);

  const subscriptions$ = [];
  const requestLoguin$ = timer(120000).pipe(
    tap(() => {
      handleCancel();
      mensajeMini('No tenemos respuesta', 'info', 8000);
      setUser((prev) => ({
        ...prev,
        tx: '',
      }));
    })
  );

  const handleCancel = () => {
    subscriptions$.forEach((sub) => sub.unsubscribe());
    setIsRequesting(false);
    setProcessing(false);
  };

  const handleChange = (item) => {
    const { name, value } = item;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (!executeRecaptcha) {
      return;
    }
    setProcessing(true);
    const token = await executeRecaptcha('onSubmit');
    const result = await requestLogin({ ...user, rc: token });
    if (result.success && result.status === 'requesting') {
      setIsRequesting(true);
      setNumberValidate(result.result);
      const t$ = timer(2000, 5000);
      const subsValidate$ = t$
        .pipe(
          concatMap(async () => {
            const response = await validateRequest(user.co);
            return response;
          }),
          takeWhile((response) => {
            if (response.status === 'finished') {
              handleCancel();
              setUser((prev) => ({
                ...prev,
                tx: '',
              }));
              mensajeMini(response.messageError);
            }
            if (response.status === 'success') {
              navigate.push('/');
              window.location.reload();
            }
            return response.status === 'requesting';
          }),
          takeUntil(requestLoguin$)
        )
        .subscribe({
          next: () => console.log('requesting'),
          error: () => console.error('error subscription'),
          complete: () => console.log('complete subscription'),
        });
      subscriptions$.push(subsValidate$);
    }
    if (result.status === 'fail') {
      setProcessing(false);
      setUser((prev) => ({
        ...prev,
        tx: '',
      }));
      setFieldsErrors(result.fieldsErrors);
      if (result.messageError) {
        mensajeMini(result.messageError);
      }
    }
    if (result.status === 'blocked' || result.status === 'invalid') {
      setProcessing(false);
      setUser((prev) => ({
        ...prev,
        tx: '',
      }));
      if (result.messageError) {
        mensajeMini(result.messageError, 'warning', 60000);
      }
      setRetriesBlock(true);
      const minutes = result.status === 'blocked' ? 5 : 30;
      timer(0, 1000)
        .pipe(takeUntil(timer(minutes * 60000)))
        .subscribe({
          next: () => {},
          error: () => {},
          complete: () => setRetriesBlock(false),
        });
    }
  };

  useEffect(() => {
    return () => handleCancel();
  }, []);

  return (
    <>
      <div className="op-content-login">
        <div className="op-login">
          <h1>Wuasho</h1>
          <Texto
            name="co"
            value={user.co}
            label="Correo"
            tabIndex={1}
            onChange={handleChange}
            error={fieldsErrors?.co}
          ></Texto>
          <Texto
            label="Contrase&ntilde;a"
            name="tx"
            value={user.tx}
            tabIndex={3}
            onChange={handleChange}
            error={fieldsErrors?.tx}
          />
          <Boton
            className="op-grabar"
            disabled={processing || retriesBlock}
            onClick={handleSubmit}
          >
            {retriesBlock ? 'Bloqueado temporalmente' : 'Ingresar'}
          </Boton>
          {processing === true && <Loading />}
          <NavLink className="op-boton op-cerrar op-otro" to="/">
            <ArrowBackIcon />
            <span>Retornar</span>
          </NavLink>
          <h5>@ 2024 Soluciones OP - Todos los derechos reservados</h5>
        </div>
      </div>
      {isRequesting && (
        <Modal title="Autenticador OP" size={'small'} onClose={handleCancel}>
          <div>
            <h1>Aprobar la solicitud de inicio de sesi&oacute;n</h1>
            <p>
              Ingrese el siguiente valor en la aplicaci&oacute;n Autenticador de
              Soluciones OP
            </p>
            <h1>{numbervalidate}</h1>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Autenticador;
