import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getSucursalesAction } from '../../redux/actions/sucursal.action';
import { solicitudModel } from '../../redux/models/solicitud.model';
import { servicios } from '../../redux/helpers';
import Formulario, { actions } from '../../commons/Formulario';

import SolicitudDetail from './SolicitudDetail';
import SolicitudFilter from './SolicitudFilter';
import SolicitudItem from './SolicitudItem';
import SolicitudList from './SolicitudList';
import SolicitudTask from './SolicitudTask';
import { TYPE_FILTER } from '../../redux/helpers/app.types';

import '../../css/components/solicitud.css';
import RequestStart from './RequestStart';

const Solicitud = () => {
  const { currentUser } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser.tr === 'NOBASICO') {
      dispatch(getSucursalesAction({}));
    }
  }, []);

  const initialState = {
    params: {
      em: currentUser.ie,
      st: '',
      iu: currentUser.tr === 'NOBASICO' ? null : currentUser.us,
    },
    serviceName: servicios.SOLICITUD,
    selected: solicitudModel,
    errors: solicitudModel,
    equipment: null,
    openModal: false,
    successTask: 0,
    successImage: 0,
    accionTask: null,
    accionName: null,
    viewContent: 'op-view-solicitud',
    openModalEdit: false,
    openModalTask: false,
    fileAttached: null,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'EDIT_REQUEST':
        return {
          ...state,
          openModalEdit: true,
          selected: action.payload,
          errors: solicitudModel,
        };
      case 'START_TASK':
        return {
          ...state,
          openModalTask: true,
          successTask: 1,
          successImage: 1,
          accionTask: action.payload.pe,
          accionName: action.payload.na,
          errors: solicitudModel,
        };
      case 'SUCCESS_TASK':
        return {
          ...state,
          successTask: state.successTask + 1,
          errors: solicitudModel,
        };
      case 'SUCCESS_IMAGE':
        return {
          ...state,
          successImage: state.successImage + 1,
          errors: solicitudModel,
          fileAttached: action.payload,
        };
      case 'CLOSE_TASK':
        return {
          ...state,
          openModalTask: false,
          successTask: 0,
          successImage: 0,
          errors: solicitudModel,
          accionTask: null,
          accionName: null,
          fileAttached: null,
        };
      case 'SELECTED_EQUIPMENT':
        return {
          ...state,
          equipment: action.payload,
        };
      case TYPE_FILTER:
        return {
          ...state,
          params: {
            ...state.params,
            st:
              action.payload.st !== undefined
                ? action.payload.st === ''
                  ? null
                  : action.payload.st
                : state.params.st,
          },
        };
      case actions.TRANSACTION_CANCEL:
        return {
          ...state,
          openModalEdit: false,
          openModalTask: false,
          successTask: 0,
          successImage: 0,
          errors: solicitudModel,
          accionTask: null,
          accionName: null,
          fileAttached: null,
        };
      default:
        return state;
    }
  };
  return (
    <Formulario reducer={reducer} initialState={initialState}>
      <SolicitudTask />
      <SolicitudList />
      <SolicitudDetail />
      <SolicitudItem />
      <SolicitudFilter />
    </Formulario>
  );
};

export default Solicitud;
