import React from 'react';
import Proptypes from 'prop-types';
import { useTablaBody } from './Formulario';

const TablaBody = ({
  columns,
  disableSelectedRow,
  emptyRowsMessage,
  indice,
  onDoubleClick,
}) => {
  const { processing, data } = useTablaBody();

  const headers = () =>
    columns.map((item, index) => (
      <th
        key={item.key}
        width={item.width}
        className={`${index > 1 ? 'sm-hide' : ''}`}
      >
        {item.label ? item.label : ''}
      </th>
    ));

  const cell = (obj, fila, index) => (
    <td
      key={obj.key}
      align={obj.align}
      className={`${index > 2 ? 'sm-hide' : ''}`}
      data-label={index > 1 && obj.label ? `${obj.label} : ` : ''}
    >
      {index === 0 ? (
        <>
          <label
            onClick={(e) => handleHide(e)}
            className="op-table-ocultar"
          ></label>
          {obj.content ? obj.content(fila) : fila[obj.key]}
        </>
      ) : obj.content ? (
        obj.content(fila)
      ) : (
        fila[obj.key]
      )}
    </td>
  );

  const row = (fila, key) => (
    <tr
      key={key}
      onClick={(e) => handleClickRow(e)}
      onDoubleClick={() => {
        onDoubleClick ? onDoubleClick(fila) : {};
      }}
    >
      {columns.map((obj, index) => cell(obj, fila, index))}
    </tr>
  );

  function handleClickRow(e) {
    e.preventDefault();
    let fila = e.target;
    fila
      .closest('tbody')
      .querySelectorAll('tr')
      .forEach((element) => {
        element.classList.remove('op-seleccionado');
      });

    fila.parentElement.classList.add('op-seleccionado');
  }

  const handleHide = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.closest('tr').classList.toggle('off');
  };

  return (
    <table>
      <thead>
        <tr>{headers()}</tr>
      </thead>
      <tbody className={`${disableSelectedRow ? '' : 'op-hover'}`}>
        {processing ? (
          <tr className="op-procesando">
            <td colSpan={columns.length}>
              Procesando...
              <i className="op-loading-mini"></i>
            </td>
          </tr>
        ) : data.length === 0 ? (
          <tr className="op-vacio">
            <td colSpan={columns.length}>{emptyRowsMessage}</td>
          </tr>
        ) : (
          data.map((i) => row(i, i[Object.keys(i)[indice]]))
        )}
      </tbody>
    </table>
  );
};

TablaBody.propTypes = {
  columns: Proptypes.arrayOf(
    Proptypes.shape({
      key: Proptypes.string.isRequired,
      label: Proptypes.string,
      align: Proptypes.oneOf(['center', 'left', 'right']),
      width: Proptypes.string,
      content: Proptypes.func,
    })
  ),
  disableSelectedRow: Proptypes.bool,
  emptyRowsMessage: Proptypes.string,
  indice: Proptypes.number,
  onDoubleClick: Proptypes.func,
};

TablaBody.defaultProps = {
  emptyRowsMessage: 'No se encontraron registros',
  indice: 0,
};

export default TablaBody;
