import React from 'react';
import { useSelector } from 'react-redux';

import { manualModel } from '../../redux/models/manual.model';
import { servicios } from '../../redux/helpers';
import Formulario, { actions } from '../../commons/Formulario';

import ManualFilter from './ManualFilter';
import ManualItem from './ManualItem';
import ManualList from './ManualList';
import { TYPE_FILTER } from '../../redux/helpers/app.types';

const Manual = () => {
  const { currentUser } = useSelector((state) => state.app);

  const initialState = {
    params: {
      em: currentUser.ie,
      st: '',
    },
    serviceName: servicios.MANUAL,
    selected: manualModel,
    errors: manualModel,
    openModal: false,
    successImage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case TYPE_FILTER:
        return {
          ...state,
          params: {
            ...state.params,
            st:
              action.payload.st !== undefined
                ? action.payload.st === ''
                  ? null
                  : action.payload.st
                : state.params.st,
          },
        };
      case actions.SELECTED_ITEM:
        return {
          ...state,
          successImage: 1,
        };
      case 'SUCCESS_IMAGE':
        return {
          ...state,
          successImage: state.successImage + 1,
          errors: manualModel,
        };
      case actions.TRANSACTION_CANCEL:
        return {
          ...state,
          successImage: 0,
        };
      default:
        return state;
    }
  };
  return (
    <Formulario reducer={reducer} initialState={initialState}>
      <ManualList />
      <ManualFilter />
      <ManualItem />
    </Formulario>
  );
};

export default Manual;
