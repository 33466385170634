import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../commons/Modal';
import Texto from '../../commons/Texto';
import Combo from '../../commons/Combo';
import Boton from '../../commons/Boton';
import Icon from '../../commons/Icon';
import FileUpload from '../../commons/FileUpload';
import Calendario from '../../commons/Calendario';
import { confirmacion } from '../../commons/Mensajes';
import {
  formatoFecha,
  formatoMonetario,
  redondeoDecimales,
} from '../../commons/Utilitarios';

import File from '../app/File';
import AdicionalList from '../adicional/AdicionalList';

import { getUsuariosAction } from '../../redux/actions/usuario.action';

import PdfFile from '../../assets/icons/pdf-file.svg';
import PngFile from '../../assets/icons/png-file.svg';
import JpgFile from '../../assets/icons/jpg-file.svg';

import SaveIcon from '@material-ui/icons/Save';

const ContratoDetalle = ({
  lista,
  accion,
  onClose,
  onAction,
  onChange,
  disabled,
  searchable,
  editable,
}) => {
  const [sucursal, setSucursal] = useState(null);
  const [imagen, setImagen] = useState([]);
  const [openElementos, setOpenElementos] = useState(false);
  const [elemento, setElemento] = useState(null);
  const [openFile, setOpenFile] = useState(false);
  const [filename, setFilename] = useState(null);

  const dispatch = useDispatch();
  const { selected, processing } = useSelector((state) => state.contrato);
  const [contrato, setContrato] = useState(selected);
  const { sucursales } = useSelector((state) => state.sucursal);
  const { usuarios } = useSelector((state) => state.usuario);
  const { currentUser } = useSelector((state) => state.app);

  const handleType = (tp) => {
    switch (tp) {
      case '02':
        return 'int';
      case '03':
        return 'money';
      default:
        return 'texto';
    }
  };

  const handleChange = (item) => {
    const { name, value } = item;
    setContrato((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleComplementary = () => {
    if (contrato.sc && !disabled) {
      return (
        <>
          <Calendario
            name={'fi'}
            value={contrato.fi}
            tabIndex={10}
            label={'Inicio de contrato'}
            size={2}
            disabled={disabled}
            onSelected={(item) => {
              handleChange(item);
              handleChange({ name: 'ci', value: item.value.getDate() });
            }}
          />
          <Texto
            name="ci"
            value={contrato.ci}
            label="Ciclo de facturaci&oacute;n"
            size={2}
            restrict="int"
            tabIndex={11}
            onChange={(item) => handleChange(item)}
          />
          <Texto
            name="cu"
            value={contrato.cu}
            label="N&uacute;mero de cuotas del contrato"
            size={2}
            restrict="int"
            tabIndex={12}
            disabled={disabled}
            onChange={(item) => {
              handleChange(item);
              const date = new Date(contrato.fi);
              date.setMonth(date.getMonth() + parseInt(item.value));
              handleChange({ name: 'ff', value: date });
            }}
          />
          <Texto
            name={'ff'}
            value={formatoFecha(contrato.ff, 'Indefinido', false)}
            tabIndex={13}
            label={'Final de contrato'}
            size={2}
            disabled
            onChange={() => {}}
          />
          <Texto
            name="si"
            value={contrato.si}
            label="Costo de instalaci&oacute;n"
            size={2}
            restrict="money"
            tabIndex={14}
            disabled={disabled}
            onChange={handleChange}
            onBlur={(e) => {
              handleChange({
                name: 'si',
                value: formatoMonetario(redondeoDecimales(e.target.value)),
              });
            }}
          />
          <Texto
            name="ic"
            value={contrato.ic}
            label="N&uacute;mero de cuotas de la instalaci&oacute;n"
            size={2}
            restrict="int"
            tabIndex={15}
            disabled={disabled}
            onChange={handleChange}
          />
        </>
      );
    } else return <></>;
  };

  const elementos = lista.map((x, i) => {
    if (['01', '02', '03'].includes(x.vt)) {
      return (
        <Texto
          key={x.pd}
          name={i.toString()}
          value={x.vd}
          tabIndex={15 + i}
          label={x.nd}
          restrict={handleType(x.vt)}
          size={2}
          disabled={disabled}
          onChange={(item) => onChange({ name: x.pd, value: item.value })}
          onBlur={(e) => {
            if (x.vt === '03') {
              onChange({ name: x.pd, value: e.target.value });
            }
          }}
        />
      );
    } else if (['04', '05'].includes(x.vt)) {
      return (
        <React.Fragment key={x.pd}>
          {disabled ? (
            <Texto
              name={i.toString()}
              value={formatoFecha(x.vd, '', x.vt === '05')}
              tabIndex={15 + i}
              label={x.nd}
              size={2}
              disabled={disabled}
              onChange={() => {}}
            />
          ) : (
            <Calendario
              name={i.toString()}
              value={x.vd}
              tabIndex={15 + i}
              label={x.nd}
              size={2}
              showTime={x.vt === '05'}
              onSelected={(item) => onChange({ name: x.pd, value: item.value })}
            />
          )}
        </React.Fragment>
      );
    } else if ('07' === x.vt && x.va === '01') {
      return (
        <React.Fragment key={x.pd}>
          {disabled ? (
            <Texto
              name={i.toString()}
              value={x.vd}
              tabIndex={15 + i}
              label={x.nd}
              size={2}
              disabled={disabled}
              onChange={() => {}}
            />
          ) : (
            <>
              <Combo
                name="sucursal"
                value={sucursal}
                label="Sucursal"
                size={2}
                tabIndex={30}
                data={sucursales.map((x) => ({ value: x.su, label: x.ns }))}
                onChange={(item) => {
                  setSucursal(item.value);
                  dispatch(getUsuariosAction(item.value));
                }}
              />
              <Combo
                name={i.toString()}
                value={x.vd}
                tabIndex={15 + i}
                label={x.nd}
                size={2}
                data={usuarios.map((x) => ({ value: x.us, label: x.np }))}
                onChange={(item) =>
                  onChange({ name: x.pd, value: item.value, label: item.label })
                }
              />
              {editable && (
                <Texto
                  name={i.toString()}
                  value={x.vd}
                  tabIndex={15 + i}
                  label={`${x.nd} actual`}
                  size={2}
                  disabled
                  onChange={() => {}}
                />
              )}
            </>
          )}
        </React.Fragment>
      );
    } else if ('07' === x.vt && x.va === '02') {
      return (
        <React.Fragment key={x.pd}>
          {disabled ? (
            <Texto
              name={i.toString()}
              value={x.vd}
              tabIndex={15 + i}
              label={x.nd}
              size={2}
              disabled={disabled}
              onChange={() => {}}
            />
          ) : (
            <>
              <Texto
                name={'elem'}
                value={elemento}
                tabIndex={30}
                label={x.nd}
                size={2}
                disabled
                search
                onChange={() => {}}
                onSearch={() => setOpenElementos(true)}
              />
              {editable && (
                <Texto
                  name={i.toString()}
                  value={x.vd}
                  tabIndex={15 + i}
                  label={`${x.nd} actual`}
                  size={2}
                  disabled
                  onChange={() => {}}
                />
              )}
              {openElementos && (
                <AdicionalList
                  searchable
                  onClose={() => setOpenElementos(false)}
                  onSend={(item) => {
                    setElemento(item.na);
                    onChange({ name: x.pd, value: item.na, label: item.ad });
                  }}
                />
              )}
            </>
          )}
        </React.Fragment>
      );
    } else if ('06' === x.vt) {
      return (
        <React.Fragment key={x.pd}>
          {disabled ? (
            <></>
          ) : (
            <FileUpload
              label={x.nd}
              extensions={['jpeg', 'jpg', 'png', 'pdf']}
              maxFiles={2}
              onSelectedFile={(files) => {
                setImagen(files.map((x) => x.file));
                handleChange({ name: 'pd', value: x.pd });
                onChange({
                  name: 'files',
                  value: { pd: x.pd, files: files.map((x) => x.file) },
                });
              }}
            />
          )}
        </React.Fragment>
      );
    }
  });

  const handleGetIcon = (filename) => {
    let extension = filename.split('.').pop().toLowerCase();
    let icono = PdfFile;
    switch (extension) {
      case 'pdf':
        icono = PdfFile;
        break;
      case 'jpg':
        icono = JpgFile;
        break;
      case 'jpeg':
        icono = JpgFile;
        break;
      case 'png':
        icono = PngFile;
        break;
      default:
        break;
    }
    return icono;
  };

  let archivos = lista
    .filter((x) => x.vt === '06' && x.vd && disabled)
    .map((x) => {
      let archs = x.vd.split(',');

      return (
        <React.Fragment key={x.pd}>
          <div className="op-file">
            <Icon svg={handleGetIcon(archs[0])} transparent />
            <div
              onClick={() => {
                setFilename(archs[0]);
                setOpenFile(true);
              }}
            >
              {x.nd}
            </div>
          </div>
          {archs.length > 1 && (
            <div className="op-file">
              <Icon svg={handleGetIcon(archs[1])} transparent />
              <div
                onClick={() => {
                  setFilename(archs[1]);
                  setOpenFile(true);
                }}
              >
                {x.nd}
              </div>
            </div>
          )}
        </React.Fragment>
      );
    });

  return (
    <>
      {searchable ? (
        <Modal
          title={`${accion ? accion.de : 'Grabar'}`}
          processing={processing}
          onActions={() => (
            <>
              {accion && (
                <Boton
                  className="op-dinamic"
                  disabled={processing}
                  icon={() => <SaveIcon />}
                  style={{ '--button-color': accion.color }}
                  tabIndex={21}
                  onClick={() =>
                    confirmacion(
                      `¿Desea ${accion.na.toLowerCase()}?`,
                      `El proceso de ${accion.de.toLowerCase()}`
                    )
                      .then(() =>
                        onAction(contrato, accion.ce, accion.pe, imagen)
                      )
                      .catch(() => {})
                  }
                >
                  {accion.na}
                </Boton>
              )}
            </>
          )}
          onClose={() => onClose()}
        >
          <>
            {handleComplementary()}
            {elementos}
            {archivos.length > 0 && (
              <div className="op-form-group">
                <span>Archivos adjuntos</span>
                {archivos}
              </div>
            )}
          </>
        </Modal>
      ) : (
        <>
          {handleComplementary()}
          {elementos}
          {archivos.length > 0 && (
            <div className="op-form-group">
              <span>Archivos adjuntos</span>
              {archivos}
            </div>
          )}
        </>
      )}
      {openFile && (
        <File
          filename={filename}
          dir={[currentUser.ie, 'CO', contrato.co]}
          onClose={() => {
            setOpenFile(false);
          }}
        />
      )}
    </>
  );
};

ContratoDetalle.propTypes = {
  disabled: PropTypes.bool,
  lista: PropTypes.array,
  accion: PropTypes.object,
  onclose: PropTypes.func,
  onAction: PropTypes.func,
  onChange: PropTypes.func,
  searchable: PropTypes.bool,
  editable: PropTypes.bool,
};

ContratoDetalle.defaultProps = {
  disabled: false,
  editable: false,
};

export default ContratoDetalle;
