import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Combo from '../../commons/Combo';
import Boton from '../../commons/Boton';
import CheckBox from '../../commons/CheckBox';
import { confirmacion } from '../../commons/Mensajes';

import {
  closeModal,
  postDetalleAction,
} from '../../redux/actions/detalle.action';

import SaveIcon from '@material-ui/icons/Save';

const DetalleItem = ({
  selected,
  processing,
  errors,
  cabeceras,
  closeModal,
  postDetalleAction,
}) => {
  const [detalle, setDetalle] = useState(selected);
  const [tipo, setTipo] = useState('');

  useEffect(() => {
    let searchTipo = cabeceras.find((s) => s.de === selected.dc);
    if (searchTipo) {
      setTipo(searchTipo.ca);
    }
  }, [cabeceras]);

  const handleChange = (item) => {
    const { name, value } = item;
    setDetalle((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === 'ca') {
      setTipo(value);
    }
  };

  return (
    <Modal
      title={`${detalle.dt ? 'Actualizar' : 'Nuevo'} detalle`}
      processing={processing}
      onActions={() => (
        <Boton
          className="op-grabar"
          disabled={processing}
          icon={() => <SaveIcon />}
          tabIndex={21}
          onClick={() =>
            confirmacion(
              '¿Desea guardar?',
              `${detalle.dt ? 'Actualización' : 'Un nuevo'} detalle`
            )
              .then(() => postDetalleAction(detalle))
              .catch(() => {})
          }
        >
          Guardar
        </Boton>
      )}
      onClose={() => closeModal()}
    >
      <Texto
        name="de"
        value={detalle.de}
        label="Descripci&oacute;n"
        size={2}
        tabIndex={11}
        error={errors.de}
        onChange={handleChange}
        enabledPaste
        autoFocus
      />
      <Combo
        name="ca"
        label="Tipo cabecera"
        value={tipo}
        tabIndex={12}
        size={2}
        data={cabeceras.map((i) => ({ value: i.ca, label: i.de }))}
        error={errors.ca}
        onChange={handleChange}
      />
      <Texto
        name="vm"
        value={detalle.vm}
        label="Valor maestro"
        size={2}
        tabIndex={13}
        error={errors.vm}
        onChange={handleChange}
      />
      <div className="op-form-group op-col-2 op-checkbox-group">
        <span>Estado del registro</span>
        <CheckBox
          name="st"
          checked={detalle.st}
          tabIndex={14}
          onChange={handleChange}
        />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.detalle.selected,
    processing: state.detalle.processing,
    errors: state.detalle.errors,
    cabeceras: state.cabecera.cabeceras,
  };
};

export default connect(mapStateToProps, { closeModal, postDetalleAction })(
  DetalleItem
);
