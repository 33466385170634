import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

import Boton from '../../commons/Boton';
import CheckBox from '../../commons/CheckBox';
import Modal from '../../commons/Modal';
import Texto from '../../commons/Texto';
import Tabla from '../../commons/Tabla';
import { useFormularioContext } from '../../commons/Formulario';

import { confirmacion } from '../../commons/Mensajes';

import { procedimientoModel } from '../../redux/models/procedimiento.model';
import { procedimientoDetalleModel } from '../../redux/models/procedimiento.detalle.model';
import { servicios } from '../../redux/helpers';
import ProcedimientoDetail from './ProcedimientoDetail';

const ProcedimientoItem = () => {
  const {
    selected,
    openModal,
    processing,
    errors,
    callTransaction,
    cancelTransaction,
  } = useFormularioContext();
  const [procedimiento, setProcedimiento] = useState(selected);
  const [detail, setDetail] = useState([]);
  const [modelDetail, setModelDetail] = useState(procedimientoDetalleModel);
  const [showDetail, setShowDetail] = useState(false);
  const { currentUser } = useSelector((state) => state.app);

  const handleChange = (item) => {
    const { name, value } = item;
    setProcedimiento((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    setProcedimiento(selected);
    setDetail(selected.lp.map((l) => ({ ...l, id: l.co })));
    if (!openModal) {
      setDetail([]);
    }
  }, [selected, openModal]);

  if (!openModal) {
    return null;
  }

  const handleSave = () => {
    let item = {
      ...procedimiento,
      ie: currentUser.ie,
      lp: detail,
    };
    callTransaction({
      method: 'post',
      url: servicios.PROCEDIMIENTO,
      data: item,
      model: procedimientoModel,
    });
  };

  const columns = [
    {
      key: 'id',
      align: 'center',
      content: (item) => (
        <>
          {item.co && (
            <EditIcon
              style={{ color: 'green', cursor: 'pointer' }}
              onClick={() => {
                setModelDetail(item);
                setShowDetail(true);
              }}
            />
          )}
          <DeleteForeverIcon
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={() =>
              confirmacion(
                '¿Deseas eliminar?',
                `Está seguro de eliminar ${item.np} de la lista`
              )
                .then(() => handleDeleteDetail(item))
                .catch(() => {})
            }
          />
        </>
      ),
    },
    {
      key: 'np',
      label: 'Nombre',
      content: (item) => (
        <span
          style={{
            color: `${item.st === true ? 'black' : 'red'}`,
            textDecoration: `${item.st === true ? 'none' : 'line-through'}`,
          }}
        >
          {item.np}
        </span>
      ),
    },
  ];

  const handleAddDetail = (item) => {
    let exits = detail.find((x) => x.id === item.id);
    if (exits) {
      setDetail(
        detail.map((x) =>
          x.id === item.id ? { ...x, nt: item.np, st: item.st } : x
        )
      );
    } else {
      item.id = new Date().getTime().toString();
      item.st = true;
      setDetail([...detail, item]);
    }
    setShowDetail(false);
  };

  const handleDeleteDetail = (item) => {
    setDetail(detail.filter((x) => x.id !== item.id));
  };

  return (
    <Modal
      title={`${
        procedimiento.pr
          ? 'Editar procedimiento ' + procedimiento.cp
          : 'Nuevo procedimiento'
      }`}
      processing={processing}
      onActions={() => (
        <>
          <Boton
            className="op-dinamic"
            icon={() => <AddIcon />}
            tabIndex={22}
            style={{ '--button-color': '#3285a8' }}
            onClick={() => {
              setModelDetail(procedimientoDetalleModel);
              setShowDetail(true);
            }}
          >
            Agregar Procedimiento Unitario
          </Boton>
          <Boton
            className="op-grabar"
            disabled={processing}
            icon={() => <SaveIcon />}
            tabIndex={19}
            onClick={() =>
              confirmacion(
                '¿Desea guardar?',
                `${
                  procedimiento.pr
                    ? 'Una actualización del procedimiento' + procedimiento.cp
                    : 'Un nuevo procedimiento'
                }`
              )
                .then(() => handleSave())
                .catch(() => {})
            }
          >
            Guardar
          </Boton>
        </>
      )}
      onClose={() => cancelTransaction(procedimientoModel)}
    >
      {procedimiento.pr === null && (
        <Texto
          name="cp"
          value={procedimiento.cp}
          label="C&oacute;digo"
          tabIndex={10}
          size={2}
          error={errors.cp}
          onChange={handleChange}
          disabled={procedimiento.gc}
        />
      )}
      <Texto
        name="np"
        value={procedimiento.np}
        label="Nombre"
        size={2}
        tabIndex={11}
        error={errors.np}
        onChange={handleChange}
      />
      {procedimiento.pr ? (
        <div className="op-form-group op-col-2 op-checkbox-group">
          <span>Estado del registro</span>
          <CheckBox
            name="st"
            tabIndex={12}
            checked={procedimiento.st}
            onChange={handleChange}
          />
        </div>
      ) : (
        <div className="op-form-group op-col-2 op-checkbox-group">
          <span>¿Desea autogenerar el c&oacute;digo?</span>
          <CheckBox
            name="gc"
            tabIndex={13}
            checked={procedimiento.gc}
            onChange={handleChange}
          />
        </div>
      )}
      <Texto
        name="dp"
        value={procedimiento.dp}
        label="Descripci&oacute;n"
        tabIndex={14}
        error={errors.dp}
        onChange={handleChange}
      />
      <div className="op-form-group op-col-1">
        <div className="op-table" style={{ padding: 0, flexBasis: '100%' }}>
          <Tabla
            columns={columns}
            data={detail}
            searcheable={false}
            pagination={false}
            hoverSelectedRow={false}
            emptyRowsMessage="Ning&uacute;n procedimiento unitario asignado"
          />
        </div>
        {showDetail && (
          <ProcedimientoDetail
            selected={modelDetail}
            onSend={handleAddDetail}
            onClose={() => setShowDetail(false)}
          />
        )}
      </div>
    </Modal>
  );
};

export default ProcedimientoItem;
