import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Adjunto from '../../commons/Adjunto';
import { useFormularioContext } from '../../commons/Formulario';
import { formatoFecha } from '../../commons/Utilitarios';
import { servicios } from '../../redux/helpers';
import useAdjunto from '../../hooks/useAdjunto';
import { mantenimientoModel } from '../../redux/models/mantenimiento.model';

const MantenimientoDetail = () => {
  const { selected, callTransaction } = useFormularioContext();
  const { currentUser } = useSelector((state) => state.app);
  const [maintenance, setMaintenance] = useState(selected);
  const [showEdit, setshowEdit] = useState(false);
  const { processAttached, attached, getAttached } = useAdjunto();

  useEffect(() => {
    setMaintenance(selected);
    getAttached(maintenance.im.ar);
  }, [selected.hi]);

  const handleSaveAttached = (attached) => {
    let form_data = new FormData();
    form_data.append('file', attached);
    form_data.append('ie', currentUser.ie);
    form_data.append('ua', currentUser.us);
    form_data.append('hi', selected.hi);
    form_data.append('eq', selected.eq.eq);
    callTransaction({
      method: 'patch',
      url: `${servicios.MANTENIMIENTO}/adjuntar`,
      data: form_data,
      headers: { 'content-type': 'multipart/form-data; charset=utf-8' },
      model: mantenimientoModel,
    });
  };

  return (
    <div className="detail-equipments">
      <div className="detail-info">
        <span>Solicitud:</span>
        <span>{maintenance.so}</span>
      </div>
      <div className="detail-info">
        <span>Fecha mantenimiento:</span>
        <span>{formatoFecha(maintenance.fa)}</span>
      </div>
      <div className="detail-info">
        <span>T&eacute;cnico:</span>
        <span>{maintenance.nt}</span>
      </div>
      <div className="detail-info">
        <span>Tipo mantenimiento:</span>
        <span>{maintenance.dt}</span>
      </div>
      <div className="detail-info">
        <span>Es Reparaci&oacute;n:</span>
        <span>{maintenance.sr ? 'Si' : 'No'}</span>
      </div>
      <div className="detail-info">
        <span>Mantenimiento:</span>
        <span>{maintenance.dm}</span>
      </div>
      <div className="detail-info">
        <span>Recomendaciones y/o observaciones:</span>
        <span>{maintenance.rc}</span>
      </div>
      <br />
      {!processAttached && (
        <Adjunto
          stateParent={maintenance.st}
          nameParent={'mantenimiento'}
          title={'Imagenes del mantenimiento'}
          extensions={['jpg', 'jpeg', 'png']}
          addButtonText="Nueva imagen"
          confirmButtonText="Adjuntar imagen"
          confirmNewText="Se agregara una nueva imagen del mantenimiento"
          confirmEditText="Se actualizara la imagen"
          data={
            attached &&
            attached.dtl &&
            attached.dtl.map((a) => ({
              id: a.cr,
              name: a.na,
              extension: a.ea,
              state: a.st,
            }))
          }
          processing={processAttached}
          file={attached && attached.ar}
          onNew={(documento) => handleSaveAttached(documento)}
          onSuccess={() => getAttached(attached.ar)}
        />
      )}
    </div>
  );
};

export default MantenimientoDetail;
