import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import EventNoteIcon from '@material-ui/icons/EventNote';
import PrintIcon from '@material-ui/icons/Print';

import Boton from '../../commons/Boton';
import Calendario from '../../commons/Calendario';
import Combo from '../../commons/Combo';
import Filtros from '../../commons/Filtros';
import Modal from '../../commons/Modal';
import { useTablaFilter } from '../../commons/Formulario';

import useSolicitud from '../../hooks/useSolicitud';

import { solicitudModel } from '../../redux/models/solicitud.model';
import { TYPE_FILTER } from '../../redux/helpers/app.types';
import { obtenerColorAleatorio } from '../../commons/Utilitarios';

const SolicitudFilter = () => {
  const { currentUser } = useSelector((state) => state.app);
  const { getData, params, dispatch, setSelectedItem } = useTablaFilter();
  const { requests, getAssignedRequest } = useSolicitud();
  const [currentMonth] = useState(new Date());
  const [showRequests, setShowRequests] = useState(false);

  return (
    <>
      <Filtros
        onFilter={() => getData()}
        onNew={() => setSelectedItem(solicitudModel)}
        actions={() => (
          <>
            {currentUser.tr === 'NOBASICO' && (
              <>
                <Boton
                  className="op-filter"
                  icon={() => <EventNoteIcon />}
                  tabIndex={21}
                  onClick={() => {
                    getAssignedRequest(currentMonth);
                    setShowRequests(true);
                  }}
                >
                  Calendario
                </Boton>
              </>
            )}
          </>
        )}
      >
        <Combo
          name="st"
          label="Estado de registro"
          value={params.st}
          placeholder="Todos"
          tabIndex={202}
          size={3}
          data={[
            { value: '1', label: 'Vigente' },
            { value: '0', label: 'No Vigente' },
          ]}
          onChange={({ value }) => {
            dispatch({ type: TYPE_FILTER, payload: { st: value } });
          }}
        />
      </Filtros>
      {showRequests && (
        <Modal
          title="Solicitudes asignadas"
          closeWithoutConfirmation
          onClose={() => setShowRequests(false)}
        >
          <Calendario
            showInline
            tabIndex={50}
            events={requests.map((m) => ({
              key: m.so,
              datetime: new Date(m.fv),
              messages: [m.cs, `Cliente: ${m.ii.dn}`, `Técnico: ${m.ta[0].np}`],
            }))}
            onSelected={() => {}}
            onSelectedMonth={({ month, year }) =>
              getAssignedRequest(new Date(year, month, 1))
            }
            style={{ flexBasis: '100%' }}
          />
        </Modal>
      )}
    </>
  );
};

export default SolicitudFilter;
