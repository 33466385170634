import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Combo from '../../commons/Combo';
import Boton from '../../commons/Boton';
import Tooltip from '../../commons/Tooltip';
import CheckBox from '../../commons/CheckBox';
import RadioButton from '../../commons/RadioButton';
import { confirmacion } from '../../commons/Mensajes';
import { formatoMonetario, redondeoDecimales } from '../../commons/Utilitarios';

import {
  closeModal,
  postServicioAction,
} from '../../redux/actions/servicio.action';

import SaveIcon from '@material-ui/icons/Save';

const ServicioItem = ({
  selected,
  processing,
  errors,
  closeModal,
  postServicioAction,
}) => {
  const [servicio, setServicio] = useState(selected);
  const [autogenerar, setAutogenerar] = useState(true);

  const { tipos } = useSelector((state) => state.categoria);

  useEffect(() => {
    handleChange({
      name: 'ps',
      value: formatoMonetario(redondeoDecimales(servicio.ps)),
    });
    handleChange({
      name: 'ds',
      value: formatoMonetario(redondeoDecimales(servicio.ds)),
    });
    let find = tipos.find((x) => x.nc === selected.nc);
    if (find) {
      handleChange({ name: 'ct', value: find.ct });
    }
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setServicio((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === 'gc') {
      setAutogenerar(!autogenerar);
    }
  };

  return (
    <Modal
      title={`${servicio.sv ? 'Actualizar' : 'Nuevo'} servicio`}
      processing={processing}
      onActions={() => (
        <Boton
          className="op-grabar"
          disabled={processing}
          icon={() => <SaveIcon />}
          tabIndex={21}
          onClick={() =>
            confirmacion(
              '¿Desea guardar?',
              `${servicio.sv ? 'Actualizar un' : 'Un nuevo'} ${servicio.ns}`
            )
              .then(() => postServicioAction(servicio))
              .catch(() => {})
          }
        >
          Guardar
        </Boton>
      )}
      onClose={() => closeModal()}
    >
      <Texto
        name="cs"
        value={servicio.cs}
        label="C&oacute;digo"
        tabIndex={10}
        size={2}
        error={errors.cs}
        onChange={handleChange}
        disabled={autogenerar}
      />
      <Texto
        name="ns"
        value={servicio.ns}
        label="Nombre"
        tabIndex={11}
        size={2}
        error={errors.nm}
        onChange={handleChange}
        autoFocus
      />
      <Texto
        name="ps"
        value={servicio.ps}
        label="Precio"
        size={2}
        tabIndex={13}
        error={errors.ps}
        onChange={handleChange}
        restrict={'money'}
        onBlur={(e) => {
          handleChange({
            name: 'ps',
            value: formatoMonetario(redondeoDecimales(e.target.value)),
          });
        }}
      />

      {servicio.sv ? (
        <div className="op-form-group op-col-2 op-checkbox-group">
          <span>Estado del registro</span>
          <CheckBox
            name="st"
            tabIndex={14}
            checked={servicio.st}
            onChange={handleChange}
          />
        </div>
      ) : (
        <div className="op-form-group op-col-2 op-checkbox-group">
          <span>¿Desea autogenerar el c&oacute;digo?</span>
          <CheckBox
            name="gc"
            tabIndex={15}
            checked={servicio.gc}
            onChange={handleChange}
          />
        </div>
      )}
      <Texto
        name="dd"
        value={servicio.dd}
        label="Descripci&oacute;n del descuento"
        tabIndex={16}
        size={2}
        error={errors.dd}
        onChange={handleChange}
      />
      <Texto
        name="ds"
        value={servicio.ds}
        label="Monto del descuento"
        size={2}
        tabIndex={17}
        error={errors.ds}
        onChange={handleChange}
        restrict={'money'}
        onBlur={(e) => {
          handleChange({
            name: 'ds',
            value: formatoMonetario(redondeoDecimales(e.target.value)),
          });
        }}
      />
      <Combo
        name="ct"
        label="Tipo categor&iacute;a"
        value={servicio.ct}
        size={2}
        tabIndex={10}
        data={tipos.map((i) => ({
          value: i.ct,
          label: i.nc,
        }))}
        onChange={handleChange}
      />
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.servicio.selected,
    processing: state.servicio.processing,
    errors: state.servicio.errors,
  };
};

export default connect(mapStateToProps, { closeModal, postServicioAction })(
  ServicioItem
);
