import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../commons/Modal';
import Boton from '../../commons/Boton';
import FileUpload from '../../commons/FileUpload';
import File from '../app/File';
import { confirmacion } from '../../commons/Mensajes';

import {
  patchImagenServicioAction,
  closeModal,
} from '../../redux/actions/servicio.action';

import SaveIcon from '@material-ui/icons/Save';

const ServicioImage = () => {
  const [imagen, setImagen] = useState();
  const dispatch = useDispatch();
  const { selected, processing } = useSelector((state) => state.servicio);
  const empresa = useSelector((state) => state.app.currentUser.ie);
  return (
    <Modal
      title={`${selected.im ? 'Modificar' : 'Agregar'} imagen`}
      loading="files"
      processing={processing}
      onActions={() => (
        <Boton
          className="op-grabar"
          disabled={processing}
          icon={() => <SaveIcon />}
          tabIndex={21}
          onClick={() =>
            confirmacion(
              `¿Desea guardar la imagen?`,
              `La imagen se asignara al servicio ${selected.nm}`
            )
              .then(() => dispatch(patchImagenServicioAction(selected, imagen)))
              .catch(() => {})
          }
        >
          Guardar imagen
        </Boton>
      )}
      closeWithoutConfirmation
      onClose={() => dispatch(closeModal())}
    >
      {selected.im && (
        <File
          filename={selected.im}
          dir={[empresa, 'SVI', selected.sv]}
          onClose={() => {}}
          showInline
        />
      )}
      <FileUpload
        label={`${selected.im ? 'Modificar' : 'Agregar'} imagen`}
        extensions={['jpg', 'jpeg', 'png']}
        onSelectedFile={(files) => {
          setImagen(files[0].file);
        }}
      />
    </Modal>
  );
};

export default ServicioImage;
