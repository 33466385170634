import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';

import Bloque from '../../commons/Bloque';
import Boton from '../../commons/Boton';
import CheckBox from '../../commons/CheckBox';
import { useFormularioContext } from '../../commons/Formulario';
import { confirmacion } from '../../commons/Mensajes';

import { solicitudModel } from '../../redux/models/solicitud.model';
import { servicios } from '../../redux/helpers';

const SolicitudProcessesResult = ({ previus }) => {
  const { selected, accionTask, callTransaction } = useFormularioContext();
  const { currentUser } = useSelector((state) => state.app);
  const [processes, setProcesses] = useState([]);
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (selected.qq) {
      setProcesses(selected.qq.filter((f) => f.pc != null));
      let res = [];
      selected.qq
        .filter((f) => f.pc != null)
        .filter((f) => {
          let a = previus
            .filter((x) => x.pc.length > 0)
            .find((x) => x.eq.ce === f.eq.ce);
          return a === undefined;
        })
        .map((t) => {
          t.pc.map((x) => {
            x.lp
              .filter((f) => f.st === true)
              .map((y) => {
                res.push({
                  eq: t.eq.eq,
                  pr: x.pr,
                  pp: x.np,
                  co: y.co,
                  np: y.np,
                  rs: false,
                });
              });
          });
        });
      setResults(res);
    }
  }, [selected.so, previus]);

  const handleChange = (c, v) => {
    setResults(results.map((x) => (x.co === c ? { ...x, rs: v } : x)));
  };

  const handleSaveResults = (eq) => {
    let request = {
      ie: currentUser.ie,
      ua: currentUser.us,
      eq: eq,
      ia: accionTask,
      lp: results.filter((f) => f.eq === eq),
    };
    callTransaction({
      method: 'post',
      url: `${servicios.MANTENIMIENTO}/procedimientos`,
      data: request,
      model: solicitudModel,
      successfulAction: { type: 'SUCCESS_TASK' },
    });
  };

  return (
    <div className="detail-equipments">
      {processes?.map((m) => (
        <React.Fragment key={m.eq.eq}>
          <div className="title">
            <h3>{`Procedimientos: ${m.eq.ce} - ${m.eq.ne}`}</h3>
            {previus
              .filter((x) => x.pc.length > 0)
              .find((x) => x.eq.ce === m.eq.ce) === undefined ? (
              <Boton
                className="op-grabar"
                icon={() => <SaveIcon />}
                onClick={() =>
                  confirmacion(
                    '¿Está seguro?',
                    `De registrar los resultados de procedimientos de ${m.eq.ne}, recuerde que ya no podra volver a enviar esta información`
                  )
                    .then(() => handleSaveResults(m.eq.eq))
                    .catch(() => {})
                }
              >
                Registrar resultados
              </Boton>
            ) : (
              <div></div>
            )}
          </div>
          {m.pc.map((x) => (
            <Bloque key={x.pr} titulo={`${x.cp} - ${x.np}`}>
              <div className="op-detail-contenedor">
                {results
                  .filter((f) => f.eq === m.eq.eq && f.pr === x.pr)
                  .map((p) => (
                    <div key={p.co} className="op-detail-result">
                      <span>{p.np}</span>
                      <CheckBox
                        checked={results.find((x) => x.co === p.co).rs}
                        onChange={({ value }) => handleChange(p.co, value)}
                      />
                    </div>
                  ))}
                {previus
                  .filter((x) => x.eq.ce === m.eq.ce)
                  .map((p) =>
                    p.pc.map((r) => (
                      <div className="op-detail-result">
                        <span>{r.nt}</span>
                        <span style={{ color: r.rs ? '#0cb018' : 'red' }}>
                          {r.rs ? 'Verificado' : 'No Verificado'}
                        </span>
                      </div>
                    ))
                  )}
              </div>
            </Bloque>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};

export default SolicitudProcessesResult;
