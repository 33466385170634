import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Boton from '../../commons/Boton';
import Combo from '../../commons/Combo';
import RadioButton from '../../commons/RadioButton';
import { confirmacion } from '../../commons/Mensajes';
import {
  formatoMonetario,
  redondeoDecimales,
  formatoFecha,
} from '../../commons/Utilitarios';

import {
  closeModal,
  postOperacionCajaChicaAction,
  putRectificarOperacionCajaChicaAction,
} from '../../redux/actions/chica.operacion.action';

import SaveIcon from '@material-ui/icons/Save';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';

const ChicaOperacionItem = ({
  selected,
  processing,
  errors,
  tipoCajas,
  tipoDocumentos,
}) => {
  const [operacion, setOperacion] = useState(selected);
  const [moneda, setMoneda] = useState(true);
  const [arrow, setArrow] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    let caja = tipoCajas.find((x) => x.de === operacion.dc);
    if (caja) {
      handleChange({ name: 'tc', value: caja.dt });
    }
    let compra = tipoDocumentos.find((x) => x.de === operacion.dd);
    if (compra) {
      handleChange({ name: 'td', value: compra.dt });
    }
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setOperacion((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <Modal
        title={`${operacion.fo ? 'Rectificar' : 'Registrar'} operación ${
          operacion.fo ? formatoFecha(operacion.fo) : ''
        }`}
        processing={processing}
        loading="payment"
        onActions={() =>
          operacion.fo ? (
            <Boton
              className="op-grabar"
              disabled={processing}
              icon={() => <SaveIcon />}
              tabIndex={21}
              onClick={() =>
                confirmacion('¿Está seguro?', `Rectificar la operación`)
                  .then(() => {
                    dispatch(putRectificarOperacionCajaChicaAction(operacion));
                  })
                  .catch(() => {})
              }
            >
              Guardar
            </Boton>
          ) : (
            <Boton
              className="op-grabar"
              disabled={processing}
              icon={() => <SaveIcon />}
              tabIndex={21}
              onClick={() =>
                confirmacion('¿Está seguro?', `Registrar la operación`)
                  .then(() => {
                    dispatch(postOperacionCajaChicaAction(operacion));
                  })
                  .catch(() => {})
              }
            >
              Guardar
            </Boton>
          )
        }
        onClose={() => dispatch(closeModal())}
      >
        <Combo
          name="td"
          label={`Tipo de documento (opcional)`}
          value={operacion.td}
          tabIndex={10}
          size={2}
          data={tipoDocumentos.map((i) => ({ value: i.dt, label: i.de }))}
          error={errors.td}
          onChange={handleChange}
          autoFocus
        />
        <Texto
          name="nd"
          value={operacion.nd}
          label="N&uacute;mero documento (opcional)"
          size={2}
          tabIndex={11}
          error={errors.nd}
          onChange={handleChange}
        />
        <Texto
          name="rz"
          value={operacion.rz}
          label="Raz&oacute;n social (opcional)"
          size={2}
          tabIndex={12}
          error={errors.rz}
          onChange={handleChange}
        />
        <Texto
          name="rs"
          value={operacion.rs}
          label="Responsable (opcional)"
          size={2}
          tabIndex={13}
          error={errors.rs}
          onChange={handleChange}
        />
        <Texto
          name="co"
          value={operacion.co}
          label="Concepto"
          size={2}
          tabIndex={14}
          error={errors.co}
          onChange={handleChange}
        />
        <Combo
          name="tc"
          label={`Tipo de caja`}
          value={operacion.tc}
          tabIndex={15}
          size={2}
          data={tipoCajas.map((i) => ({ value: i.dt, label: i.de }))}
          error={errors.tc}
          onChange={handleChange}
        />
        <Texto
          name="mo"
          value={operacion.mo}
          label={`Monto`}
          size={2}
          tabIndex={16}
          error={errors.mo}
          restrict={'money'}
          onChange={handleChange}
          onBlur={(e) => {
            handleChange({
              name: 'mo',
              value: formatoMonetario(redondeoDecimales(e.target.value)),
            });
          }}
        />
        <div className="op-form-group op-col-2 op-checkbox-group">
          <RadioButton
            name="tm"
            data={[
              {
                name: 'so',
                label: 'Soles',
                checked: moneda,
              },
              {
                name: 'do',
                label: 'Dolares',
                checked: !moneda,
              },
            ]}
            onChange={(item) => {
              setMoneda(item.value === 'so');
              handleChange({
                name: 'tm',
                value: item.value === 'so' ? '0' : '1',
              });
            }}
          />
        </div>
        <Texto
          name="ia"
          value={operacion.ia}
          label="Informaci&oacute;n adicional (opcional)"
          size={2}
          tabIndex={17}
          error={errors.ia}
          onChange={handleChange}
        />
        <div className="op-form-group op-col-2 op-checkbox-group">
          <RadioButton
            name="is"
            data={[
              {
                name: 'in',
                label: 'Ingreso',
                checked: arrow,
                icon: () => <TrendingUpIcon style={{ color: 'green' }} />,
              },
              {
                name: 'out',
                label: 'Egreso',
                checked: !arrow,
                icon: () => <TrendingDownIcon style={{ color: 'red' }} />,
              },
            ]}
            onChange={(item) => {
              setArrow(item.value === 'in');
              handleChange({
                name: 'is',
                value: item.value === 'in' ? '1' : '0',
              });
            }}
          />
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.chicaOperacion.selected,
    processing: state.chicaOperacion.processing,
    errors: state.chicaOperacion.errors,
    tipoCajas: state.detalle.tipoCajas,
    tipoDocumentos: state.detalle.tipoDocumentos,
  };
};

export default connect(mapStateToProps)(ChicaOperacionItem);
