import { useState } from 'react';
import { useSelector } from 'react-redux';
import { api } from '../redux/axios/api';
import { servicios } from '../redux/helpers';

const useMantenimiento = () => {
  const { currentUser } = useSelector((state) => state.app);
  const [processingMaintenance, setProcessingMaintenance] = useState(false);
  const [maintenance, setMaintenance] = useState([]);

  const getPreviusMaintenance = (so) => {
    setProcessingMaintenance(true);
    api
      .get(`${servicios.MANTENIMIENTO}/${so}/previos`, {
        params: { em: currentUser.ie },
      })
      .then((response) => setMaintenance(response))
      .catch((error) => console.log(error.data))
      .finally(() => setProcessingMaintenance(false));
  };

  return {
    processingMaintenance,
    maintenance,
    getPreviusMaintenance,
  };
};

export default useMantenimiento;
