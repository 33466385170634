import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Combo from '../../commons/Combo';
import Tabla from '../../commons/Tabla';
import Boton from '../../commons/Boton';
import Adjunto from '../../commons/Adjunto';
import AdjuntoList from '../../commons/AdjuntoList';
import Icon from '../../commons/Icon';
import { confirmacion, mensajeMini } from '../../commons/Mensajes';
import { formatoMonetario, redondeoDecimales } from '../../commons/Utilitarios';

import ServicioList from '../servicio/ServicioList';
import PedidoPagar from './PedidoPagar';

import {
  closeModal,
  patchAdjuntarImagenPedidoAction,
  patchRecepcionarPedidoAction,
  patchCancelarPedidoAction,
  patchListoPedidoAction,
} from '../../redux/actions/pedido.action';

import { getServiciosAction } from '../../redux/actions/servicio.action';

import useCancelacion from '../../hooks/useCancelacion';

import SaveIcon from '@material-ui/icons/Save';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import MapIcon from '@material-ui/icons/Map';
import CancelFile from '../../assets/icons/file-cancel.svg';

const PedidoItem = () => {
  const dispatch = useDispatch();
  const { selected, processing } = useSelector((state) => state.pedido);
  const perfil = useSelector((state) => state.app.currentUser.nr);
  const { mm, md } = useSelector((state) => state.app.datos);
  const { cancelations, processingCancelation } = useCancelacion();
  const [pedido, setPedido] = useState(selected);
  const [detalle, setDetalle] = useState(selected.det);
  const [openServicio, setOpenServicio] = useState(false);
  const [openPagar, setOpenPagar] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [reason, setReason] = useState({ co: 0, dc: '' });
  const handleChange = (item) => {
    const { name, value } = item;
    setPedido((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const getCategoria = (cc) => {
    switch (cc) {
      case 'LE':
        return 'Lavado premium';
      case 'LS':
        return 'Lavado al seco';
      case 'PL':
        return 'Planchado';
      default:
        return 'Lavado';
    }
  };

  const columns = [
    {
      key: 'co',
      align: 'center',
      content: (item) => (
        <div
          title={`${item.st === true ? 'Quitar' : 'Agregar'}`}
          style={{ display: 'inline-block' }}
        >
          {selected.me === 'NUEPED' || selected.me === 'RECPED' ? (
            item.st === true ? (
              <CheckIcon
                onClick={() =>
                  confirmacion(
                    '¿Está seguro?',
                    `De quitar ${item.ns} del pedido`
                  )
                    .then(() => handleTacharMaterial(item.sv, false))
                    .catch(() => {})
                }
                style={{ color: '#1ab394', cursor: 'pointer' }}
              />
            ) : (
              <CloseIcon
                onClick={() =>
                  confirmacion(
                    '¿Está seguro?',
                    `De volver agregar ${item.ns} al pedido`
                  )
                    .then(() => handleTacharMaterial(item.sv, true))
                    .catch(() => {})
                }
                style={{ color: 'red', cursor: 'pointer' }}
              />
            )
          ) : (
            <></>
          )}
        </div>
      ),
    },
    {
      key: 'ns',
      label: 'Prenda',
      content: (item) => (
        <span
          style={{
            color: `${item.st === true ? 'black' : 'red'}`,
            textDecoration: `${item.st === true ? 'none' : 'line-through'}`,
          }}
        >
          {item.ns}
        </span>
      ),
    },
    {
      key: 'cc',
      label: 'Categoria',
      content: (item) => (
        <span
          style={{
            color: `${item.st === true ? 'black' : 'red'}`,
            textDecoration: `${item.st === true ? 'none' : 'line-through'}`,
          }}
        >
          {getCategoria(item.cc)}
        </span>
      ),
    },
    {
      key: 'ps',
      label: 'Precio',
      align: 'right',
      content: (item) => (
        <div className="op-centrar-componentes">
          {formatoMonetario(redondeoDecimales(item.ps))}
        </div>
      ),
    },
    {
      key: 'ds',
      label: 'Descuento',
      align: 'right',
      content: (item) => (
        <div className="op-centrar-componentes">
          {formatoMonetario(redondeoDecimales(item.ds))}
        </div>
      ),
    },
    {
      key: 'ca',
      label: 'Cantidad',
      align: 'right',
      content: (item) => (
        <div className="op-centrar-componentes">
          {selected.me === 'NUEPED' || selected.me === 'RECPED' ? (
            <>
              <AddIcon
                style={{ color: 'green', cursor: 'pointer' }}
                onClick={() => handleCantidadMaterial(item.sv, 1)}
              />
              <div className={`cantidad${item.edit ? ' edit' : ''}`}>
                <span
                  style={{ color: `${item.st === true ? 'black' : 'red'}` }}
                  onClick={(e) =>
                    handleHabilitarEdicion(item.sv, !item.edit, 'edit', e)
                  }
                >
                  {item.ca}
                </span>
                <input
                  type="text"
                  value={item.ct}
                  onChange={(e) =>
                    handleEditarValor(item.mt, e.target.value, 'ca')
                  }
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleHabilitarEdicion(item.sv, !item.edit, 'edit', e);
                    } else {
                      let re = new RegExp('[0-9]');
                      if (!re.test(e.key)) {
                        e.preventDefault();
                      }
                    }
                  }}
                  onPaste={(e) => e.preventDefault()}
                  onFocus={(e) => e.target.select()}
                />
                <div className="edicion">
                  <EditIcon
                    style={{ fontSize: '15px' }}
                    onClick={(e) =>
                      handleHabilitarEdicion(item.sv, !item.edit, 'edit', e)
                    }
                  />
                </div>
              </div>
              <RemoveIcon
                style={{ color: 'red', cursor: 'pointer' }}
                onClick={() => handleCantidadMaterial(item.sv, -1)}
              />
            </>
          ) : (
            <span style={{ color: `${item.st === true ? 'black' : 'red'}` }}>
              {item.ca}
            </span>
          )}
        </div>
      ),
    },
    {
      key: 'su',
      label: 'SubTotales',
      align: 'right',
      content: (item) => (
        <span
          style={{
            color: `${item.st === true ? 'black' : 'red'}`,
            textDecoration: `${item.st === true ? 'none' : 'line-through'}`,
          }}
        >
          {`${formatoMonetario(
            redondeoDecimales(parseFloat(item.ps - item.ds) * parseInt(item.ca))
          )}`}
        </span>
      ),
    },
  ];

  const columnsStatus = [
    {
      key: 'co',
      align: 'center',
    },
    {
      key: 'de',
      label: 'Estado',
    },
    {
      key: 'rc',
      label: 'Responsable',
    },
    {
      key: 'fc',
      label: 'Fecha',
    },
  ];

  const handleAgregarServicio = (item) => {
    let existe = detalle.find((x) => x.sv === item.sv);
    if (existe === undefined) {
      item.ca = 1;
      item.sv = item.sv;
      item.edit = false;
      setDetalle([...detalle, item]);
    }
  };

  const handleTacharMaterial = (sv, valor) => {
    setDetalle(
      detalle.map((x) =>
        x.sv === sv
          ? {
              ...x,
              st: valor,
            }
          : x
      )
    );
  };

  const handleCantidadMaterial = (sv, valor) => {
    setDetalle(
      detalle.map((x) =>
        x.sv === sv
          ? {
              ...x,
              ca:
                valor === -1 && x.ca <= 1
                  ? 1
                  : parseInt(x.ca ? x.ca : 1) + valor,
            }
          : x
      )
    );
  };

  const handleHabilitarEdicion = (sv, valor, name, e) => {
    setDetalle(detalle.map((x) => (x.sv === sv ? { ...x, [name]: valor } : x)));
    let caja = e.target
      .closest('.op-centrar-componentes')
      .querySelector('input');
    let label = e.target
      .closest('.op-centrar-componentes')
      .querySelector('span');
    caja.style.width = `${label.offsetWidth}px`;
    caja.focus();
  };

  const handleEditarValor = (sv, valor, name) => {
    setDetalle(
      detalle.map((x) =>
        x.sv === sv
          ? {
              ...x,
              [name]: valor < 0 ? 0 : valor,
            }
          : x
      )
    );
  };

  useEffect(() => {
    let total = detalle
      .filter((f) => f.st)
      .reduce((a, b) => a + (b.ps - b.ds) * b.ca, 0);

    let delivery = total < mm ? md : 0;

    handleChange({
      name: 'md',
      value: delivery,
    });

    handleChange({
      name: 'mp',
      value: total,
    });
  }, [detalle]);

  return (
    <>
      <Modal
        title={`Pedido ${pedido.np}`}
        processing={processing}
        onActions={() => (
          <>
            {perfil !== 'Delivery' && (
              <>
                {(selected.me === 'NUEPED' || selected.me === 'RECPED') && (
                  <Boton
                    className="op-dinamic"
                    icon={() => <AddIcon />}
                    tabIndex={26}
                    style={{ '--button-color': '#3285a8' }}
                    onClick={() => {
                      dispatch(getServiciosAction({}));
                      setOpenServicio(true);
                    }}
                  >
                    Agregar servicio
                  </Boton>
                )}
                {(selected.me === 'NUEPED' ||
                  selected.me === 'RECPED' ||
                  selected.me === 'LAVPED') && (
                  <Boton
                    className="op-dinamic"
                    icon={() => <Icon svg={CancelFile} color="red" />}
                    disabled={processing}
                    style={{ '--button-color': 'red' }}
                    onClick={() => setOpenCancel(true)}
                  >
                    Cancelar pedido
                  </Boton>
                )}
                {selected.me === 'LAVPED' && (
                  <Boton
                    className="op-grabar"
                    disabled={processing}
                    icon={() => <SaveIcon />}
                    onClick={() =>
                      confirmacion(
                        '¿Está seguro?',
                        `Pedido está listo para entrega`
                      )
                        .then(() => dispatch(patchListoPedidoAction(selected)))
                        .catch(() => {})
                    }
                  >
                    Listo para entrega
                  </Boton>
                )}
              </>
            )}
            {selected.me === 'LISPED' && (
              <Boton
                className="op-grabar"
                disabled={processing}
                icon={() => <SaveIcon />}
                onClick={() =>
                  confirmacion(
                    '¿Está seguro?',
                    `Pedido está listo para entrega`
                  )
                    .then(() => dispatch(patchListoPedidoAction(selected)))
                    .catch(() => {})
                }
              >
                Recoger para entrega
              </Boton>
            )}
            {perfil !== 'Delivery' && (
              <>
                {(selected.me === 'NUEPED' || selected.me === 'RECPED') && (
                  <Boton
                    className="op-grabar"
                    disabled={processing}
                    icon={() => <SaveIcon />}
                    onClick={() =>
                      confirmacion('¿Está seguro?', `De recepcionar el pedido`)
                        .then(() =>
                          dispatch(
                            patchRecepcionarPedidoAction(pedido, detalle)
                          )
                        )
                        .catch(() => {})
                    }
                  >
                    Recepcionar pedido
                  </Boton>
                )}
                {selected.me === 'DELPED' && selected.fg === null && (
                  <Boton
                    className="op-grabar"
                    disabled={processing}
                    icon={() => <SaveIcon />}
                    onClick={() => setOpenPagar(true)}
                  >
                    Confirmar pago
                  </Boton>
                )}
              </>
            )}
          </>
        )}
        onClose={() => dispatch(closeModal())}
      >
        <Texto
          name="av"
          value={selected.av}
          label="Empresa"
          size={3}
          tabIndex={10}
          disabled
          onChange={() => {}}
        />
        <Texto
          name="ns"
          value={selected.ns}
          label="Sucursal"
          size={3}
          tabIndex={11}
          disabled
          onChange={() => {}}
        />
        <Texto
          name="nc"
          value={selected.nc}
          label="Cliente"
          size={3}
          tabIndex={12}
          disabled
          onChange={() => {}}
        />
        <Texto
          name="fp"
          value={selected.fp}
          label="Fecha pedido"
          size={3}
          tabIndex={13}
          disabled
          onChange={() => {}}
        />
        {selected.rr === 0 && (
          <Texto
            name="fe"
            value={selected.fe}
            label="Fecha entrega"
            size={3}
            tabIndex={14}
            disabled
            onChange={() => {}}
          />
        )}
        <Texto
          name="de"
          value={selected.de}
          label="Estado del pedido"
          size={3}
          tabIndex={15}
          disabled
          onChange={() => {}}
        />
        <Texto
          name="fa"
          value={
            selected.fa === 0 ? 'Efectivo' : selected.fa === 1 ? 'Yape' : 'Plin'
          }
          label="Forma de pago"
          size={3}
          tabIndex={16}
          disabled
          onChange={() => {}}
        />
        <Texto
          name="dp"
          value={selected.dp}
          label="Direcci&oacute;n del pedido"
          size={3}
          tabIndex={17}
          disabled
          onChange={() => {}}
        />
        {selected.rr === 0 && (
          <>
            <Texto
              name="rf"
              value={selected.rf}
              label="Referencia de direcci&oacute;n"
              size={3}
              tabIndex={18}
              disabled
              onChange={() => {}}
            />
            <Texto
              name="nt"
              value={selected.nt}
              label="Tel&eacute;fono"
              size={3}
              tabIndex={19}
              disabled
              onChange={() => {}}
            />
          </>
        )}
        {selected.me === 'CANPED' && (
          <Texto
            name="mc"
            value={selected.mc}
            label={`Cancelado - Por ${
              selected.tc ? 'cliente' : 'la lavandería'
            }`}
            size={3}
            tabIndex={20}
            disabled
            onChange={() => {}}
          />
        )}
        {selected.fg && (
          <>
            <Texto
              name="fg"
              value={selected.fg}
              label="Fecha de pago"
              size={3}
              tabIndex={21}
              disabled
              onChange={() => {}}
            />
            <Texto
              name="no"
              value={selected.no}
              label="N&uacute;mero documento"
              size={3}
              tabIndex={22}
              disabled
              onChange={() => {}}
            />
          </>
        )}
        {selected.me === 'NUEPED' && selected.rr === 0 && (
          <div className="op-form-group op-col-2">
            <a
              href={`https://maps.google.com/?q=${selected.la},${selected.lo}&z=25`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Ubicaci&oacute;n en el mapa
            </a>
            <MapIcon />
          </div>
        )}
        <Texto
          name="ob"
          value={pedido.ob}
          label="Observaciones del pedido"
          tabIndex={13}
          disabled={!(selected.me === 'NUEPED' || selected.me === 'RECPED')}
          onChange={handleChange}
          info="Puede agregar imagenes para tener m&aacute;s detalle de la observaci&oacute;n seleccionando el boton nuevo archivo"
        />
        <div className="op-form-group op-col-1">
          <Tabla
            columns={columns}
            data={detalle}
            pagination={false}
            searcheable={false}
            hoverSelectedRow={false}
            style={{ padding: '0px' }}
          />
          <div className="op-totales">
            <label>Prendas</label>
            <span>{`S/${formatoMonetario(redondeoDecimales(pedido.mp))}`}</span>
            <label>Delivery</label>
            <span>{`S/${formatoMonetario(redondeoDecimales(pedido.md))}`}</span>
            {pedido.ms != 0 && (
              <>
                <label>{`Promoción ${pedido.pp}`}</label>
                <span className="op-error">{`S/-${formatoMonetario(
                  redondeoDecimales(pedido.ms)
                )}`}</span>
              </>
            )}
            <label>
              <strong>Importe Total</strong>
            </label>
            <span>
              <strong>{`S/${formatoMonetario(
                redondeoDecimales(pedido.mp + pedido.md - pedido.ms)
              )}`}</strong>
            </span>
          </div>
        </div>
        <div className="op-form-group op-col-1">
          <Tabla
            columns={columnsStatus}
            data={selected.sts.sort((a, b) => a.co - b.co)}
            pagination={false}
            searcheable={false}
            hoverSelectedRow={false}
            style={{ padding: '0px' }}
          />
        </div>
        {selected.me === 'NUEPED' ||
        selected.me === 'RECPED' ||
        selected.me === 'LAVPED' ||
        selected.me === 'DELPED' ? (
          <Adjunto
            stateParent={pedido.st}
            extensions={['jpg', 'jpeg', 'png']}
            nameParent={'pedido'}
            processing={processing}
            data={
              pedido.adj &&
              pedido.adj.dtl &&
              pedido.adj.dtl.map((a) => ({
                id: a.cr,
                name: a.na,
                extension: a.ea,
                state: a.st,
              }))
            }
            file={pedido.adj && pedido.adj.ar}
            onNew={(documento) =>
              dispatch(patchAdjuntarImagenPedidoAction(pedido, documento))
            }
          />
        ) : (
          <AdjuntoList
            data={
              pedido.adj &&
              pedido.adj.dtl &&
              pedido.adj.dtl.map((a) => ({
                id: a.cr,
                name: a.na,
                extension: a.ea,
                state: a.st,
              }))
            }
            file={pedido.adj && pedido.adj.ar}
          />
        )}
      </Modal>
      {openServicio && (
        <ServicioList
          searchable
          onClose={() => setOpenServicio(false)}
          onSend={(item) => handleAgregarServicio(item)}
        />
      )}
      {openPagar && <PedidoPagar onClose={() => setOpenPagar(false)} />}
      {openCancel && (
        <Modal
          title={`Cancelar pedido ${selected.np}`}
          size="small"
          onActions={() => (
            <Boton
              className="op-dinamic"
              icon={() => <Icon svg={CancelFile} color="red" />}
              disabled={processing}
              style={{ '--button-color': 'red' }}
              onClick={() => {
                if (reason.dc) {
                  confirmacion(
                    '¿Está seguro?',
                    `De cancelar el pedido ${selected.np}`
                  )
                    .then(() =>
                      dispatch(patchCancelarPedidoAction(pedido, reason.dc))
                    )
                    .catch(() => {});
                } else {
                  mensajeMini('Seleccione motivo de cancelación');
                }
              }}
            >
              Cancelar pedido
            </Boton>
          )}
          closeWithoutConfirmation
          onClose={() => setOpenCancel(false)}
        >
          <Combo
            name="res"
            label="Motivo"
            value={reason.co}
            placeholder="Seleccione motivo de cancelaci&oacute;n"
            tabIndex={50}
            disabled={processingCancelation}
            data={cancelations.map((i) => ({ value: i.co, label: i.dc }))}
            onChange={({ value, label }) => setReason({ co: value, dc: label })}
          />
        </Modal>
      )}
    </>
  );
};

export default PedidoItem;
