import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';

import Boton from '../../commons/Boton';
import Bloque from '../../commons/Bloque';
import Combo from '../../commons/Combo';
import CheckBox from '../../commons/CheckBox';
import Texto from '../../commons/Texto';

import { useFormularioContext } from '../../commons/Formulario';
import { confirmacion } from '../../commons/Mensajes';

import useMaestro from '../../hooks/useMaestro';

import { servicios } from '../../redux/helpers';
import { solicitudModel } from '../../redux/models/solicitud.model';

const SolicitudMaintenance = () => {
  const { selected, errors, accionTask, accionName, callTransaction } =
    useFormularioContext();
  const { currentUser } = useSelector((state) => state.app);
  const { getDetails, maestros } = useMaestro();

  const [solicitud, setSolicitud] = useState(selected);
  const [equipments, setEquipments] = useState([]);
  const [results, setResults] = useState([]);

  useEffect(() => {
    getDetails('TIPOMANTENIMIENTO');
  }, []);

  useEffect(() => {
    setSolicitud(selected);
    if (selected.qq) {
      setEquipments(selected.qq);
      setResults(
        selected.qq.map((x) => ({ eq: x.eq.eq, tm: null, dt: null, sr: false }))
      );
    }
  }, [selected.so]);

  const handleChange = (e, v, n) => {
    setResults(results.map((x) => (x.eq === e ? { ...x, [n]: v } : x)));
  };

  const handleSave = () => {
    let request = {
      so: solicitud.so,
      ie: currentUser.ie,
      ua: currentUser.us,
      ia: accionTask,
      dt: results,
    };
    callTransaction({
      method: 'post',
      url: `${servicios.MANTENIMIENTO}`,
      data: request,
      model: solicitudModel,
    });
  };

  return (
    <div className="detail-equipments">
      <div className="container">
        <Boton
          className="op-grabar"
          icon={() => <SaveIcon />}
          onClick={() =>
            confirmacion(
              '¿Está seguro?',
              `De ${accionName.toLowerCase()} , recuerde que ya no podra volver a enviar esta información`
            )
              .then(() => handleSave())
              .catch(() => {})
          }
        >
          {accionName}
        </Boton>
        {errors && <span className="op-error">{Object.values(errors)[0]}</span>}
      </div>
      {equipments.map((e) => (
        <Bloque
          key={e.eq.eq}
          titulo={`${e.eq.ce} - ${e.eq.ne}`}
          hideArrowButton
        >
          <div className="op-form">
            <Combo
              label="Tipo Mantenimiento"
              name="tipo"
              size={2}
              tabIndex={10}
              value={results.find((x) => x.eq === e.eq.eq).tm}
              data={maestros.map((x) => ({ value: x.dt, label: x.de }))}
              onChange={({ value }) => handleChange(e.eq.eq, value, 'tm')}
            />
            <div className="op-form-group op-col-2 op-checkbox-group">
              <span>¿Es reparaci&oacute;n?</span>
              <CheckBox
                name="repara"
                tabIndex={11}
                checked={results.find((x) => x.eq === e.eq.eq).sr}
                onChange={({ value }) => handleChange(e.eq.eq, value, 'sr')}
              />
            </div>
            <Texto
              label="Mantenimiento"
              name={'manto'}
              tabIndex={12}
              value={results.find((x) => x.eq === e.eq.eq).dt}
              onChange={({ value }) => handleChange(e.eq.eq, value, 'dt')}
            />
          </div>
        </Bloque>
      ))}
    </div>
  );
};

export default SolicitudMaintenance;
