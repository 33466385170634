import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import BuildIcon from '@material-ui/icons/Build';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save';

import Boton from '../../commons/Boton';
import Combo from '../../commons/Combo';
import Modal from '../../commons/Modal';
import Tabla from '../../commons/Tabla';
import Texto from '../../commons/Texto';
import { useFormularioContext } from '../../commons/Formulario';
import Bloque from '../../commons/Bloque';
import { confirmacion, mensajeMini } from '../../commons/Mensajes';

import { solicitudModel } from '../../redux/models/solicitud.model';
import { servicios } from '../../redux/helpers';

const SolicitudSupplyResult = ({ previus }) => {
  const { selected, errors, accionTask, callTransaction } =
    useFormularioContext();
  const { currentUser } = useSelector((state) => state.app);
  const [supplies, setSupplies] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [showEquipment, setShowEquipment] = useState(false);
  const [equipment, setEquipment] = useState(null);
  const [item, setItem] = useState(null);
  const [amount, setAmount] = useState(0);
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (selected.nn) {
      setSupplies(
        selected.nn
          .filter((f) => f.st === true)
          .map((m, i) => {
            let prev = 0;
            previus
              .filter((x) => x.in.length > 0)
              .forEach((y) => {
                prev = y.in
                  .filter((x) => x.mt.cm === m.mt.cm)
                  .reduce((p, n) => p + n.ct, 0);
              });
            return {
              ...m.mt,
              id: i,
              um: m.mt.du,
              un: m.ia - prev,
              iu: m.iu,
              in: m.in,
            };
          })
      );
      setResults([]);
    }
    if (selected.qq) {
      setEquipments(selected.qq);
    }
  }, [selected.so, previus]);

  const columnsSupplies = [
    {
      key: 'id',
      content: (item) => (
        <CheckIcon
          onClick={() => {
            setShowEquipment(true);
            setItem(item);
            setAmount(item.un);
          }}
          style={{ color: 'green', cursor: 'pointer' }}
        />
      ),
    },
    {
      key: 'nm',
      label: 'Material / Producto',
    },
    {
      key: 'um',
      label: 'Unidad medida',
    },
    {
      key: 'un',
      align: 'center',
      label: 'Asignados',
    },
  ];

  const handleEditAmount = () => {
    let mat = supplies.find((x) => x.cm === item.cm);
    if (mat.un < amount || mat.un <= 0) {
      mensajeMini('Insumos insuficientes');
      return;
    }
    setSupplies(
      supplies.map((m) => (m.cm === item.cm ? { ...m, un: m.un - amount } : m))
    );
    setResults([
      ...results,
      { eq: equipment, mt: item.mt, cm: item.cm, nm: item.nm, ct: amount },
    ]);
    handleClose();
  };

  const handleClose = () => {
    setShowEquipment(false);
    setEquipment(null);
    setItem(null);
    setAmount(0);
  };

  const handleSaveResult = (eq) => {
    let request = {
      ie: currentUser.ie,
      ua: currentUser.us,
      eq: eq,
      ia: accionTask,
      so: selected.so,
      li: results.filter((f) => f.eq === eq),
    };
    callTransaction({
      method: 'post',
      url: `${servicios.MANTENIMIENTO}/insumos`,
      data: request,
      model: solicitudModel,
      successfulAction: { type: 'SUCCESS_TASK' },
    });
  };

  return (
    <>
      <div className="detail-equipments">
        {errors && (
          <>
            <span className="op-error">{errors.li}</span>
            <br />
          </>
        )}
        <span>Insumos asignados</span>
        <Tabla
          columns={columnsSupplies}
          data={supplies}
          searcheable={false}
          pagination={false}
          hoverSelectedRow={false}
          emptyRowsMessage="Ning&uacute;n material asignado"
          style={{ padding: 0 }}
        />
        <br />
        {equipments.map((e) => (
          <Bloque
            key={e.eq.eq}
            titulo={`${e.eq.ce} - ${e.eq.ne}`}
            hideArrowButton
            onActions={() => (
              <>
                {previus
                  .filter((x) => x.in.length > 0)
                  .find((x) => x.eq.ce === e.eq.ce) === undefined && (
                  <Boton
                    className="op-grabar"
                    icon={() => <SaveIcon />}
                    onClick={() =>
                      confirmacion(
                        '¿Está seguro?',
                        `De registrar los insumos de ${e.eq.ne}, recuerde que ya no podra volver a enviar esta información`
                      )
                        .then(() => handleSaveResult(e.eq.eq))
                        .catch(() => {})
                    }
                  >
                    Registrar insumos
                  </Boton>
                )}
              </>
            )}
          >
            <div className="op-detail-contenedor">
              {results
                .filter((f) => f.eq === e.eq.eq)
                .map((m) => (
                  <div key={m.mt} className="op-detail-result">
                    <span>{m.nm}</span>
                    <span>{m.ct}</span>
                  </div>
                ))}
              {previus
                .filter((f) => f.eq.ce === e.eq.ce)
                .filter((x) => x.in.length > 0)
                .map((m) =>
                  m.in.map((m) => (
                    <div key={m.mt.cm} className="op-detail-result">
                      <span>{m.mt.nm}</span>
                      <span style={{ color: '#0cb018' }}>{m.ct}</span>
                    </div>
                  ))
                )}
            </div>
          </Bloque>
        ))}
      </div>
      {showEquipment && (
        <Modal
          title="Seleccione equipo"
          size={'small'}
          onActions={() => (
            <Boton
              className={'op-dinamic'}
              icon={() => <BuildIcon />}
              style={{ '--button-color': '#599dc7' }}
              onClick={handleEditAmount}
            >
              Agregar insumos
            </Boton>
          )}
          closeWithoutConfirmation
          onClose={handleClose}
        >
          <Combo
            label="Equipo"
            value={equipment}
            name="equipment"
            data={equipments.map((x) => ({ value: x.eq.eq, label: x.eq.ne }))}
            onChange={({ value }) => setEquipment(value)}
          />
          <Texto
            label="Cantidad"
            name={'cantidad'}
            value={amount}
            restrict="int"
            onChange={({ value }) => setAmount(value)}
          />
        </Modal>
      )}
    </>
  );
};

export default SolicitudSupplyResult;
