import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';

import Boton from '../../commons/Boton';
import Icon from '../../commons/Icon';
import { confirmacion } from '../../commons/Mensajes';
import { useFormularioContext } from '../../commons/Formulario';

import TestIcon from '../../assets/icons/test-tube.svg';
import Busqueda from '../../commons/Busqueda';
import { servicios, columnsTestings } from '../../redux/helpers';
import { solicitudModel } from '../../redux/models/solicitud.model';

const SolicitudTesting = () => {
  const { selected, processing, equipment, callTransaction } =
    useFormularioContext();
  const { currentUser } = useSelector((state) => state.app);
  const [solicitud, setSolicitud] = useState(selected);
  const [testings, setTestings] = useState([]);
  const [showTestings, setShowTestings] = useState(false);

  useEffect(() => {
    setSolicitud(selected);
    setTestings(equipment.ts ? equipment.ts : []);
  }, [selected.so]);

  const handleAddTestings = (item) => {
    let exist = testings.find((x) => x.ct === item.ct);
    if (exist === undefined) {
      setTestings([...testings, item]);
    }
    setShowTestings(false);
  };

  const handleDeleteTesting = (ct) => {
    setTestings(testings.filter((x) => x.ct !== ct));
  };

  const handleSaveTestings = () => {
    let request = {
      so: solicitud.so,
      ie: currentUser.ie,
      eq: equipment.eq.eq,
      pc: equipment.pc?.map((p) => p.pr),
      ts: testings.map((t) => t.te),
      mn: equipment.mn?.map((m) => m.mn),
    };
    callTransaction({
      method: 'patch',
      url: `${servicios.SOLICITUD}/equipodetalle`,
      data: request,
      model: solicitudModel,
    });
  };

  return (
    <div className="detail-equipments">
      <h3>{`Testeos - ${equipment.eq?.ne}`}</h3>
      <div className="container">
        {testings?.map((m) => (
          <div key={m.te} className="equipment">
            <Icon svg={TestIcon} size={30} transparent />
            <span>{m.ct}</span>
            <span>{m.nt}</span>
            {solicitud.ep !== 2 && currentUser.tr === 'NOBASICO' && (
              <div className="close">
                <CancelIcon
                  color="secondary"
                  onClick={() => handleDeleteTesting(m.ct)}
                />
              </div>
            )}
          </div>
        ))}
        {solicitud.ep !== 2 && currentUser.tr === 'NOBASICO' && (
          <>
            <Boton
              className="op-dinamic"
              disabled={processing}
              icon={() => <AddIcon />}
              tabIndex={18}
              style={{ '--button-color': '#3285a8' }}
              onClick={() => setShowTestings(true)}
            >
              Agregar Testeo
            </Boton>
            <Boton
              className="op-grabar"
              disabled={processing}
              icon={() => <SaveIcon />}
              tabIndex={19}
              onClick={() =>
                confirmacion(
                  '¿Está seguro?',
                  `De registrar los testeos al equipo ${equipment.eq.ne}`
                )
                  .then(() => handleSaveTestings())
                  .catch(() => {})
              }
            >
              Guardar Testeos
            </Boton>
          </>
        )}
      </div>
      {showTestings && (
        <Busqueda
          serviceName={servicios.TESTEO}
          title="B&uacute;squeda de testeos"
          columns={columnsTestings((item) => handleAddTestings(item))}
          emptyRowsMessage="No existen testeos"
          onClose={() => setShowTestings(false)}
          onDoubleClick={(item) => handleAddTestings(item)}
        />
      )}
    </div>
  );
};

export default SolicitudTesting;
