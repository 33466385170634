import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Combo from '../../commons/Combo';
import Boton from '../../commons/Boton';
import Tooltip from '../../commons/Tooltip';
import CheckBox from '../../commons/CheckBox';
import RadioButton from '../../commons/RadioButton';
import { confirmacion } from '../../commons/Mensajes';
import { formatoMonetario, redondeoDecimales } from '../../commons/Utilitarios';

import {
  closeModal,
  postMaterialAction,
} from '../../redux/actions/material.action';

import SaveIcon from '@material-ui/icons/Save';

const MaterialItem = ({
  selected,
  processing,
  errors,
  tipoUnidadMedida,
  closeModal,
  postMaterialAction,
}) => {
  const [material, setMaterial] = useState(selected);
  const [autogenerar, setAutogenerar] = useState(true);

  useEffect(() => {
    let searchTipo = tipoUnidadMedida.find((s) => s.de === selected.du);
    if (searchTipo) {
      handleChange({ name: 'um', value: searchTipo.dt });
    }
    handleChange({
      name: 'pc',
      value: formatoMonetario(redondeoDecimales(selected.pc)),
    });
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setMaterial((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === 'gc') {
      setAutogenerar(!autogenerar);
    }
  };

  return (
    <Modal
      title={`${material.mt ? 'Actualizar' : 'Nuevo'} producto y/o insumo`}
      processing={processing}
      onActions={() => (
        <Boton
          className="op-grabar"
          disabled={processing}
          icon={() => <SaveIcon />}
          tabIndex={21}
          onClick={() =>
            confirmacion(
              '¿Desea guardar?',
              `${material.mt ? 'Actualizar un' : 'Un nuevo'} ${
                material.pr ? 'producto' : 'insumo'
              }`
            )
              .then(() => postMaterialAction(material))
              .catch(() => {})
          }
        >
          Guardar
        </Boton>
      )}
      onClose={() => closeModal()}
    >
      <Texto
        name="cm"
        value={material.cm}
        label="C&oacute;digo"
        tabIndex={10}
        size={2}
        error={errors.cm}
        onChange={handleChange}
        disabled={autogenerar}
      />
      <Texto
        name="nm"
        value={material.nm}
        label="Nombre"
        tabIndex={11}
        size={2}
        error={errors.nm}
        onChange={handleChange}
        autoFocus
      />
      <Combo
        name="um"
        label="Unidad medida"
        value={material.um}
        tabIndex={12}
        size={2}
        data={tipoUnidadMedida.map((i) => ({ value: i.dt, label: i.de }))}
        error={errors.um}
        onChange={handleChange}
        searcheable
      />
      <Texto
        name="pc"
        value={material.pc}
        label="Precio de compra referencial"
        size={2}
        tabIndex={13}
        error={errors.pc}
        onChange={handleChange}
        restrict={'money'}
        onBlur={(e) => {
          handleChange({
            name: 'pc',
            value: formatoMonetario(redondeoDecimales(e.target.value)),
          });
        }}
      />
      <div className="op-form-group op-col-2 op-checkbox-group">
        <RadioButton
          name="pr"
          data={[
            {
              name: 'producto',
              label: 'Producto',
              checked: material.pr ? true : false,
            },
            {
              name: 'insumo',
              label: 'Insumo',
              checked: material.pr ? false : true,
            },
          ]}
          onChange={(item) => {
            handleChange({ name: 'pr', value: item.value === 'producto' });
          }}
        />
        <Tooltip>
          {
            'Los insumos son elementos ya procesados que ayudan a desarrollar un producto o servicio final.'
          }
        </Tooltip>
      </div>
      {material.mt ? (
        <div className="op-form-group op-col-2 op-checkbox-group">
          <span>Estado del registro</span>
          <CheckBox name="st" checked={material.st} onChange={handleChange} />
        </div>
      ) : (
        <div className="op-form-group op-col-2 op-checkbox-group">
          <span>¿Desea autogenerar el c&oacute;digo?</span>
          <CheckBox name="gc" checked={material.gc} onChange={handleChange} />
        </div>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.material.selected,
    processing: state.material.processing,
    errors: state.material.errors,
    tipoUnidadMedida: state.detalle.tipoUnidadMedida,
  };
};

export default connect(mapStateToProps, { closeModal, postMaterialAction })(
  MaterialItem
);
