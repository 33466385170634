import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Autenticador from './Autenticador';

const Layout = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.RECAPTCHA}
      scriptProps={{
        async: false,
        defer: false,
        appendTo: 'head',
        nonce: undefined,
      }}
    >
      <Autenticador />
    </GoogleReCaptchaProvider>
  );
};

export default Layout;
