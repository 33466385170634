import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { onMessage } from 'firebase/messaging';

import { userInactivoAction } from '../../redux/actions/app.action';
import { addNotificacion } from '../../redux/actions/pedido.action';

import { messaging } from '../../redux/helpers/firebase';

import Inicio from '../Inicio';
import Cambio from '../cambio/Cambio';
import Cabecera from '../cabecera/Cabecera';
import Detalle from '../detalle/Detalle';
import Almacen from '../almacen/Almacen';
import Material from '../material/Material';
import Proveedor from '../proveedor/Proveedor';
import OrdenCompra from '../ordencompra/OrdenCompra';
import Sucursal from '../sucursal/Sucursal';
import Inventario from '../inventario/Inventario';
import Cliente from '../cliente/Cliente';
import Venta from '../venta/Venta';
import VentaDellate from '../venta/VentaDellate';
import Operacion from '../operacion/Operacion';
import Servicio from '../servicio/Servicio';
import Caja from '../caja/Caja';
import Chica from '../chica/Chica';
import ChicaOperacion from '../chica/ChicaOperacion';
import Diagrama from '../diagrama/Diagrama';
import Maquina from '../maquina/Maquina';
import Cajero from '../cajero/Cajero';
import CajeroOperacionList from '../cajero/CajeroOperacionList';
import Adicional from '../adicional/Adicional';
import Plantilla from '../plantilla/Plantilla';
import Perfiles from '../perfiles/Perfiles';
import Contrato from '../contrato/Contrato';
import Cobranza from '../cobranza/Cobranza';
import Empresa from '../empresa/Empresa';
import Correlativo from '../correlativo/Correlativo';
import Guia from '../guia/Guia';
import Conductor from '../conductor/Conductor';
import PagoProveedores from '../pagoproveedores/PagoProveedores';
import Categoria from '../categoria/Categoria';
import Pedido from '../pedido/Pedido';
import PedidoMap from '../pedido/PedidoMap';
import RedSocial from '../redsocial/RedSocial';
import Testeo from '../../pages/testeo/Testeo';
import Procedimiento from '../../pages/procedimiento/Procedimiento';
import Manual from '../../pages/manual/Manual';
import Equipo from '../../pages/equipo/Equipo';
import Solicitud from '../../pages/solicitud/Solicitud';
import Mantenimiento from '../../pages/mantenimiento/Mantenimiento';
import Cotizacion from '../../pages/cotizacion/Cotizacion';

import { mensajeMini } from '../../commons/Mensajes';

import '../../css/body.css';
import '../../css/commons/tabla.css';

const Body = ({ headers, closeMenu, selected, perfil }) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.app);

  let list = headers.map((head) => {
    switch (head.mm) {
      case 'SEGT_CAMB':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <Cambio />
            </div>
          </Route>
        );
      case 'CON_CABE':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <Cabecera />
            </div>
          </Route>
        );
      case 'CON_DETA':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <Detalle />
            </div>
          </Route>
        );
      case 'MAN_ALMA':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <Almacen />
            </div>
          </Route>
        );
      case 'MAN_EMPR':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <Empresa />
            </div>
          </Route>
        );
      case 'SUM_MATE':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <Material />
            </div>
          </Route>
        );
      case 'SUM_PROV':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <Proveedor />
            </div>
          </Route>
        );
      case 'INV_ORCO':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <OrdenCompra />
            </div>
          </Route>
        );
      case 'MAN_SUCU':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <Sucursal />
            </div>
          </Route>
        );
      case 'INV_INV':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <Inventario />
            </div>
          </Route>
        );
      case 'COM_CLI':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <Cliente />
            </div>
          </Route>
        );
      case 'COM_DOCU':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <Venta />
            </div>
          </Route>
        );
      case 'COM_VEN_DETA':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <VentaDellate />
            </div>
          </Route>
        );
      case 'INV_OPER':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <Operacion />
            </div>
          </Route>
        );
      case 'SUM_SERV':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <Servicio />
            </div>
          </Route>
        );
      case 'SUM_SERV_DIAGRA':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <Diagrama />
            </div>
          </Route>
        );
      case 'BAN_CAGE':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <Caja />
            </div>
          </Route>
        );
      case 'BAN_CACH':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <Chica />
            </div>
          </Route>
        );
      case 'BAN_CACH_OPER':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <ChicaOperacion />
            </div>
          </Route>
        );
      case 'BAN_PTVE':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <Maquina />
            </div>
          </Route>
        );
      case 'BAN_CAJA':
        return (
          <div
            key={head.mm}
            className={`op-content-component op-view-cajero${
              selected && selected.mm === head.mm ? '' : ' op-close'
            }`}
          >
            {perfil === 'BASICO' ? <CajeroOperacionList /> : <Cajero />}
          </div>
        );
      case 'BAN_CAJA_OPER':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component op-view-cajero${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <CajeroOperacionList />
            </div>
          </Route>
        );
      case 'SUM_INSTLF':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <Adicional />
            </div>
          </Route>
        );
      case 'MAN_PLAN':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <Plantilla />
            </div>
          </Route>
        );
      case 'MAN_PEAC':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <Perfiles />
            </div>
          </Route>
        );
      case 'COM_CON':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <Contrato />
            </div>
          </Route>
        );
      case 'COM_CON_COBR':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <Cobranza />
            </div>
          </Route>
        );
      case 'MAN_CORR':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <Correlativo />
            </div>
          </Route>
        );
      case 'INV_GUIA':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <Guia />
            </div>
          </Route>
        );
      case 'INV_CONDU':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <Conductor />
            </div>
          </Route>
        );
      case 'INV_PAPO':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <PagoProveedores />
            </div>
          </Route>
        );
      case 'MAN_CATE':
        return (
          <Route key={head.mm} path="/">
            <div
              className={`op-content-component${
                selected && selected.mm === head.mm ? '' : ' op-close'
              }`}
            >
              <Categoria />
            </div>
          </Route>
        );
      case 'COM_PED':
        return (
          <div
            key={head.mm}
            className={`op-content-component${
              selected && selected.mm === head.mm ? '' : ' op-close'
            }`}
          >
            <Pedido />
          </div>
        );
      case 'COM_PED_MAP':
        return (
          <div
            key={head.mm}
            className={`op-content-component${
              selected && selected.mm === head.mm ? '' : ' op-close'
            }`}
          >
            <PedidoMap />
          </div>
        );
      case 'SEV_RDSO':
        return (
          <div
            key={head.mm}
            className={`op-content-component${
              selected && selected.mm === head.mm ? '' : ' op-close'
            }`}
          >
            <RedSocial />
          </div>
        );
      case 'MNT_TEST':
        return (
          <div
            key={head.mm}
            className={`op-content-component${
              selected && selected.mm === head.mm ? '' : ' op-close'
            }`}
          >
            <Testeo />
          </div>
        );
      case 'MNT_PROC':
        return (
          <div
            key={head.mm}
            className={`op-content-component${
              selected && selected.mm === head.mm ? '' : ' op-close'
            }`}
          >
            <Procedimiento />
          </div>
        );
      case 'MNT_MANU':
        return (
          <div
            key={head.mm}
            className={`op-content-component${
              selected && selected.mm === head.mm ? '' : ' op-close'
            }`}
          >
            <Manual />
          </div>
        );
      case 'MNT_EQUI':
        return (
          <div
            key={head.mm}
            className={`op-content-component${
              selected && selected.mm === head.mm ? '' : ' op-close'
            }`}
          >
            <Equipo />
          </div>
        );
      case 'MNT_SOLI':
        return (
          <div
            key={head.mm}
            className={`op-content-component${
              selected && selected.mm === head.mm ? '' : ' op-close'
            }`}
          >
            <Solicitud />
          </div>
        );
      case 'MNT_MNTO':
        return (
          <div
            key={head.mm}
            className={`op-content-component${
              selected && selected.mm === head.mm ? '' : ' op-close'
            }`}
          >
            <Mantenimiento />
          </div>
        );
      case 'MNT_COTI':
        return (
          <div
            key={head.mm}
            className={`op-content-component${
              selected && selected.mm === head.mm ? '' : ' op-close'
            }`}
          >
            <Cotizacion />
          </div>
        );
      default:
        return null;
    }
  });

  useEffect(() => {
    return () => {
      dispatch(userInactivoAction(currentUser.us));
    };
  }, []);

  onMessage(messaging, (payload) => {
    mensajeMini(payload.notification.title, 'success', 10000);
    dispatch(addNotificacion());
  });

  return (
    <div className={`op-content-body${closeMenu === true ? ' active' : ''}`}>
      <div className="op-content-component">
        <Route path="/" component={Inicio} />
      </div>
      {list}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    headers: state.app.headers,
    closeMenu: state.app.closeMenu,
    selected: state.app.selected,
    perfil: state.app.currentUser.tr,
  };
};

export default connect(mapStateToProps)(Body);
