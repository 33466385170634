import React, { useEffect, useState } from 'react';

import AdjuntoList from '../../commons/AdjuntoList';
import { useFormularioContext } from '../../commons/Formulario';

const SolicitudHandBookView = () => {
  const { selected } = useFormularioContext();
  const [handbooks, setHandbooks] = useState([]);

  useEffect(() => {
    if (selected.qq) {
      setHandbooks(selected.qq.filter((f) => f.mn != null));
    }
  }, [selected.so]);

  return (
    <div className="detail-equipments">
      {handbooks?.map((m) => (
        <React.Fragment key={m.eq.eq}>
          <h3>{`Manuales: ${m.eq.ce} - ${m.eq.ne}`}</h3>
          {m.mn.map((x) => (
            <React.Fragment key={x.mn}>
              <span>{x.nm}</span>
              <AdjuntoList
                data={x.dm.dtl.map((a) => ({
                  id: a.cr,
                  name: a.na,
                  extension: a.ea,
                  state: a.st,
                }))}
                file={x.dm.ar}
                hideToolBar
              />
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};

export default SolicitudHandBookView;
