import React from 'react';
import CryptoJS from 'crypto-js';

import PdfFile from '../assets/icons/pdf-file.svg';
import PngFile from '../assets/icons/png-file.svg';
import JpgFile from '../assets/icons/jpg-file.svg';
import ExcelFile from '../assets/icons/excel-file.svg';
import DocFile from '../assets/icons/docx-file.svg';

import '../../public/jsencrypt.min.js';
/**
 * Formatear date a string.
 * @param {date|number|string} fecha fecha a formatear
 * @param {string} texto respuesta si no hay fecha
 * @param {boolean} time mostrar tiempo (default = true)
 */
export const formatoFecha = (fecha, texto, time = true) => {
  if (fecha === null) return texto ? texto : 'Indefinido';
  if (fecha === '') return texto ? texto : 'Indefinido';
  var date = new Date(fecha);
  if (isNaN(date.getTime())) return texto ? texto : 'Indefinido';
  var day = date.getDate();
  var month = date.getMonth() + 1;
  var year = date.getFullYear();

  var hour = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();

  if (time) {
    return (
      (day < 10 ? '0' + day : day) +
      '/' +
      (month < 10 ? '0' + month : month) +
      '/' +
      year +
      ' ' +
      (hour < 10 ? '0' + hour : hour) +
      ':' +
      (minutes < 10 ? '0' + minutes : minutes) +
      ':' +
      (seconds < 10 ? '0' + seconds : seconds)
    );
  } else {
    return (
      (day < 10 ? '0' + day : day) +
      '/' +
      (month < 10 ? '0' + month : month) +
      '/' +
      year
    );
  }
};

/**
 * Compara si la fecha1 es menor o igual que le fecha2.
 * @param {number | string | date} fecha1
 * @param {number | string | date} fecha2
 */
export const compararFechas = (fecha1, fecha2) => {
  if (fecha1 === null && fecha2 === null) {
    return false;
  }
  if (fecha1 === null) {
    return false;
  }
  let date1 = new Date(fecha1);
  let date2 = fecha2 ? new Date(fecha2) : new Date();

  return +date1 <= +date2;
};

export const obtenerDiasTranscurridos = (fecha1, fecha2) => {
  if (fecha1 === null || fecha2 === null) {
    return 0;
  }
  let d1 = new Date(fecha1);
  d1.setHours(0, 0, 0, 0);
  let d2 = new Date(fecha2);
  d2.setHours(0, 0, 0, 0);
  return (d1.getTime() - d2.getTime()) / (1000 * 60 * 60 * 24);
};

export const encriptado = (data) => {
  let pass = process.env.AUDITOR;

  let salt = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
  let iv = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);

  let key = CryptoJS.PBKDF2(pass, CryptoJS.enc.Hex.parse(salt), {
    keySize: 128 / 32,
    iterations: 1000,
  });

  let enc = CryptoJS.AES.encrypt(data, key, {
    iv: CryptoJS.enc.Hex.parse(iv),
  });

  let str = `${iv}::${salt}::${enc.ciphertext.toString(CryptoJS.enc.Base64)}`;

  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(pass);
  return encrypt.encrypt(data);
};

export function hex2b64(h) {
  let b64map =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
  let b64pad = '=';
  let i;
  let c;
  let ret = '';
  for (i = 0; i + 3 <= h.length; i += 3) {
    c = parseInt(h.substring(i, i + 3), 16);
    ret += b64map.charAt(c >> 6) + b64map.charAt(c & 63);
  }
  if (i + 1 == h.length) {
    c = parseInt(h.substring(i, i + 1), 16);
    ret += b64map.charAt(c << 2);
  } else if (i + 2 == h.length) {
    c = parseInt(h.substring(i, i + 2), 16);
    ret += b64map.charAt(c >> 2) + b64map.charAt((c & 3) << 4);
  }
  while ((ret.length & 3) > 0) {
    ret += b64pad;
  }
  return ret;
}

/**
 * Formater un valor monetario con comas y decimales.
 * @param {decimal} val valor decimal
 */
export const formatoMonetario = (val) => {
  let negativo = false;
  let v = '';
  val = val + '';
  if (val.charAt(0) == '-') {
    for (var i = 1; i < val.length; i++) {
      v += val.charAt(i);
    }
    val = v;
    negativo = true;
  }
  let cadena = val.split('.');
  let num = cadena[0];
  let fmto = '';
  let ind = 1;
  for (i = num.length - 1; i >= 0; i--) {
    fmto = num.charAt(i) + fmto;
    if (ind % 3 == 0 && ind != 3 && ind != num.length)
      //para poner las '
      fmto = ',' + fmto;
    else if (ind == 3 && ind != num.length)
      //para poner las ,
      fmto = ',' + fmto;
    ind++;
  }

  var signo = negativo == true ? '-' : '';
  let decimal = cadena[1];
  if (typeof decimal != 'undefined') {
    //si no es undefined
    if (decimal.toString().length === 1) {
      decimal = decimal + '0';
    }
    return `${signo + fmto}.${decimal}`;
  } else return signo + fmto + '.00';
};

export const redondeoDecimales = (monto, decimales = 2) => {
  monto = quitarFormatoMonetario(monto);
  return +(Math.round(monto + 'e+' + decimales) + 'e-' + decimales);
};

/**
 * Quitar el formato #'###,###
 * @param {string} val valor monetario formateado
 */
export const quitarFormatoMonetario = (val) => {
  val = val + '';
  var cadena1 = val.split(',');
  if (cadena1.length == 1)
    if (isNaN(val))
      //No tiene ,
      return parseFloat('0.00');
    else {
      if (val != '') {
        return parseFloat(val);
      } else {
        return parseFloat('0.00');
      }
    }
  else {
    var fmto = '';
    var cadena2 = cadena1[0].split(',');
    if (cadena2.length == 1)
      //No tiene '
      for (var i = 0; i < cadena1.length; i++) fmto += cadena1[i];
    else {
      for (i = 0; i < cadena2.length; i++) fmto += cadena2[i];

      fmto += cadena1[1];
    }

    if (isNaN(fmto)) return parseFloat('0.00');
    else return parseFloat(fmto);
  }
};

export const obtenerColorAleatorio = () => {
  let letters = '0123456789ABCDEF'.split('');
  let color = '#';
  for (let i = 0; i < 3; i++) {
    if (i === 2) {
      const lastcolor = letters[Math.floor(Math.random() * 16)];
      if (color.search(lastcolor) >= 0) {
        color += letters[Math.floor(Math.random() * 16)];
      } else {
        i--;
      }
    } else {
      color += letters[Math.floor(Math.random() * 16)];
    }
  }
  return color;
};

/**
 * Dar formato de fecha.
 * @param {Date} date fecha a formatear
 * @param {String} format formato de fecha (defecto DD/MM/YYYY)
 * @param {boolean} time mostrar hora (defecto falso)
 */
export const formatearFecha = (date, format = 'DD/MM/YYYY', time = false) => {
  if (date === null) return;
  let formato1 = format.toLocaleUpperCase().split('/');
  let separador = '/';
  if (formato1.length === 1) {
    formato1 = format.toLocaleUpperCase().split('-');
    separador = '-';
    if (formato1.length === 1) return;
  }

  let fecha = new Date(date);
  let day = fecha.getDate();
  let month = fecha.getMonth() + 1;
  let year = fecha.getFullYear();

  let iyear = formato1.indexOf('YYYY');
  let imonth = formato1.indexOf('MM');
  let iday = formato1.indexOf('DD');

  let stime = '';
  if (time) {
    let hour = fecha.getHours();
    let minutes = fecha.getMinutes();
    let seconds = fecha.getSeconds();
    stime = ` ${padLef(hour)}:${padLef(minutes)}:${padLef(seconds)}`;
  }

  if (iday === 0) {
    return `${padLef(day)}${separador}${
      imonth < iyear ? padLef(month) : year
    }${separador}${imonth > iyear ? padLef(month) : year}${stime}`;
  } else if (imonth === 0) {
    return `${padLef(month)}${separador}${
      iday < iyear ? padLef(day) : year
    }${separador}${iday > iyear ? padLef(day) : year}${stime}`;
  } else {
    return `${year}${separador}${
      iday < imonth ? padLef(day) : padLef(month)
    }${separador}${iday > imonth ? padLef(day) : padLef(month)}${stime}`;
  }
};

export const agregarDiasFecha = (fecha, days) => {
  let res = new Date();
  if (fecha) {
    res = new Date(fecha);
  }
  res.setDate(res.getDate() + days);
  return res;
};

const padLef = (n) => {
  return ('00' + n).slice(-2);
};

export const caracteresUnicode = () => {
  let str = '';
  str += '\u00e1'; //a con tilde
  str += '\u00e9'; //e con tilde
  str += '\u00ed'; //i con tilde
  str += '\u00f3'; //o con tilde
  str += '\u00fa'; //u con tilde
  str += '\u00c1'; //A con tilde
  str += '\u00c9'; //E con tilde
  str += '\u00cd'; //I con tilde
  str += '\u00d3'; //O con tilde
  str += '\u00da'; //U con tilde
  str += '\u00f1'; //enie
  str += '\u00d1'; //ENIE
  str += '\u0040'; //Arroba
  return str;
};

export const obtenerIcono = (extension) => {
  let icono = PdfFile;
  switch (extension) {
    case 'pdf':
      icono = PdfFile;
      break;
    case 'png':
      icono = PngFile;
      break;
    case 'jpg':
      icono = JpgFile;
      break;
    case 'jpeg':
      icono = JpgFile;
      break;
    case 'xls':
      icono = ExcelFile;
      break;
    case 'xlsx':
      icono = ExcelFile;
      break;
    case 'doc':
      icono = DocFile;
      break;
    case 'docx':
      icono = DocFile;
      break;
    default:
      break;
  }
  return icono;
};

/**
 * Cambiar el verbo accionar a efecto.
 * @param {string} accion texto a modificar
 * @returns {string} texto cambiado a efecto
 * @usage
 *   cambiarAccionEfecto('Registrar'); // -> Registrada
 *   cambiarAccionEfecto('Registrar compra'); // -> Compra registrada
 *   cambiarAccionEfecto('Pedir compra'); // -> Compra pedida
 */
export const cambiarAccionEfecto = (accion) => {
  const reg1 = /AR$/i;
  const reg2 = /ER|IR$/i;

  let palabras = accion.split(' ');
  let arreglo = [];
  let efecto = '';
  for (let index = 0; index < palabras.length; index++) {
    const element = palabras[index];
    if (reg1.test(element)) {
      efecto = element.replace(reg1, 'ada').toLowerCase();
    } else if (reg2.test(element)) {
      efecto = element.replace(reg1, 'ida').toLowerCase();
    } else {
      arreglo.push(element.toLowerCase());
    }
  }
  arreglo.push(efecto);
  return arreglo.map((x, i) => (i == 0 ? capitalize(x, true) : x)).join(' ');
};

/**
 * Elimina caracteres extra&ntilde;os del texto.
 * @param {string} text texto a limpiar
 * @returns texto limpio
 */
export const removeStrangeCharacters = (text) => {
  return text
    .replace(
      /[^a-zA-Z0-9-/.:+,;_\u00e1\u00e9\u00ed\u00f3\u00fa\u00c1\u00c9\u00cd\u00d3\u00da\u00f1\u00d1\u0040\u000a ]/g,
      ''
    )
    .trim();
};

/**
 * Se agrega saltos de linea en formato html.
 * @param {string} text texto a modificar
 * @returns texto con saltos de linea en html
 */
export const addNewLine = (text) => {
  if (text) {
    const result = text.replace(/(\n).*\n/, '<span>').split('<span>');

    return (
      <>
        {result.map((r, i) => (
          <React.Fragment key={i}>
            {i > 0 && <span>-</span>}
            {r}
          </React.Fragment>
        ))}
      </>
    );
  } else {
    return <></>;
  }
};

/**
 * Obtiene el color del evento.
 * @param {number} process proceso del evento
 * @param {string} state estado del evento
 * @returns color
 */
export const getColorProcess = (process, state) => {
  switch (process) {
    case 0:
      return state === '1' ? 'green' : '#34baeb';
    case 1:
      return 'red';
    case 2:
      return '#1ab394';
    case 3:
      return 'orange';
    default:
      return '#808080';
  }
};

/**
 * Convertir a n&uacute;mero a hexadecimal.
 * @param {number} number n&uacute;mero a convertir
 * @returns {string} valor hexadecimal
 */
export const toHexadecimal = (number) => {
  return number.toString(16);
};

/**
 * Capitalizes first letters of words in string.
 * @param {string} str String to be modified
 * @param {boolean=false} lower Whether all other letters should be lowercased
 * @return {string}
 * @usage
 *   capitalize('fix this string');     // -> 'Fix This String'
 *   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT'
 *   capitalize('javaSCrIPT', true);    // -> 'Javascript'
 */
const capitalize = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
    match.toUpperCase()
  );
