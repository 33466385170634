import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Combo from '../../commons/Combo';
import Boton from '../../commons/Boton';
import Ubigueo from '../../components/ubigueo/Ubigueo';
import { confirmacion } from '../../commons/Mensajes';

import {
  closeModal,
  postClienteAction,
} from '../../redux/actions/cliente.action';

import SaveIcon from '@material-ui/icons/Save';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const ClienteItem = ({
  selected,
  processing,
  errors,
  tipoDocumentoIdentidad,
  closeModal,
  postClienteAction,
}) => {
  const [cliente, setCliente] = useState(selected);
  const [openUbigueo, setOpenUbigueo] = useState(false);

  useEffect(() => {
    let searchTipo = tipoDocumentoIdentidad.find((s) => s.de === selected.dt);
    if (searchTipo) {
      handleChange({ name: 'td', value: searchTipo.dt });
    }
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setCliente((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <Modal
        title={`${cliente.cl ? 'Actualizar' : 'Nuevo'} cliente`}
        processing={processing}
        onActions={() => (
          <Boton
            className="op-grabar"
            disabled={processing}
            icon={() => <SaveIcon />}
            tabIndex={21}
            onClick={() =>
              confirmacion(
                '¿Desea guardar?',
                `${cliente.cl ? 'Actualizar' : 'Un nuevo'} cliente`
              )
                .then(() => postClienteAction(cliente))
                .catch(() => {})
            }
          >
            Guardar
          </Boton>
        )}
        onClose={() => closeModal()}
      >
        <Combo
          name="td"
          label="Tipo documento"
          value={cliente.td}
          tabIndex={10}
          size={2}
          data={tipoDocumentoIdentidad.map((i) => ({
            value: i.dt,
            label: i.de,
          }))}
          error={errors.td}
          onChange={handleChange}
        />
        <Texto
          name="nd"
          value={cliente.nd}
          label="N&uacute;mero de documento"
          tabIndex={11}
          size={2}
          error={errors.nd}
          onChange={handleChange}
          restrict={'int'}
        />
        <Texto
          name="dn"
          value={cliente.dn}
          label="Nombres completos"
          tabIndex={12}
          size={2}
          error={errors.dn}
          onChange={handleChange}
        />
        <Texto
          name="tf"
          value={cliente.tf}
          label="Tel&eacute;fono"
          tabIndex={13}
          size={2}
          enabledPaste={true}
          error={errors.tf}
          onChange={handleChange}
        />
        <Texto
          name="ce"
          value={cliente.ce}
          label="Correo electr&oacute;nico"
          tabIndex={14}
          size={2}
          enabledPaste={true}
          error={errors.ce}
          onChange={handleChange}
        />
        <Texto
          name="du"
          value={cliente.du}
          label="Ubigueo"
          size={2}
          tabIndex={15}
          error={errors.du}
          onChange={() => {}}
          icon={() => <LocationOnIcon onClick={() => setOpenUbigueo(true)} />}
        />
        <Texto
          name="di"
          value={cliente.di}
          label="Direcci&oacute;n"
          tabIndex={16}
          enabledPaste={true}
          error={errors.di}
          onChange={handleChange}
        />
      </Modal>
      {openUbigueo && (
        <Ubigueo
          onClose={() => setOpenUbigueo(false)}
          onSend={(ubigueo) => {
            handleChange({ name: 'ubl', value: ubigueo.ub });
            handleChange({ name: 'du', value: ubigueo.di });
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.cliente.selected,
    processing: state.cliente.processing,
    errors: state.cliente.errors,
    tipoDocumentoIdentidad: state.detalle.tipoDocumentoIdentidad,
  };
};

export default connect(mapStateToProps, { closeModal, postClienteAction })(
  ClienteItem
);
