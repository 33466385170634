import React, { useState } from 'react';
import { connect } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Modal from '../../commons/Modal';
import Bloque from '../../commons/Bloque';
import CheckBox from '../../commons/CheckBox';
import { formatoMonetario, redondeoDecimales } from '../../commons/Utilitarios';

import {
  getServiciosAction,
  getSeleccionarServicioAction,
  actualizarFiltros,
} from '../../redux/actions/servicio.action';

import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';

const ServicioList = ({
  servicios,
  processing,
  total,
  getServiciosAction,
  getSeleccionarServicioAction,
  actualizarFiltros,
  searchable,
  fromPedido,
  onClose,
  onSend,
}) => {
  const [buscar, setBuscar] = useState('');
  const columns = [
    {
      key: 'sv',
      align: 'center',
      content: (item) => (
        <>
          {searchable ? (
            <div title="Enviar">
              <CheckIcon
                onClick={() => {
                  onSend(item);
                  onClose();
                }}
                style={{ color: 'green', cursor: 'pointer' }}
              />
            </div>
          ) : (
            <>
              <div title="Editar" style={{ display: 'inline-block' }}>
                <EditIcon
                  onClick={() => getSeleccionarServicioAction(item)}
                  style={{ color: 'green', cursor: 'pointer' }}
                />
              </div>
              <div title="Imagen" style={{ display: 'inline-block' }}>
                <InsertPhotoIcon
                  onClick={() => getSeleccionarServicioAction(item, 'imagen')}
                  style={{ color: '#3285a8', cursor: 'pointer' }}
                />
              </div>
            </>
          )}
        </>
      ),
    },
    {
      key: 'cs',
      label: 'Código',
    },
    {
      key: 'ns',
      label: 'Nombre',
    },
    {
      key: 'ps',
      label: 'Precio',
      align: 'right',
      content: (item) => formatoMonetario(redondeoDecimales(item.ps)),
    },
    {
      key: 'st',
      label: 'Estado de registro',
      align: 'center',
      content: (item) => <CheckBox checked={item.st} disabled />,
    },
  ];
  if (fromPedido || (servicios.length > 0 && servicios[0].nc !== '')) {
    columns.splice(3, 0, { key: 'nc', label: 'Categoria' });
    columns.splice(5, 0, {
      key: 'ds',
      label: 'Descuento',
      align: 'right',
      content: (item) => formatoMonetario(redondeoDecimales(item.ds)),
    });
  }
  return (
    <>
      {searchable ? (
        <Modal
          title="B&uacute;squeda de servicios"
          closePressEscape={false}
          closeWithoutConfirmation
          onClose={() => onClose()}
        >
          <Tabla
            columns={columns}
            data={servicios}
            processing={processing}
            total={total}
            onPagination={({ page, rows }) =>
              getServiciosAction({ page, pageSize: rows })
            }
            onSearching={() => getServiciosAction({})}
            onChangeSearching={(search) => actualizarFiltros({ search })}
            onDoubleClick={(item) => {
              onSend(item);
              onClose();
            }}
            style={{ flexBasis: '100%' }}
          />
        </Modal>
      ) : (
        <Bloque titulo="Resultado de b&uacute;squeda">
          <Tabla
            columns={columns}
            data={servicios}
            processing={processing}
            total={total}
            onPagination={({ page, rows }) =>
              getServiciosAction({ page, pageSize: rows })
            }
            onSearching={() => {
              actualizarFiltros({ search: buscar });
              getServiciosAction({});
            }}
            onChangeSearching={(search) => setBuscar(search)}
          />
        </Bloque>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    servicios: state.servicio.servicios,
    processing: state.servicio.processing,
    total: state.servicio.total,
  };
};

export default connect(mapStateToProps, {
  getServiciosAction,
  getSeleccionarServicioAction,
  actualizarFiltros,
})(ServicioList);
