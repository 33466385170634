export const solicitudModel = {
  so: null,
  is: null,
  ns: null,
  iv: null,
  nv: null,
  cs: null,
  fs: null,
  ds: null,
  ii: null,
  cl: null,
  nl: null,
  sv: null,
  sn: null,
  ee: null,
  ct: null,
  nt: null,
  ep: 0,
  ua: null,
  na: null,
  fa: null,
  fv: null,
  ft: null,
  ta: [],
  fc: null,
  uc: null,
  nc: null,
  mc: null,
  dd: [],
  qq: [],
  st: false,
  gc: true,
};
