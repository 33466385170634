import React from 'react';
import { useSelector } from 'react-redux';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Bloque from '../../commons/Bloque';
import { useFormularioContext } from '../../commons/Formulario';
import { confirmacion } from '../../commons/Mensajes';
import TablaBody from '../../commons/TablaBody';
import Paginacion from '../../commons/Paginacion';

import { servicios } from '../../redux/helpers';
import { cotizacionModel } from '../../redux/models/cotizacion.model';
import Swal from 'sweetalert2';
import { formatoFecha } from '../../commons/Utilitarios';

const CotizacionList = () => {
  const { currentUser } = useSelector((state) => state.app);
  const { callTransaction, setSelectedItem } = useFormularioContext();

  const columns = [
    {
      key: 'co',
      align: 'center',
      label: 'Cotización',
      content: (item) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <DeleteForeverIcon
            onClick={() =>
              confirmacion('¿Desea eliminar?', `La cotización ${item.co}`)
                .then(() => handleRemove(item.co))
                .catch(() => {})
            }
            style={{ color: 'red', cursor: 'pointer' }}
          />
          <div title="Convertir a solicitud">
            <NoteAddIcon
              onClick={() =>
                confirmacion(
                  '¿Está seguro?',
                  `De generar una solicitud de mantenimiento con la cotización ${item.co}`
                )
                  .then(() => handleGenerate(item.co, item.rq))
                  .catch(() => {})
              }
              style={{ color: 'blue', cursor: 'pointer' }}
            />
          </div>
          <div title="Ver cotización">
            <VisibilityIcon
              onClick={() => setSelectedItem(item)}
              style={{ color: 'green', cursor: 'pointer' }}
            />
          </div>
          <span>{item.co}</span>
        </div>
      ),
    },
    {
      key: 'fr',
      label: 'Fecha generación',
      content: (item) => <span>{formatoFecha(item.fr, '')}</span>,
    },
    {
      key: 'cc.dn',
      label: 'Cliente',
      content: (item) => <span>{item.cc?.dn}</span>,
    },
    {
      key: 'vc',
      label: 'Vigencia en días',
      align: 'center',
    },
    {
      key: 'ex',
      label: 'Expira',
    },
  ];

  const handleRemove = (id) => {
    callTransaction({
      method: 'delete',
      url: `${servicios.COTIZACION}/${id}/${currentUser.ie}`,
      model: cotizacionModel,
    });
  };

  const handleGenerate = (co, ds) => {
    let data = {
      ie: currentUser.ie,
      iu: currentUser.us,
      co,
      ds,
    };
    callTransaction({
      method: 'post',
      url: `${servicios.COTIZACION}/generar`,
      data,
      model: cotizacionModel,
    });
  };

  return (
    <Bloque titulo="Cotizaciones">
      <div className="op-table">
        <TablaBody columns={columns} />
        <Paginacion />
      </div>
    </Bloque>
  );
};

export default CotizacionList;
