import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Texto from '../../commons/Texto';
import Bloque from '../../commons/Bloque';
import Boton from '../../commons/Boton';
import {
  formatoFecha,
  formatoMonetario,
  quitarFormatoMonetario,
  redondeoDecimales,
} from '../../commons/Utilitarios';

import { confirmacion } from '../../commons/Mensajes';

import {
  getCajaAction,
  patchConfirmarCajeroAction,
  putCierreCajeroAction,
  refrescarOperacionesCajeroAction,
} from '../../redux/actions/cajero.action';

import RestoreIcon from '@material-ui/icons/Restore';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import SaveIcon from '@material-ui/icons/Save';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import Modal from '../../commons/Modal';

import '../../css/components/cajero.css';

const CajeroOperacionList = ({
  responsable,
  selected,
  operaciones,
  processing,
  perfil,
}) => {
  const dispatch = useDispatch();
  const [openCierre, setOpenCierre] = useState(false);
  const [operacion, setOperacion] = useState(selected);
  const [disponibleSoles, setDisponibleSoles] = useState(0);
  const [disponibleDolares, setDisponibleDolares] = useState(0);
  const [balanceSoles, setBalanceSoles] = useState(0);
  const [balanceDolares, setBalanceDolares] = useState(0);
  const columns = [
    {
      key: 'fo',
      label: 'Fecha',
      align: 'right',
      width: '160px',
      content: (item) => handleColor(item, formatoFecha(item.fo, 'Indefinido')),
    },
    {
      key: 'co',
      label: 'Concepto',
      content: (item) =>
        handleColor(item, `${item.co}${item.st ? '' : ' (Anulado)'}`),
    },
    {
      key: 'fp',
      label: 'Forma pago',
      content: (item) => (
        <span>{`${item.fp === 0 ? 'Efectivo' : 'Tarjeta'}`}</span>
      ),
    },
    {
      key: 'tc',
      label: 'Comprobante',
    },
    {
      key: 'nc',
      label: 'Numeración',
      content: (item) => handleColor(item, item.nc),
    },
    {
      key: 'mo',
      label: 'Monto',
      align: 'right',
      content: (item) => (
        <div
          className={`op-arrow-${item.is === 1 ? 'up' : 'down'}`}
          style={{
            color: `${item.is === 1 && item.st ? 'green' : 'red'}`,
            justifyContent: 'flex-end',
          }}
        >
          {item.tm === 0 ? 'S/' : '$'}
          {formatoMonetario(redondeoDecimales(item.mo))}
        </div>
      ),
    },
    {
      key: 'tt',
      label: 'Transacción',
      content: (item) =>
        handleColor(item, item.tt === 0 ? 'Contado' : 'Credito'),
    },
    {
      key: 'cc',
      label: 'T. Cambio',
      align: 'right',
      content: (item) => formatoMonetario(redondeoDecimales(item.cc)),
    },
  ];

  const handleColor = (item, texto) => {
    let color = '#000000';
    if (item.st && item.tt === 1) {
      color = 'orange';
    } else if (item.st && item.tt === 0) {
      color = '#000000';
    } else {
      color = 'red';
    }

    return <span style={{ color: color }}>{texto}</span>;
  };

  const handleChange = (item) => {
    const { name, value } = item;
    setOperacion((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (perfil === 'BASICO') {
      dispatch(getCajaAction());
    }
  }, []);

  const calcularTotales = () => {
    let bs = quitarFormatoMonetario(operacion.bs);
    let ms = quitarFormatoMonetario(operacion.ms);
    let ts = quitarFormatoMonetario(operacion.ts);
    let bd = quitarFormatoMonetario(operacion.bd);
    let md = quitarFormatoMonetario(operacion.md);
    let td = quitarFormatoMonetario(operacion.td);

    setDisponibleSoles(bs + ms + ts);
    setDisponibleDolares(bd + md + td);

    let soles = operaciones
      .filter((x) => x.tm === 0 && x.st)
      .reduce((a, b) => a + b.mo, 0);
    let dolares = operaciones
      .filter((x) => x.tm === 1 && x.st)
      .reduce((a, b) => a + b.mo, 0);

    setBalanceSoles(bs + ms + ts - soles);
    setBalanceDolares(bd + md + td - dolares);
  };

  return (
    <>
      <Bloque
        titulo="Operaciones de cajero"
        onActions={() => (
          <>
            {selected.mr && selected.fc === null && (
              <Boton
                className="op-dinamic"
                icon={() => <PlaylistAddCheckIcon />}
                tabIndex={22}
                style={{
                  '--button-color': '#3285a8',
                }}
                onClick={() => setOpenCierre(true)}
              >
                Cerrar caja
              </Boton>
            )}
            {selected.mr === false && responsable === selected.ur && (
              <Boton
                className="op-dinamic"
                icon={() => <DoneAllIcon />}
                tabIndex={22}
                style={{
                  '--button-color': '#3285a8',
                }}
                onClick={() =>
                  confirmacion(
                    '¿Está seguro de confirmar la apertura de caja?',
                    'Con la confirmación podra realizar ventas'
                  )
                    .then(() => dispatch(patchConfirmarCajeroAction(selected)))
                    .catch(() => {})
                }
              >
                Confirmar apertura
              </Boton>
            )}
            {selected.mr && selected.fc === null && (
              <Boton
                className="op-dinamic"
                style={{ '--button-color': '#1ab394' }}
                icon={() => <RestoreIcon />}
                onClick={() => dispatch(refrescarOperacionesCajeroAction())}
              >
                Refrescar operaciones
              </Boton>
            )}
          </>
        )}
      >
        <div className="op-form" style={{ padding: '20px' }}>
          <Texto
            name="ns"
            value={selected ? selected.ns : ''}
            label="Sucursal"
            size={3}
            tabIndex={30}
            onChange={() => {}}
            disabled
          />
          <Texto
            name="ur"
            value={selected ? selected.ur : ''}
            label="usuario responsable"
            size={3}
            tabIndex={30}
            onChange={() => {}}
            disabled
          />
          <Texto
            name="np"
            value={selected ? selected.np : ''}
            label="Caja"
            size={3}
            tabIndex={30}
            onChange={() => {}}
            disabled
          />
          <Texto
            name="ss"
            value={
              selected ? formatoMonetario(redondeoDecimales(selected.ss)) : ''
            }
            label="Saldo inicial soles"
            size={4}
            tabIndex={30}
            onChange={() => {}}
            disabled
          />
          <Texto
            name="sd"
            value={
              selected ? formatoMonetario(redondeoDecimales(selected.sd)) : ''
            }
            label="Saldo inicial dolares"
            size={4}
            tabIndex={30}
            onChange={() => {}}
            disabled
          />
          <Texto
            name="fa"
            value={selected ? formatoFecha(selected.fa, '') : ''}
            label="Apertura"
            size={4}
            tabIndex={30}
            onChange={() => {}}
            disabled
          />
          <Texto
            name="fc"
            value={selected ? formatoFecha(selected.fc, 'Pendiente') : ''}
            label="Cierre"
            size={4}
            tabIndex={30}
            onChange={() => {}}
            disabled
          />
          {selected && selected.fc && (
            <>
              <Texto
                name="sb"
                value={
                  selected
                    ? formatoMonetario(redondeoDecimales(selected.sb))
                    : ''
                }
                label="Soles billetes"
                size={6}
                tabIndex={30}
                onChange={() => {}}
                disabled
              />
              <Texto
                name="sm"
                value={
                  selected
                    ? formatoMonetario(redondeoDecimales(selected.sm))
                    : ''
                }
                label="Soles monedas"
                size={6}
                tabIndex={30}
                onChange={() => {}}
                disabled
              />
              <Texto
                name="st"
                value={
                  selected
                    ? formatoMonetario(redondeoDecimales(selected.st))
                    : ''
                }
                label="Soles tarjeta"
                size={6}
                tabIndex={30}
                onChange={() => {}}
                disabled
              />
              <Texto
                name="db"
                value={
                  selected
                    ? formatoMonetario(redondeoDecimales(selected.db))
                    : ''
                }
                label="Dolares billetes"
                size={6}
                tabIndex={30}
                onChange={() => {}}
                disabled
              />
              <Texto
                name="dm"
                value={
                  selected
                    ? formatoMonetario(redondeoDecimales(selected.dm))
                    : ''
                }
                label="Dolares monedas"
                size={6}
                tabIndex={30}
                onChange={() => {}}
                disabled
              />
              <Texto
                name="dt"
                value={
                  selected
                    ? formatoMonetario(redondeoDecimales(selected.dt))
                    : ''
                }
                label="Dolares tarjeta"
                size={6}
                tabIndex={30}
                onChange={() => {}}
                disabled
              />
            </>
          )}
        </div>
        <Tabla
          columns={columns}
          data={operaciones}
          processing={processing}
          searcheable={false}
          pagination={false}
          hoverSelectedRow={false}
        />
      </Bloque>
      {openCierre && (
        <Modal
          title={`Cierre de ${selected.np}`}
          size="medium"
          onActions={() => (
            <Boton
              className="op-grabar"
              disabled={processing}
              icon={() => <SaveIcon />}
              onClick={() =>
                confirmacion(
                  '¿Está seguro de realizar el cierre de caja?',
                  'No podra cambiar los datos enviados'
                )
                  .then(() => dispatch(putCierreCajeroAction(operacion)))
                  .catch(() => {})
              }
            >
              Confirmar cierre
            </Boton>
          )}
          closeWithoutConfirmation
          onClose={() => setOpenCierre(false)}
        >
          <div className="op-datos-cierre">
            <span> </span>
            <b>Soles</b>
            <b>Dolares</b>
            <span>Total Billetes</span>
            <Texto
              name="bs"
              value={operacion.bs}
              label=" "
              tabIndex={50}
              restrict="money"
              onChange={handleChange}
              onBlur={(e) => {
                handleChange({
                  name: 'bs',
                  value: formatoMonetario(redondeoDecimales(e.target.value)),
                });
                calcularTotales();
              }}
            />
            <Texto
              name="bd"
              value={operacion.bd}
              label=" "
              tabIndex={51}
              restrict="money"
              onChange={handleChange}
              onBlur={(e) => {
                handleChange({
                  name: 'bd',
                  value: formatoMonetario(redondeoDecimales(e.target.value)),
                });
                calcularTotales();
              }}
            />
            <span>Total Monedas</span>
            <Texto
              name="ms"
              value={operacion.ms}
              label=" "
              tabIndex={52}
              restrict="money"
              onChange={handleChange}
              onBlur={(e) => {
                handleChange({
                  name: 'ms',
                  value: formatoMonetario(redondeoDecimales(e.target.value)),
                });
                calcularTotales();
              }}
            />
            <Texto
              name="md"
              value={operacion.md}
              label=" "
              tabIndex={53}
              restrict="money"
              onChange={handleChange}
              onBlur={(e) => {
                handleChange({
                  name: 'md',
                  value: formatoMonetario(redondeoDecimales(e.target.value)),
                });
                calcularTotales();
              }}
            />
            <span>Total Tarjetas</span>
            <Texto
              name="ts"
              value={operacion.ts}
              label=" "
              tabIndex={54}
              restrict="money"
              onChange={handleChange}
              onBlur={(e) => {
                handleChange({
                  name: 'ts',
                  value: formatoMonetario(redondeoDecimales(e.target.value)),
                });
                calcularTotales();
              }}
            />
            <Texto
              name="td"
              value={operacion.td}
              label=" "
              tabIndex={55}
              restrict="money"
              onChange={handleChange}
              onBlur={(e) => {
                handleChange({
                  name: 'td',
                  value: formatoMonetario(redondeoDecimales(e.target.value)),
                });
                calcularTotales();
              }}
            />
            <span>Total Disponible</span>
            <Texto
              name="tos"
              value={formatoMonetario(redondeoDecimales(disponibleSoles))}
              label=" "
              tabIndex={56}
              onChange={() => {}}
              disabled
            />
            <Texto
              name="tod"
              value={formatoMonetario(redondeoDecimales(disponibleDolares))}
              label=" "
              tabIndex={57}
              onChange={() => {}}
              disabled
            />
            <span>Ingresos</span>
            <Texto
              name="ins"
              value={formatoMonetario(
                redondeoDecimales(
                  operaciones
                    .filter((x) => x.tm === 0 && x.st)
                    .reduce((a, b) => a + b.mo, 0)
                )
              )}
              label=" "
              tabIndex={58}
              onChange={() => {}}
              disabled
            />
            <Texto
              name="ind"
              value={formatoMonetario(
                redondeoDecimales(
                  operaciones
                    .filter((x) => x.tm === 1 && x.st)
                    .reduce((a, b) => a + b.mo, 0)
                )
              )}
              label=" "
              tabIndex={59}
              onChange={() => {}}
              disabled
            />
            <span>Balance</span>
            <Texto
              name="bas"
              value={formatoMonetario(redondeoDecimales(balanceSoles))}
              label=" "
              tabIndex={60}
              onChange={() => {}}
              disabled
            />
            <Texto
              name="bad"
              value={formatoMonetario(redondeoDecimales(balanceDolares))}
              label=" "
              tabIndex={61}
              onChange={() => {}}
              disabled
            />
          </div>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.cajero.selected,
    operaciones: state.cajero.operaciones,
    processing: state.cajero.processing,
    responsable: state.app.currentUser.np,
    perfil: state.app.currentUser.tr,
  };
};

export default connect(mapStateToProps)(CajeroOperacionList);
