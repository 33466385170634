import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';

import Boton from '../../commons/Boton';
import Icon from '../../commons/Icon';
import { confirmacion } from '../../commons/Mensajes';
import { useFormularioContext } from '../../commons/Formulario';

import BookIcon from '../../assets/icons/book.svg';
import Busqueda from '../../commons/Busqueda';
import { servicios, columnsHandBooks } from '../../redux/helpers';
import { solicitudModel } from '../../redux/models/solicitud.model';

const SolicitudHandBook = () => {
  const { selected, processing, equipment, callTransaction } =
    useFormularioContext();
  const { currentUser } = useSelector((state) => state.app);
  const [solicitud, setSolicitud] = useState(selected);
  const [handbooks, setHandbooks] = useState([]);
  const [showHandbooks, setShowHandbooks] = useState(false);

  useEffect(() => {
    setSolicitud(selected);
    setHandbooks(equipment.mn ? equipment.mn : []);
  }, [selected.so]);

  const handleAddHandBook = (item) => {
    let exist = handbooks.find((x) => x.cm === item.cm);
    if (exist === undefined) {
      setHandbooks([...handbooks, item]);
    }
    setShowHandbooks(false);
  };

  const handleDeleteHandBook = (cm) => {
    setHandbooks(handbooks.filter((x) => x.cm !== cm));
  };

  const handleSaveHandBookg = () => {
    let request = {
      so: solicitud.so,
      ie: currentUser.ie,
      eq: equipment.eq.eq,
      pc: equipment.pc?.map((p) => p.pr),
      ts: equipment.ts?.map((t) => t.te),
      mn: handbooks.map((h) => h.mn),
    };
    callTransaction({
      method: 'patch',
      url: `${servicios.SOLICITUD}/equipodetalle`,
      data: request,
      model: solicitudModel,
    });
  };

  return (
    <div className="detail-equipments">
      <h3>{`Manuales - ${equipment.eq?.ne}`}</h3>
      <div className="container">
        {handbooks?.map((m) => (
          <div key={m.mn} className="equipment">
            <Icon svg={BookIcon} size={30} transparent />
            <span>{m.cm}</span>
            <span>{m.nm}</span>
            {solicitud.ep !== 2 && currentUser.tr === 'NOBASICO' && (
              <div className="close">
                <CancelIcon
                  color="secondary"
                  onClick={() => handleDeleteHandBook(m.cm)}
                />
              </div>
            )}
          </div>
        ))}
        {solicitud.ep !== 2 && currentUser.tr === 'NOBASICO' && (
          <>
            <Boton
              className="op-dinamic"
              disabled={processing}
              icon={() => <AddIcon />}
              tabIndex={18}
              style={{ '--button-color': '#3285a8' }}
              onClick={() => setShowHandbooks(true)}
            >
              Agregar manual
            </Boton>
            <Boton
              className="op-grabar"
              disabled={processing}
              icon={() => <SaveIcon />}
              tabIndex={19}
              onClick={() =>
                confirmacion(
                  '¿Está seguro?',
                  `De registrar los manuales al equipo ${equipment.eq.ne}`
                )
                  .then(() => handleSaveHandBookg())
                  .catch(() => {})
              }
            >
              Guardar manuales
            </Boton>
          </>
        )}
      </div>
      {showHandbooks && (
        <Busqueda
          serviceName={servicios.MANUAL}
          title="B&uacute;squeda de manuales"
          columns={columnsHandBooks((item) => handleAddHandBook(item))}
          emptyRowsMessage="No existen manuales"
          onClose={() => setShowHandbooks(false)}
          onDoubleClick={(item) => handleAddHandBook(item)}
        />
      )}
    </div>
  );
};

export default SolicitudHandBook;
