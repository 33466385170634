export const testeoDetalleModel = {
  id: null,
  co: null,
  nt: null,
  td: {
    dt: null,
    de: null,
    vm: null,
  },
  st: false,
};
