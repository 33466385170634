import React from 'react';

import Combo from '../../commons/Combo';
import Filtros from '../../commons/Filtros';
import { useTablaFilter } from '../../commons/Formulario';

import { manualModel } from '../../redux/models/manual.model';
import { TYPE_FILTER } from '../../redux/helpers/app.types';

const ManualFilter = () => {
  const { getData, params, dispatch, setSelectedItem } = useTablaFilter();
  return (
    <Filtros
      onFilter={() => getData()}
      onNew={() => setSelectedItem(manualModel)}
    >
      <Combo
        name="st"
        label="Estado de registro"
        value={params.st}
        placeholder="Todos"
        tabIndex={202}
        size={3}
        data={[
          { value: '1', label: 'Vigente' },
          { value: '0', label: 'No Vigente' },
        ]}
        onChange={({ value }) => {
          dispatch({ type: TYPE_FILTER, payload: { st: value } });
        }}
      />
    </Filtros>
  );
};

export default ManualFilter;
