import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import Boton from '../../commons/Boton';
import Busqueda from '../../commons/Busqueda';
import Combo from '../../commons/Combo';
import CheckBox from '../../commons/CheckBox';
import Modal from '../../commons/Modal';
import { confirmacion, mensajeMini } from '../../commons/Mensajes';
import Tabla from '../../commons/Tabla';
import Texto from '../../commons/Texto';
import TextArea from '../../commons/TextArea';
import { useFormularioContext } from '../../commons/Formulario';

import Report from '../../components/app/Report';

import { cotizacionModel } from '../../redux/models/cotizacion.model';
import {
  formatoFecha,
  formatoMonetario,
  quitarFormatoMonetario,
  redondeoDecimales,
  removeStrangeCharacters,
} from '../../commons/Utilitarios';
import {
  columnsEquipments,
  columnsServices,
  servicios,
} from '../../redux/helpers';

const CotizacionItem = () => {
  const {
    selected,
    openModal,
    processing,
    callTransaction,
    cancelTransaction,
    getData,
  } = useFormularioContext();
  const { currentUser } = useSelector((state) => state.app);
  const [cotizacion, setCotizacion] = useState(selected);
  const [detail, setDetail] = useState([]);
  const [showCustom, setShowCustom] = useState(false);
  const [showEquipments, setShowEquipments] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const [custom, setCustom] = useState({
    ci: null,
    di: null,
    pi: 0,
    cu: 1,
    be: false,
    bp: false,
    bs: false,
  });
  const [openReporte, setOpenReporte] = useState(false);

  const handleChange = (item) => {
    const { name, value } = item;
    setCotizacion((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeCustom = (item) => {
    const { name, value } = item;
    setCustom((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    setCotizacion(selected);
    if (selected.de) {
      setDetail(selected.de);
    }
  }, [selected, openModal]);

  if (!openModal) {
    return null;
  }

  const handleAddDetail = (item) => {
    let exits = detail.find((x) => x.ci === item.ci);
    if (exits) {
      setDetail(detail.map((x) => (x.ci === item.ci ? item : x)));
    } else {
      setDetail([
        ...detail,
        {
          ci: item.ci,
          di: item.di,
          cu: item.cu,
          pi: item.pi,
          be: item.be,
          bp: item.bp,
          bs: item.bs,
          edit: false,
          editPrice: false,
        },
      ]);
    }
    setShowCustom(false);
  };

  const handleAddService = (item) => {
    let exits = detail.find((x) => x.ci === item.cs);
    if (exits === undefined) {
      setDetail([
        ...detail,
        {
          ci: item.cs,
          di: item.ns,
          cu: 1,
          pi: item.ps,
          be: false,
          bp: false,
          bs: true,
          edit: false,
          editPrice: false,
        },
      ]);
    }
    setShowServices(false);
  };

  const handleAddEquipment = (item) => {
    let exits = detail.find((x) => x.ci === item.ce);
    if (exits === undefined) {
      setDetail([
        ...detail,
        {
          ci: item.ce,
          di: item.ne,
          cu: 1,
          pi: 0,
          be: true,
          bp: false,
          bs: false,
          edit: false,
          editPrice: false,
        },
      ]);
    }
    setShowEquipments(false);
  };

  const handleDeleteDetail = (item) => {
    setDetail(detail.filter((x) => x.ci !== item.ci));
  };

  const columnsDetail = [
    {
      key: 'ci',
      align: 'center',
      content: (item) => (
        <div style={{ display: 'flex' }}>
          <EditIcon
            onClick={() => {
              setCustom(item);
              setShowCustom(true);
            }}
            style={{ color: 'green', cursor: 'pointer' }}
          />
          <DeleteForeverIcon
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={() =>
              confirmacion(
                '¿Deseas eliminar?',
                `Está seguro de eliminar ${item.di} de la lista`
              )
                .then(() => handleDeleteDetail(item))
                .catch(() => {})
            }
          />
        </div>
      ),
    },
    {
      key: 'di',
      label: 'Descripción',
    },
    {
      key: 'cu',
      label: 'Cantidad',
      align: 'center',
    },
    {
      key: 'pi',
      label: 'Precio unitario',
      align: 'right',
      content: (item) => <>{formatoMonetario(redondeoDecimales(item.pi))}</>,
    },
  ];

  const handleGenerate = () => {
    let data = {
      ie: currentUser.ie,
      iu: currentUser.us,
      co: cotizacion.co,
      ds: cotizacion.rq,
    };
    callTransaction({
      method: 'post',
      url: `${servicios.COTIZACION}/generar`,
      data,
      model: cotizacionModel,
    });
  };

  const handleDelete = () => {
    callTransaction({
      method: 'delete',
      url: `${servicios.COTIZACION}/${cotizacion.co}/${currentUser.ie}`,
      model: cotizacionModel,
    });
  };

  return (
    <>
      <Modal
        title={`Cotizacion ${cotizacion.co}`}
        processing={processing}
        onActions={() => (
          <>
            <Boton
              className="op-dinamic"
              icon={() => <NoteAddIcon />}
              tabIndex={20}
              style={{ '--button-color': 'blue' }}
              onClick={() => {
                confirmacion(
                  '¿Está seguro?',
                  `De generar una solicitud de mantenimiento con la cotización ${cotizacion.co}`
                )
                  .then(() => handleGenerate())
                  .catch(() => {});
              }}
            >
              Convertir a solicitud
            </Boton>
            <Boton
              className="op-dinamic"
              icon={() => <DeleteForeverIcon />}
              tabIndex={20}
              style={{ '--button-color': 'red' }}
              onClick={() => {
                confirmacion(
                  '¿Está seguro?',
                  `De elimina la cotización ${cotizacion.co}`
                )
                  .then(() => handleDelete())
                  .catch(() => {});
              }}
            >
              Eliminar
            </Boton>
            <Boton
              className="op-dinamic"
              icon={() => <AddIcon />}
              tabIndex={20}
              style={{ '--button-color': '#3285a8' }}
              onClick={() => {
                setCustom({
                  ci: null,
                  di: null,
                  pi: 0,
                  cu: 1,
                  be: false,
                  bp: false,
                  bs: false,
                });
                setShowCustom(true);
              }}
            >
              Nuevo detalle
            </Boton>
            <Boton
              className="op-dinamic"
              icon={() => <AddIcon />}
              tabIndex={20}
              style={{ '--button-color': '#3285a8' }}
              onClick={() => {
                if (cotizacion.cc.cl) {
                  setShowEquipments(true);
                } else {
                  mensajeMini('Seleccione cliente');
                }
              }}
            >
              Buscar equipos
            </Boton>
            <Boton
              className="op-dinamic"
              icon={() => <AddIcon />}
              tabIndex={20}
              style={{ '--button-color': '#3285a8' }}
              onClick={() => setShowServices(true)}
            >
              Buscar servicios
            </Boton>
            <Boton
              className="op-dinamic"
              icon={() => <PictureAsPdfIcon />}
              tabIndex={20}
              style={{ '--button-color': 'red' }}
              onClick={() => {
                if (!cotizacion.pp && parseInt(cotizacion.pp) <= 0) {
                  mensajeMini('Ingrese condición');
                  return;
                }
                if (!cotizacion.dm) {
                  mensajeMini('Seleccione tipo condición');
                  return;
                }
                if (cotizacion.vc === '') {
                  mensajeMini('Ingrese vigencia');
                  return;
                }
                if (parseInt(cotizacion.vc) > 15) {
                  mensajeMini('Vigencia no puede ser superior a 15 días');
                  return;
                }
                if (detail.length === 0) {
                  mensajeMini('Seleccione al menos un servicio');
                  return;
                }
                setOpenReporte(true);
              }}
            >
              Reimprimir
            </Boton>
          </>
        )}
        onClose={() => cancelTransaction(cotizacionModel)}
      >
        <Texto
          name="cc"
          value={cotizacion.cc?.dn}
          label="Cliente"
          size={2}
          tabIndex={11}
          disabled
          onChange={() => {}}
        />
        <Texto
          name="di"
          value={cotizacion.cc?.di}
          label="Direcci&oacute;n"
          size={2}
          tabIndex={12}
          disabled
          onChange={() => {}}
        />
        <Texto
          name="fg"
          value={formatoFecha(cotizacion.fr, '')}
          label="Fecha de generaci&oacute;n"
          size={2}
          tabIndex={13}
          disabled
          onChange={() => {}}
        />
        <Combo
          name="tm"
          label="Tipo moneda"
          value={cotizacion.tm}
          tabIndex={14}
          size={4}
          data={[
            { value: '0', label: 'Soles' },
            { value: '1', label: 'Dolares' },
          ]}
          onChange={handleChange}
        />
        <div className="op-form-group op-col-4 op-checkbox-group">
          <span>¿Precios incluyen IGV?</span>
          <CheckBox
            name="ii"
            tabIndex={15}
            checked={cotizacion.ii}
            onChange={handleChange}
          />
        </div>
        <Texto
          name="pp"
          value={cotizacion.pp}
          label="Condicion de pago"
          size={4}
          restrict="int"
          tabIndex={16}
          onChange={handleChange}
        />
        <Combo
          name="dm"
          label="D&iacute;as / Meses"
          value={cotizacion.dm}
          tabIndex={17}
          size={4}
          data={[
            { value: '0', label: 'Días' },
            { value: '1', label: 'Meses' },
          ]}
          onChange={handleChange}
        />
        <Texto
          name="vc"
          value={cotizacion.vc}
          label="Vigencia d&iacute;as"
          size={2}
          restrict="int"
          tabIndex={18}
          onChange={handleChange}
        />
        <Texto
          name="re"
          value={cotizacion.re}
          label="Recepciona"
          size={2}
          tabIndex={19}
          onChange={handleChange}
        />
        <Texto
          name="ex"
          value={cotizacion.ex}
          label="Expira"
          size={2}
          tabIndex={21}
          disabled
          onChange={() => {}}
        />
        <TextArea
          name="rq"
          value={cotizacion.rq}
          label="Requerimiento"
          tabIndex={22}
          rows={3}
          onChange={handleChange}
          enabledPaste
          onBlur={(e) => {
            const text = e.target.value;
            handleChange({
              name: 'rq',
              value: removeStrangeCharacters(text),
            });
          }}
        />
        <TextArea
          name="nt"
          value={cotizacion.nt}
          label="Notas"
          tabIndex={23}
          rows={3}
          onChange={handleChange}
          enabledPaste
          onBlur={(e) => {
            const text = e.target.value;
            handleChange({
              name: 'nt',
              value: removeStrangeCharacters(text),
            });
          }}
        />
        <div className="op-form-group op-col-1">
          <div className="op-table" style={{ padding: 0, flexBasis: '100%' }}>
            <Tabla
              columns={columnsDetail}
              data={detail}
              searcheable={false}
              pagination={false}
              hoverSelectedRow={false}
              emptyRowsMessage="Ning&uacute;n detalle"
            />
          </div>
        </div>
      </Modal>
      {openReporte && (
        <Report
          url={`${servicios.COTIZACION}/${currentUser.ie}/base64`}
          method="post"
          data={{
            nm: cotizacion.co,
            rc: cotizacion.re,
            cl: cotizacion.cc.cl,
            tm: cotizacion.tm,
            pp: cotizacion.pp,
            dm: cotizacion.dm,
            vc: cotizacion.vc,
            ii: cotizacion.ii,
            rq: cotizacion.rq,
            nt: cotizacion.nt,
            dtl: detail.map((x) => ({
              ...x,
              pi: quitarFormatoMonetario(x.pi),
            })),
          }}
          extension={'pdf'}
          filename="Cotizaci&oacute;n"
          onClose={() => {
            setOpenReporte(false);
          }}
          onSucess={() => {
            getData();
          }}
        />
      )}
      {showServices && (
        <Busqueda
          serviceName={servicios.SERVICIO}
          title="B&uacute;squeda de servicios"
          columns={columnsServices((item) => handleAddService(item))}
          emptyRowsMessage="No existen servicios"
          onClose={() => setShowServices(false)}
          onDoubleClick={(item) => handleAddService(item)}
        />
      )}
      {showEquipments && (
        <Busqueda
          serviceName={servicios.EQUIPO}
          title="B&uacute;squeda de equipos"
          columns={columnsEquipments((item) => handleAddEquipment(item))}
          emptyRowsMessage="No existen equipos"
          params={{ cl: cotizacion.cc.cl }}
          onClose={() => setShowEquipments(false)}
          onDoubleClick={(item) => handleAddEquipment(item)}
        />
      )}
      {showCustom && (
        <Modal
          title={`${custom.ci ? 'Modificar' : 'Agregar'} detalle`}
          closeConfirmationMessage="La ventana de detalle"
          size="medium"
          onActions={() => (
            <Boton
              className="op-dinamic"
              icon={() => <AddIcon />}
              tabIndex={42}
              style={{ '--button-color': '#3285a8' }}
              onClick={() => {
                handleAddDetail({
                  ...custom,
                  ci: custom.ci ? custom.ci : new Date().getTime().toString(),
                });
              }}
            >
              Agregar detalle
            </Boton>
          )}
          onClose={() => setShowCustom(false)}
        >
          <TextArea
            name="di"
            value={custom.di}
            label="Descripci&oacute;n"
            tabIndex={40}
            rows={10}
            enabledPaste
            onChange={handleChangeCustom}
            onBlur={(e) => {
              const text = e.target.value;
              handleChangeCustom({
                name: 'di',
                value: removeStrangeCharacters(text),
              });
            }}
          />
          <Texto
            name="cu"
            value={custom.cu}
            label="Cantidad"
            tabIndex={41}
            restrict={'int'}
            enabledPaste
            size={2}
            onChange={handleChangeCustom}
          />
          <Texto
            name="pi"
            value={custom.pi}
            label="Precio"
            tabIndex={42}
            onChange={handleChangeCustom}
            restrict={'money'}
            size={2}
            onBlur={(e) => {
              handleChangeCustom({
                name: 'pi',
                value: formatoMonetario(redondeoDecimales(e.target.value)),
              });
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default CotizacionItem;
