import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { testeoModel } from '../../redux/models/testeo.model';
import { servicios } from '../../redux/helpers';
import Formulario from '../../commons/Formulario';

import TesteoFilter from './TesteoFilter';
import TesteoItem from './TesteoItem';
import TesteoList from './TesteoList';
import { TYPE_FILTER } from '../../redux/helpers/app.types';

import { getDetallesPorCabeceraAction } from '../../redux/actions/detalle.action';

const Testeo = () => {
  const { currentUser } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDetallesPorCabeceraAction({ ca: 'TIPODATO' }));
  }, []);

  const initialState = {
    params: {
      em: currentUser.ie,
      st: '',
    },
    serviceName: servicios.TESTEO,
    selected: testeoModel,
    errors: testeoModel,
    openModal: false,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case TYPE_FILTER:
        return {
          ...state,
          params: {
            ...state.params,
            st:
              action.payload.st !== undefined
                ? action.payload.st === ''
                  ? null
                  : action.payload.st
                : state.params.st,
          },
        };
      default:
        return state;
    }
  };
  return (
    <Formulario reducer={reducer} initialState={initialState}>
      <TesteoList />
      <TesteoFilter />
      <TesteoItem />
    </Formulario>
  );
};

export default Testeo;
