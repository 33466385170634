import { mensaje } from '../../commons/Mensajes';
import { api } from '../axios/api';
import { servicios, constantes } from '../helpers/index';
import { pedidoModel } from '../models/pedido.model';

export const PEDIDO_PROCESS = '[Pedido] Pedido procesando';
export const PEDIDO_SAVE_SUCCESS = '[Pedido] Pedido guardar exito';
export const PEDIDO_FAIL = '[Pedido] Pedido guardar error';
export const PEDIDO_SELECTED = '[Pedido] Pedido seleccionado';
export const PEDIDO_NEW = '[Pedido] Pedido nuevo';
export const PEDIDO_PICK = '[Pedido] Pedido recoger';
export const PEDIDO_LIST_SUCCESS = '[Pedido] Pedido lista exito';
export const PEDIDO_LIST_FAIL = '[Pedido] Pedido lista error';
export const PEDIDO_LIST_REPORT = '[Pedido] Pedido lista reporteria';
export const PEDIDO_FILTER = '[Pedido] Pedido filtros';
export const PEDIDO_PAGINATE = '[Pedido] Pedido paginado';
export const PEDIDO_CLOSE = '[Pedido] Pedido close';
export const PEDIDO_ADD_ARRIVED = '[Pedido] Pedido de notificacion';
export const PEDIDO_RESTARD_ARRIVED = '[Pedido] Pedido restar notificaciones';

export const getPedidosAction = () => (dispatch, getState) => {
  let { currentUser } = getState().app;
  let { ep, su, search, page, rows } = getState().pedido;

  dispatch({
    type: PEDIDO_PROCESS,
  });

  const params = {
    em: currentUser.ie,
    ie: currentUser.ie,
    ep,
    su: currentUser.nr === 'Vendedor' ? currentUser.is : su,
    search,
    page,
    pageSize: rows,
  };

  api
    .get(servicios.PEDIDO, { params })
    .then((response) => {
      dispatch({
        type: PEDIDO_LIST_SUCCESS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: PEDIDO_LIST_FAIL,
      });
    });
};

export const postNuevoPedidoAction =
  (item, detalle) => (dispatch, getState) => {
    let { currentUser } = getState().app;

    dispatch({
      type: PEDIDO_PROCESS,
    });

    let pedido = {
      ...item,
      ie: currentUser.ie,
      iu: currentUser.is,
      cl: currentUser.us,
      nr: currentUser.np,
      ap: constantes.LAVANDERIA,
      rr: 1,
      md: 0,
      fa: 0,
      det: detalle,
    };

    api
      .post(`${servicios.PEDIDO}`, pedido)
      .then(() => {
        mensaje('Operación exitosa');
        dispatch(getPedidosAction({}));
        dispatch({ type: PEDIDO_SAVE_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: PEDIDO_FAIL,
          payload: error.data.errors ? error.data.errors : pedidoModel,
        });
      });
  };

export const patchRecogerPedidoAction =
  (item, detalle) => (dispatch, getState) => {
    let { currentUser } = getState().app;

    dispatch({
      type: PEDIDO_PROCESS,
    });

    let pedido = {
      ...item,
      ie: item.em,
      nr: currentUser.np,
      det: detalle,
    };

    api
      .patch(`${servicios.PEDIDO}/recoger`, pedido)
      .then(() => {
        mensaje('Operación exitosa');
        dispatch(getPedidosAction({}));
        dispatch({ type: PEDIDO_SAVE_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: PEDIDO_FAIL,
          payload: error.data.errors ? error.data.errors : pedidoModel,
        });
      });
  };

export const patchRecepcionarPedidoAction =
  (item, detalle) => (dispatch, getState) => {
    let { currentUser } = getState().app;

    dispatch({
      type: PEDIDO_PROCESS,
    });

    let pedido = {
      ...item,
      ie: item.em,
      iu: currentUser.us,
      nu: currentUser.np,
      det: detalle,
    };

    api
      .patch(`${servicios.PEDIDO}/recepcionar`, pedido)
      .then(() => {
        mensaje('Operación exitosa');
        dispatch(getPedidosAction({}));
        dispatch({ type: PEDIDO_SAVE_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: PEDIDO_FAIL,
          payload: error.data.errors ? error.data.errors : pedidoModel,
        });
      });
  };

export const patchConfirmarPagoPedidoAction =
  (item) => (dispatch, getState) => {
    let { currentUser } = getState().app;

    dispatch({
      type: PEDIDO_PROCESS,
    });

    let pedido = {
      ...item,
      ie: currentUser.ie,
      iu: currentUser.us,
      nu: currentUser.np,
    };

    api
      .patch(`${servicios.PEDIDO}/pagar`, pedido)
      .then(() => {
        mensaje('Operación exitosa');
        dispatch(getPedidosAction({}));
        dispatch({ type: PEDIDO_SAVE_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: PEDIDO_FAIL,
          payload: error.data.errors ? error.data.errors : pedidoModel,
        });
      });
  };

export const patchCancelarPedidoAction =
  (item, motivo) => (dispatch, getState) => {
    let { currentUser } = getState().app;

    dispatch({
      type: PEDIDO_PROCESS,
    });

    let pedido = {
      ...item,
      ie: item.em,
      mc: motivo,
      tc: 0,
      nr: currentUser.np,
    };

    api
      .patch(`${servicios.PEDIDO}/cancelar`, pedido)
      .then(() => {
        mensaje('Operación exitosa');
        dispatch(getPedidosAction({}));
        dispatch({ type: PEDIDO_SAVE_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: PEDIDO_FAIL,
          payload: error.data.errors ? error.data.errors : pedidoModel,
        });
      });
  };

export const patchListoPedidoAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: PEDIDO_PROCESS,
  });

  let pedido = {
    ...item,
    ie: item.em,
    nr: currentUser.np,
  };

  api
    .patch(`${servicios.PEDIDO}/cambio`, pedido)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getPedidosAction({}));
      dispatch({ type: PEDIDO_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: PEDIDO_FAIL,
        payload: error.data.errors ? error.data.errors : pedidoModel,
      });
    });
};

export const patchAdjuntarImagenPedidoAction =
  (item, archivo) => (dispatch, getState) => {
    let { currentUser } = getState().app;

    dispatch({
      type: PEDIDO_PROCESS,
    });

    let form_data = new FormData();
    form_data.append('file', archivo);
    form_data.append('pd', item.pd);
    form_data.append('ie', currentUser.ie);
    form_data.append('iu', currentUser.us);

    api
      .patch(`${servicios.PEDIDO}/adjuntar`, form_data, {
        headers: { 'content-type': 'multipart/form-data; charset=utf-8' },
      })
      .then(() => {
        mensaje('Operación exitosa');
        dispatch(getPedidosAction());
        dispatch({ type: PEDIDO_SAVE_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: PEDIDO_FAIL,
          payload: error.data.errors ? error.data.errors : pedidoModel,
        });
      });
  };

export const getSeleccionarPedidoAction = (item) => (dispatch) => {
  if (item) {
    dispatch({
      type: PEDIDO_SELECTED,
      payload: item,
    });
  } else {
    dispatch({
      type: PEDIDO_NEW,
      payload: pedidoModel,
    });
  }
};

export const getSeleccionPorMapaPedidoAction = (item) => (dispatch) => {
  dispatch({
    type: PEDIDO_PICK,
    payload: item,
  });
};

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { su, em, ep, search } = getState().pedido;

  let item = {
    em: filtros.em !== undefined ? (filtros.em === '' ? null : filtros.em) : em,
    ep: filtros.ep !== undefined ? (filtros.ep === '' ? null : filtros.ep) : ep,
    su: filtros.su !== undefined ? (filtros.su === '' ? null : filtros.su) : su,
    search:
      filtros.search !== undefined
        ? filtros.search === ''
          ? null
          : filtros.search
        : search,
  };

  dispatch({
    type: PEDIDO_FILTER,
    payload: item,
  });
};

export const paginado = (page) => (dispatch) => {
  dispatch({
    type: PEDIDO_PAGINATE,
    payload: page,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: PEDIDO_CLOSE,
  });
};

export const getPedidosReporteAction = () => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: PEDIDO_PROCESS,
  });

  api
    .get(`${servicios.PEDIDO}/${currentUser.ie}/reporte`)
    .then((response) => {
      dispatch({
        type: PEDIDO_LIST_REPORT,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: PEDIDO_LIST_REPORT,
        payload: [],
      });
    });
};

export const addNotificacion = () => (dispatch) => {
  dispatch({
    type: PEDIDO_ADD_ARRIVED,
  });
};

export const restartNotificacion = () => (dispatch) => {
  dispatch({
    type: PEDIDO_RESTARD_ARRIVED,
  });
};
