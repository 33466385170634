import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Boton from '../../commons/Boton';
import Adjunto from '../../commons/Adjunto';
import { confirmacion } from '../../commons/Mensajes';
import {
  formatoMonetario,
  redondeoDecimales,
  formatoFecha,
} from '../../commons/Utilitarios';

import {
  closeModal,
  openModal,
  patchEliminarOperacionCajaChicaAction,
  patchAdjuntarOperacionCajaChicaAction,
} from '../../redux/actions/chica.operacion.action';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';

const ChicaOperacionView = ({ selected, processing, empresa }) => {
  const [operacion] = useState(selected);

  const dispatch = useDispatch();

  return (
    <>
      <Modal
        title={`Detalle operación ${formatoFecha(operacion.fo)}`}
        processing={processing}
        onActions={() =>
          operacion.st && (
            <>
              <Boton
                className="op-dinamic"
                disabled={processing}
                icon={() => <DeleteForeverIcon />}
                tabIndex={19}
                style={{ '--button-color': 'red' }}
                onClick={() =>
                  confirmacion(
                    '¿Está seguro de eliminar la operación?',
                    'Ya no podra revertir los cambios'
                  )
                    .then(() =>
                      dispatch(patchEliminarOperacionCajaChicaAction(operacion))
                    )
                    .catch(() => {})
                }
              >
                Eliminar operaci&oacute;n
              </Boton>
              <Boton
                className="op-grabar"
                disabled={processing}
                icon={() => <EditIcon />}
                tabIndex={21}
                onClick={() => dispatch(openModal(operacion))}
              >
                Rectificar operaci&oacute;n
              </Boton>
            </>
          )
        }
        onClose={() => dispatch(closeModal())}
      >
        <Texto
          name="dd"
          value={operacion.dd}
          label="Tipo documento (opcional)"
          size={2}
          tabIndex={11}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="nd"
          value={operacion.nd}
          label="N&uacute;mero documento (opcional)"
          size={2}
          tabIndex={11}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="rz"
          value={operacion.rz}
          label="Raz&oacute;n social (opcional)"
          size={2}
          tabIndex={12}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="rs"
          value={operacion.rs}
          label="Responsable (opcional)"
          size={2}
          tabIndex={13}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="co"
          value={operacion.co}
          label="Concepto"
          size={2}
          tabIndex={14}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="dc"
          value={operacion.dc}
          label="Tipo de caja"
          size={2}
          tabIndex={11}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="mo"
          value={formatoMonetario(redondeoDecimales(operacion.mo))}
          label={`Monto`}
          size={2}
          tabIndex={16}
          restrict={'money'}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="tm"
          value={operacion.tm === 0 ? 'Soles' : 'Dolares'}
          label="Tipo de moneda"
          size={2}
          tabIndex={17}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="ia"
          value={operacion.ia}
          label="Informaci&oacute;n adicional (opcional)"
          size={2}
          tabIndex={17}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="in"
          value={operacion.in === 0 ? 'Ingreso' : 'Salida'}
          label="Tipo de moneda"
          size={2}
          tabIndex={17}
          onChange={() => {}}
          disabled
        />
        {operacion.st === false && (
          <Texto
            name="st"
            value={'Eliminado'}
            label="Estado del registro"
            size={2}
            tabIndex={24}
            onChange={() => {}}
            disabled
            info="Las operaciones eliminadas no se consideran para c&aacute;lculo de saldos, y solo quedan como registro hist&oacute;rico"
          />
        )}
        <Adjunto
          stateParent={operacion.st}
          nameParent={'chica'}
          processing={processing}
          data={
            operacion.arc &&
            operacion.arc.dtl &&
            operacion.arc.dtl.map((a) => ({
              id: a.cr,
              name: a.na,
              extension: a.ea,
              state: a.st,
            }))
          }
          file={operacion.arc && operacion.arc.ar}
          onNew={(documento) =>
            dispatch(
              patchAdjuntarOperacionCajaChicaAction(operacion, documento)
            )
          }
        />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.chicaOperacion.selected,
    processing: state.chicaOperacion.processing,
  };
};

export default connect(mapStateToProps)(ChicaOperacionView);
