import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Boton from '../../commons/Boton';
import Tabla from '../../commons/Tabla';
import Icon from '../../commons/Icon';
import RadioButton from '../../commons/RadioButton';
import PackageOpen from '../../assets/icons/package-open.svg';
import PackageClose from '../../assets/icons/package-close.svg';

import { confirmacion } from '../../commons/Mensajes';

import {
  closeModal,
  patchConvertirInventarioAction,
} from '../../redux/actions/inventario.action';

import {
  getMaterialesAction,
  actualizarFiltros as actulizarMateriales,
} from '../../redux/actions/material.action';

import SaveIcon from '@material-ui/icons/Save';
import CheckIcon from '@material-ui/icons/Check';

const InventarioConvertir = ({
  materiales,
  selected,
  total,
  processing,
  processingList,
  errors,
  closeModal,
  patchConvertirInventarioAction,
  actulizarMateriales,
  getMaterialesAction,
}) => {
  const [inventario, setInventario] = useState(selected);
  const [openSearch, setOpenSearch] = useState(false);
  const [arrow, setArrow] = useState(true);

  useEffect(() => {
    actulizarMateriales({
      search: null,
      um: null,
      pr: null,
      st: '1',
    });
    getMaterialesAction({});
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setInventario((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const columns = [
    {
      key: 'mt',
      label: 'Enviar',
      align: 'center',
      content: (item) => (
        <div title="Enviar">
          <CheckIcon
            onClick={() => {
              handleChange({ name: 'md', value: item.mt });
              handleChange({ name: 'mc', value: item.nm });
              handleChange({ name: 'ud', value: item.du });
              setOpenSearch(false);
            }}
            style={{ color: 'green', cursor: 'pointer' }}
          />
        </div>
      ),
    },
    {
      key: 'cm',
      align: 'center',
      label: 'Código',
    },
    {
      key: 'nm',
      label: 'Producto / Insumo',
    },
    {
      key: 'du',
      label: 'Unidad de medida',
    },
  ];

  return (
    <>
      <Modal
        title={`Convertir - ${selected.nm}`}
        processing={processing}
        onActions={() => (
          <>
            <Boton
              className="op-grabar"
              disabled={processing}
              icon={() => <SaveIcon />}
              tabIndex={21}
              onClick={() =>
                confirmacion(
                  '¿Está seguro?',
                  `Convertir el producto o insumo de ${inventario.um.toLowerCase()} a ${
                    inventario.ud ? inventario.ud.toLowerCase() : ''
                  }`
                )
                  .then(() => {
                    actulizarMateriales({
                      search: null,
                      um: null,
                      pr: null,
                      st: null,
                    });
                    getMaterialesAction({});
                    patchConvertirInventarioAction(inventario);
                  })
                  .catch(() => {})
              }
            >
              Guardar
            </Boton>
          </>
        )}
        onClose={() => {
          actulizarMateriales({
            search: null,
            um: null,
            pr: null,
            st: null,
          });
          getMaterialesAction({});
          closeModal();
        }}
      >
        <Texto
          name="ns"
          value={inventario.ns}
          label="Sucursal"
          size={3}
          tabIndex={10}
          error={errors.ns}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="cm"
          value={inventario.cm}
          label="C&oacute;digo"
          size={3}
          tabIndex={11}
          error={errors.cm}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="um"
          value={inventario.um}
          label="Unidad de medida"
          size={3}
          tabIndex={12}
          error={errors.um}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="sd"
          value={inventario.sd}
          label="Stock disponible"
          size={3}
          tabIndex={13}
          error={errors.sd}
          onChange={() => {}}
          restrict={'int'}
          disabled
        />
        <Texto
          name="mc"
          value={inventario.mc}
          label="Producto / Insumo a convertir"
          size={3}
          tabIndex={14}
          error={errors.md}
          onChange={() => {}}
          search
          disabled
          onSearch={() => setOpenSearch(true)}
        />
        <Texto
          name="ud"
          value={inventario.ud}
          label="Unidad de medida a convertir"
          size={3}
          tabIndex={15}
          onChange={() => {}}
          disabled
        />
        <div className="op-form-group op-col-1">
          {inventario.md ? (
            <h2 style={{ textAlign: 'center' }}>
              Formula de conversi&oacute;n
            </h2>
          ) : (
            <h3 style={{ textAlign: 'center' }}>
              Seleccione producto o insumo a convertir
            </h3>
          )}
        </div>
        {inventario.md && (
          <>
            <div className="op-form-group op-col-3 op-checkbox-group">
              <RadioButton
                name="pk"
                data={[
                  {
                    name: 'unpackage',
                    label: 'Desempaquetar',
                    checked: arrow ? true : false,
                    icon: () => (
                      <Icon svg={PackageOpen} size={30} transparent />
                    ),
                  },
                  {
                    name: 'package',
                    label: 'Empaquetar',
                    checked: arrow ? false : true,
                    icon: () => (
                      <Icon svg={PackageClose} size={30} transparent />
                    ),
                  },
                ]}
                onChange={(item) => {
                  setArrow(item.value === 'unpackage');
                  if (item.value === 'unpackage') {
                    handleChange({ name: 'co', value: 1 });
                  } else {
                    handleChange({ name: 'cd', value: 1 });
                  }
                }}
              />
            </div>
            <div className="op-form-group op-col-3">
              <span>{`${
                arrow
                  ? 'Desempaquetar 1 ' + inventario.um.toLowerCase() + ' en'
                  : 'Empaquetar '
              } `}</span>
              <input
                type="text"
                style={{ width: '25px' }}
                tabIndex={16}
                onKeyPress={(e) => {
                  let re = new RegExp('[0-9]');
                  if (!re.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  const { value } = e.target;
                  if (arrow) {
                    handleChange({ name: 'co', value: 1 });
                    handleChange({ name: 'cd', value });
                  } else {
                    handleChange({ name: 'co', value });
                    handleChange({ name: 'cd', value: 1 });
                  }
                }}
                autoFocus
              ></input>
              <span>{` ${arrow ? '' : inventario.um}${
                inventario.ud && arrow ? inventario.ud.toLowerCase() : ''
              } ${arrow ? '' : 'en 1 ' + inventario.ud.toLowerCase()}`}</span>
            </div>
            <Texto
              name="cc"
              value={inventario.cc}
              label={`Cantidad a convertir`}
              size={3}
              tabIndex={17}
              onChange={handleChange}
              info={`Cantidad de ${inventario.um.toLowerCase()} a ${
                arrow ? 'desempaquetar' : 'empaquetar'
              }`}
            />
          </>
        )}
      </Modal>
      {openSearch && (
        <Modal
          title="B&uacute;squeda de producto / insumo"
          closePressEscape={false}
          onClose={() => setOpenSearch(false)}
        >
          <Tabla
            columns={columns}
            data={materiales}
            total={total}
            processing={processingList}
            onPagination={({ page, rows }) =>
              getMaterialesAction({ page, pageSize: rows })
            }
            onSearching={() => getMaterialesAction({})}
            onChangeSearching={(search) => actulizarMateriales({ search })}
            onDoubleClick={(item) => {
              handleChange({ name: 'md', value: item.mt });
              handleChange({ name: 'mc', value: item.nm });
              handleChange({ name: 'ud', value: item.du });
              setOpenSearch(false);
            }}
            style={{ flexBasis: '100%' }}
          />
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.inventario.selected,
    processing: state.inventario.processing,
    materiales: state.material.materiales,
    total: state.material.total,
    processingList: state.material.processing,
    errors: state.inventario.errors,
  };
};

export default connect(mapStateToProps, {
  closeModal,
  patchConvertirInventarioAction,
  actulizarMateriales,
  getMaterialesAction,
})(InventarioConvertir);
