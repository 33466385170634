import React from 'react';
import EditIcon from '@material-ui/icons/Edit';

import Bloque from '../../commons/Bloque';
import TablaBody from '../../commons/TablaBody';
import TablaSearch from '../../commons/TablaSearch';
import Paginacion from '../../commons/Paginacion';
import { useTablaFilter, useFormularioContext } from '../../commons/Formulario';
import { formatoFecha, cambiarAccionEfecto } from '../../commons/Utilitarios';

const SolicitudList = () => {
  const { dispatch } = useTablaFilter();
  const { openModalTask } = useFormularioContext();

  const columns = [
    {
      key: 'so',
      align: 'center',
      content: (item) => (
        <EditIcon
          onClick={() => dispatch({ type: 'EDIT_REQUEST', payload: item })}
          style={{ color: 'green', cursor: 'pointer' }}
        />
      ),
    },
    {
      key: 'cs',
      label: 'Código',
      content: (item) => handleColor(item, item.cs),
    },
    {
      key: 'ns',
      label: 'Sucursal',
      content: (item) => handleColor(item, item.ns),
    },
    {
      key: 'nv',
      label: 'Vendedor',
      content: (item) => handleColor(item, item.nv),
    },
    {
      key: 'fv',
      align: 'right',
      label: 'Visita técnica',
      content: (item) => <span>{formatoFecha(item.fv)}</span>,
    },
    {
      key: 'nt',
      label: 'Estado',
      content: (item) => handleColor(item, cambiarAccionEfecto(item.nt)),
    },
  ];

  const handleColor = (item, texto) => {
    let color = '#000000';
    switch (item.ep) {
      case 1:
        color = 'red';
        break;
      case 3:
        color = 'orange';
        break;
      case 0:
        color = item.ee.va === '1' ? 'green' : '#34baeb';
        break;
      case 2:
        color = '#1ab394';
        break;
      default:
        break;
    }

    return <span style={{ color: color }}>{texto}</span>;
  };

  return (
    <Bloque
      titulo="Solicitudes ordenes de compra"
      className={`half list ${openModalTask ? 'inactive' : ''}`}
    >
      <div className="op-table">
        <TablaSearch />
        <TablaBody columns={columns} />
        <Paginacion />
      </div>
    </Bloque>
  );
};

export default SolicitudList;
