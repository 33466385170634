import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';

import Bloque from '../../commons/Bloque';
import Boton from '../../commons/Boton';
import Calendario from '../../commons/Calendario';
import CheckBox from '../../commons/CheckBox';
import Texto from '../../commons/Texto';
import { useFormularioContext } from '../../commons/Formulario';
import { confirmacion } from '../../commons/Mensajes';

import { servicios } from '../../redux/helpers';
import { solicitudModel } from '../../redux/models/solicitud.model';
import { formatoFecha } from '../../commons/Utilitarios';

const SolicitudTestingsResult = ({ previus }) => {
  const { selected, accionTask, callTransaction } = useFormularioContext();
  const { currentUser } = useSelector((state) => state.app);
  const [testings, setTestings] = useState([]);
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (selected.qq) {
      setTestings(selected.qq.filter((f) => f.ts != null));
      let res = [];
      selected.qq
        .filter((f) => f.ts != null)
        .filter((f) => {
          let a = previus
            .filter((x) => x.ts.length > 0)
            .find((x) => x.eq.ce === f.eq.ce);
          return a === undefined;
        })
        .map((t) => {
          t.ts.map((x) => {
            x.lt
              .filter((f) => f.st === true)
              .map((y) => {
                res.push({
                  eq: t.eq.eq,
                  te: x.te,
                  tp: x.nt,
                  co: y.co,
                  nt: y.nt,
                  td: y.td,
                  rs: '',
                });
              });
          });
        });
      setResults(res);
    }
  }, [selected.so, previus]);

  const handleType = (tp) => {
    switch (tp) {
      case '02':
        return 'int';
      case '03':
        return 'money';
      default:
        return 'texto';
    }
  };

  const handleChange = (c, v) => {
    setResults(results.map((x) => (x.co === c ? { ...x, rs: v } : x)));
  };

  const handleGet = (testeo) => {
    switch (testeo.td.vm) {
      case '01':
      case '02':
      case '03':
        return (
          <div key={testeo.co} className="op-detail-result">
            <span>{testeo.nt}</span>
            <Texto
              label={'Resultado'}
              name={testeo.nt}
              tabIndex={80}
              value={results.find((x) => x.co === testeo.co).rs}
              restrict={handleType(testeo.td.vm)}
              onChange={({ value }) => handleChange(testeo.co, value)}
            />
          </div>
        );
      case '04':
      case '05':
        return (
          <div key={testeo.co} className="op-detail-result">
            <span>{testeo.nt}</span>
            <Calendario
              label={'Resultado'}
              value={results.find((x) => x.co === testeo.co).rs}
              showTime={testeo.td.vm === '05'}
              onSelected={({ value }) => handleChange(testeo.co, value)}
            />
          </div>
        );
      case '09':
        return (
          <div key={testeo.co} className="op-detail-result">
            <span>{testeo.nt}</span>
            <CheckBox
              name={testeo.co}
              tabIndex={90}
              checked={
                results.find((x) => x.co === testeo.co).rs ? true : false
              }
              onChange={({ value }) => handleChange(testeo.co, value)}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const handleSaveResults = (eq) => {
    let request = {
      ie: currentUser.ie,
      ua: currentUser.us,
      eq: eq,
      ia: accionTask,
      lr: results
        .filter((f) => f.eq === eq)
        .map((x) =>
          ['04', '05'].includes(x.td.vm)
            ? { ...x, rs: formatoFecha(x.rs, '', x.td.vm === '05') }
            : x.td.vm === '09' && x.rs === ''
            ? 'false'
            : x
        ),
    };
    callTransaction({
      method: 'post',
      url: `${servicios.MANTENIMIENTO}/testeos`,
      data: request,
      model: solicitudModel,
      successfulAction: { type: 'SUCCESS_TASK' },
    });
  };

  return (
    <div className="detail-equipments">
      {testings?.map((m) => (
        <React.Fragment key={m.eq.eq}>
          <div className="title">
            <h3>{`Testeos: ${m.eq.ce} - ${m.eq.ne}`}</h3>
            {previus
              .filter((x) => x.ts.length > 0)
              .find((x) => x.eq.ce === m.eq.ce) === undefined ? (
              <Boton
                className="op-grabar"
                icon={() => <SaveIcon />}
                onClick={() =>
                  confirmacion(
                    '¿Está seguro?',
                    `De registrar los resultados de testeos de ${m.eq.ne}\nRecuerde que ya no podra volver a enviar esta información`
                  )
                    .then(() => handleSaveResults(m.eq.eq))
                    .catch(() => {})
                }
              >
                Registrar resultados
              </Boton>
            ) : (
              <div></div>
            )}
          </div>
          {m.ts.map((x) => (
            <Bloque key={x.te} titulo={`${x.ct} - ${x.nt}`}>
              <div className="op-detail-contenedor">
                {results
                  .filter((f) => f.eq === m.eq.eq && f.te === x.te)
                  .map((p) => handleGet(p))}
                {previus
                  .filter((f) => f.eq.ce === m.eq.ce)
                  .map((p) =>
                    p.ts
                      .filter((y) => y.tp === x.nt)
                      .map((r) => (
                        <div key={r.co} className="op-detail-result">
                          <span>{r.nt}</span>
                          <span>{r.rs}</span>
                        </div>
                      ))
                  )}
              </div>
            </Bloque>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};

export default SolicitudTestingsResult;
