import React, { useState } from 'react';
import { api } from '../redux/axios/api';
import { servicios } from '../redux/helpers';

const useUsuario = () => {
  const [processingUser, setProcessingUser] = useState(false);
  const [users, setUsers] = useState([]);

  const getUsersByStore = (is) => {
    setProcessingUser(true);
    api
      .get(`${servicios.USUARIO}/${is}/sucursal`)
      .then((response) => setUsers(response))
      .catch((error) => console.log(error.data))
      .finally(() => setProcessingUser(false));
  };
  return {
    processingUser,
    users,
    getUsersByStore,
  };
};

export default useUsuario;
