import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import Swal from 'sweetalert2';

import Bloque from '../../commons/Bloque';
import Boton from '../../commons/Boton';
import Icon from '../../commons/Icon';
import Loading from '../../commons/Loading';

import { useFormularioContext } from '../../commons/Formulario';
import { confirmacion, mensajeMini } from '../../commons/Mensajes';

import SolicitudEquipment from './SolicitudEquipment';
import SolicitudHandBook from './SolicitudHandBook';
import SolicitudProcesses from './SolicitudProcesses';
import SolicitudTecnicians from './SolicitudTecnicians';
import SolicitudTestings from './SolicitudTestings';
import SolicitudSupply from './SolicitudSupply';

import { solicitudModel } from '../../redux/models/solicitud.model';
import useSolicitud from '../../hooks/useSolicitud';

import AprobarFile from '../../assets/icons/file-edit.svg';
import RequestIcon from '../../assets/icons/file-request.svg';
import BookIcon from '../../assets/icons/book.svg';
import TestIcon from '../../assets/icons/test-tube.svg';
import EngineerIcon from '../../assets/icons/engineer.svg';
import CancelFile from '../../assets/icons/file-cancel.svg';
import ConfirmarFile from '../../assets/icons/file-confirm.svg';
import SuppliesIcon from '../../assets/icons/supplies.svg';
import { servicios } from '../../redux/helpers';
import IconRequest from '../../commons/IconRequest';
import {
  cambiarAccionEfecto,
  formatoFecha,
  getColorProcess,
} from '../../commons/Utilitarios';

const SolicitudDetail = () => {
  const {
    selected,
    processing,
    dispatch,
    equipment,
    errors,
    openModalEdit,
    openModalTask,
    callTransaction,
    cancelTransaction,
  } = useFormularioContext();
  const { processSolicitud, requestComplement, getComplementRequest } =
    useSolicitud();
  const [solicitud, setSolicitud] = useState(selected);
  const { currentUser } = useSelector((state) => state.app);
  const [childSelected, setChildSelected] = useState(0);
  const [botones, setBotones] = useState([]);

  useEffect(() => {
    if (requestComplement) {
      dispatch({ type: 'SELECTED_EQUIPMENT', payload: null });
      setChildSelected(0);
      setBotones(
        requestComplement.act.map((x) => ({
          pe: x.pe,
          na: x.na,
          de: x.de,
          ce: x.ce,
          ep: x.ep,
          co: x.ep === 1 ? 'red' : x.ep === 3 ? 'orange' : '#1ab394',
        }))
      );
    }
  }, [requestComplement]);

  useEffect(() => {
    setSolicitud(selected);
    if (selected.so && openModalEdit) {
      getComplementRequest(selected.so);
    }
  }, [selected.so, openModalEdit]);

  if (!openModalEdit) {
    return null;
  }

  const getIcon = (ep) => {
    switch (ep) {
      case 1:
        return <Icon svg={CancelFile} color="red" />;
      case 3:
        return <Icon svg={AprobarFile} color="orange" />;
      default:
        return <Icon svg={ConfirmarFile} color="green" />;
    }
  };

  const handleTemplateByAccion = (item) => {
    if (item.ep == 1 || item.ep == 3) {
      Swal.fire({
        input: 'textarea',
        inputLabel: `${item.na} del proceso de  ${item.de.toLowerCase()}`,
        inputPlaceholder: 'Ingrese el motivo ...',
        inputAttributes: {
          'aria-label': 'Ingrese el motivo',
          style: { resize: 'none' },
        },
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirmar',
        cancelButtonColor: 'red',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          confirmacion('¿Está seguro?', `De ${item.na.toLowerCase()}`)
            .then(() => {
              let request = {
                so: solicitud.so,
                ie: currentUser.ie,
                ua: currentUser.us,
                mc: result.value,
                ia: item.pe,
              };
              callTransaction({
                method: 'patch',
                url: `${servicios.SOLICITUD}/proceso`,
                data: request,
                model: solicitudModel,
              });
            })
            .catch(() => {});
        } else {
          mensajeMini('Agrege motivo');
        }
      });
    } else if (item.ep === 2) {
      confirmacion(
        `¿Está seguro?`,
        `De Iniciar el mantenimiento de la solicitud ${solicitud.cs}`
      )
        .then(() => {
          dispatch({ type: 'START_TASK', payload: item });
        })
        .catch(() => {});
    } else {
      confirmacion(
        `¿Deseas ${item.na.toLowerCase()}?`,
        `Del proceso de  ${item.de.toLowerCase()}`
      )
        .then(() => {
          let request = {
            so: solicitud.so,
            ie: currentUser.ie,
            ua: currentUser.us,
            ia: item.pe,
          };
          callTransaction({
            method: 'patch',
            url: `${servicios.SOLICITUD}/proceso`,
            data: request,
            model: solicitudModel,
          });
        })
        .catch(() => {});
    }
  };

  const handleSelectedChild = (child) => {
    if (solicitud.ee.va === '1') {
      mensajeMini('Debe aprobar la solicitud');
    } else if (equipment) {
      setChildSelected(child);
    } else {
      mensajeMini('Seleccione equipo');
    }
  };

  return (
    <>
      {(processSolicitud || processing) && <Loading tipo={'request'} />}
      <Bloque
        titulo={`Solicitud - ${solicitud.cs}`}
        className={`half detail ${openModalTask ? 'inactive' : ''}`}
        hideArrowButton
        onActions={() => (
          <Boton
            className="op-cerrar"
            icon={() => <CloseIcon />}
            onClick={() => cancelTransaction(solicitudModel)}
          >
            Cerrar
          </Boton>
        )}
      >
        <div className="op-form" style={{ padding: '20px' }}>
          <div className="op-detail-container">
            <div
              className={`op-detail-icon ${
                childSelected === 0 ? 'active' : ''
              }`}
              onClick={() => setChildSelected(0)}
            >
              <Icon svg={RequestIcon} size={50} transparent />
              <span>Solicitud</span>
            </div>
            <div
              className={`op-detail-icon ${
                childSelected === 1 ? 'active' : ''
              }`}
              onClick={() => {
                if (solicitud.ep === 1) {
                  mensajeMini('Solicitud cancelada');
                } else {
                  setChildSelected(1);
                }
              }}
            >
              <Icon svg={EngineerIcon} size={50} transparent />
              <span>T&eacute;cnicos</span>
            </div>
            <div
              className={`op-detail-icon ${
                childSelected === 2 ? 'active' : ''
              }`}
              onClick={() => handleSelectedChild(2)}
            >
              <Icon svg={BookIcon} size={50} transparent />
              <span>Manuales</span>
            </div>
            {/*<div
              className={`op-detail-icon ${
                childSelected === 3 ? 'active' : ''
              }`}
              onClick={() => handleSelectedChild(3)}
            >
              <Icon svg={ProcessIcon} size={50} transparent />
              <span>Procesamientos</span>
            </div>*/}
            <div
              className={`op-detail-icon ${
                childSelected === 4 ? 'active' : ''
              }`}
              onClick={() => handleSelectedChild(4)}
            >
              <Icon svg={TestIcon} size={50} transparent />
              <span>Testeos</span>
            </div>
            <div
              className={`op-detail-icon ${
                childSelected === 5 ? 'active' : ''
              }`}
              onClick={() => {
                if (solicitud.ee.va === '1' && solicitud.ep === 0) {
                  mensajeMini('Debe aprobar la solicitud');
                } else {
                  setChildSelected(5);
                }
              }}
            >
              <Icon svg={SuppliesIcon} size={50} transparent />
              <span>Insumos</span>
            </div>
            <div className="op-detail-main">
              {childSelected === 0 && (
                <SolicitudEquipment
                  onActions={() => (
                    <>
                      {botones
                        .filter((x) => x.ep === 1 || x.ep === 2)
                        .map((x, i) => (
                          <Boton
                            key={x.pe}
                            className="op-dinamic"
                            disabled={processing}
                            icon={() => getIcon(x.ep)}
                            style={{ '--button-color': x.co }}
                            tabIndex={21 + i}
                            onClick={() => handleTemplateByAccion(x)}
                          >
                            {x.na}
                          </Boton>
                        ))}
                    </>
                  )}
                  bitacora={() => (
                    <>
                      {requestComplement?.bit?.map((x, i) => (
                        <div key={x.co} className="group">
                          <IconRequest
                            process={x.ep}
                            state={x.or}
                            image={x.im}
                          />
                          <div>
                            <div>
                              <small>{formatoFecha(x.fa)}</small>
                            </div>
                            <div
                              style={{
                                color: getColorProcess(x.ep, x.or),
                              }}
                            >
                              <strong>{cambiarAccionEfecto(x.na)}</strong>
                            </div>
                            <div>
                              <strong>{x.rs}</strong>
                            </div>
                          </div>
                          {i > 0 && (
                            <div
                              className="lineStatus"
                              style={{
                                borderLeftColor: getColorProcess(x.ep, x.or),
                              }}
                            ></div>
                          )}
                        </div>
                      ))}
                      {requestComplement?.act
                        ?.filter((x) => x.ep === 0 || x.ep === 2)
                        .map((x) => (
                          <div key={x.pe} className="group">
                            <IconRequest
                              process={x.ep}
                              state={'4'}
                              image={x.im}
                              off
                            />
                            <div>
                              <div>{'Pendiente'}</div>
                              <div>{x.na}</div>
                            </div>
                            <div
                              className="lineStatus"
                              style={{ borderLeftStyle: 'dotted' }}
                            ></div>
                          </div>
                        ))}
                    </>
                  )}
                />
              )}
              {errors.mc && (
                <span className="op-error">{`Motivo: ${errors.mc}`}</span>
              )}
              {childSelected === 1 && (
                <SolicitudTecnicians
                  onActions={botones.filter((x) => x.ep === 0 || x.ep === 3)}
                />
              )}
              {childSelected === 2 && <SolicitudHandBook />}
              {childSelected === 3 && <SolicitudProcesses />}
              {childSelected === 4 && <SolicitudTestings />}
              {childSelected === 5 && <SolicitudSupply />}
            </div>
          </div>
        </div>
      </Bloque>
    </>
  );
};

export default SolicitudDetail;
