import React from 'react';
import { useSelector } from 'react-redux';

import { equipoModel } from '../../redux/models/equipo.model';
import { servicios } from '../../redux/helpers';
import Formulario, { actions } from '../../commons/Formulario';

import EquipoFilter from './EquipoFilter';
import EquipoItem from './EquipoItem';
import EquipoList from './EquipoList';
import EquipoPreconfig from './EquipoPreconfig';
import { TYPE_FILTER } from '../../redux/helpers/app.types';

import '../../css/components/equipo.css';

const Equipo = () => {
  const { currentUser } = useSelector((state) => state.app);

  const initialState = {
    params: {
      em: currentUser.ie,
      cl: '',
      st: '',
    },
    serviceName: servicios.EQUIPO,
    viewContent: 'op-view-equipo',
    selected: equipoModel,
    errors: equipoModel,
    openModal: false,
    openModalPre: false,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case TYPE_FILTER:
        return {
          ...state,
          params: {
            ...state.params,
            cl:
              action.payload.cl !== undefined
                ? action.payload.cl === ''
                  ? null
                  : action.payload.cl
                : state.params.cl,
            st:
              action.payload.st !== undefined
                ? action.payload.st === ''
                  ? null
                  : action.payload.st
                : state.params.st,
          },
        };
      case 'EQUIPO_PRECONFIG':
        return {
          ...state,
          openModalPre: true,
          selected: action.payload,
          errors: equipoModel,
        };
      case actions.TRANSACTION_CANCEL:
        return {
          ...state,
          openModalPre: false,
        };
      default:
        return state;
    }
  };
  return (
    <Formulario reducer={reducer} initialState={initialState}>
      <EquipoList />
      <EquipoFilter />
      <EquipoItem />
      <EquipoPreconfig />
    </Formulario>
  );
};

export default Equipo;
