import { useState } from 'react';
import { useSelector } from 'react-redux';
import { formatearFecha } from '../commons/Utilitarios';
import { api } from '../redux/axios/api';
import { servicios } from '../redux/helpers';

const useSolicitud = () => {
  const { currentUser } = useSelector((state) => state.app);
  const [processSolicitud, setProcessSolicitud] = useState(false);
  const [requestComplement, setRequestComplement] = useState(null);
  const [requests, setRequests] = useState([]);

  const getComplementRequest = (so) => {
    setProcessSolicitud(true);
    api
      .get(`${servicios.SOLICITUD}/${so}/usuario/${currentUser.us}`, {
        params: {
          em: currentUser.ie,
        },
      })
      .then((response) => setRequestComplement(response))
      .catch((error) => console.log(error.data))
      .finally(() => setProcessSolicitud(false));
  };

  const getAssignedRequest = (fv) => {
    setProcessSolicitud(true);
    api
      .get(`${servicios.SOLICITUD}/asignados`, {
        params: {
          em: currentUser.ie,
          iu: currentUser.us,
          fv: formatearFecha(fv, 'YYYY-MM-DD'),
        },
      })
      .then((response) => setRequests(response))
      .catch((error) => console.log(error.data))
      .finally(() => setProcessSolicitud(false));
  };

  return {
    processSolicitud,
    requestComplement,
    requests,
    getComplementRequest,
    getAssignedRequest,
  };
};

export default useSolicitud;
